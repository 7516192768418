import { isEmpty } from 'lodash';

import { SortFilter } from './sort-filter';

export const defaultPageSize = 20;

export interface BaseQuery<T> {
  page: number;
  size: number;
  sort?: SortFilter<T>;
  maxReached?: boolean;
  textSearch?: string;
}

export function initializeQueries<T>(sort?: SortFilter<T>): BaseQuery<T> {
  return {
    page: 0,
    size: defaultPageSize,
    sort: sort && !isEmpty(sort.key) ? sort : undefined,
    maxReached: false,
  };
}
