/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeleteFormLink } from './deleteFormLink';
import { DocumentFlowSummaryResponseDTO } from './documentFlowSummaryResponseDTO';
import { FlowLinkResponseDTO } from './flowLinkResponseDTO';
import { TechnicalObjectSummaryResponseDTO } from './technicalObjectSummaryResponseDTO';

export interface FlowDeleteResponseDTO {
  success: boolean;
  natureLink: FlowDeleteResponseDTO.NatureLinkEnum;
  flowLinks: Array<FlowLinkResponseDTO>;
  documentFlowLinks: Array<DocumentFlowSummaryResponseDTO>;
  stepLinks: Array<DeleteFormLink>;
  technicalObjectLinks: Array<TechnicalObjectSummaryResponseDTO>;
}
export namespace FlowDeleteResponseDTO {
  export type NatureLinkEnum = 'STEP_LINK' | 'FLOW_LINK' | 'DOCUMENT_FLOW_LINK' | 'FLOW_CONTENT_LINK' | 'FLOW_HISTORIC_LINK' | 'TECHNIAL_OBJECT_LINK';
  export const NatureLinkEnum = {
    STEPLINK: 'STEP_LINK' as NatureLinkEnum,
    FLOWLINK: 'FLOW_LINK' as NatureLinkEnum,
    DOCUMENTFLOWLINK: 'DOCUMENT_FLOW_LINK' as NatureLinkEnum,
    FLOWCONTENTLINK: 'FLOW_CONTENT_LINK' as NatureLinkEnum,
    FLOWHISTORICLINK: 'FLOW_HISTORIC_LINK' as NatureLinkEnum,
    TECHNIALOBJECTLINK: 'TECHNIAL_OBJECT_LINK' as NatureLinkEnum,
  };
}
