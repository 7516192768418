import {
  BlocFormResponseSummaryResponseDTO,
  FormDetailsResponseDTO,
  GetOrphansFormResponsesRequestDTO,
  PageBlocFormResponseRequestDTO,
  RequestOrEntityUuidsRequestDTOPageFormResponseRequestDTO,
  ResponseDetailsResponseDTO,
} from '../../generated/models/models';
import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';
import { DateFilterData } from '../utils/date-filter-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { FormSummaryData } from '../form-builder/form-summary';

import { InnerSortDirectionEnum, ResponseProvenanceEnum, ResponseStateEnum } from '../../enums';

export interface BlockFormResponseSummaryData {
  uuidEntity: string;
  reference: string;
  responseDate: string;
  createDate: string;
  customStatus: CustomStatusLightData;
  state: ResponseStateEnum;
  indice: string;
  provenance: ResponseProvenanceEnum;
  form: FormSummaryData;
  formBusinessLinkUuidEntity: string;
  frozen: boolean;
  uuidType: string;
}

export namespace BlockFormResponseSummaryData {
  const sortFields = ['provenance', 'reference', 'customStatus', 'index', 'dateResponse'];
  export interface QueryRequest extends BaseQuery<BlockFormResponseSummaryData> {
    provenance: ResponseProvenanceEnum;
    state: ResponseStateEnum;
    statuses: string[];
    dateFilter: DateFilterData;
    flowUuidEntity?: string;
    formUuidEntity?: string;
    projectUuidEntity?: string;
  }

  export function mapFromApiValue(response: BlocFormResponseSummaryResponseDTO): BlockFormResponseSummaryData {
    const customStatus = response.customStatus && CustomStatusLightData.mapFromApiValue(response.customStatus);

    return {
      uuidEntity: response.uuidEntity,
      reference: response.reference,
      responseDate: DateUtils.toDateFormat(response.responseDate),
      createDate: DateUtils.toDateFormat(response.createDate),
      indice: response.indice,
      customStatus,
      state: ResponseStateEnum.convertFromApiValue.getValue(response.state),
      provenance: ResponseProvenanceEnum.convertFromApiValue.getValue(response.provenance),
      form: response.form && FormSummaryData.mapFromApiValue(response.form),
      formBusinessLinkUuidEntity: response.formBusinessLinkUuidEntity,
      frozen: !customStatus?.exportable,
      uuidType: response.uuidType,
    };
  }

  export function mapFromResponseDetails(response: FormDetailsResponseDTO): BlockFormResponseSummaryData {
    const customStatus = response.customStatus && CustomStatusLightData.mapFromApiValue(response.customStatus);

    return {
      uuidEntity: response.uuidEntity,
      reference: response.referenceResponse,
      responseDate: DateUtils.toDateFormat(response.dateResponse),
      createDate: DateUtils.toDateFormat(response.createDate),
      indice: response.indice,
      customStatus,
      provenance: ResponseProvenanceEnum.convertFromApiValue.getValue(response.responseProvenance),
      state: null,
      form: null,
      formBusinessLinkUuidEntity: null,
      frozen: false,
      uuidType: response.uuidType,
    };
  }

  export function mapToExportFormResponseRequestDTO(
    responses: BlockFormResponseSummaryData[],
    onlyExportableValues: boolean,
    customFileUuidEntity?: string,
  ): RequestOrEntityUuidsRequestDTOPageFormResponseRequestDTO {
    return {
      request: undefined,
      entityUuids: responses.map(res => res.uuidEntity),
      customFileUuidEntity: customFileUuidEntity,
      onlyExportableValues: onlyExportableValues,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageBlocFormResponseRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      provenance: (ResponseProvenanceEnum.convertToApiValue.getValue(queries.provenance) as ResponseDetailsResponseDTO.ProvenanceEnum) || undefined,
      state: ResponseStateEnum.convertToApiValue.getValue(queries.state) || undefined,
      customStatusesUuidEntity: (queries.statuses || []).length ? queries.statuses : undefined,
      flowUuidEntity: queries.flowUuidEntity || undefined,
      textSearch: queries.textSearch || undefined,
      dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
      projectUuidEntity: queries.projectUuidEntity,
      formBusinessLinkUuidEntity: queries.formUuidEntity,
    };
  }

  export function mapGetOrphansFormResponsesRequestDTOApiValue(queries: QueryRequest): GetOrphansFormResponsesRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      provenance: (ResponseProvenanceEnum.convertToApiValue.getValue(queries.provenance) as ResponseDetailsResponseDTO.ProvenanceEnum) || undefined,
      state: ResponseStateEnum.convertToApiValue.getValue(queries.state) || undefined,
      customStatusesUuidEntity: (queries.statuses || []).length ? queries.statuses : undefined,
      flowUuidEntity: queries.flowUuidEntity || undefined,
      textSearch: queries.textSearch || undefined,
      dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
    };
  }

  export function initializeQueryRequest(
    provenance: string,
    state: string,
    statuses: string[],
    searchText: string,
    sort: SortFilter<BlockFormResponseSummaryData>,
    dateFilter: DateFilterData,
  ): QueryRequest {
    return {
      ...initializeQueries<BlockFormResponseSummaryData>(sort),
      textSearch: searchText || undefined,
      provenance: ResponseProvenanceEnum.convertFromParamsValue.getValue(provenance) || undefined,
      state: ResponseStateEnum.convertFromParamsValue.getValue(state) || undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      dateFilter: dateFilter || undefined,
    };
  }
}
