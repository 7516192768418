import { SfxMap } from '../utils/enum-utils';
import { UserRightEnum } from './user-right.enum';
import { UserRoleEnum } from './user-role.enum';
import { RouteIdEnum } from './router-enums/route.enum';

export type permissionType = (UserRightEnum | UserRoleEnum)[];
export type sfxPermissionObject = SfxMap<RouteIdEnum, SfxMap<PermissionActionEnum, permissionType>>;

export enum PermissionActionEnum {
  Read = 'read',
  Add = 'add',
  Edit = 'edit',
  Duplicate = 'duplicate',
  Delete = 'delete',
  Publish = 'publish',
  Export = 'export',
  Import = 'import',
  Upload = 'upload',
  Download = 'download',
  Restore = 'restore',
  Unlock = 'unlock',
  EditStatus = 'editStatus',
  EditRef = 'editRef',
  ExecuteFlowRef = 'executeFlowRef',
  Unlink = 'unlink',
  ControlDiffusion = 'controlDiffusion',
  MultiPr = 'multiPr',
  Pr = 'Pr',
  DeleteSignature = 'deleteSignature',
  View = 'view',
  Share = 'share',
  Archive = 'archive',
  GenerateDocument = 'generateDocument',
  EditFlowContributors = 'editFlowContributors',
  Deactivate = 'deactivate',
  Navigate = 'navigate',
  Guide = 'guide',
  Reset = 'reset',
}

export interface PermissionObject {
  [key: string]: permissionType;
}

export namespace PermissionActionEnum {
  /* Settings Account */

  export const settingsModule = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);
  /* Settings Account */

  export const accountPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Settings Client */

  export const clientPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const clientModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Settings Companies */
  export const companiesPage = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal]]]);

  export const companiesModal = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal]]]);

  /* Settings Families */

  export const familyPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Archive, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const familyModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Settings Categories */

  export const categoryPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Archive, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const categoryModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Settings custom status */

  export const customStatusPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const customStatusModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Settings references */

  export const referencePage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const referenceModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Settings customField (attributes) */

  export const customFieldPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const customFieldModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Settings custom file */

  export const customFilePage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const customFileModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Settings Custom cell  */

  export const customCellPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const customCellModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Settings folder (section)  */

  export const folderPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const folderModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Settings Trash */

  export const trashPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Settings general */

  export const generalPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow]],
  ]);

  /* Settings Trams */

  export const tramPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow]],
  ]);

  export const tramModal = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  /* Settings Backups */

  export const backupPage = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  /* Settings LogsPush */

  export const logsPush = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  /* settings general organization */
  export const settingsGeneralOrganization = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  /* Settings KPI */

  export const kpiPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Projects */

  export const projectPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
    [PermissionActionEnum.Publish, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
  ]);

  export const projectModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
  ]);

  export const addProjectModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.NormalUser, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor],
    ],
  ]);

  export const fileProcessingAndPgacModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Library module */
  export const libraryModule = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser, UserRightEnum.UserContractor],
    ],
  ]);

  /* Library Risks */
  export const riskPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Duplicate, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Archive, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const riskModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Upload, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Import, [UserRightEnum.Any, UserRoleEnum.NotContractor]],
  ]);

  export const editRiskModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  export const viewRisk = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
  ]);

  /* Library Equipments */

  export const equipmentPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Duplicate, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Archive, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const equipmentModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Upload, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Import, [UserRightEnum.Any, UserRoleEnum.NotContractor]],
  ]);

  export const editEquipmentModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  export const viewEquipmentModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
  ]);

  /* Library Documents */

  export const documentPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.ControlDiffusion, [UserRightEnum.AdminSiteFlow, UserRightEnum.UserManageDiffusion]],
  ]);

  export const documentModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Library Forms */

  export const formPage = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser, UserRightEnum.UserContractor],
    ],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor]],
    [PermissionActionEnum.Duplicate, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor]],
    [PermissionActionEnum.Export, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserContractor]],
    [PermissionActionEnum.Archive, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserContractor]],
  ]);

  export const libraryFormModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Add, [UserRightEnum.AdminSiteFlow, UserRightEnum.UserGenericForm, UserRightEnum.UserModelForm]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.UserGenericForm, UserRightEnum.UserModelForm]],
  ]);

  /** Form Local Modal */
  export const formLocalModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminApp,
        UserRightEnum.AdminAppGlobal,
        UserRightEnum.UserAdvanced,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRoleEnum.Writer,
      ],
    ],
  ]);

  /* Library Objects */

  export const objectPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const objectModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  export const generateFlowModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
  ]);

  /* Library Tags */

  export const tagsPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const tagModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const linkFlowModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
  ]);

  /* Contributor module */

  export const contributorModule = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
  ]);

  /* Contributor -> Users */

  export const userPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Download, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Export, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Restore, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Unlock, [UserRightEnum.AdminSiteFlow]],
  ]);

  export const userModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  export const moveApplicabilityModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal]],
  ]);

  export const userSkillsModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Contributor -> external users */

  export const externalUsersPage = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  export const externalUsersModal = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  /* Contributor -> Operators */

  export const operatorPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Download, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Export, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Restore, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const operatorModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  export const operatorSkillsModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Contributors -> Tablets */

  export const tabletPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.View, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
    [PermissionActionEnum.Unlock, [UserRightEnum.AdminSiteFlow]],
  ]);

  export const tabletModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* External API */

  export const externalApiPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow]],
  ]);

  export const externalApiModal = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);
  export const externalApiV2Modal = new SfxMap<PermissionActionEnum, permissionType>([[PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow]]]);

  /* Dashboard -> Flow -> List vue */

  export const flowVueListPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Expert, UserRoleEnum.Responsible]],
    [
      PermissionActionEnum.EditStatus,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.EditFlowContributors, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
  ]);

  export const flowListVueModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.EditFlowContributors, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
  ]);

  /* Dashboard ->Flow -> Plan */

  export const flowVuePlanPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
    [PermissionActionEnum.Unlink, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  /* Dashboard -> Document */

  export const dashDocumentPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Delete,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
    [PermissionActionEnum.ControlDiffusion, [UserRightEnum.AdminSiteFlow, UserRightEnum.UserManageDiffusion]],
  ]);

  export const dashDocumentModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.EditRef, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  export const dashDocumentImportModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
  ]);

  /* Dashboard -> Form */

  export const dashFormPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Delete,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
    [PermissionActionEnum.Add, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [
      PermissionActionEnum.Share,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
  ]);

  export const dashFromImportModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  export const dashFromResponseModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminApp,
        UserRightEnum.AdminAppGlobal,
        UserRightEnum.UserAdvanced,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRoleEnum.Writer,
      ],
    ],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.EditStatus, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [
      PermissionActionEnum.Share,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
  ]);

  /* Dashboard -> Flow Ref */
  export const flowReferentialPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Delete,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
    [PermissionActionEnum.Unlock, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [PermissionActionEnum.EditStatus, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
  ]);

  export const flowReferentialModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminApp,
        UserRightEnum.AdminAppGlobal,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRoleEnum.Writer,
        UserRoleEnum.NotContractor,
      ],
    ],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.ExecuteFlowRef,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.EditStatus, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
  ]);

  /* Dashboard -> Lists */
  export const dashboardListPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Delete,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
  ]);

  export const dashboardListModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminApp,
        UserRightEnum.AdminAppGlobal,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRoleEnum.Writer,
        UserRoleEnum.NotContractor,
      ],
    ],
  ]);

  /* Dashboard -> Schedule tasks */
  export const dashboardScheduleTasks = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  /* Dashboard -> Contributors */
  export const dashboardContributorPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.NotContractor]],
  ]);

  export const dashboardContributorsModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
  ]);

  export const dashboardContributorsEditModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any, UserRoleEnum.NotContractor]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  /* Dashboard -> Tablets */
  export const dashboardTabletsPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
    [PermissionActionEnum.View, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  export const dashboardTabletModal = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  /* Dashboard -> Field Feedback */

  export const fieldFeedbackPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.Download, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [PermissionActionEnum.EditStatus, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [
      PermissionActionEnum.Share,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.GenerateDocument,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminApp,
        UserRightEnum.AdminAppGlobal,
        UserRightEnum.UserAdvanced,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRoleEnum.Writer,
      ],
    ],
  ]);

  /* Dashboard -> closing folder */

  export const dashboardClosingFolderPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Delete,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRoleEnum.Responsible, UserRoleEnum.Expert],
    ],
    [PermissionActionEnum.ControlDiffusion, [UserRightEnum.AdminSiteFlow, UserRightEnum.UserManageDiffusion]],
  ]);

  export const dashboardClosingFolderModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Read,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminApp,
        UserRightEnum.AdminAppGlobal,
        UserRightEnum.UserAdvanced,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRoleEnum.Writer,
      ],
    ],
    [PermissionActionEnum.EditRef, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced]],
  ]);

  /* Dashboard -> category */

  export const dashboardCategoryPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [PermissionActionEnum.Edit, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.NotContractor]],
    [PermissionActionEnum.Archive, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  export const dashboardCategoryModal = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Add,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.NotContractor],
    ],
  ]);
  export const multiPRActionSelect = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
  ]);

  /* Flow content */

  export const flowContentPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Edit,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.MultiPr,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Pr,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [PermissionActionEnum.DeleteSignature, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [
      PermissionActionEnum.Navigate,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer, UserRoleEnum.Operator],
    ],
    [
      PermissionActionEnum.Share,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
  ]);

  /* Step content */
  export const stepContentPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Reset, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
  ]);

  /* Multi bloc content */
  export const multiBlocPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [
      PermissionActionEnum.Delete,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Deactivate,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
    [
      PermissionActionEnum.Duplicate,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
  ]);

  /* Form builder */

  export const formBuilderPage = new SfxMap<PermissionActionEnum, permissionType>([
    [PermissionActionEnum.Read, [UserRightEnum.Any]],
    [PermissionActionEnum.DeleteSignature, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
    [PermissionActionEnum.Unlock, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal]],
  ]);

  /* Response Management Modal*/

  const responseManagement = new SfxMap<PermissionActionEnum, permissionType>([
    [
      PermissionActionEnum.Edit,
      [
        UserRightEnum.AdminSiteFlow,
        UserRightEnum.AdminAppGlobal,
        UserRightEnum.AdminApp,
        UserRightEnum.UserAdvanced,
        UserRoleEnum.Responsible,
        UserRoleEnum.Expert,
        UserRightEnum.NormalUser,
        UserRightEnum.SimpleUser,
      ],
    ],
    [PermissionActionEnum.EditStatus, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert]],
    [PermissionActionEnum.Duplicate, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible]],
    [PermissionActionEnum.Delete, [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal, UserRightEnum.AdminApp]],
    [
      PermissionActionEnum.Share,
      [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
    ],
  ]);

  export const allPermissions = new SfxMap<RouteIdEnum, SfxMap<PermissionActionEnum, permissionType>>([
    /* Settings module */
    [RouteIdEnum.Settings, settingsModule],
    /* Settings  Account */
    [RouteIdEnum.SettingsAccount, accountPage],
    /* Settings  SettingsGeneralOrganization */
    [RouteIdEnum.SettingsGeneralOrganization, settingsGeneralOrganization],
    /* Settings Client */
    [RouteIdEnum.SettingsClientBook, clientPage],
    [RouteIdEnum.SettingsClientBookAddModal, clientModal],
    [RouteIdEnum.SettingsClientBookEditModal, clientModal],
    /* Settings Companies */
    [RouteIdEnum.SettingsCompanies, companiesPage],
    [RouteIdEnum.SettingsCompaniesAddModal, companiesModal],
    [RouteIdEnum.SettingsCompaniesEditModal, companiesModal],
    /* Settings Families */
    [RouteIdEnum.SettingsFamilies, familyPage],
    [RouteIdEnum.SettingsEditFamily, familyModal],
    [RouteIdEnum.SettingsAddFamily, familyModal],
    /* Settings Categories */
    [RouteIdEnum.DefaultCategories, categoryPage],
    [RouteIdEnum.AddDefaultCategory, categoryModal],
    [RouteIdEnum.EditDefaultCategory, categoryModal],
    /* Settings statuses */
    [RouteIdEnum.CustomStatus, customStatusPage],
    [RouteIdEnum.AddCustomStatus, customStatusModal],
    [RouteIdEnum.EditCustomStatus, customStatusModal],
    /* Settings references */
    [RouteIdEnum.SettingsReferences, referencePage],
    [RouteIdEnum.ReferenceAdd, referenceModal],
    [RouteIdEnum.ReferenceEdit, referenceModal],
    /* Settings custom field (attributes) */
    [RouteIdEnum.SettingsCustomField, customFieldPage],
    [RouteIdEnum.EditCustomField, customFieldModal],
    [RouteIdEnum.AddCustomField, customFieldModal],
    /* Settings custom file */
    [RouteIdEnum.SettingsCustomFile, customFilePage],
    [RouteIdEnum.EditCustomFile, customFileModal],
    [RouteIdEnum.AddCustomFile, customFileModal],
    /* Settings custom cell */
    [RouteIdEnum.SettingsCustomCell, customCellPage],
    [RouteIdEnum.AddCustomCell, customCellModal],
    [RouteIdEnum.EditCustomCell, customCellModal],
    /* Settings folder (section) */
    [RouteIdEnum.SettingsSections, folderPage],
    [RouteIdEnum.SectionAdd, folderModal],
    [RouteIdEnum.SectionEdit, folderModal],
    [RouteIdEnum.FolderAdd, folderModal],
    [RouteIdEnum.FolderEdit, folderModal],
    /* Settings trash */
    [RouteIdEnum.SettingsTrash, trashPage],
    /* Settings general */
    [RouteIdEnum.SettingsGeneralAccount, generalPage],
    /* Settings trams */
    [RouteIdEnum.SettingsTrams, tramPage],
    [RouteIdEnum.AddTram, tramModal],
    [RouteIdEnum.EditTram, tramModal],
    /* Settings backup */
    [RouteIdEnum.SettingsBackups, backupPage],
    /* Settings logsPush */
    [RouteIdEnum.SettingsLogsPush, logsPush],
    /* Settings kpis */
    [RouteIdEnum.SettingsKpis, kpiPage],
    /* Projects */
    [RouteIdEnum.Projects, projectPage],
    [RouteIdEnum.ProjectEditModal, projectModal],
    [RouteIdEnum.ProjectAddModal, addProjectModal],
    [RouteIdEnum.PrepareFolderModal, projectModal],
    [RouteIdEnum.ProjectDuplicateModal, projectModal],
    [RouteIdEnum.ProjectFolderDuplicateModal, projectModal],
    [RouteIdEnum.ProjectFolder, projectModal],
    [RouteIdEnum.ProjectPgacModal, fileProcessingAndPgacModal],
    [RouteIdEnum.ProjectFileProcessingModal, fileProcessingAndPgacModal],
    /* Library module */
    [RouteIdEnum.Library, libraryModule],
    /* Library risks */
    [RouteIdEnum.LibraryRisks, riskPage],
    [RouteIdEnum.LibraryRisksAddDialogue, riskModal],
    [RouteIdEnum.LibraryRisksEditDialogue, editRiskModal],
    [RouteIdEnum.LibraryRisksViewDialogue, viewRisk],
    [RouteIdEnum.LibraryRisksImportDialogue, riskModal],
    [RouteIdEnum.LibraryRiskReorganizeDialog, riskModal],

    /* Library Equipments */
    [RouteIdEnum.LibraryEquipments, equipmentPage],
    [RouteIdEnum.EquipmentsAdd, equipmentModal],
    [RouteIdEnum.EquipmentsEdit, editEquipmentModal],
    [RouteIdEnum.ViewEquipments, viewEquipmentModal],
    [RouteIdEnum.EquipmentsUpload, equipmentModal],
    [RouteIdEnum.LibraryEquipmentReorganizeDialog, equipmentModal],

    /* Library Documents */
    [RouteIdEnum.LibraryDocuments, documentPage],
    [RouteIdEnum.LibraryDocumentsReorganizeModal, documentModal],
    [RouteIdEnum.LibraryDocumentsEditModal, documentModal],
    [RouteIdEnum.LibraryDocumentsAddModal, documentModal],
    /* Library Forms */
    [RouteIdEnum.LibraryForms, formPage],
    [RouteIdEnum.LibraryFormsAddModal, libraryFormModal],
    [RouteIdEnum.LibraryFormsEditModal, libraryFormModal],
    [RouteIdEnum.LibraryFormsEditLocalModal, formLocalModal],
    [RouteIdEnum.LibraryFormsDuplicateModal, libraryFormModal],
    [RouteIdEnum.LibraryFormsReorganizeModal, libraryFormModal],
    /* Library Objects */
    [RouteIdEnum.LibraryObjectManagement, objectPage],
    [RouteIdEnum.LibraryObjectManagementAddModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementEditModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementAssignModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementDuplicateModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementGenerateModal, generateFlowModal],
    /* Library Tags */
    [RouteIdEnum.LibraryTags, tagsPage],
    [RouteIdEnum.LibraryTagsAddDialog, tagModal],
    [RouteIdEnum.LibraryTagsEditDialog, tagModal],
    [RouteIdEnum.LibraryTagLinksDialog, linkFlowModal],
    /* Contributor module */
    [RouteIdEnum.Contributors, contributorModule],
    /* Contributor -> Users  */
    [RouteIdEnum.ContributorUsers, userPage],
    [RouteIdEnum.UserAddModal, userModal],
    [RouteIdEnum.UserEditModal, userModal],
    [RouteIdEnum.UsersQuickConnectionModal, userModal],
    [RouteIdEnum.UserReorganizeModal, userModal],
    [RouteIdEnum.UserUploadModal, userModal],
    [RouteIdEnum.UserMoveApplicabilityModal, moveApplicabilityModal],
    [RouteIdEnum.UserSkillModal, userSkillsModal],
    /* Contributor -> operators */
    [RouteIdEnum.ContributorExternalUsers, externalUsersPage],
    [RouteIdEnum.EditExternalUser, externalUsersModal],
    /* Contributor -> operators */
    [RouteIdEnum.ContributorOperators, operatorPage],

    [RouteIdEnum.OperatorAddModal, operatorModal],
    [RouteIdEnum.OperatorEditModal, operatorModal],
    [RouteIdEnum.OperatorReorganizeModal, operatorModal],
    [RouteIdEnum.OperatorUploadModal, operatorModal],
    [RouteIdEnum.OperatorMoveApplicabilityModal, moveApplicabilityModal],
    [RouteIdEnum.OperatorSkillsModal, operatorSkillsModal],
    /* Contributor -> Tablets */
    [RouteIdEnum.ContributorTablets, tabletPage],
    [RouteIdEnum.AddTabletModal, tabletModal],
    [RouteIdEnum.EditTabletModal, tabletModal],
    /* External API */
    [RouteIdEnum.contributorExternalAPI, externalApiPage],
    [RouteIdEnum.AddAccessApiModal, externalApiModal],
    [RouteIdEnum.EditAccessApiModal, externalApiModal],
    [RouteIdEnum.AddAccessApiV2Modal, externalApiV2Modal],
    [RouteIdEnum.EditAccessApiV2Modal, externalApiV2Modal],
    /* Dashboard -> flow -> vue list */
    [RouteIdEnum.FlowManagement, flowVueListPage],
    [RouteIdEnum.FlowManagementAddModal, flowListVueModal],
    [RouteIdEnum.FlowManagementEditModal, flowListVueModal],
    [RouteIdEnum.FlowManagementDuplicateModal, flowListVueModal],
    [RouteIdEnum.FlowManagementMoveModal, flowListVueModal],
    [RouteIdEnum.FlowManagementGeoMapModal, flowListVueModal],
    [RouteIdEnum.FlowManagementContributorsModal, flowListVueModal],
    [RouteIdEnum.ProjectPgacDetailsModal, flowListVueModal],
    [RouteIdEnum.FlowManagementPgacDuplicateModal, flowListVueModal],
    [RouteIdEnum.FlowManagementReorganizeModal, flowListVueModal],
    /* Dashboard -> flow -> plan */
    [RouteIdEnum.FlowManagementPlan, flowVuePlanPage],
    /* Dashboard -> Document */
    [RouteIdEnum.DashboardDocuments, dashDocumentPage],
    [RouteIdEnum.DashboardDocumentsImportFromLibraryDialog, dashDocumentImportModal],
    [RouteIdEnum.DashboardDocumentsReorganizeDialog, dashDocumentModal],
    [RouteIdEnum.DashDocumentsEditModal, dashDocumentModal],
    [RouteIdEnum.DashDocumentsAddModal, dashDocumentModal],
    /* Dashboard -> Form */
    [RouteIdEnum.DashboardForms, dashFormPage],
    [RouteIdEnum.DashboardFormsImportModal, dashFromImportModal],
    [RouteIdEnum.DashboardFormsReplyModal, dashFromResponseModal],
    [RouteIdEnum.DashboardFormsResponseModal, dashFromResponseModal],
    /* Dashboard -> Flow Ref */
    [RouteIdEnum.DashboardFlowReferential, flowReferentialPage],
    [RouteIdEnum.DashboardFlowReferentialEditModal, flowReferentialModal],
    [RouteIdEnum.DashboardFlowReferentialManagementImportModal, flowReferentialModal],
    [RouteIdEnum.FlowManagementExecuteModal, flowReferentialModal],
    [RouteIdEnum.FlowManagementExecuteListModal, flowReferentialModal],
    [RouteIdEnum.DashboardFlowPgacDetailsModal, flowReferentialModal],
    /* Dashboard -> Lists */
    [RouteIdEnum.DashboardLists, dashboardListPage],
    [RouteIdEnum.DashboardListAddModal, dashboardListModal],
    [RouteIdEnum.DashboardListDialogGeneralTabModal, dashboardListModal],
    [RouteIdEnum.DashboardListDialogImportationTabModal, dashboardListModal],

    /* Dashboard -> Schedule Tasks */
    [RouteIdEnum.DashboardScheduleTasks, dashboardScheduleTasks],

    /* Dashboard -> Contributors */
    [RouteIdEnum.DashboardContributors, dashboardContributorPage],
    [RouteIdEnum.DashboardContributorEditModal, dashboardContributorsEditModal],
    [RouteIdEnum.DashboardCollaboratorsReorganizeModal, dashboardContributorsModal],
    [RouteIdEnum.DashboardCollaboratorsImportModal, dashboardContributorsModal],
    /* Dashboard -> Tablets */
    [RouteIdEnum.DashboardTablets, dashboardTabletsPage],
    [RouteIdEnum.DashboardTabletsImportModal, dashboardTabletModal],
    [RouteIdEnum.DashboardTabletsQuickConnectionModal, dashboardTabletModal],
    /* Dashboard field feedback */
    [RouteIdEnum.DashboardFieldFeedback, fieldFeedbackPage],
    /* Dashboard -> closing folder */
    [RouteIdEnum.DashboardClosingFolder, dashboardClosingFolderPage],
    [RouteIdEnum.DashboardEditSection, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardAddSection, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardAddFolder, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardEditFolder, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardVerificationSection, dashboardClosingFolderModal],
    /* Dashboard -> category */
    [RouteIdEnum.DashboardCategories, dashboardCategoryPage],
    [RouteIdEnum.DashboardEditCategory, dashboardCategoryModal],
    [RouteIdEnum.DashboardAddCategory, dashboardCategoryModal],
    /* Flow content */
    [RouteIdEnum.FlowContent, flowContentPage],
    /* Step content */
    [RouteIdEnum.BlockPrContent, stepContentPage],
    /* Multi bloc content */
    [RouteIdEnum.BlockMultiPrContent, multiBlocPage],
    /* Form builder */
    [RouteIdEnum.Form, formBuilderPage],
    /* Response Management Modal*/
    [RouteIdEnum.ResponseManagementModal, responseManagement],
    /* multi pr action select */
    [RouteIdEnum.MultiPRActionSelect, multiPRActionSelect],
    /* flow content > edit flow */
    [RouteIdEnum.EditFlowContentModal, flowListVueModal],
    /* Flow content > Import from library */
    [RouteIdEnum.ImportEmmModal, equipmentModal],
    [RouteIdEnum.ImportAdrModal, riskModal],
  ]);
}
