import { ProjectUpdateStatusRequestDTO } from '../../generated/models/models';
import { ProjectStatusEnum } from '../../enums';

export interface ProjectStatusForUpdate {
  statusProject: ProjectStatusEnum;
  uuidEntity: string;
}

export namespace ProjectStatusForUpdate {
  export function mapToRequestApiValue(data: ProjectStatusForUpdate): ProjectUpdateStatusRequestDTO {
    return {
      statusProject: ProjectStatusEnum.convertToApiValue.getValue(data.statusProject),
      uuidEntity: data.uuidEntity,
    };
  }
}
