import { Action, createReducer, on } from '@ngrx/store';

import { ExternalUserDetailsData } from '../../../../../core/models';

import * as fromDialogActions from './external-user-dialog.actions';

export interface ExternalUserDialogState {
  loading: boolean;
  externalUser: ExternalUserDetailsData;
}

export const initialExternalUserDialogState: ExternalUserDialogState = {
  loading: false,
  externalUser: {} as ExternalUserDetailsData,
};

const _externalUserDialogReducer = createReducer<ExternalUserDialogState>(
  initialExternalUserDialogState,
  on(fromDialogActions.loadExternalUserDetailsSuccess, (state, { externalUser }) => ({ ...state, externalUser })),

  on(fromDialogActions.updateExternalUser, (state): ExternalUserDialogState => ({ ...state, loading: true })),
  on(fromDialogActions.updateExternalUserFail, (state): ExternalUserDialogState => ({ ...state, loading: false })),
);

export function externalUserDialogReducer(state: ExternalUserDialogState, action: Action | undefined) {
  return _externalUserDialogReducer(state, action);
}
