import { ActivatedRouteSnapshot } from '@angular/router';
import { Action } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { OperatorFunction, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { resetStates, dummyAction } from '../../main/state/main.actions';
import { routerNavigatedChange } from '../router.action';

import { ActivatedRouteData } from '../../../core/models';

import { RouteIdEnum, StateName } from '../../../core/enums';
import { RouteDataEnum } from '../../../core/enums/router-enums/route-data.enum';

export function ofRouteChanged(...segments: RouteIdEnum[]): OperatorFunction<Action, { action: Action; isLastSegment: boolean }> {
  return pipe(
    ofType(routerNavigatedChange),
    filter(
      action =>
        (action.routerState.segments && !!action.routerState.segments.find(_ => segments.includes(_.id))) ||
        (action.routerState.dialogSegments && !!action.routerState.dialogSegments.find(_ => segments.includes(_.id))),
    ),
    map(action => ({ action, isLastSegment: segments.includes(action.routerState.currentRouteId) })),
  );
}

export function ofSegmentsChanged(...segments: RouteIdEnum[]): OperatorFunction<Action, { action: Action; isLastSegment: boolean }> {
  return pipe(
    ofType(routerNavigatedChange),
    filter(
      action =>
        (action.routerState.segments && segments.every(segment => action.routerState.segments.map(_ => _.id).includes(segment))) ||
        (action.routerState.dialogSegments && segments.every(segment => action.routerState.dialogSegments.map(_ => _.id).includes(segment))),
    ),
    map(action => ({ action, isLastSegment: segments.includes(action.routerState.currentRouteId) })),
  );
}

export function onResetState(stateName: StateName): OperatorFunction<Action, Action> {
  return pipe(
    ofType(resetStates),
    filter(({ statesNames }) => (statesNames || []).includes(stateName)),
    map(_ => dummyAction()),
  );
}

export function extractSegments(activatedRoute: ActivatedRouteSnapshot | null): ActivatedRouteSnapshot[] {
  const segments: ActivatedRouteSnapshot[] = [];
  let segment: ActivatedRouteSnapshot | null = activatedRoute;
  while (segment) {
    if (segment.routeConfig && segment.routeConfig.path) {
      segments.push(segment);
    }
    segment = segment.firstChild;
  }

  return segments;
}

export function extractLastSegment(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  let segment = route;
  while (segment && segment.firstChild) {
    segment = segment.firstChild;
  }

  return segment || ({} as ActivatedRouteSnapshot);
}

export function mapActivatedRouteSnapshotToActivatedRouteData(activatedRoute: ActivatedRouteSnapshot): ActivatedRouteData {
  return {
    id: activatedRoute.data[RouteDataEnum.routeId],
    parentId: activatedRoute.parent && activatedRoute.parent.data[RouteDataEnum.routeId],
    url: activatedRoute.url.map(_ => _.path).join('/'),
    params: activatedRoute.params || {},
    queryParams: activatedRoute.queryParams || {},
    data: activatedRoute.data,
  };
}
