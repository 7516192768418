import dayjs, { Dayjs, OpUnitType, UnitTypeLong } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { LocalStorageEnum } from '../enums/local-storage.enum';
import { includes, some } from 'lodash';

// used plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isWeek);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

// set default timezone
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());

// set locale
dayjs.locale(localStorage.getItem(LocalStorageEnum.currentLanguage) || 'en');

type FormattedDate = string | Date | Dayjs;
export namespace DateUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function toZonedDateTime(date: string | Date): any {
    return date && dayjs(date).format();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function toLocalDateTime(date: string | Date, keepLocalTime = true): any {
    return date && dayjs(validDate(date)).utc(keepLocalTime).format();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function toLocalDate(date: string | Date): any {
    return date && dayjs(date).utc(true).format('YYYY-MM-DD');
  }

  export function toDateFormat(date: FormattedDate): string {
    return date && dayjs(validDate(date)).format(localStorage.getItem(LocalStorageEnum.DateFormat) || 'DD/MM/YYYY');
  }

  export function toCustomDateFormat(date: string | Date, format: string, initialFormat?: string): string {
    return date && dayjs(date, initialFormat).format(format);
  }

  export function toDateTimeFormat(dateTime: FormattedDate): string {
    return dateTime && dayjs(validDate(dateTime)).format(localStorage.getItem(LocalStorageEnum.DateTimeFormat) || 'DD/MM/YYYY HH:mm');
  }

  export function toTimeFormat(time: string | Date): string {
    return time && dayjs(time).format(localStorage.getItem(LocalStorageEnum.TimeFormat) || 'HH:mm');
  }

  export function toChartTimeFormat(time: string | Date, format: string): string {
    return time && dayjs(time, 'HH:mm:ss').format(format);
  }

  export function toSensitiveTimeFormat(time: string | Date): string {
    return time && dayjs(time).format(localStorage.getItem(LocalStorageEnum.SensitiveDateTimeFormat) || 'DD/MM/YYYY HH:mm');
  }

  export function toEventDateTimeFormat(dateTime: FormattedDate): string {
    return dateTime && dayjs(dateTime).format(localStorage.getItem(LocalStorageEnum.EventDateTimeFormat) || 'DD/MM/YYYY HH:mm');
  }

  export function toCalendarFormat(date: FormattedDate, onlyDate?: boolean): string {
    return date && dayjs(validDate(date)).format(onlyDate ? 'YYYY-MM-DD' : 'YYYY-MM-DD[T]HH:mm');
  }

  export function toMonthFormat(date: string | Date): string {
    return date && dayjs(date).format('YYYY-MM');
  }

  export function add(date: string | Date, value: number, unit: dayjs.UnitTypeLong): string {
    return date && dayjs(date).add(value, unit).format('YYYY-MM-DD[T]HH:mm');
  }

  export function subtract(date: string | Date, value: number, unit: dayjs.UnitTypeLong): string {
    return date && dayjs(date).subtract(value, unit).format('YYYY-MM-DD[T]HH:mm');
  }

  export function today(): Date {
    return dayjs().toDate();
  }

  export function isValidDate(d: string | Dayjs): boolean {
    return dayjs(d, 'YYYY-MM-DD[T]HH:mm').isValid();
  }

  export function isStrictValidDate(d: Dayjs | string): boolean {
    return dayjs(d, 'YYYY-MM-DD[T]HH:mm', true).isValid();
  }

  export function isAfter(d1: FormattedDate, d2: FormattedDate, unit?: UnitTypeLong): boolean {
    return d1 && d2 && dayjs(d1).isAfter(d2, unit || 'year');
  }

  export function startOf(date: string | Date, unit: OpUnitType, format: string): string {
    return dayjs(date).startOf(unit).format(format);
  }

  export function endOf(date: string | Date, unit: OpUnitType, format: string): string {
    return dayjs(date).endOf(unit).format(format);
  }

  export function sorDate(date1: string | Date, date2: string | Date): number {
    return dayjs(date1).isAfter(dayjs(date2)) ? 1 : -1;
  }

  export function diffBy(diff: UnitTypeLong, date1: string | Date, date2: string | Date = new Date()): number {
    return Math.max(dayjs(date1).diff(dayjs(date2), diff), 0);
  }

  export function dayPerMonth(month: number): number {
    return month
      ? dayjs()
          .set('month', month - 1)
          .daysInMonth()
      : 0;
  }

  export function isStrictDateFormat(format: string): boolean {
    const forbiddenKeys = ['H', 'm', 'h', 'd', 's', 'S', 'Z', 'A', 'a'];
    const rgxSearchValue = /\[(.*?)\]/;
    const rgxSplitValue = /\/|,|\:|\-|\s/;
    const keys = format.replace(rgxSearchValue, '').split(rgxSplitValue);

    return !keys.filter(key => some(forbiddenKeys, el => includes(key, el))).length;
  }

  export function validDate(date: FormattedDate, isDateFormat?: boolean): dayjs.Dayjs {
    let value = isDateFormat ? dayjs(date).hour(0).minute(0).second(0) : dayjs(date).second(0);

    if (!value.isValid()) {
      const parseFormat = localStorage.getItem(isDateFormat ? LocalStorageEnum.DateFormat : LocalStorageEnum.DateTimeFormat);
      const escapeFormat = isDateFormat ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm';
      value = dayjs(date, parseFormat || escapeFormat);
    }

    return dayjs(value);
  }

  export function mapLanguageToLocale(locale: string): string {
    switch (locale.toLowerCase()) {
      case 'fr':
        return 'fr-FR';
      case 'de':
        return 'de-DE';
      case 'en':
        return 'en-US';
      case 'it':
        return 'it-IT';

      default:
        return 'fr-Fr';
    }
  }
}
