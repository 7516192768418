/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDto } from './sortDto';

export interface PageTeamRoleTabletRequestDTO {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  labelFamiliesUuidEntity?: Array<string>;
  statuses?: Array<PageTeamRoleTabletRequestDTO.StatusesEnum>;
  natures?: Array<PageTeamRoleTabletRequestDTO.NaturesEnum>;
  applicabilityUuides?: Array<string>;
  projectUuidEntity?: string;
}
export namespace PageTeamRoleTabletRequestDTO {
  export type StatusesEnum = 'ACTIVE' | 'DISABLE' | 'BROKEN' | 'DOWN' | 'MAINTENANCE';
  export const StatusesEnum = {
    ACTIVE: 'ACTIVE' as StatusesEnum,
    DISABLE: 'DISABLE' as StatusesEnum,
    BROKEN: 'BROKEN' as StatusesEnum,
    DOWN: 'DOWN' as StatusesEnum,
    MAINTENANCE: 'MAINTENANCE' as StatusesEnum,
  };
  export type NaturesEnum = 'NORMAL' | 'EXTERN' | 'DEVICE' | 'DEVICE_EXT' | 'EXTERNAL_API_ACCESS' | 'USER_CYPRESS' | 'EXTERNAL_CONTRIBUTOR';
  export const NaturesEnum = {
    NORMAL: 'NORMAL' as NaturesEnum,
    EXTERN: 'EXTERN' as NaturesEnum,
    DEVICE: 'DEVICE' as NaturesEnum,
    DEVICEEXT: 'DEVICE_EXT' as NaturesEnum,
    EXTERNALAPIACCESS: 'EXTERNAL_API_ACCESS' as NaturesEnum,
    USERCYPRESS: 'USER_CYPRESS' as NaturesEnum,
    EXTERNALCONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR' as NaturesEnum,
  };
}
