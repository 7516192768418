import { SfxMap } from '../../utils/enum-utils';

import { ProjectTypeEnum } from '../project/project-type.enum';

export enum FlowColumnEnum {
  FolderRef = 'folderRef',
  Family = 'family',
  Name = 'name',
  Local = 'local',
  Marker = 'marker',
  Status = 'status',
  LastUpdate = 'lastUpdate',
  LastImport = 'lastImport',
  RefIntern = 'refIntern',
  Team = 'team',

  Categories = 'categories',
  Tags = 'tags',
  Description = 'description',
  Reporter = 'reporter',
  Assigned = 'assigned',
  Actor1 = 'actor1',
  Actor2 = 'actor2',
  Actor3 = 'actor3',
  StartPlanned = 'startPlanned',
  EndPlanned = 'endPlanned',
  StartPrevu = 'startPrevu',
  EndPrevu = 'endPrevu',
  StartRealized = 'startRealized',
  EndRealized = 'endRealized',
  InternalSupervisorName1 = 'internalSupervisorName1',
  InternalSupervisorName2 = 'internalSupervisorName2',
  InternalSupervisorName3 = 'InternalSupervisorName3',
  ExternalSupervisorName1 = 'externalSupervisorName1',
  ExternalSupervisorName2 = 'externalSupervisorName2',
  ExternalSupervisorName3 = 'externalSupervisorName3',

  Actions = 'actions',
}

export namespace FlowColumnEnum {
  const extraColumns = [
    FlowColumnEnum.Categories,
    FlowColumnEnum.Tags,
    FlowColumnEnum.Description,
    FlowColumnEnum.Reporter,
    FlowColumnEnum.Assigned,
    FlowColumnEnum.Actor1,
    FlowColumnEnum.Actor2,
    FlowColumnEnum.Actor3,
    FlowColumnEnum.StartPlanned,
    FlowColumnEnum.EndPlanned,
    FlowColumnEnum.StartPrevu,
    FlowColumnEnum.EndPrevu,
    FlowColumnEnum.StartRealized,
    FlowColumnEnum.EndRealized,
    FlowColumnEnum.InternalSupervisorName1,
    FlowColumnEnum.InternalSupervisorName2,
    FlowColumnEnum.InternalSupervisorName3,
    FlowColumnEnum.ExternalSupervisorName1,
    FlowColumnEnum.ExternalSupervisorName2,
    FlowColumnEnum.ExternalSupervisorName3,
  ];

  export const mandatoryColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status];

  export const allOptionalColumns = [FlowColumnEnum.RefIntern, FlowColumnEnum.LastUpdate, FlowColumnEnum.Team, ...extraColumns];
  export const pgacAllOptionalColumns = [
    FlowColumnEnum.FolderRef,
    FlowColumnEnum.Local,
    FlowColumnEnum.Marker,
    FlowColumnEnum.RefIntern,
    FlowColumnEnum.LastUpdate,
    FlowColumnEnum.Team,
    ...extraColumns,
  ];

  export const flowDndColumns = [FlowColumnEnum.Family, FlowColumnEnum.RefIntern, FlowColumnEnum.Name, FlowColumnEnum.Status, FlowColumnEnum.LastUpdate, FlowColumnEnum.Team];
  export const pgacDndColumns = [
    FlowColumnEnum.FolderRef,
    FlowColumnEnum.Family,
    FlowColumnEnum.RefIntern,
    FlowColumnEnum.Name,
    FlowColumnEnum.Local,
    FlowColumnEnum.Marker,
    FlowColumnEnum.Status,
    FlowColumnEnum.LastUpdate,
    FlowColumnEnum.Team,
  ];

  export const all = [...mandatoryColumns, ...pgacAllOptionalColumns];

  export const allColumns = [
    FlowColumnEnum.FolderRef,
    FlowColumnEnum.Family,
    FlowColumnEnum.Name,
    FlowColumnEnum.Local,
    FlowColumnEnum.Marker,
    FlowColumnEnum.Status,
    FlowColumnEnum.LastUpdate,
    FlowColumnEnum.Team,
    FlowColumnEnum.Actions,
  ];
  export const flowTableColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status, FlowColumnEnum.LastUpdate, FlowColumnEnum.Team, FlowColumnEnum.Actions];
  export const flowReferentialColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status, FlowColumnEnum.LastImport, FlowColumnEnum.Actions];
  export const flowTemplateColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status, FlowColumnEnum.LastUpdate, FlowColumnEnum.Actions];
  export const importFlowTableColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status, FlowColumnEnum.LastUpdate];
  export const importFlowsForPlanColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status];
  export const executeListColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status];
  export const flowReorganizeColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status];
  export const flowDocumentColumns = [FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Status];
  export const flowPgacDocumentColumns = [FlowColumnEnum.FolderRef, FlowColumnEnum.Family, FlowColumnEnum.Name, FlowColumnEnum.Local, FlowColumnEnum.Status];
  export const flowPgacTableColumns = [
    FlowColumnEnum.FolderRef,
    FlowColumnEnum.Family,
    FlowColumnEnum.Name,
    FlowColumnEnum.Local,
    FlowColumnEnum.Marker,
    FlowColumnEnum.Status,
    FlowColumnEnum.LastUpdate,
    FlowColumnEnum.Team,
    FlowColumnEnum.Actions,
  ];

  export const tableColumns: SfxMap<ProjectTypeEnum, FlowColumnEnum[]> = new SfxMap<ProjectTypeEnum, FlowColumnEnum[]>(
    [
      [ProjectTypeEnum.Pgac, flowPgacTableColumns],
      [ProjectTypeEnum.Template, flowTemplateColumns],
    ],
    flowTableColumns,
  );

  export const documentTableColumns: SfxMap<ProjectTypeEnum, FlowColumnEnum[]> = new SfxMap<ProjectTypeEnum, FlowColumnEnum[]>(
    [[ProjectTypeEnum.Pgac, flowPgacDocumentColumns]],
    flowDocumentColumns,
  );

  const key = 'flows.table.header.';
  export const toString = new SfxMap<FlowColumnEnum, string>([
    [FlowColumnEnum.FolderRef, key + 'folderRef'],
    [FlowColumnEnum.Family, key + 'family'],
    [FlowColumnEnum.Name, key + 'name'],
    [FlowColumnEnum.Local, key + 'local'],
    [FlowColumnEnum.Marker, key + 'marker'],
    [FlowColumnEnum.Status, key + 'status'],
    [FlowColumnEnum.LastUpdate, key + 'lastUpdate'],
    [FlowColumnEnum.LastImport, key + 'lastImport'],
    [FlowColumnEnum.Team, key + 'team'],
    [FlowColumnEnum.Actions, key + 'actions'],
    [FlowColumnEnum.RefIntern, key + 'refIntern'],
    [FlowColumnEnum.Categories, key + 'categories'],
    [FlowColumnEnum.Tags, key + 'tags'],
    [FlowColumnEnum.Description, key + 'description'],
    [FlowColumnEnum.Reporter, key + 'reporter'],
    [FlowColumnEnum.Assigned, key + 'assigned'],
    [FlowColumnEnum.Actor1, key + 'actor1'],
    [FlowColumnEnum.Actor2, key + 'actor2'],
    [FlowColumnEnum.Actor3, key + 'actor3'],
    [FlowColumnEnum.StartPlanned, key + 'startPlanned'],
    [FlowColumnEnum.EndPlanned, key + 'endPlanned'],
    [FlowColumnEnum.StartPrevu, key + 'startPrevu'],
    [FlowColumnEnum.EndPrevu, key + 'endPrevu'],
    [FlowColumnEnum.StartRealized, key + 'startRealized'],
    [FlowColumnEnum.EndRealized, key + 'endRealized'],
    [FlowColumnEnum.InternalSupervisorName1, key + 'internalSupervisorName1'],
    [FlowColumnEnum.InternalSupervisorName2, key + 'internalSupervisorName2'],
    [FlowColumnEnum.InternalSupervisorName3, key + 'internalSupervisorName3'],
    [FlowColumnEnum.ExternalSupervisorName1, key + 'externalSupervisorName1'],
    [FlowColumnEnum.ExternalSupervisorName2, key + 'externalSupervisorName2'],
    [FlowColumnEnum.ExternalSupervisorName3, key + 'externalSupervisorName3'],
  ]);

  export const sortField = new SfxMap<FlowColumnEnum, string>(
    [
      [FlowColumnEnum.Name, 'name'],
      [FlowColumnEnum.Family, 'family'],
      [FlowColumnEnum.LastUpdate, 'modificationDate'],
      [FlowColumnEnum.FolderRef, 'refExt'],
      [FlowColumnEnum.Marker, 'functionalMarker'],
      [FlowColumnEnum.Local, 'locationLocal'],
      [FlowColumnEnum.Status, 'customStatusFlow'],
      [FlowColumnEnum.LastImport, 'modificationDate'],
      [FlowColumnEnum.Status, 'customStatusFlow'],
      [FlowColumnEnum.Categories, 'category'],
      [FlowColumnEnum.Actor1, 'tablet_1'],
      [FlowColumnEnum.Actor2, 'tablet_2'],
      [FlowColumnEnum.Actor3, 'tablet_3'],
      [FlowColumnEnum.StartPlanned, 'estimatedStartDate'],
      [FlowColumnEnum.EndPlanned, 'estimatedEndDate'],
      [FlowColumnEnum.StartPrevu, 'startDate'],
      [FlowColumnEnum.EndPrevu, 'finishDate'],
      [FlowColumnEnum.StartRealized, 'achievedStartDate'],
      [FlowColumnEnum.EndRealized, 'achievedEndDate'],
      [FlowColumnEnum.InternalSupervisorName1, 'supervisionIntern1'],
      [FlowColumnEnum.InternalSupervisorName2, 'supervisionIntern2'],
      [FlowColumnEnum.InternalSupervisorName3, 'supervisionIntern3'],
      [FlowColumnEnum.ExternalSupervisorName1, 'supervision1'],
      [FlowColumnEnum.ExternalSupervisorName2, 'supervision2'],
      [FlowColumnEnum.ExternalSupervisorName3, 'supervision3'],
      [FlowColumnEnum.RefIntern, 'refInt'],
    ],
    null,
  );
}
