import { createAction, props } from '@ngrx/store';

import { ErrorResponseData, EntityLightData, SignaturesProgressData } from '../../../../core/models';
import { PhaseSignatureSummaryData } from '../../../../core/models/signatures/phase-signature-summary-data';
import { StepSignatureSummaryData } from '../../../../core/models/signatures/step-signature-summary-data';

export const setSelectedFlow = createAction('[Dashboard Signatures/API] Set Selected Flow Details', props<{ flowData: EntityLightData }>());

// Count Actions
export const loadSignaturesCount = createAction('[Dashboard Signatures/API] Load Signatures count', props<{ flowUuidEntity: string }>());
export const loadSignaturesCountSuccess = createAction('[Dashboard Signatures/API] Load Signatures count success', props<SignaturesProgressData>());
export const loadSignaturesCountFail = createAction('[Dashboard Signatures/API] Load Signatures count fail', props<{ error: ErrorResponseData }>());

// Phases Actions
export const reloadFlowPhases = createAction('[Dashboard Signatures/API] Reload signatures flow phases');
export const loadFlowPhases = createAction('[Dashboard Signatures/API] Load signatures flow phases', props<{ flowUuidEntity: string }>());
export const loadNextFlowPhasesPage = createAction('[Dashboard Signatures/API] Load next flows phases page');
export const loadFlowPhasesSuccess = createAction(
  '[Dashboard Signatures/API] Load signatures flow phases success',
  props<{ phases: PhaseSignatureSummaryData[]; totalCount: number; reset?: boolean }>(),
);
export const loadFlowPhasesFail = createAction('[Dashboard Signatures/API] Load signatures flow phases fail', props<{ error: ErrorResponseData }>());

// Steps Actions
export const loadPhaseSteps = createAction('[Dashboard Signatures/API] Load phase steps', props<{ phaseUuidEntity: string; parentOrder: number }>());
export const loadNextPhaseStepsPage = createAction('[Dashboard Signatures/API] Load next phase steps page', props<{ phaseUuidEntity: string; parentOrder: number }>());
export const loadPhaseStepsSuccess = createAction(
  '[Dashboard Signatures/API] Load phase steps success',
  props<{ phaseUuidEntity: string; steps: StepSignatureSummaryData[]; totalCount: number; reset?: boolean }>(),
);
export const loadPhaseStepsFail = createAction('[Dashboard Signatures/API] Load phase steps fail', props<{ error: ErrorResponseData }>());

export const openSignatureFilterDatePopover = createAction('[Dashboard Signatures/API] Open filter date popover', props<{ origin: HTMLElement }>());
