import { ProjectSummaryResponseDTO, PageProjectRequest } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { UserLightData } from '../user/user-light-data';

import { SortFilter } from '../utils/sort-filter';
import { InnerSortDirectionEnum, ProjectTypeEnum, ProjectStatusEnum, UserRoleEnum, StringUtilsEnum } from '../../enums';
import { ProjectPublishedEnum } from '../../enums/project/project-published.enum';

export interface ProjectSummaryData {
  uuidEntity: string;
  businessRef: string;
  clientName: string;
  designation: string;
  description: string;
  status: ProjectStatusEnum;
  labelFamilyApplication: LabelFamilyLightData;
  location: string;
  modificationDate: Date;
  type: ProjectTypeEnum;
  currentUserRoles: UserRoleEnum[];
  applicabilityName: string;
  sharedProject: boolean;
  managers: EntityLightData[];
  clientFullName: string;
  published?: boolean;
  folderUuidEntity?: string;
  atLeastOneFlow?: boolean;
  projectIndex: string;
  customStatus: CustomStatusLightData;
}

export namespace ProjectSummaryData {
  const sortFields = ['labelFamilyApplication', 'designation', 'businessRef', 'clientRef', 'locationLibelle'];
  export interface QueryRequest extends BaseQuery<ProjectSummaryData> {
    types?: ProjectTypeEnum[];
    applicability?: string[];
    customStatus?: string[];
    labelFamilies?: string[];
    managers?: string[];
    clients?: string[];
    flowToDuplicateUuidEntity?: string;
    folderUuidEntity?: string;
    shareType?: ProjectPublishedEnum;
  }

  export function mapFromApiValue(projectSummary: ProjectSummaryResponseDTO): ProjectSummaryData {
    return {
      uuidEntity: projectSummary.uuidEntity,
      businessRef: projectSummary.businessRef,
      clientName: projectSummary.clientName,
      designation: projectSummary.designation,
      description: projectSummary.description,
      labelFamilyApplication:
        (projectSummary.labelFamilyApplication && LabelFamilyLightData.mapFromApiValue(projectSummary.labelFamilyApplication)) || LabelFamilyLightData.defaultData,
      location: projectSummary.locationLibelle,
      modificationDate: projectSummary.modificationDate,
      status: projectSummary.status && ProjectStatusEnum.convertFromApiValue.getValue(projectSummary.status),
      type: projectSummary.typeProject && ProjectTypeEnum.convertFromApiValue.getValue(projectSummary.typeProject),
      currentUserRoles: projectSummary?.currentUserRoles?.map(role => UserRoleEnum.convertFromApiValue.getValue(role)) || [],
      applicabilityName: projectSummary.applicablityName,
      sharedProject: projectSummary.sharedProject,
      managers: projectSummary?.managers?.map(u => UserLightData.mapUserToEntityLightData(u)),
      clientFullName: getClientFullName(projectSummary),
      published: projectSummary.published,
      projectIndex: projectSummary.index,
      atLeastOneFlow: projectSummary.atLeastOneFlow,
      customStatus: projectSummary?.customStatus && CustomStatusLightData.mapFromApiValue(projectSummary?.customStatus),
    };
  }

  function getClientFullName(projectSummary: ProjectSummaryResponseDTO): string {
    const clientName1 = projectSummary.clientName1 ? StringUtilsEnum.DashSeparator.concat(projectSummary.clientName1) : '';
    const clientName2 = projectSummary.clientName2 ? StringUtilsEnum.DashSeparator.concat(projectSummary.clientName2) : '';

    if (projectSummary.clientName) {
      return projectSummary.clientName.concat(clientName1).concat(clientName2);
    }

    return '';
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageProjectRequest {
    return {
      applicabilties: queries.applicability || undefined,
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      customStatusUuidEntity: queries.customStatus || undefined,
      typesProject: (queries.types && queries.types.map(type => ProjectTypeEnum.convertToApiValue.getValue(type))) || undefined,
      labelFamilyUuides: queries.labelFamilies || undefined,
      usersUuides: queries.managers || undefined,
      clientsUuides: queries.clients || undefined,
      textSearch: queries.textSearch || undefined,
      folderUuidEntity: queries.folderUuidEntity || undefined,
      shareType: ProjectPublishedEnum.convertToApiValue.getValue(queries.shareType || ProjectPublishedEnum.All),
    } as PageProjectRequest; // @BackEnd: makemodels:  customStatusUuidEntity still missing =>  PageProjectRequest is not updated
  }

  export function initializeProjectQueryRequest(
    filters: { applicability: string[]; types: string[]; families: string[]; managers: string[]; clients: string[]; status: string[]; published: string },
    search: string,
    sort: SortFilter<ProjectSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<ProjectSummaryData>(sort),
      applicability: (filters.applicability || []).length ? filters.applicability : undefined,
      textSearch: search || undefined,
      shareType: ProjectPublishedEnum.convertFromParamsValue.getValue(filters.published),
      labelFamilies: filters.families && filters.families.length ? filters.families : undefined,
      managers: filters.managers && filters.managers.length ? filters.managers : undefined,
      clients: filters.clients && filters.clients.length ? filters.clients : undefined,
      customStatus: filters?.status || undefined,
      types: (filters.types && filters.types.length && filters.types.map(type => ProjectTypeEnum.convertFromParamsValue.getValue(type))) || undefined,
    };
  }

  export function initializeProjectFolderQueryRequest(
    types: string[],
    families: string[],
    managers: string[],
    clients: string[],
    status: string[],
    search: string,
    sort: SortFilter<ProjectSummaryData>,
    folderUuidEntity: string,
  ): QueryRequest {
    return {
      ...initializeQueries<ProjectSummaryData>(sort),
      textSearch: search || undefined,
      labelFamilies: families && families.length ? families : undefined,
      managers: managers && managers.length ? managers : undefined,
      clients: clients && clients.length ? clients : undefined,
      customStatus: status?.length ? status : undefined,
      types: (types && types.length && types.map(type => ProjectTypeEnum.convertFromParamsValue.getValue(type))) || undefined,
      folderUuidEntity,
    };
  }

  export function toString(project: ProjectSummaryData): string {
    return project.designation;
  }
}
