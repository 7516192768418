import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

import './v2.0/core/utils/date.utils';
import { RouterPaths } from './v2.0/core/constant/route.constant';

import { LocalStorageEnum } from './v2.0/core/enums';

import { AppState } from './v2.0/modules/app.state';
import { frontAppConfig } from './v2.0/core/models/utils/front-app-config';
import { logOut, setAppVersion } from './v2.0/modules/main/state/main.actions';

import { AppConfigService } from './v2.0/core/services/app-config.service';

@Component({
  selector: 'sfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private appConfigService: AppConfigService, private store: Store<AppState>, private storage: LocalStorageService, private router: Router) {
    this.initializeApp();
    window.addEventListener('storage', events => this.handleMultipleSessions(events));
  }

  public initializeApp(): void {
    this.appConfigService
      .getBackendVersion()
      .pipe(first())
      .subscribe(data => {
        const backendFragments = (data.version || '').split('.');
        let version = frontAppConfig.version;
        /* al least 4 digest in the backend version, if 4 digest => take the last one, if 5 digest => release version => take the last 2 */
        if (backendFragments.length >= 4) {
          version = `${version} - ${backendFragments[3]}${backendFragments.length === 5 ? '.' + backendFragments[4] : ''}`;
        }

        this.store.dispatch(setAppVersion({ appVersion: version }));
      });
  }

  private handleMultipleSessions(events: StorageEvent): void {
    if (events.key === 'ngx-webstorage|authenticationtoken') {
      const authToken = this.storage.retrieve(LocalStorageEnum.AuthToken);

      if (authToken && !events.oldValue) {
        // Redirect to home if a session is already open
        this.router.navigate(['/', RouterPaths.RootPaths.mainPath], { replaceUrl: true });
      } else if (!authToken && events.oldValue) {
        // Disconnect all sessions if one is already logged out
        this.store.dispatch(logOut({ redirect: true }));
      }
    }
  }
}
