import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StateName, RouteQueryParamEnum } from '../../../../core/enums';
import { getListQueryParam, getQueryParam, getSortFilters, getTextSearchFilter } from '../../../router';

import { ProjectSummaryData } from '../../../../core/models';
import * as fromProjects from './projects.reducer';

const projectsState = createFeatureSelector<fromProjects.ProjectsState>(StateName.Projects);

const getLoadedProjects = createSelector(projectsState, fromProjects.selectAllProjects);
const getReset = createSelector(projectsState, state => state.reset);
export const getTotalCount = createSelector(projectsState, state => state.totalCount);
export const getFilteredTotalCount = createSelector(projectsState, state => state.filteredTotalCount);
export const getLoadedCounter = createSelector(getFilteredTotalCount, getTotalCount, (filterTotalCount, totalCount) => !(filterTotalCount > -1 && totalCount > -1));
export const getProjectList = createSelector(getLoadedProjects, getFilteredTotalCount, getReset, (projects, totalCount, reset) => ({ projects, totalCount, reset }));
export const getFolderExistence = createSelector(projectsState, state => state.folderExists);
const getProjectFilter = createSelector(
  getListQueryParam(RouteQueryParamEnum.Applicability),
  getListQueryParam(RouteQueryParamEnum.Type),
  getListQueryParam(RouteQueryParamEnum.Family),
  getListQueryParam(RouteQueryParamEnum.Assignee),
  getListQueryParam(RouteQueryParamEnum.Client),
  getListQueryParam(RouteQueryParamEnum.Status),
  getQueryParam(RouteQueryParamEnum.Published),
  (applicability: string[], types: string[], families: string[], managers: string[], clients: string[], status: string[], published: string) => ({
    applicability,
    types,
    families,
    managers,
    clients,
    status,
    published,
  }),
);
export const getProjectQueryParams = createSelector(getProjectFilter, getTextSearchFilter, getSortFilters, ProjectSummaryData.initializeProjectQueryRequest);
