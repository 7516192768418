import { DocumentFlowDetailsResponseDTO } from '../../generated/models/models';

import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum DocumentProvenanceEnum {
  Library = 1, // Deprecated never use this
  LibraryCreated = 2,
  Local = 3,
  ProvenanceLibrary = 4,
  ProvenanceReferential = 5,
  Referential = 6, // Deprecated never use this
  ReferentialCreated = 7,
}

export namespace DocumentProvenanceEnum {
  export const getValues = [DocumentProvenanceEnum.Local, DocumentProvenanceEnum.ProvenanceLibrary, DocumentProvenanceEnum.ProvenanceReferential];
  export const getDocumentValues = [DocumentProvenanceEnum.Local, DocumentProvenanceEnum.Library, DocumentProvenanceEnum.Referential];

  export const convertFromApiValue: SfxMap<DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum, DocumentProvenanceEnum> = new SfxMap<
    DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum,
    DocumentProvenanceEnum
  >([
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.LIBRARY, DocumentProvenanceEnum.Library],
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.LIBRARYCREATED, DocumentProvenanceEnum.LibraryCreated],
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.LOCAL, DocumentProvenanceEnum.Local],
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.PROVENANCELIBRARY, DocumentProvenanceEnum.ProvenanceLibrary],
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.PROVENANCEREFERENTIAL, DocumentProvenanceEnum.ProvenanceReferential],
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.REFERENTIAL, DocumentProvenanceEnum.Referential],
    [DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.REFERENTIALCREATED, DocumentProvenanceEnum.ReferentialCreated],
  ]);

  export const convertToApiValue: SfxMap<DocumentProvenanceEnum, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum> = new SfxMap<
    DocumentProvenanceEnum,
    DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum
  >([
    [DocumentProvenanceEnum.Library, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.LIBRARY],
    [DocumentProvenanceEnum.LibraryCreated, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.LIBRARYCREATED],
    [DocumentProvenanceEnum.Local, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.LOCAL],
    [DocumentProvenanceEnum.ProvenanceLibrary, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.PROVENANCELIBRARY],
    [DocumentProvenanceEnum.ProvenanceReferential, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.PROVENANCEREFERENTIAL],
    [DocumentProvenanceEnum.Referential, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.REFERENTIAL],
    [DocumentProvenanceEnum.ReferentialCreated, DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum.REFERENTIALCREATED],
  ]);

  export const convertFromParamsValue: SfxMap<string, DocumentProvenanceEnum> = new SfxMap<string, DocumentProvenanceEnum>([
    ['library', DocumentProvenanceEnum.Library],
    ['provenanceLibrary', DocumentProvenanceEnum.ProvenanceLibrary],
    ['referential', DocumentProvenanceEnum.Referential],
    ['provenanceReferential', DocumentProvenanceEnum.ProvenanceReferential],
    ['local', DocumentProvenanceEnum.Local],
  ]);

  export const convertToParamsValue: SfxMap<DocumentProvenanceEnum, string> = new SfxMap<DocumentProvenanceEnum, string>([
    [DocumentProvenanceEnum.Library, 'library'],
    [DocumentProvenanceEnum.ProvenanceLibrary, 'provenanceLibrary'],
    [DocumentProvenanceEnum.Referential, 'referential'],
    [DocumentProvenanceEnum.ProvenanceReferential, 'provenanceReferential'],
    [DocumentProvenanceEnum.Local, 'local'],
  ]);

  const key = 'documentProvenance.';
  export const toString: SfxMap<DocumentProvenanceEnum, string> = new SfxMap<DocumentProvenanceEnum, string>(
    [
      [DocumentProvenanceEnum.Library, key + 'library'],
      [DocumentProvenanceEnum.ProvenanceLibrary, key + 'provenanceLibrary'],
      [DocumentProvenanceEnum.LibraryCreated, key + 'library'],
      [DocumentProvenanceEnum.Referential, key + 'referential'],
      [DocumentProvenanceEnum.ProvenanceReferential, key + 'provenanceReferential'],
      [DocumentProvenanceEnum.Local, key + 'local'],
    ],
    'documentProvenance.provenance',
  );

  export const tooltip: SfxMap<DocumentProvenanceEnum, string> = new SfxMap<DocumentProvenanceEnum, string>(
    [
      [DocumentProvenanceEnum.ProvenanceLibrary, 'documentProvenance.library'],
      [DocumentProvenanceEnum.LibraryCreated, 'documentProvenance.library'],
      [DocumentProvenanceEnum.ProvenanceReferential, 'documentProvenance.referential'],
    ],
    '',
  );

  export const color: SfxMap<DocumentProvenanceEnum, string> = new SfxMap<DocumentProvenanceEnum, string>(
    [
      [DocumentProvenanceEnum.ProvenanceLibrary, 'sfx-chip-provenance-library'],
      [DocumentProvenanceEnum.LibraryCreated, 'sfx-chip-provenance-library'],
      [DocumentProvenanceEnum.ProvenanceReferential, 'sfx-chip-provenance-referential'],
    ],
    undefined,
  );

  export const toLetter: SfxMap<DocumentProvenanceEnum, string> = new SfxMap<DocumentProvenanceEnum, string>(
    [
      [DocumentProvenanceEnum.ProvenanceLibrary, 'documentProvenance.letter.library'],
      [DocumentProvenanceEnum.LibraryCreated, 'documentProvenance.letter.library'],
      [DocumentProvenanceEnum.ProvenanceReferential, 'documentProvenance.letter.referential'],
    ],
    '',
  );

  export const toLetterTooltip: SfxMap<DocumentProvenanceEnum, string> = new SfxMap<DocumentProvenanceEnum, string>(
    [
      [DocumentProvenanceEnum.ProvenanceLibrary, 'documentProvenance.library'],
      [DocumentProvenanceEnum.LibraryCreated, 'documentProvenance.library'],
      [DocumentProvenanceEnum.ProvenanceReferential, 'documentProvenance.referential'],
    ],
    '',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<DocumentProvenanceEnum>[]> =>
    of(
      getValues.map(provence => ({
        value: provence,
        title: toString.getValue(provence),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<DocumentProvenanceEnum>, item: DocumentProvenanceEnum) => node.value === item;
}
