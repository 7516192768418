/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SignatoryLightDTO } from './signatoryLightDTO';

export interface BlocDsiDetailsResponsesDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  technicalControl: boolean;
  technicalControlDetails: string;
  technicalControlStep: boolean;
  signatoriesStatus: BlocDsiDetailsResponsesDTO.SignatoriesStatusEnum;
  aip: boolean;
  documentRequired: boolean;
  supervisionExtern1: boolean;
  supervisionExtern2: boolean;
  supervisionExtern3: boolean;
  supervisionIntern1: boolean;
  supervisionIntern2: boolean;
  supervisionIntern3: boolean;
  observation: string;
  reference: string;
  signatoryDisabledComment: string;
  listSignatories: Array<SignatoryLightDTO>;
  supervisionNameIntern1: string;
  supervisionNameIntern2: string;
  supervisionNameIntern3: string;
  supervisionNameExtern1: string;
  supervisionNameExtern2: string;
  supervisionNameExtern3: string;
  disabled: boolean;
  alertConvocationSupervisionIntern1: boolean;
  alertConvocationSupervisionIntern2: boolean;
  alertConvocationSupervisionIntern3: boolean;
  signatureConvocationSupervisionIntern1: boolean;
  signatureConvocationSupervisionIntern2: boolean;
  signatureConvocationSupervisionIntern3: boolean;
  signatureBreakpointSupervisionIntern1: boolean;
  signatureBreakpointSupervisionIntern2: boolean;
  signatureBreakpointSupervisionIntern3: boolean;
  alertConvocationSupervisionExtern1: boolean;
  alertConvocationSupervisionExtern2: boolean;
  alertConvocationSupervisionExtern3: boolean;
  signatureBreakpointSupervisionExtern1: boolean;
  signatureBreakpointSupervisionExtern2: boolean;
  signatureBreakpointSupervisionExtern3: boolean;
  signatureConvocationSupervisionExtern1: boolean;
  signatureConvocationSupervisionExtern2: boolean;
  signatureConvocationSupervisionExtern3: boolean;
}
export namespace BlocDsiDetailsResponsesDTO {
  export type SignatoriesStatusEnum = 'ENABLED' | 'DISABLED' | 'REQUIRED' | 'OPTIONAL';
  export const SignatoriesStatusEnum = {
    ENABLED: 'ENABLED' as SignatoriesStatusEnum,
    DISABLED: 'DISABLED' as SignatoriesStatusEnum,
    REQUIRED: 'REQUIRED' as SignatoriesStatusEnum,
    OPTIONAL: 'OPTIONAL' as SignatoriesStatusEnum,
  };
}
