import { FillExternalContributorInfoRequestDto } from '../../generated/models/models';
import { SharedLinkExternalUserResponseDto } from '../../generated/models/sharedLinkExternalUserResponseDto';

export interface ExternalUserDetailsData {
  companyName: string;
  firstName: string;
  lastName: string;
  profession: string;
  email: string;
  uuidEntity: string;
}

export namespace ExternalUserDetailsData {
  export function mapFromApiValue(data: SharedLinkExternalUserResponseDto): ExternalUserDetailsData {
    return {
      profession: data._function,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      uuidEntity: data.uuidEntity,
    };
  }

  export function mapToApiValue(data: ExternalUserDetailsData): FillExternalContributorInfoRequestDto {
    return {
      _function: data.profession,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
    };
  }
}
