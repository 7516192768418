import { OptionUpdateRequestDTO } from '../../generated/models/optionUpdateRequestDTO';
import { OptionSignatoryUpdateRequestDTO } from '../../generated/models/optionSignatoryUpdateRequestDTO';
import { OptionCreateRequestDTO } from '../../generated/models/optionCreateRequestDTO';
import { OptionSignatoryCreateRequestDTO } from '../../generated/models/optionSignatoryCreateRequestDTO';

import { optionValueType, QuestionOptionData, questionOptionResponseType } from '../form-builder/question-option-data';
import { QuestionDetailsData } from '../question/question-details-data';
import { QuestionLocationData } from '../form-builder/question-location-data';
import { QuestionDurationData } from '../form-builder/question-duration-data';

import { DateUtils } from '../../utils/date.utils';
import { ExportableValueEnum, PhotoSizeEnum, QuestionTypeEnum, ToasterTypeEnum, ValidationNatureEnum } from '../../enums';
import { durationHandler } from '../../utils/time.utils';

export interface OptionDetailsData extends QuestionOptionData<optionValueType> {
  preFillCheck: boolean;
  exportableValueCheck: boolean;
  descriptionCheck: boolean;
  exportXlsCheck: boolean;
  redirectionCheck: boolean;
  alertCheck: boolean;
  pdfFieldNameCheck: boolean;
  validationCheck: boolean;
  imageSizeCheck: boolean;
  photoSize: PhotoSizeEnum;
  pdfFieldName: string;
  exportXlsFieldName: string;
  preEvaluationEnums: string;
  preEvaluationValue: string | QuestionDurationData;
  disableSignature: boolean;
  exportableValue: ExportableValueEnum;
  filledWithDynamicVariable: boolean;
  optionParent: QuestionDetailsData;
  hasSignatory?: boolean;
}
export namespace OptionDetailsData {
  export function mapFromApiValue(option: questionOptionResponseType, parent: QuestionDetailsData): OptionDetailsData {
    return {
      ...QuestionOptionData.mapFromApiValue(option, parent?.type),
      preFillCheck:
        parent?.type === QuestionTypeEnum.Formula
          ? true
          : !!option.preEvaluationValue ||
            !!option.textValue ||
            !!option.content ||
            !!option.dateValue ||
            !!option.dateTimeValue ||
            !!option.timeValue ||
            (QuestionTypeEnum.fileQuestions.includes(parent?.type) && !!option.applicationFiles?.length),
      exportableValueCheck: option.checkedTargetValue,
      descriptionCheck: !!option.description,
      exportXlsCheck: option.exportXlsCheck,
      redirectionCheck: option.checkedNextQuestion,
      alertCheck: option.checkedAlert,
      imageSizeCheck: option.checkedImageSize,
      pdfFieldNameCheck: option.checkedPdfFieldName,
      validationCheck: option.checkedValidation,
      photoSize: PhotoSizeEnum.convertFromApiValue.getValue(option.attachementWidth),
      pdfFieldName: option.pdfFieldName,
      preEvaluationEnums: option.preEvaluationEnums,
      preEvaluationValue: convertPreEvaluationValue(parent?.type, option.preEvaluationValue),
      exportXlsFieldName: option.exportXlsFieldName,
      exportableValue: ExportableValueEnum.convertFromApiValue.getValue(option.targetValue),
      optionParent: parent,
      disableSignature: option.disableSignature,
      filledWithDynamicVariable: QuestionTypeEnum.preFillingNotTextQuestions.includes(parent.type) && !!option.preEvaluationValue,
      hasSignatory: !!option.imageSignature,
    };
  }

  export function mapToUpdateRequestApiValue(option: OptionDetailsData, questionType: QuestionTypeEnum): OptionUpdateRequestDTO & OptionSignatoryUpdateRequestDTO {
    return {
      uuidEntity: option.uuidEntity,
      ...setOptionValueWhenPreFill(option, questionType, option.preFillCheck && !option.filledWithDynamicVariable),
      /* check values */
      checkedTargetValue: option.exportableValueCheck,
      checkedNextQuestion: option.redirectionCheck,
      checkedAlert: option.alertCheck,
      checkedPdfFieldName: option.pdfFieldNameCheck,
      checkedValidation: option.validationCheck,
      checkedDisabledValue: option.disabled,
      description: option.description,
      pdfFieldName: option.pdfFieldNameCheck ? option.pdfFieldName : null,
      targetValue: option.exportableValueCheck ? ExportableValueEnum.convertToApiValue.getValue(option.exportableValue) : null,
      /* validation data */
      validationAlertText: option.validationCheck ? option.validationData.validationMessage : null,
      checkedBlockingControl: option.validationCheck ? option.validationData.blockValueToLimit : null,
      valueOne: option.validationCheck ? option.validationData.min : null,
      valueTwo: option.validationCheck ? option.validationData.max : null,
      natureValidation: option.validationCheck ? ValidationNatureEnum.mapToApiValue.getValue(option.validationData.nature) : null,
      /* alert data */
      natureAlert: option.alertCheck ? ToasterTypeEnum.mapToAlertNature.getValue(option.alertData.nature) : null,
      alertText: option.alertCheck ? option.alertData.alertMessage : null,
      /* Redirection */
      uuidTypeNextRubric: option.redirectionCheck ? option.redirectionData.nextRubric && option.redirectionData.nextRubric.uuidType : null,
      uuidTypeNextQuestion: option.redirectionCheck ? option.redirectionData.nextQuestion && option.redirectionData.nextQuestion.uuidType : null,

      /* Image size */
      checkedImageSize: !!option.photoSize,
      attachementWidth: PhotoSizeEnum.convertToApiValue.getValue(option.photoSize),

      /* Export Xls */
      exportXlsCheck: option.exportXlsCheck,
      exportXlsFieldName: option.exportXlsCheck ? option.exportXlsFieldName : null,

      name: option.name,
      textValueUnit: option.unitValue,
      preEvaluationEnums: option.preEvaluationEnums,
      preEvaluationValue: option.preFillCheck ? getPreEvaluationValue(questionType, option.preEvaluationValue) : null,
      placeholder: option.placeholder,
      disableSignature: option.disableSignature,
      locked: option.locked,
    };
  }
  export function getPreEvaluationValue(questionType: QuestionTypeEnum, preEvaluationValue: QuestionDurationData | string): string {
    if (questionType === QuestionTypeEnum.Duration && preEvaluationValue) {
      const time = preEvaluationValue as QuestionDurationData;

      return `${time?.hours}:${time?.minutes}`;
    }

    return preEvaluationValue as string;
  }

  export function convertPreEvaluationValue(questionType: QuestionTypeEnum, preEvaluationValue: string): string | QuestionDurationData {
    if (questionType === QuestionTypeEnum.Duration) {
      return { hours: durationHandler(preEvaluationValue?.substring(0, 2)), minutes: durationHandler(preEvaluationValue?.substring(3, 5), true) } as QuestionDurationData;
    }

    return preEvaluationValue;
  }

  export function setOptionValueWhenPreFill(
    option: OptionDetailsData,
    questionType: QuestionTypeEnum,
    fillOptionValue: boolean,
  ): Partial<OptionUpdateRequestDTO & OptionSignatoryUpdateRequestDTO> {
    switch (questionType) {
      case QuestionTypeEnum.Date:
        return { dateValue: fillOptionValue ? DateUtils.toLocalDate(option.value as string) : null };

      case QuestionTypeEnum.DateTime:
        return { dateTimeValue: fillOptionValue ? DateUtils.toLocalDateTime(option.value as string) : null };

      case QuestionTypeEnum.Duration:
        const time = option.preEvaluationValue as QuestionDurationData;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { timeValue: (fillOptionValue && time.hours && time.minutes && (`${time?.hours}:${time?.minutes}` as any)) || null };

      case QuestionTypeEnum.Location:
        return {
          altitude: fillOptionValue ? (option.value as QuestionLocationData).altitude : null,
          latitude: fillOptionValue ? (option.value as QuestionLocationData).latitude : null,
          longitude: fillOptionValue ? (option.value as QuestionLocationData).longitude : null,
        };

      case QuestionTypeEnum.UniqueChoice:
      case QuestionTypeEnum.MultipleChoices:
        return { checkedValue: fillOptionValue ? (option.value as boolean) : null };

      case QuestionTypeEnum.ValueUnit:
        return { textValue: fillOptionValue ? (option.value as string) : null };

      case QuestionTypeEnum.TextEditorAnswer:
        return { content: fillOptionValue ? (option.value as string) : null };

      default:
        return {};
    }
  }

  export function mapToCreateRequestApiValue(questionUuidEntity: string, name: string): OptionCreateRequestDTO & OptionSignatoryCreateRequestDTO {
    return { questionUuidEntity, name };
  }
}
