import { createReducer, Action, on } from '@ngrx/store';
import { Dictionary, EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { flatten } from 'lodash';
import * as fromActions from './signatures.actions';

import { EntityLightData, FlowLightData, SignaturesProgressData } from '../../../../core/models';
import { StepSignatureSummaryData } from '../../../../core/models/signatures/step-signature-summary-data';
import { PhaseSignatureSummaryData } from '../../../../core/models/signatures/phase-signature-summary-data';

import { FlowTypeEnum, SignatureNatureEnum } from '../../../../core/enums';

export interface SignaturesState extends EntityState<PhaseSignatureSummaryData> {
  loading: boolean;
  reset: boolean;
  selectedFlow: EntityLightData;
  phasesCount: number;
  progressSignatures: SignaturesProgressData;
  steps: Dictionary<{ children: (StepSignatureSummaryData | FlowLightData)[]; childrenCount: number; page: number }>;
}

const adapter: EntityAdapter<PhaseSignatureSummaryData> = createEntityAdapter<PhaseSignatureSummaryData>({
  selectId: signature => signature.uuidEntity,
});

export const signaturesInitialState: SignaturesState = adapter.getInitialState<SignaturesState>({
  ids: [],
  entities: {},
  reset: true,
  loading: false,
  selectedFlow: null,
  steps: {},
  phasesCount: null,
  progressSignatures: null,
});

const _signaturesReducer = createReducer<SignaturesState>(
  signaturesInitialState,
  on(fromActions.loadSignaturesCountSuccess, (state, progressSignatures) => ({ ...state, progressSignatures })),
  on(fromActions.setSelectedFlow, (state, { flowData }): SignaturesState => ({ ...state, selectedFlow: flowData })),
  on(fromActions.loadFlowPhases, (state): SignaturesState => ({ ...state, steps: {}, entities: {}, ids: [], phasesCount: null, loading: true })),
  on(
    fromActions.loadFlowPhasesSuccess,
    (state, { phases, totalCount, reset }): SignaturesState => {
      const newState: SignaturesState = { ...state, loading: false, reset, phasesCount: totalCount };

      return reset ? adapter.setAll(phases, newState) : adapter.addMany(phases, newState);
    },
  ),
  on(fromActions.loadFlowPhasesFail, state => ({ ...state, loading: false })),
  on(
    fromActions.loadPhaseStepsSuccess,
    (state, { phaseUuidEntity, steps, totalCount, reset }): SignaturesState => {
      const newSteps = flatten(
        steps.map(step => {
          const validLinkedFlow = step.linkedFlow &&
            [FlowTypeEnum.Specific, FlowTypeEnum.Generic, FlowTypeEnum.FlowForm].includes(step.linkedFlow.type) && { ...step.linkedFlow, signatures: undefined };

          return step.technicalControlStep
            ? step
            : [
                { ...step, signatures: step.signatures.filter(item => item.signatureNature !== SignatureNatureEnum.ControlTech) },
                { ...step, signatures: step.signatures.filter(item => item.signatureNature === SignatureNatureEnum.ControlTech), navigationList: [], isTechnicalControl: true },
                validLinkedFlow,
              ].filter(auxStep => auxStep && (auxStep.signatures && auxStep.signatures.length) !== 0);
        }),
      );
      const newStepsCount = reset ? totalCount : ((state.steps[phaseUuidEntity] && state.steps[phaseUuidEntity].childrenCount) || 0) + newSteps.length - steps.length;
      const nextPage = reset ? 0 : ((state.steps[phaseUuidEntity] && state.steps[phaseUuidEntity].page) || 0) + 1;
      const stepChildren = reset ? newSteps : [...state.steps[phaseUuidEntity].children, ...newSteps];

      return { ...state, reset: false, steps: { ...state.steps, [phaseUuidEntity]: { children: stepChildren, childrenCount: newStepsCount, page: nextPage } } };
    },
  ),
);

export function signaturesReducer(state: SignaturesState | undefined, action: Action): SignaturesState {
  return _signaturesReducer(state, action);
}

export const selectAllPhases = adapter.getSelectors().selectAll;
