import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseHttpService } from './base-http.service';

import { AppConfigLightDTO, AppConfigResponseDTO, AppConfigUpdateRequestDTO } from '../generated/models/models';

import { AppConfigDetailsData, AppConfigLightData, HttpParamsType } from '../models';

import { TimeZonesResponseDTO } from '../generated/models/timeZonesResponseDTO';
import { SingleSelectItemData } from '../../modules/shared/components/select-v2/utils/select-item-data';
import { ApiUrlsEnum } from './helpers/api-url';
import { apiUrlMatcher } from './helpers/api-url-matcher';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  constructor(private baseHttpService: BaseHttpService) {}

  public getAppConfigInfo(): Observable<AppConfigLightData> {
    return this.baseHttpService.get<AppConfigLightDTO>(apiUrlMatcher(ApiUrlsEnum.GetCurrentAppConfigLight)).pipe(map(appConfig => AppConfigLightData.mapFromApiValue(appConfig)));
  }

  public getBackendVersion(): Observable<{ version: string }> {
    return this.baseHttpService.get<{ version: string }>(apiUrlMatcher(ApiUrlsEnum.BackendVersion));
  }

  public getAppConfigDetails(): Observable<AppConfigDetailsData> {
    return this.baseHttpService
      .get<AppConfigResponseDTO>(apiUrlMatcher(ApiUrlsEnum.GetCurrentAppConfigDetails))
      .pipe(map(appConfig => AppConfigDetailsData.mapFromApiValue(appConfig)));
  }

  public getTimeZones = (): Observable<SingleSelectItemData<string>[]> =>
    this.baseHttpService.get<TimeZonesResponseDTO>(apiUrlMatcher(ApiUrlsEnum.GetTimeZones)).pipe(
      map(dto =>
        dto.timeZones.map(v => ({
          value: v,
          title: v,
        })),
      ),
    );

  public updateAppConfig(params: HttpParamsType<AppConfigUpdateRequestDTO>): Observable<void> {
    return this.baseHttpService.put<void>(apiUrlMatcher(ApiUrlsEnum.UpdateCurrentAppConfig), params);
  }
}
