import { TemplateParamListRequestDTO } from '../../generated/models/models';

import { SfxMap } from '../../utils/enum-utils';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { Observable, of } from 'rxjs';
import { SearchStrategyEnum } from '../../../modules/shared/components/select-v2/utils/search-strategy.enum';

export enum TramPageTypeEnum {
  CoverPage = 'CoverPage',
  CoverPageClient = 'CoverPageClient',
  ControlCoverPage = 'ControlCoverPage',
  CollaboratorListCoverPage = 'CollaboratorListCoverPage',
  DocumentListCoverPage = 'DocumentListCoverPage',
  ClotureCoverPage = 'ClotureCoverPage',
  FormContent = 'FormContent',
  FormGeneric = 'FormGeneric',
  FormGenericHorizontal = 'FormGenericHorizontal',
  FormModel = 'FormModel',
  FormModelHorizontal = 'FormModelHorizontal',
  FormTemplate = 'FormTemplate',
  AnnexPage = 'AnnexPage',
  PageContent = 'PageContent',
  PageContentCloture = 'PageContentCloture',
  DocumentPageContentMonitoring = 'DocumentPageContentMonitoring',
  ControlPageContentMonitoring = 'ControlPageContentMonitoring',
  CloturePageContentMonitoring = 'CloturePageContentMonitoring',
  PageContentVertical = 'PageContentVertical',
  ControlPageHorizontal = 'ControlPageHorizontal',
  CollaboratorHorizontalPAge = 'CollaboratorHorizontalPAge',
  CloturePageSection = 'CloturePageSection',
  SkillsPage = 'SkillsPage',
  ClotureSummaryPAge = 'ClotureSummaryPAge',
  ControlVerticalPage = 'ControlVerticalPage',
  PageContentHorizontal = 'PageContentHorizontal',
  PageContentSuiviMultiPr = 'PageContentSuiviMultiPr',
  PageContentVerticalMultiPr = 'PageContentVerticalMultiPr',
  PageAnnexMultiPr = 'PageAnnexMultiPr',
  ControlPageExplication = 'ControlPageExplication',
  CoverPageMultiPr = 'CoverPageMultiPr',
  DsiSignatureTrackingPage = 'DsiSignatureTrackingPage',
  Parent0 = 'Parent0',
  Parent1 = 'Parent1',
  Parent2 = 'Parent2',
  Parent3 = 'Parent3',
  Parent4 = 'Parent4',
  Parent5 = 'Parent5',
  Parent6 = 'Parent6',
}

export namespace TramPageTypeEnum {
  export const convertFromApiValue: SfxMap<TemplateParamListRequestDTO.PageTypeEnum, TramPageTypeEnum> = new SfxMap<TemplateParamListRequestDTO.PageTypeEnum, TramPageTypeEnum>([
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGE, TramPageTypeEnum.CoverPage],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGECLIENT, TramPageTypeEnum.CoverPageClient],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGEDSI, TramPageTypeEnum.ControlCoverPage],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGELCA, TramPageTypeEnum.CollaboratorListCoverPage],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGELCA, TramPageTypeEnum.CollaboratorListCoverPage],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGERFI, TramPageTypeEnum.ClotureCoverPage],
    [TemplateParamListRequestDTO.PageTypeEnum.FORMCONTENT, TramPageTypeEnum.FormContent],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGEHLCA, TramPageTypeEnum.CollaboratorHorizontalPAge],
    [TemplateParamListRequestDTO.PageTypeEnum.FORMGENERIC, TramPageTypeEnum.FormGeneric],
    [TemplateParamListRequestDTO.PageTypeEnum.FORMGENERICH, TramPageTypeEnum.FormGenericHorizontal],
    [TemplateParamListRequestDTO.PageTypeEnum.FORMMODEL, TramPageTypeEnum.FormModel],
    [TemplateParamListRequestDTO.PageTypeEnum.FORMMODELH, TramPageTypeEnum.FormModelHorizontal],
    [TemplateParamListRequestDTO.PageTypeEnum.FORMTEMPLATE, TramPageTypeEnum.FormTemplate],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGEANNEXE, TramPageTypeEnum.AnnexPage],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTH, TramPageTypeEnum.PageContentHorizontal],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTV, TramPageTypeEnum.PageContentVertical],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTRFI, TramPageTypeEnum.PageContentCloture],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVI, TramPageTypeEnum.DocumentPageContentMonitoring],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVIDSI, TramPageTypeEnum.ControlPageContentMonitoring],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVIRFI, TramPageTypeEnum.CloturePageContentMonitoring],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGEEXPLICATIONDSI, TramPageTypeEnum.ControlPageExplication],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGEHDSI, TramPageTypeEnum.ControlPageHorizontal],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGESECTIONRFI, TramPageTypeEnum.CloturePageSection],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGESKILLS, TramPageTypeEnum.SkillsPage],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGESOMMAIRERFI, TramPageTypeEnum.ClotureSummaryPAge],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGEVDSI, TramPageTypeEnum.ControlVerticalPage],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGELDA, TramPageTypeEnum.DocumentListCoverPage],
    [TemplateParamListRequestDTO.PageTypeEnum.COVERPAGEMULTIPR, TramPageTypeEnum.CoverPageMultiPr],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVIMULTIPR, TramPageTypeEnum.PageContentSuiviMultiPr],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTVMULTIPR, TramPageTypeEnum.PageContentVerticalMultiPr],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGEANNEXEMULTIPR, TramPageTypeEnum.PageAnnexMultiPr],
    [TemplateParamListRequestDTO.PageTypeEnum.PAGESUIVISIGNATUREDSI, TramPageTypeEnum.DsiSignatureTrackingPage],
  ]);

  export const convertToApiValue: SfxMap<TramPageTypeEnum, TemplateParamListRequestDTO.PageTypeEnum> = new SfxMap<TramPageTypeEnum, TemplateParamListRequestDTO.PageTypeEnum>([
    [TramPageTypeEnum.CoverPage, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGE],
    [TramPageTypeEnum.CoverPageClient, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGECLIENT],
    [TramPageTypeEnum.ControlCoverPage, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGEDSI],
    [TramPageTypeEnum.CollaboratorListCoverPage, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGELCA],
    [TramPageTypeEnum.ClotureCoverPage, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGERFI],
    [TramPageTypeEnum.FormContent, TemplateParamListRequestDTO.PageTypeEnum.FORMCONTENT],
    [TramPageTypeEnum.FormGeneric, TemplateParamListRequestDTO.PageTypeEnum.FORMGENERIC],
    [TramPageTypeEnum.FormGenericHorizontal, TemplateParamListRequestDTO.PageTypeEnum.FORMGENERICH],
    [TramPageTypeEnum.FormModel, TemplateParamListRequestDTO.PageTypeEnum.FORMMODEL],
    [TramPageTypeEnum.FormModelHorizontal, TemplateParamListRequestDTO.PageTypeEnum.FORMMODELH],
    [TramPageTypeEnum.FormTemplate, TemplateParamListRequestDTO.PageTypeEnum.FORMTEMPLATE],
    [TramPageTypeEnum.AnnexPage, TemplateParamListRequestDTO.PageTypeEnum.PAGEANNEXE],
    [TramPageTypeEnum.PageContentHorizontal, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTH],
    [TramPageTypeEnum.CollaboratorHorizontalPAge, TemplateParamListRequestDTO.PageTypeEnum.PAGEHLCA],
    [TramPageTypeEnum.PageContentCloture, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTRFI],
    [TramPageTypeEnum.DocumentPageContentMonitoring, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVI],
    [TramPageTypeEnum.ControlPageContentMonitoring, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVIDSI],
    [TramPageTypeEnum.CloturePageContentMonitoring, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVIRFI],
    [TramPageTypeEnum.ControlPageExplication, TemplateParamListRequestDTO.PageTypeEnum.PAGEEXPLICATIONDSI],
    [TramPageTypeEnum.ControlPageHorizontal, TemplateParamListRequestDTO.PageTypeEnum.PAGEHDSI],
    [TramPageTypeEnum.CloturePageSection, TemplateParamListRequestDTO.PageTypeEnum.PAGESECTIONRFI],
    [TramPageTypeEnum.SkillsPage, TemplateParamListRequestDTO.PageTypeEnum.PAGESKILLS],
    [TramPageTypeEnum.ClotureSummaryPAge, TemplateParamListRequestDTO.PageTypeEnum.PAGESOMMAIRERFI],
    [TramPageTypeEnum.ControlVerticalPage, TemplateParamListRequestDTO.PageTypeEnum.PAGEVDSI],
    [TramPageTypeEnum.PageContentVertical, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTV],
    [TramPageTypeEnum.DocumentListCoverPage, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGELDA],
    [TramPageTypeEnum.CoverPageMultiPr, TemplateParamListRequestDTO.PageTypeEnum.COVERPAGEMULTIPR],
    [TramPageTypeEnum.PageContentSuiviMultiPr, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTSUIVIMULTIPR],
    [TramPageTypeEnum.PageContentVerticalMultiPr, TemplateParamListRequestDTO.PageTypeEnum.PAGECONTENTVMULTIPR],
    [TramPageTypeEnum.PageAnnexMultiPr, TemplateParamListRequestDTO.PageTypeEnum.PAGEANNEXEMULTIPR],
    [TramPageTypeEnum.DsiSignatureTrackingPage, TemplateParamListRequestDTO.PageTypeEnum.PAGESUIVISIGNATUREDSI],
  ]);

  export const convertFromParamsValue: SfxMap<string, TramPageTypeEnum> = new SfxMap<string, TramPageTypeEnum>([
    ['CoverPage', TramPageTypeEnum.CoverPage],
    ['CoverPageClient', TramPageTypeEnum.CoverPageClient],
    ['ControlCoverPage', TramPageTypeEnum.ControlCoverPage],
    ['CollaboratorListCoverPage', TramPageTypeEnum.CollaboratorListCoverPage],
    ['ClotureCoverPage', TramPageTypeEnum.ClotureCoverPage],
    ['FormContent', TramPageTypeEnum.FormContent],
    ['FormGeneric', TramPageTypeEnum.FormGeneric],
    ['FormGenericHorizontal', TramPageTypeEnum.FormGenericHorizontal],
    ['FormModel', TramPageTypeEnum.FormModel],
    ['FormModelHorizontal', TramPageTypeEnum.FormModelHorizontal],
    ['FormTemplate', TramPageTypeEnum.FormTemplate],
    ['AnnexPage', TramPageTypeEnum.AnnexPage],
    ['PageContentHorizontal', TramPageTypeEnum.PageContentHorizontal],
    ['CollaboratorHorizontalPAge', TramPageTypeEnum.CollaboratorHorizontalPAge],
    ['PageContentCloture', TramPageTypeEnum.PageContentCloture],
    ['DocumentPageContentMonitoring', TramPageTypeEnum.DocumentPageContentMonitoring],
    ['ControlPageContentMonitoring', TramPageTypeEnum.ControlPageContentMonitoring],
    ['CloturePageContentMonitoring', TramPageTypeEnum.CloturePageContentMonitoring],
    ['ControlPageExplication', TramPageTypeEnum.ControlPageExplication],
    ['ControlPageHorizontal', TramPageTypeEnum.ControlPageHorizontal],
    ['CloturePageSection', TramPageTypeEnum.CloturePageSection],
    ['SkillsPage', TramPageTypeEnum.SkillsPage],
    ['ClotureSummaryPAge', TramPageTypeEnum.ClotureSummaryPAge],
    ['ControlVerticalPage', TramPageTypeEnum.ControlVerticalPage],
    ['PageContentVertical', TramPageTypeEnum.PageContentVertical],
    ['DocumentListCoverPage', TramPageTypeEnum.DocumentListCoverPage],
    ['CoverPageMultiPr', TramPageTypeEnum.CoverPageMultiPr],
    ['PageContentSuiviMultiPr', TramPageTypeEnum.PageContentSuiviMultiPr],
    ['PageContentVerticalMultiPr', TramPageTypeEnum.PageContentVerticalMultiPr],
    ['PageAnnexMultiPr', TramPageTypeEnum.PageAnnexMultiPr],
    ['DsiSignatureTrackingPage', TramPageTypeEnum.DsiSignatureTrackingPage],
  ]);

  export const convertToParamsValue: SfxMap<TramPageTypeEnum, string> = new SfxMap<TramPageTypeEnum, string>([
    [TramPageTypeEnum.CoverPage, 'CoverPage'],
    [TramPageTypeEnum.CoverPageClient, 'CoverPageClient'],
    [TramPageTypeEnum.ControlCoverPage, 'ControlCoverPage'],
    [TramPageTypeEnum.CollaboratorListCoverPage, 'CollaboratorListCoverPage'],
    [TramPageTypeEnum.ClotureCoverPage, 'ClotureCoverPage'],
    [TramPageTypeEnum.FormContent, 'FormContent'],
    [TramPageTypeEnum.FormGeneric, 'FormGeneric'],
    [TramPageTypeEnum.FormGenericHorizontal, 'FormGenericHorizontal'],
    [TramPageTypeEnum.FormModel, 'FormModel'],
    [TramPageTypeEnum.FormModelHorizontal, 'FormModelHorizontal'],
    [TramPageTypeEnum.FormTemplate, 'FormTemplate'],
    [TramPageTypeEnum.AnnexPage, 'AnnexPage'],
    [TramPageTypeEnum.PageContentHorizontal, 'PageContentHorizontal'],
    [TramPageTypeEnum.CollaboratorHorizontalPAge, 'CollaboratorHorizontalPAge'],
    [TramPageTypeEnum.PageContentCloture, 'PageContentCloture'],
    [TramPageTypeEnum.DocumentPageContentMonitoring, 'DocumentPageContentMonitoring'],
    [TramPageTypeEnum.ControlPageContentMonitoring, 'ControlPageContentMonitoring'],
    [TramPageTypeEnum.CloturePageContentMonitoring, 'CloturePageContentMonitoring'],
    [TramPageTypeEnum.ControlPageExplication, 'ControlPageExplication'],
    [TramPageTypeEnum.ControlPageHorizontal, 'ControlPageHorizontal'],
    [TramPageTypeEnum.CloturePageSection, 'CloturePageSection'],
    [TramPageTypeEnum.SkillsPage, 'SkillsPage'],
    [TramPageTypeEnum.ClotureSummaryPAge, 'ClotureSummaryPAge'],
    [TramPageTypeEnum.ControlVerticalPage, 'ControlVerticalPage'],
    [TramPageTypeEnum.PageContentVertical, 'PageContentVertical'],
    [TramPageTypeEnum.DocumentListCoverPage, 'DocumentListCoverPage'],
    [TramPageTypeEnum.CoverPageMultiPr, 'CoverPageMultiPr'],
    [TramPageTypeEnum.PageContentSuiviMultiPr, 'PageContentSuiviMultiPr'],
    [TramPageTypeEnum.PageContentVerticalMultiPr, 'PageContentVerticalMultiPr'],
    [TramPageTypeEnum.PageAnnexMultiPr, 'PageAnnexMultiPr'],
    [TramPageTypeEnum.DsiSignatureTrackingPage, 'DsiSignatureTrackingPage'],
  ]);

  const key = 'trams.dialog.pageType.';
  export const toString: SfxMap<TramPageTypeEnum, string> = new SfxMap<TramPageTypeEnum, string>([
    [TramPageTypeEnum.CoverPageClient, key + 'COVER_PAGE_CLIENT'],
    [TramPageTypeEnum.CoverPage, key + 'COVER_PAGE'],
    [TramPageTypeEnum.ControlCoverPage, key + 'COVER_PAGE_DSI'],
    [TramPageTypeEnum.CollaboratorListCoverPage, key + 'COVER_PAGE_LCA'],
    [TramPageTypeEnum.ClotureCoverPage, key + 'COVER_PAGE_RFI'],
    [TramPageTypeEnum.FormContent, key + 'FORM_CONTENT'],
    [TramPageTypeEnum.FormGeneric, key + 'FORM_GENERIC'],
    [TramPageTypeEnum.FormGenericHorizontal, key + 'FORM_GENERIC_H'],
    [TramPageTypeEnum.FormModel, key + 'FORM_MODEL'],
    [TramPageTypeEnum.FormModelHorizontal, key + 'FORM_MODEL_H'],
    [TramPageTypeEnum.FormTemplate, key + 'FORM_TEMPLATE'],
    [TramPageTypeEnum.AnnexPage, key + 'PAGE_ANNEXE'],
    [TramPageTypeEnum.PageContentHorizontal, key + 'PAGE_CONTENT_H'],
    [TramPageTypeEnum.PageContentVertical, key + 'PAGE_CONTENT_V'],
    [TramPageTypeEnum.PageContentCloture, key + 'PAGE_CONTENT_RFI'],
    [TramPageTypeEnum.DocumentPageContentMonitoring, key + 'PAGE_CONTENT_SUIVI'],
    [TramPageTypeEnum.ControlPageContentMonitoring, key + 'PAGE_CONTENT_SUIVI_DSI'],
    [TramPageTypeEnum.CloturePageContentMonitoring, key + 'PAGE_CONTENT_SUIVI_RFI'],
    [TramPageTypeEnum.ControlPageExplication, key + 'PAGE_EXPLICATION_DSI'],
    [TramPageTypeEnum.ControlPageHorizontal, key + 'PAGE_H_DSI'],
    [TramPageTypeEnum.CloturePageSection, key + 'PAGE_SECTION_RFI'],
    [TramPageTypeEnum.SkillsPage, key + 'PAGE_SKILLS'],
    [TramPageTypeEnum.ClotureSummaryPAge, key + 'PAGE_SOMMAIRE_RFI'],
    [TramPageTypeEnum.ControlVerticalPage, key + 'PAGE_V_DSI'],
    [TramPageTypeEnum.CollaboratorHorizontalPAge, key + 'PAGE_H_LCA'],
    [TramPageTypeEnum.DocumentListCoverPage, key + 'COVER_PAGE_LDA'],
    [TramPageTypeEnum.CoverPageMultiPr, key + 'COVER_PAGE_MULTI_PR'],
    [TramPageTypeEnum.PageContentSuiviMultiPr, key + 'PAGE_CONTENT_SUIVI_MULTI_PR'],
    [TramPageTypeEnum.PageContentVerticalMultiPr, key + 'PAGE_CONTENT_VERTICAL_MULTI_PR'],
    [TramPageTypeEnum.PageAnnexMultiPr, key + 'PAGE_ANNEX_MULTI_PR'],
    [TramPageTypeEnum.DsiSignatureTrackingPage, key + 'PAGE_SUIVI_SIGNATURE_DSI'],
  ]);

  const tramKey = 'trams.tramType.';
  export const toTramType: SfxMap<TramPageTypeEnum, string> = new SfxMap<TramPageTypeEnum, string>([
    [TramPageTypeEnum.CoverPage, tramKey + 'COVER_PAGE'],
    [TramPageTypeEnum.ControlCoverPage, tramKey + 'COVER_PAGE_DSI'],
    [TramPageTypeEnum.CollaboratorListCoverPage, tramKey + 'COVER_PAGE_LCA'],
    [TramPageTypeEnum.ClotureCoverPage, tramKey + 'COVER_PAGE_RFI'],
    [TramPageTypeEnum.FormContent, tramKey + 'FORM_CONTENT'],
    [TramPageTypeEnum.FormGeneric, tramKey + 'FORM_GENERIC'],
    [TramPageTypeEnum.FormGenericHorizontal, tramKey + 'FORM_GENERIC_H'],
    [TramPageTypeEnum.FormModel, tramKey + 'FORM_MODEL'],
    [TramPageTypeEnum.FormModelHorizontal, tramKey + 'FORM_MODEL_H'],
    [TramPageTypeEnum.FormTemplate, tramKey + 'FORM_TEMPLATE'],
    [TramPageTypeEnum.AnnexPage, tramKey + 'PAGE_ANNEXE'],
    [TramPageTypeEnum.PageContentHorizontal, tramKey + 'PAGE_CONTENT_H'],
    [TramPageTypeEnum.PageContentVertical, tramKey + 'PAGE_CONTENT_V'],
    [TramPageTypeEnum.PageContentCloture, tramKey + 'PAGE_CONTENT_RFI'],
    [TramPageTypeEnum.DocumentPageContentMonitoring, tramKey + 'PAGE_CONTENT_SUIVI'],
    [TramPageTypeEnum.ControlPageContentMonitoring, tramKey + 'PAGE_CONTENT_SUIVI_DSI'],
    [TramPageTypeEnum.CloturePageContentMonitoring, tramKey + 'PAGE_CONTENT_SUIVI_RFI'],
    [TramPageTypeEnum.ControlPageExplication, tramKey + 'PAGE_EXPLICATION_DSI'],
    [TramPageTypeEnum.ControlPageHorizontal, tramKey + 'PAGE_H_DSI'],
    [TramPageTypeEnum.CloturePageSection, tramKey + 'PAGE_SECTION_RFI'],
    [TramPageTypeEnum.SkillsPage, tramKey + 'PAGE_SKILLS'],
    [TramPageTypeEnum.ClotureSummaryPAge, tramKey + 'PAGE_SOMMAIRE_RFI'],
    [TramPageTypeEnum.ControlVerticalPage, tramKey + 'PAGE_V_DSI'],
    [TramPageTypeEnum.CollaboratorHorizontalPAge, tramKey + 'PAGE_H_LCA'],
    [TramPageTypeEnum.DocumentListCoverPage, tramKey + 'COVER_PAGE_LDA'],
    [TramPageTypeEnum.CoverPageMultiPr, tramKey + 'COVER_PAGE_MULTI_PR'],
    [TramPageTypeEnum.PageContentSuiviMultiPr, tramKey + 'PAGE_CONTENT_SUIVI_MULTI_PR'],
    [TramPageTypeEnum.PageContentVerticalMultiPr, tramKey + 'PAGE_CONTENT_VERTICAL_MULTI_PR'],
    [TramPageTypeEnum.PageAnnexMultiPr, tramKey + 'PAGE_ANNEX_MULTI_PR'],
    [TramPageTypeEnum.DsiSignatureTrackingPage, tramKey + 'PAGE_SUIVI_SIGNATURE_DSI'],
  ]);

  const dialogKey = 'trams.dialog.pageType.';
  export const toDialogString: SfxMap<TramPageTypeEnum, string> = new SfxMap<TramPageTypeEnum, string>([
    [TramPageTypeEnum.CoverPageClient, dialogKey + 'COVER_PAGE_CLIENT'],
    [TramPageTypeEnum.CoverPage, dialogKey + 'COVER_PAGE'],
    [TramPageTypeEnum.ControlCoverPage, dialogKey + 'COVER_PAGE_DSI'],
    [TramPageTypeEnum.CollaboratorListCoverPage, dialogKey + 'COVER_PAGE_LCA'],
    [TramPageTypeEnum.ClotureCoverPage, dialogKey + 'COVER_PAGE_RFI'],
    [TramPageTypeEnum.FormContent, dialogKey + 'FORM_CONTENT'],
    [TramPageTypeEnum.FormGeneric, dialogKey + 'FORM_GENERIC'],
    [TramPageTypeEnum.FormGenericHorizontal, dialogKey + 'FORM_GENERIC_H'],
    [TramPageTypeEnum.FormModel, dialogKey + 'FORM_MODEL'],
    [TramPageTypeEnum.FormModelHorizontal, dialogKey + 'FORM_MODEL_H'],
    [TramPageTypeEnum.FormTemplate, dialogKey + 'FORM_TEMPLATE'],
    [TramPageTypeEnum.AnnexPage, dialogKey + 'PAGE_ANNEXE'],
    [TramPageTypeEnum.PageContentHorizontal, dialogKey + 'PAGE_CONTENT_H'],
    [TramPageTypeEnum.PageContentVertical, dialogKey + 'PAGE_CONTENT_V'],
    [TramPageTypeEnum.PageContentCloture, dialogKey + 'PAGE_CONTENT_RFI'],
    [TramPageTypeEnum.DocumentPageContentMonitoring, dialogKey + 'PAGE_CONTENT_SUIVI'],
    [TramPageTypeEnum.ControlPageContentMonitoring, dialogKey + 'PAGE_CONTENT_SUIVI_DSI'],
    [TramPageTypeEnum.CloturePageContentMonitoring, dialogKey + 'PAGE_CONTENT_SUIVI_RFI'],
    [TramPageTypeEnum.ControlPageExplication, dialogKey + 'PAGE_EXPLICATION_DSI'],
    [TramPageTypeEnum.ControlPageHorizontal, dialogKey + 'PAGE_H_DSI'],
    [TramPageTypeEnum.CloturePageSection, dialogKey + 'PAGE_SECTION_RFI'],
    [TramPageTypeEnum.SkillsPage, dialogKey + 'PAGE_SKILLS'],
    [TramPageTypeEnum.ClotureSummaryPAge, dialogKey + 'PAGE_SOMMAIRE_RFI'],
    [TramPageTypeEnum.ControlVerticalPage, dialogKey + 'PAGE_V_DSI'],
    [TramPageTypeEnum.CollaboratorHorizontalPAge, dialogKey + 'PAGE_H_LCA'],
    [TramPageTypeEnum.DocumentListCoverPage, key + 'COVER_PAGE_LDA'],
    [TramPageTypeEnum.CoverPageMultiPr, key + 'COVER_PAGE_MULTI_PR'],
    [TramPageTypeEnum.PageContentSuiviMultiPr, key + 'PAGE_CONTENT_SUIVI_MULTI_PR'],
    [TramPageTypeEnum.PageContentVerticalMultiPr, key + 'PAGE_CONTENT_VERTICAL_MULTI_PR'],
    [TramPageTypeEnum.PageAnnexMultiPr, key + 'PAGE_ANNEX_MULTI_PR'],
    [TramPageTypeEnum.DsiSignatureTrackingPage, key + 'PAGE_SUIVI_SIGNATURE_DSI'],
  ]);

  export const multiSelectValues: MultiSelectItemData<TramPageTypeEnum>[] = [
    {
      value: TramPageTypeEnum.Parent0,
      title: 'trams.table.pageType.client',
      children: [
        {
          value: TramPageTypeEnum.CoverPageClient,
          title: toString.getValue(TramPageTypeEnum.CoverPageClient),
          parent: { value: TramPageTypeEnum.Parent1 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },
    {
      value: TramPageTypeEnum.Parent1,
      title: 'trams.table.pageType.defaultDoc',
      children: [
        {
          value: TramPageTypeEnum.CoverPage,
          title: toString.getValue(TramPageTypeEnum.CoverPage),
          parent: { value: TramPageTypeEnum.Parent1 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.DocumentPageContentMonitoring,
          title: toString.getValue(TramPageTypeEnum.DocumentPageContentMonitoring),
          parent: { value: TramPageTypeEnum.Parent1 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.PageContentVertical,
          title: toString.getValue(TramPageTypeEnum.PageContentVertical),
          parent: { value: TramPageTypeEnum.Parent1 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.PageContentHorizontal,
          title: toString.getValue(TramPageTypeEnum.PageContentHorizontal),
          parent: { value: TramPageTypeEnum.Parent1 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.AnnexPage,
          title: toString.getValue(TramPageTypeEnum.AnnexPage),
          parent: { value: TramPageTypeEnum.Parent1 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },
    {
      value: TramPageTypeEnum.Parent2,
      title: 'trams.table.pageType.specificControl',
      children: [
        {
          value: TramPageTypeEnum.ControlCoverPage,
          title: toString.getValue(TramPageTypeEnum.ControlCoverPage),
          parent: { value: TramPageTypeEnum.Parent2 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.ControlPageContentMonitoring,
          title: toString.getValue(TramPageTypeEnum.ControlPageContentMonitoring),
          parent: { value: TramPageTypeEnum.Parent2 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.ControlPageExplication,
          title: toString.getValue(TramPageTypeEnum.ControlPageExplication),
          parent: { value: TramPageTypeEnum.Parent2 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.ControlVerticalPage,
          title: toString.getValue(TramPageTypeEnum.ControlVerticalPage),
          parent: { value: TramPageTypeEnum.Parent2 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.ControlPageHorizontal,
          title: toString.getValue(TramPageTypeEnum.ControlPageHorizontal),
          parent: { value: TramPageTypeEnum.Parent2 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.DsiSignatureTrackingPage,
          title: toString.getValue(TramPageTypeEnum.DsiSignatureTrackingPage),
          parent: { value: TramPageTypeEnum.Parent2 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },
    {
      value: TramPageTypeEnum.Parent3,
      title: 'trams.table.pageType.specificList',
      children: [
        {
          value: TramPageTypeEnum.DocumentListCoverPage,
          title: toString.getValue(TramPageTypeEnum.DocumentListCoverPage),
          parent: { value: TramPageTypeEnum.Parent3 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.CollaboratorHorizontalPAge,
          title: toString.getValue(TramPageTypeEnum.CollaboratorHorizontalPAge),
          parent: { value: TramPageTypeEnum.Parent3 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },

    {
      value: TramPageTypeEnum.Parent4,
      title: 'trams.table.pageType.specificListMultiPr',
      children: [
        {
          value: TramPageTypeEnum.CoverPageMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.CoverPageMultiPr),
          parent: { value: TramPageTypeEnum.Parent4 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.PageContentSuiviMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.PageContentSuiviMultiPr),
          parent: { value: TramPageTypeEnum.Parent4 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.PageContentVerticalMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.PageContentVerticalMultiPr),
          parent: { value: TramPageTypeEnum.Parent4 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.PageAnnexMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.PageAnnexMultiPr),
          parent: { value: TramPageTypeEnum.Parent4 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },

    {
      value: TramPageTypeEnum.Parent5,
      title: 'trams.table.pageType.defaultCloture',
      children: [
        {
          value: TramPageTypeEnum.ClotureCoverPage,
          title: toString.getValue(TramPageTypeEnum.ClotureCoverPage),
          parent: { value: TramPageTypeEnum.Parent5 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.CloturePageContentMonitoring,
          title: toString.getValue(TramPageTypeEnum.CloturePageContentMonitoring),
          parent: { value: TramPageTypeEnum.Parent5 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.ClotureSummaryPAge,
          title: toString.getValue(TramPageTypeEnum.ClotureSummaryPAge),
          parent: { value: TramPageTypeEnum.Parent5 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.CloturePageSection,
          title: toString.getValue(TramPageTypeEnum.CloturePageSection),
          parent: { value: TramPageTypeEnum.Parent5 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.PageContentCloture,
          title: toString.getValue(TramPageTypeEnum.PageContentCloture),
          parent: { value: TramPageTypeEnum.Parent5 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },

    {
      value: TramPageTypeEnum.Parent6,
      title: 'trams.table.pageType.defaultFormAndCol',
      children: [
        {
          value: TramPageTypeEnum.FormModel,
          title: toString.getValue(TramPageTypeEnum.FormModel),
          parent: { value: TramPageTypeEnum.Parent6 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.FormModelHorizontal,
          title: toString.getValue(TramPageTypeEnum.FormModelHorizontal),
          parent: { value: TramPageTypeEnum.Parent6 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.FormGeneric,
          title: toString.getValue(TramPageTypeEnum.FormGeneric),
          parent: { value: TramPageTypeEnum.Parent6 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.FormGenericHorizontal,
          title: toString.getValue(TramPageTypeEnum.FormGenericHorizontal),
          parent: { value: TramPageTypeEnum.Parent6 } as MultiSelectItemData<TramPageTypeEnum>,
        },
        {
          value: TramPageTypeEnum.SkillsPage,
          title: toString.getValue(TramPageTypeEnum.SkillsPage),
          parent: { value: TramPageTypeEnum.Parent6 } as MultiSelectItemData<TramPageTypeEnum>,
        },
      ],
    },
  ];

  export const singleSelectValues = (isFamilyActive: boolean): SingleSelectItemData<TramPageTypeEnum>[] => [
    {
      value: TramPageTypeEnum.Parent0,
      title: 'trams.table.pageType.client',
      children: [
        {
          value: TramPageTypeEnum.CoverPageClient,
          title: toString.getValue(TramPageTypeEnum.CoverPageClient),
          parent: TramPageTypeEnum.Parent1,
        },
      ],
    },
    {
      value: TramPageTypeEnum.Parent1,
      title: 'trams.table.pageType.defaultDoc',
      children: [
        {
          value: TramPageTypeEnum.CoverPage,
          title: toString.getValue(TramPageTypeEnum.CoverPage),
          parent: TramPageTypeEnum.Parent1,
        },
        {
          value: TramPageTypeEnum.DocumentPageContentMonitoring,
          title: toString.getValue(TramPageTypeEnum.DocumentPageContentMonitoring),
          parent: TramPageTypeEnum.Parent1,
        },
        {
          value: TramPageTypeEnum.PageContentVertical,
          title: toString.getValue(TramPageTypeEnum.PageContentVertical),
          parent: TramPageTypeEnum.Parent1,
        },
        {
          value: TramPageTypeEnum.PageContentHorizontal,
          title: toString.getValue(TramPageTypeEnum.PageContentHorizontal),
          parent: TramPageTypeEnum.Parent1,
        },
        {
          value: TramPageTypeEnum.AnnexPage,
          title: toString.getValue(TramPageTypeEnum.AnnexPage),
          parent: TramPageTypeEnum.Parent1,
        },
      ],
    },
    {
      value: TramPageTypeEnum.Parent2,
      title: 'trams.table.pageType.specificControl',
      children: [
        {
          value: TramPageTypeEnum.ControlCoverPage,
          title: toString.getValue(TramPageTypeEnum.ControlCoverPage),
          parent: TramPageTypeEnum.Parent2,
        },
        {
          value: TramPageTypeEnum.ControlPageContentMonitoring,
          title: toString.getValue(TramPageTypeEnum.ControlPageContentMonitoring),
          parent: TramPageTypeEnum.Parent2,
        },
        {
          value: TramPageTypeEnum.ControlPageExplication,
          title: toString.getValue(TramPageTypeEnum.ControlPageExplication),
          parent: TramPageTypeEnum.Parent2,
        },
        {
          value: TramPageTypeEnum.ControlVerticalPage,
          title: toString.getValue(TramPageTypeEnum.ControlVerticalPage),
          parent: TramPageTypeEnum.Parent2,
        },
        {
          value: TramPageTypeEnum.ControlPageHorizontal,
          title: toString.getValue(TramPageTypeEnum.ControlPageHorizontal),
          parent: TramPageTypeEnum.Parent2,
        },
        {
          value: TramPageTypeEnum.DsiSignatureTrackingPage,
          title: toString.getValue(TramPageTypeEnum.DsiSignatureTrackingPage),
          parent: TramPageTypeEnum.Parent2,
        },
      ],
    },
    {
      value: TramPageTypeEnum.Parent3,
      title: 'trams.table.pageType.specificList',
      children: [
        {
          value: TramPageTypeEnum.DocumentListCoverPage,
          title: toString.getValue(TramPageTypeEnum.DocumentListCoverPage),
          parent: TramPageTypeEnum.Parent3,
        },
        {
          value: TramPageTypeEnum.CollaboratorHorizontalPAge,
          title: toString.getValue(TramPageTypeEnum.CollaboratorHorizontalPAge),
          parent: TramPageTypeEnum.Parent3,
        },
      ],
    },

    {
      value: TramPageTypeEnum.Parent4,
      title: 'trams.table.pageType.specificListMultiPr',
      children: [
        {
          value: TramPageTypeEnum.CoverPageMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.CoverPageMultiPr),
          parent: TramPageTypeEnum.Parent4,
        },
        {
          value: TramPageTypeEnum.PageContentSuiviMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.PageContentSuiviMultiPr),
          parent: TramPageTypeEnum.Parent4,
        },
        {
          value: TramPageTypeEnum.PageContentVerticalMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.PageContentVerticalMultiPr),
          parent: TramPageTypeEnum.Parent4,
        },
        {
          value: TramPageTypeEnum.PageAnnexMultiPr,
          title: toDialogString.getValue(TramPageTypeEnum.PageAnnexMultiPr),
          parent: TramPageTypeEnum.Parent4,
        },
      ],
    },

    {
      value: TramPageTypeEnum.Parent5,
      title: 'trams.table.pageType.defaultCloture',
      children: [
        {
          value: TramPageTypeEnum.ClotureCoverPage,
          title: toString.getValue(TramPageTypeEnum.ClotureCoverPage),
          parent: TramPageTypeEnum.Parent5,
        },
        {
          value: TramPageTypeEnum.CloturePageContentMonitoring,
          title: toString.getValue(TramPageTypeEnum.CloturePageContentMonitoring),
          parent: TramPageTypeEnum.Parent5,
        },
        {
          value: TramPageTypeEnum.ClotureSummaryPAge,
          title: toString.getValue(TramPageTypeEnum.ClotureSummaryPAge),
          parent: TramPageTypeEnum.Parent5,
        },
        {
          value: TramPageTypeEnum.CloturePageSection,
          title: toString.getValue(TramPageTypeEnum.CloturePageSection),
          parent: TramPageTypeEnum.Parent5,
        },
        {
          value: TramPageTypeEnum.PageContentCloture,
          title: toString.getValue(TramPageTypeEnum.PageContentCloture),
          parent: TramPageTypeEnum.Parent5,
        },
      ],
    },

    {
      value: TramPageTypeEnum.Parent6,
      title: 'trams.table.pageType.defaultFormAndCol',
      children: [
        {
          value: TramPageTypeEnum.FormModel,
          title: toString.getValue(TramPageTypeEnum.FormModel),
          disabled: isFamilyActive,
          parent: TramPageTypeEnum.Parent6,
        },
        {
          value: TramPageTypeEnum.FormModelHorizontal,
          title: toString.getValue(TramPageTypeEnum.FormModelHorizontal),
          disabled: isFamilyActive,
          parent: TramPageTypeEnum.Parent6,
        },
        {
          value: TramPageTypeEnum.FormGeneric,
          title: toString.getValue(TramPageTypeEnum.FormGeneric),
          disabled: isFamilyActive,
          parent: TramPageTypeEnum.Parent6,
        },
        {
          value: TramPageTypeEnum.FormGenericHorizontal,
          title: toString.getValue(TramPageTypeEnum.FormGenericHorizontal),
          disabled: isFamilyActive,
          parent: TramPageTypeEnum.Parent6,
        },
        {
          value: TramPageTypeEnum.SkillsPage,
          title: toString.getValue(TramPageTypeEnum.SkillsPage),
          disabled: isFamilyActive,
          parent: TramPageTypeEnum.Parent6,
        },
      ],
    },
  ];

  export const multiItemsResolver = (): Observable<MultiSelectItemData<TramPageTypeEnum>[]> => of(multiSelectValues);

  export const singleItemsResolver = (_: string, __: SearchStrategyEnum, isFamilyActive: boolean): Observable<SingleSelectItemData<TramPageTypeEnum>[]> =>
    of(singleSelectValues(isFamilyActive));

  export const comparePredicate = (node: SingleSelectItemData<TramPageTypeEnum>, item: TramPageTypeEnum) => node.value === item;

  export const disableFamilyByForms = [TramPageTypeEnum.FormModel, TramPageTypeEnum.FormModelHorizontal, TramPageTypeEnum.FormGeneric, TramPageTypeEnum.FormGenericHorizontal];
}
