import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Actions, ofType } from '@ngrx/effects';

import { first, tap, withLatestFrom } from 'rxjs/operators';

import { AppState } from '../../app.state';

import { SfxDialogComponent } from '../../sfx-dialog/sfx-dialog.component';
import { resetStates } from '../../main/state/main.actions';
import { getNavigationRouteSegmentsIds } from '../../router';

@Injectable({ providedIn: 'root' })
export class ResetStateGuard {
  constructor(private store: Store<AppState>, private actions$: Actions) {}

  canDeactivate(__: SfxDialogComponent, route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    this.actions$
      .pipe(
        ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
        first(),
        withLatestFrom(this.store.pipe(select(getNavigationRouteSegmentsIds))),
        tap(([___, segments]) => route.data && !segments.includes(route.data.routeId) && this.store.dispatch(resetStates({ statesNames: route.data.stateToReset }))),
      )
      .subscribe();

    return true;
  }
}
