import { UserAccountResponseDTO, ProjectDetailsResponseDTO } from '../../generated/models/models';

import { DateUtils } from '../../utils/date.utils';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { AccountTabletData } from './account-tablet-data';
import { UserForUpdate } from './user-for-update';
import { CompanySummaryData } from '../companies/companies-summary-data';

import { UserRightEnum, LanguageEnum } from '../../enums';

export interface UserData {
  uuid: string;
  uuidEntity: string;
  firstName: string;
  lastName: string;
  companyName: string;
  authority: UserRightEnum;
  company: CompanySummaryData;
  additionalAuthorities: UserRightEnum[];
  email: string;
  ssoIdentifier: string;
  language: LanguageEnum;
  labelFamilyFunction: EntityLightData;
  applicability: EntityLightData;
  profession: string;
  subdomain: string;
  hmacIntercom: string;
  signature: string;
  trigram: string;
  modificationDate: string;
  uniqueDeviceId: string;
  batteryLevel: string;
  longitude: string;
  latitude: string;
  version: string;
  androidVersion: string;
  backupActivated: boolean;
  addressMac: string;
  lastSeen: string;
  deviceScreenSize: string;
  deviceModel: string;
  hasMultiApplicability: boolean;
  uuidOrganization: string;
}

export namespace UserData {
  export function mapFromApiValue(userData: UserAccountResponseDTO): UserData {
    return {
      uuidEntity: userData.uuidEntity,
      uuid: userData.uuid,
      firstName: userData.firstName,
      lastName: userData.lastName,
      companyName: userData.companyName,
      authority: UserRightEnum.convertFromApiValue.getValue(userData.userRight?.name),
      company: userData.userRight?.company && CompanySummaryData.mapFromApi(userData.userRight?.company),
      additionalAuthorities: (userData.additionalAuthorities || []).map(role => UserRightEnum.convertFromApiValue.getValue(role)),
      email: userData.email,
      ssoIdentifier: userData.ssoIdentifier,
      language:
        LanguageEnum.convertFromApiValue.getValue(
          userData.langKey ? (userData.langKey.toUpperCase() as ProjectDetailsResponseDTO.ExportLanguageOneEnum) : ProjectDetailsResponseDTO.ExportLanguageOneEnum.FR,
        ) || LanguageEnum.None,
      labelFamilyFunction: userData.labelFamilyFunction && LabelFamilyLightData.mapToEntityLightData(userData.labelFamilyFunction),
      applicability: userData.applicability && LabelApplicabilityLightData.mapToEntityLightData(userData.applicability),
      profession: userData.profession,
      signature: userData.signature,
      trigram: userData.trigram?.toLocaleUpperCase(),
      subdomain: userData.subdomain,
      hmacIntercom: userData.hmacIntercom,
      modificationDate: userData.lastModifiedDate && DateUtils.toDateFormat(userData.lastModifiedDate),
      batteryLevel: userData.lastBatteryLevel,
      longitude: userData.lastLongitude,
      latitude: userData.lastLatitude,
      version: userData.versionApp,
      androidVersion: userData.androidVersion,
      uniqueDeviceId: userData.uniqueDeviceId,
      backupActivated: userData['backupActivated'], // @Review: @MakeModels: @Ihsen
      addressMac: userData.addressMac,
      lastSeen: userData.lastSeen && DateUtils.toCalendarFormat(userData.lastSeen),
      deviceScreenSize: userData.deviceScreenSize,
      deviceModel: userData.deviceModel,
      hasMultiApplicability: userData.hasMultiApplicability,
      uuidOrganization: userData.uuidOrganization,
    };
  }

  export function mapToUserForUpdate(userData: UserData): UserForUpdate {
    return {
      uuidEntity: userData.uuidEntity,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      ssoIdentifier: userData.ssoIdentifier,
      language: userData.language,
      labelFamilyFunction: userData.labelFamilyFunction,
      profession: userData.profession,
      signature: userData.signature,
      trigram: (userData.trigram || '').toLocaleUpperCase(),
      modificationDate: userData.modificationDate,
    };
  }

  export function mapToTabletData(userData: UserData): AccountTabletData {
    return {
      uuidEntity: userData.uuidEntity,
      uniqueDeviceId: userData.uniqueDeviceId,
      batteryLevel: userData.batteryLevel ? `${userData.batteryLevel} %` : null,
      latitude: userData.latitude,
      longitude: userData.longitude,
      version: userData.version,
      androidVersion: userData.androidVersion,
      backupActivated: false,
      addressMac: userData.addressMac,
      lastSeen: userData.lastSeen && DateUtils.toCalendarFormat(userData.lastSeen),
      deviceScreenSize: userData.deviceScreenSize,
      deviceModel: userData.deviceModel,
    };
  }
}
