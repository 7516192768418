import { sortBy } from 'lodash';

import { PageStepViewRequestDTO } from '../../generated/models/pageStepViewRequestDTO';
import { StepViewDetailsResponseDTO } from '../../generated/models/stepViewDetailsResponseDTO';
import { BlockViewDetailsData } from '../block/block-view-details-data';

import { BlockStatusEnum, BlockTypeEnum } from '../../enums';
import { PhaseSummaryData } from '../phase/phase-summary-data';
import { FlowLightData } from '../flows/flow-light-data';

export interface StepDetailsViewData {
  name: string;
  customNodeOrder: string;
  revisionNodeOrder: string;
  nodeOrder: number;
  uuidEntity: string;
  blocks: BlockViewDetailsData[];
  linkedFlow: FlowLightData;
  parentPhase: PhaseSummaryData;
  disabled: boolean;
  additionalComment?: string;
  nodeOrderToDisplay?: number;
  parentFlowUuidEntity: string;
}

export namespace StepDetailsViewData {
  export interface QueryRequest {
    projectUuidEntity: string;
    stepUuidEntity: string;
    blocks?: BlockTypeEnum[];
    statuses?: BlockStatusEnum[];
    families?: string[];
    categories?: string[];
  }

  export function mapFromApiValue(stepViewDetails: StepViewDetailsResponseDTO): StepDetailsViewData {
    return {
      name: stepViewDetails.name,
      customNodeOrder: stepViewDetails.customNodeOrder,
      revisionNodeOrder: stepViewDetails.revisionNodeOrder,
      nodeOrder: stepViewDetails.nodeOrder,
      uuidEntity: stepViewDetails.uuidEntity,
      linkedFlow: stepViewDetails.linkedFlow && FlowLightData.mapFromApiValue(stepViewDetails.linkedFlow),
      blocks: sortBy(
        (stepViewDetails.blocs || []).map(block =>
          BlockViewDetailsData.mapFromApiValue(isBlockEnabled(stepViewDetails, BlockTypeEnum.convertFromApiValue.getValue(block.natureBloc)), block),
        ),
        'type',
      ),
      parentPhase: PhaseSummaryData.mapFromApiValue(stepViewDetails.phase),
      additionalComment: stepViewDetails.additionalComment,
      nodeOrderToDisplay: stepViewDetails.nodeOrderToDisplay,
      disabled: stepViewDetails.disabled,
      parentFlowUuidEntity: stepViewDetails.parentFlowUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageStepViewRequestDTO {
    return {
      projectUuidEntity: queries.projectUuidEntity,
      stepUuidEntity: queries.stepUuidEntity,
      listNatureBloc: (queries.blocks && queries.blocks.map(block => BlockTypeEnum.convertToApiValue.getValue(block))) || undefined,
      listStatusBloc: (queries.statuses && queries.statuses.map(status => BlockStatusEnum.convertToApiValue.getValue(status))) || undefined,
    };
  }

  export function initializeQueryRequest(stepUuidEntity: string, projectUuidEntity: string, blocks: string[], statuses: string[]): QueryRequest {
    return {
      projectUuidEntity,
      stepUuidEntity,
      blocks: (blocks && blocks.map(block => BlockTypeEnum.convertFromParamsValue.getValue(block))) || undefined,
      statuses: (statuses && statuses.map(status => BlockStatusEnum.convertFromParamsValue.getValue(status))) || undefined,
    };
  }

  export function isBlockEnabled(
    stepViewDetails: { adr: boolean; emm: boolean; form: boolean; dsi: boolean; txt: boolean; pr: boolean; multiPr: boolean; rhp: boolean },
    blockType: BlockTypeEnum,
  ): boolean {
    switch (blockType) {
      case BlockTypeEnum.Adr:
        return stepViewDetails.adr;

      case BlockTypeEnum.Emm:
        return stepViewDetails.emm;

      case BlockTypeEnum.Form:
        return stepViewDetails.form;

      case BlockTypeEnum.Dsi:
        return stepViewDetails.dsi;

      case BlockTypeEnum.Txt:
        return stepViewDetails.txt;

      case BlockTypeEnum.Pr:
        return stepViewDetails.pr;

      case BlockTypeEnum.MultiPr:
        return stepViewDetails.multiPr;

      case BlockTypeEnum.Rhp:
        return stepViewDetails.rhp;

      default:
        return false;
    }
  }
}
