export enum TranslationFilesPathEnum {
  AccessApi = './assets/i18n/access-api/',
  AccountSettings = './assets/i18n/account-settings/',
  Activate = './assets/i18n/activate-v2/',
  AdvancedSearch = 'assets/i18n/advanced-search/',
  ApplicationEvent = 'assets/i18n/application-event/',
  AppMenu = 'assets/i18n/app-menu/',
  Backups = 'assets/i18n/backup/',
  LogsPush = 'assets/i18n/logs-push/',
  Blocks = 'assets/i18n/block/',
  BreadCrumb = 'assets/i18n/breadcrumb/',
  BusinessClassName = 'assets/i18n/business-class-name/',
  Categories = 'assets/i18n/category/',
  Clients = 'assets/i18n/client/',
  ClosingFolder = 'assets/i18n/closing-folder/',
  Contributors = 'assets/i18n/contributors/',
  CustomCell = 'assets/i18n/custom-cell/',
  CustomColumn = 'assets/i18n/custom-columns/',
  CustomFields = 'assets/i18n/custom-fields/',
  CustomFiles = 'assets/i18n/custom-file/',
  CustomMenu = 'assets/i18n/custom-menu/',
  CustomStatus = 'assets/i18n/custom-status/',
  Companies = 'assets/i18n/companies/',
  DashboardDocuments = 'assets/i18n/dashboard-documents/',
  DashboardMetrics = 'assets/i18n/dashboard-metrics/',
  DashboardTab = 'assets/i18n/dashboard-tab/',
  DocumentEvent = 'assets/i18n/document-event/',
  DocumentNature = 'assets/i18n/nature-document/',
  DocumentProvenance = 'assets/i18n/document-provenance/',
  Documents = 'assets/i18n/document/',
  Emm = 'assets/i18n/emm/',
  EntityChronos = 'assets/i18n/entity-timeline/',
  ExportableValues = 'assets/i18n/exportable-value/',
  ExternalUsers = './assets/i18n/external-users/',
  Families = './assets/i18n/families/',
  FieldFeedback = './assets/i18n/field-feedback/',
  FileProcessing = './assets/i18n/file-processing/',
  Flow = 'assets/i18n/flow/',
  Flows = 'assets/i18n/flows/',
  FlowTab = 'assets/i18n/flow-tab/',
  FlowsContent = 'assets/i18n/flow-content/',
  Folders = 'assets/i18n/folder/',
  Forms = 'assets/i18n/form-v2/',
  Information = 'assets/i18n/information/',
  GeneralSettings = './assets/i18n/general-settings/',
  Globals = 'assets/i18n/globals/',
  Languages = 'assets/i18n/language/',
  LabelFamilies = 'assets/i18n/label-family/',
  LabelFamiliesNature = 'assets/i18n/label-family-nature/',
  LibraryDocuments = 'assets/i18n/library-documents/',
  Lists = 'assets/i18n/lists/',
  Login = 'assets/i18n/login-v2/',
  Navbar = 'assets/i18n/navbar/',
  Onboarding = 'assets/i18n/onboarding-v2/',
  Operators = 'assets/i18n/operators/',
  Options = 'assets/i18n/option/',
  Phases = 'assets/i18n/phase/',
  Plan = 'assets/i18n/plan/',
  Pgac = 'assets/i18n/pgac/',
  Projects = 'assets/i18n/projects/',
  Profile = 'assets/i18n/profile/',
  Questions = 'assets/i18n/question-v2/',
  References = 'assets/i18n/reference/',
  ReferentialFlow = 'assets/i18n/referential-flow/',
  Response = 'assets/i18n/response/',
  ResponseProvenance = 'assets/i18n/response-provenance/',
  ResetPwd = 'assets/i18n/reset-password/',
  Risk = 'assets/i18n/risk/',
  Rubric = 'assets/i18n/rubric-v2/',
  RubricOption = 'assets/i18n/rubric-option/',
  ScheduleTasks = 'assets/i18n/schedule-tasks/',
  Schedule = 'assets/i18n/schedule/',
  Sections = 'assets/i18n/sections-v2/',
  SettingsKpis = 'assets/i18n/settings-kpi/',
  SettingWordingConfiguration = 'assets/i18n/setting-wording-configuration/',
  Share = 'assets/i18n/share/',
  Signatory = 'assets/i18n/signatory/',
  SignatureList = 'assets/i18n/signature-list/',
  SignUp = 'assets/i18n/sign-up-v2/',
  Skills = 'assets/i18n/skill/',
  Step = 'assets/i18n/step/',
  Tablets = 'assets/i18n/tablets/',
  Tags = 'assets/i18n/tags/',
  TemplateParams = 'assets/i18n/template-params/',
  TechnicalObject = 'assets/i18n/technical-object/',
  Toaster = 'assets/i18n/toaster/',
  Trams = 'assets/i18n/frames/',
  Trash = 'assets/i18n/trash/',
  Users = 'assets/i18n/users/',
  visualization = 'assets/i18n/visualization/',
}
