export enum LocalStorageEnum {
  AuthToken = 'authenticationToken',
  SessionAuthToken = 'sessionAuthenticationToken',
  DateFormat = 'sfx_date_format',
  DateTimeFormat = 'sfx_date-time-format',
  TimeFormat = 'sfx_time_format',
  SensitiveDateTimeFormat = 'sfx_sensitive_date_time_format',
  EventDateTimeFormat = 'sfx_event_date_time_format',
  currentLanguage = 'currentLang',
  oauth2 = 'oauth2',
  refreshToken = 'refresh-token',
  authStateControl = 'auth-state-control',
  historyAccess = 'history_access_',
  BlockCopyPaste = 'block_copy_paste',
  MainMenuCollapsed = 'main_menu_collapsed',
  FlowMenuCollapsed = 'flow_menu_collapsed',
  FormMenuCollapsed = 'form_menu_collapsed',
  MainMenuWidth = 'main_menu_width',
  FormMenuWidth = 'form_menu_width',
  FlowMenuWidth = 'flow_menu_width',
  CopyPastePGAC = 'CopyPastePGAC',
  LastBlockVisited = 'step_',
  LastStepVisited = 'flow_',
  SelectedFilters = 'filters',
  SelectedFlowFilter = 'SelectedFlowFilter',
  SelectedFlows = 'SelectedFlows',
  ReturnUrl = 'returnUrl',
  CollapseDateColumn = 'collapseDateColumn',
  DateColumnWidth = 'dateColumnWidth',
  ColumnsConfigurations = 'columns_configurations',
  NumberFormat = 'number_format',
}
