import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { DocumentFlowSummaryResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { DocumentVersionStatusEnum } from './document-version-status.enum';
import { IconData } from '../../../modules/shared/interfaces/icon-data';

export enum DocumentStatusEnum {
  Prel = 1, // Préliminaire
  Bpo = 2, // Bon Pour Observation
  Bpe = 3, // Bon Pour Exécution
  Temp = 4, // TEMP
  Bpa = 5, // Bon Pour Application
  Other = 6,
}
export namespace DocumentStatusEnum {
  const diffusionValues: DocumentStatusEnum[] = [DocumentStatusEnum.Bpo, DocumentStatusEnum.Bpe, DocumentStatusEnum.Bpa];
  const documentMetrics: DocumentStatusEnum[] = [DocumentStatusEnum.Prel, DocumentStatusEnum.Bpo, DocumentStatusEnum.Bpe, DocumentStatusEnum.Bpa, DocumentStatusEnum.Other];

  export const convertFromApiValue = new SfxMap<DocumentFlowSummaryResponseDTO.EtatEnum, DocumentStatusEnum>([
    [DocumentFlowSummaryResponseDTO.EtatEnum.PREL, DocumentStatusEnum.Prel],
    [DocumentFlowSummaryResponseDTO.EtatEnum.BPE, DocumentStatusEnum.Bpe],
    [DocumentFlowSummaryResponseDTO.EtatEnum.BPO, DocumentStatusEnum.Bpo],
    [DocumentFlowSummaryResponseDTO.EtatEnum.TEMP, DocumentStatusEnum.Temp],
    [DocumentFlowSummaryResponseDTO.EtatEnum.AUTRE, DocumentStatusEnum.Other],
    [DocumentFlowSummaryResponseDTO.EtatEnum.BPA, DocumentStatusEnum.Bpa],
  ]);

  export const convertToApiValue: SfxMap<DocumentStatusEnum, DocumentFlowSummaryResponseDTO.EtatEnum> = new SfxMap<DocumentStatusEnum, DocumentFlowSummaryResponseDTO.EtatEnum>([
    [DocumentStatusEnum.Prel, DocumentFlowSummaryResponseDTO.EtatEnum.PREL],
    [DocumentStatusEnum.Bpe, DocumentFlowSummaryResponseDTO.EtatEnum.BPE],
    [DocumentStatusEnum.Bpo, DocumentFlowSummaryResponseDTO.EtatEnum.BPO],
    [DocumentStatusEnum.Temp, DocumentFlowSummaryResponseDTO.EtatEnum.TEMP],
    [DocumentStatusEnum.Other, null],
    [DocumentStatusEnum.Bpa, DocumentFlowSummaryResponseDTO.EtatEnum.BPA],
  ]);

  export const convertToParamsValue: SfxMap<DocumentStatusEnum, string> = new SfxMap<DocumentStatusEnum, string>([
    [DocumentStatusEnum.Prel, 'prel'],
    [DocumentStatusEnum.Bpe, 'bpe'],
    [DocumentStatusEnum.Bpo, 'bpo'],
    [DocumentStatusEnum.Temp, 'temp'],
    [DocumentStatusEnum.Other, 'other'],
    [DocumentStatusEnum.Bpa, 'bpa'],
  ]);

  export const convertFromParamsValue: SfxMap<string, DocumentStatusEnum> = new SfxMap<string, DocumentStatusEnum>([
    ['prel', DocumentStatusEnum.Prel],
    ['bpe', DocumentStatusEnum.Bpe],
    ['bpo', DocumentStatusEnum.Bpo],
    ['temp', DocumentStatusEnum.Temp],
    ['other', DocumentStatusEnum.Other],
    ['bpa', DocumentStatusEnum.Bpa],
  ]);

  const key = 'document.statuses.';
  export const toString: SfxMap<DocumentStatusEnum, string> = new SfxMap<DocumentStatusEnum, string>([
    [DocumentStatusEnum.Prel, key + 'prel'],
    [DocumentStatusEnum.Bpe, key + 'bpe'],
    [DocumentStatusEnum.Bpo, key + 'bpo'],
    [DocumentStatusEnum.Temp, key + 'bpe'],
    [DocumentStatusEnum.Other, key + 'other'],
    [DocumentStatusEnum.Bpa, key + 'bpa'],
  ]);

  const filtreKey = 'document.filtreStatuses.';
  export const toFiltreString: SfxMap<DocumentStatusEnum, string> = new SfxMap<DocumentStatusEnum, string>([
    [DocumentStatusEnum.Prel, filtreKey + 'prel'],
    [DocumentStatusEnum.Bpe, filtreKey + 'bpe'],
    [DocumentStatusEnum.Bpo, filtreKey + 'bpo'],
    [DocumentStatusEnum.Temp, filtreKey + 'temp'],
    [DocumentStatusEnum.Other, filtreKey + 'other'],
    [DocumentStatusEnum.Bpa, filtreKey + 'bpa'],
  ]);

  const indexKey = 'document.indexStatuses.';
  export const concatDocIndex = (docVersionStatus?: DocumentVersionStatusEnum) =>
    new SfxMap<DocumentStatusEnum, string>([
      [DocumentStatusEnum.Prel, indexKey + 'prel'],
      [DocumentStatusEnum.Bpe, indexKey + (docVersionStatus === DocumentVersionStatusEnum.BpeValid ? 'bpeValid' : 'bpe')],
      [DocumentStatusEnum.Bpo, indexKey + 'bpo'],
      [DocumentStatusEnum.Temp, indexKey + 'bpe'],
      [DocumentStatusEnum.Other, indexKey + 'other'],
      [DocumentStatusEnum.Bpa, indexKey + 'bpa'],
    ]);

  export const tooltip = (docVersionStatus?: DocumentVersionStatusEnum) =>
    new SfxMap<DocumentStatusEnum, string>([
      [DocumentStatusEnum.Prel, key + 'tooltip.prel'],
      [DocumentStatusEnum.Bpe, key + (docVersionStatus === DocumentVersionStatusEnum.BpeValid ? 'tooltip.bpeValid' : 'tooltip.bpe')],
      [DocumentStatusEnum.Bpo, key + (docVersionStatus === DocumentVersionStatusEnum.ClientRes ? 'tooltip.bpoForClientRes' : 'tooltip.bpoForDiffusion')],
      [DocumentStatusEnum.Temp, key + 'tooltip.temp'],
      [DocumentStatusEnum.Other, key + 'tooltip.other'],
      [DocumentStatusEnum.Bpa, key + getDocumentStatusText(docVersionStatus)],
    ]);

  export const sfsTooltip: SfxMap<string, string> = new SfxMap<string, string>([
    ['PREL', key + 'tooltip.prel'],
    ['BPE', key + 'tooltip.bpe'],
    ['BPO', key + 'tooltip.bpo'],
    ['TEMP', key + 'tooltip.temp'],
    ['OTHER', key + 'tooltip.other'],
    ['BPA', key + 'tooltip.bpa'],
  ]);

  export const translate: SfxMap<string, string> = new SfxMap<string, string>([
    ['PREL', key + 'prel'],
    ['BPE', key + 'bpe'],
    ['BPO', key + 'bpo'],
    ['TEMP', key + 'temp'],
    ['OTHER', key + 'other'],
    ['BPA', key + 'bpa'],
  ]);

  export const toChartColor: SfxMap<DocumentStatusEnum, string> = new SfxMap<DocumentStatusEnum, string>([
    [DocumentStatusEnum.Prel, '#878E95'],
    [DocumentStatusEnum.Bpo, '#F3AE3D'],
    [DocumentStatusEnum.Bpe, '#5DB082'],
    [DocumentStatusEnum.Bpa, '#EF9040'],
    [DocumentStatusEnum.Other, '#19244A'],
  ]);

  export const color = (docVersionStatus?: DocumentVersionStatusEnum) =>
    new SfxMap<DocumentStatusEnum, string>([
      [DocumentStatusEnum.Prel, '#868E96'],
      [DocumentStatusEnum.Bpe, '#36B37E'],
      [DocumentStatusEnum.Bpo, '#FFAB01'],
      [DocumentStatusEnum.Temp, '#36B37E'],
      [DocumentStatusEnum.Other, '#596D86'],
      [DocumentStatusEnum.Bpa, [DocumentVersionStatusEnum.ClientResBpa, DocumentVersionStatusEnum.DiffusionBpa].includes(docVersionStatus) ? '#fe8b24' : '#4dbeff'],
    ]);

  function mapToSingleSelectChartItem(status: DocumentStatusEnum): SingleSelectItemData<DocumentStatusEnum> {
    return {
      value: status,
      title: toString.getValue(status),
      color: toChartColor.getValue(status),
    };
  }

  export const selectChartItems = documentMetrics.map(status => mapToSingleSelectChartItem(status));

  export const itemsResolver = (): Observable<SingleSelectItemData<DocumentStatusEnum>[]> =>
    of(diffusionValues.map(status => ({ value: status, title: toString.getValue(status) })));

  export const metricsItemsResolver = (): Observable<SingleSelectItemData<DocumentStatusEnum>[]> =>
    of(documentMetrics.map(status => ({ value: status, title: toString.getValue(status) })));

  export function getDocumentStatusText(docVersionStatus: DocumentVersionStatusEnum): string {
    if (docVersionStatus === DocumentVersionStatusEnum.ClientResBpa) {
      return 'tooltip.bpaForClientRes';
    }

    return docVersionStatus === DocumentVersionStatusEnum.DiffusionBpa ? 'tooltip.bpaForDiffusion' : 'tooltip.bpa';
  }

  export function getIconDataDetails(docVersionState: DocumentStatusEnum): IconData {
    if (docVersionState === DocumentStatusEnum.Bpo) {
      return { name: 'mail-bulk', prefix: 'fas', order: 3 };
    }

    return docVersionState === DocumentStatusEnum.Temp ? { name: 'hourglass', prefix: 'fas', order: 3 } : undefined;
  }
}
