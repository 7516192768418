import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseHttpService } from '../../../core/services/base-http.service';
import { SessionService } from '../services/session.service';

import { RouterPaths } from '../../../core/constant/route.constant';

@Injectable({ providedIn: 'root' })
export class SessionGuard {
  constructor(private router: Router, private sessionService: SessionService, private baseHttpService: BaseHttpService) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const idToken = this.baseHttpService.getJwtAccessToken();
    if (!idToken) {
      this.router.navigate([RouterPaths.EntryPaths.loginPath], { replaceUrl: true });

      return of(false);
    }

    return this.sessionService.checkTokenValidity({ body: { idToken } }).pipe(
      tap(hasValidToken => {
        if (_state.url === '/') {
          this.router.navigate([hasValidToken ? RouterPaths.RootPaths.mainPath : RouterPaths.EntryPaths.loginPath], { replaceUrl: true });
        }
      }),
    );
  }
}
