import { ProjectSummaryResponseDTO } from '../generated/models/models';
import { IconData } from '../../modules/shared/interfaces/icon-data';
import { SfxMap } from '../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../modules/shared/components/select-v2/utils/select-item-data';

/* INFO: start from 100, because we combine RoleEnum and UserRoleEnum is some phase */
export enum UserRoleEnum {
  ReadOnly = 100,
  Operator = 101,
  Writer = 102,
  Expert = 103,
  Responsible = 104,
  NotContractor = -1,
}

export interface UserRoleType {
  [uuidEntity: string]: UserRoleEnum[];
}

export namespace UserRoleEnum {
  const values: UserRoleEnum[] = [UserRoleEnum.ReadOnly, UserRoleEnum.Writer, UserRoleEnum.Expert, UserRoleEnum.Responsible];

  export const convertFromApiValue = new SfxMap<ProjectSummaryResponseDTO.CurrentUserRolesEnum, UserRoleEnum>([
    [ProjectSummaryResponseDTO.CurrentUserRolesEnum.ADMINPROJECT, UserRoleEnum.Responsible],
    [ProjectSummaryResponseDTO.CurrentUserRolesEnum.ADVANCED, UserRoleEnum.Expert],
    [ProjectSummaryResponseDTO.CurrentUserRolesEnum.NORMAL, UserRoleEnum.Writer],
    [ProjectSummaryResponseDTO.CurrentUserRolesEnum.OPERATOR, UserRoleEnum.Operator],
    [ProjectSummaryResponseDTO.CurrentUserRolesEnum.READONLY, UserRoleEnum.ReadOnly],
  ]);

  export const convertToApiValue = new SfxMap<UserRoleEnum, ProjectSummaryResponseDTO.CurrentUserRolesEnum>([
    [UserRoleEnum.Responsible, ProjectSummaryResponseDTO.CurrentUserRolesEnum.ADMINPROJECT],
    [UserRoleEnum.Expert, ProjectSummaryResponseDTO.CurrentUserRolesEnum.ADVANCED],
    [UserRoleEnum.Writer, ProjectSummaryResponseDTO.CurrentUserRolesEnum.NORMAL],
    [UserRoleEnum.Operator, ProjectSummaryResponseDTO.CurrentUserRolesEnum.OPERATOR],
    [UserRoleEnum.ReadOnly, ProjectSummaryResponseDTO.CurrentUserRolesEnum.READONLY],
  ]);

  const key = 'contributors.filters.';
  export const toString: SfxMap<UserRoleEnum, string> = new SfxMap<UserRoleEnum, string>([
    [UserRoleEnum.ReadOnly, key + 'guest'],
    [UserRoleEnum.Writer, key + 'redactor'],
    [UserRoleEnum.Expert, key + 'expert'],
    [UserRoleEnum.Responsible, key + 'responsible'],
    [UserRoleEnum.Operator, key + 'operator'],
  ]);

  export const convertFromParamsValue: SfxMap<string, UserRoleEnum> = new SfxMap<string, UserRoleEnum>([
    ['guest', UserRoleEnum.ReadOnly],
    ['redactor', UserRoleEnum.Writer],
    ['expert', UserRoleEnum.Expert],
    ['responsible', UserRoleEnum.Responsible],
    ['operator', UserRoleEnum.Operator],
  ]);

  export const convertToParamsValue: SfxMap<UserRoleEnum, string> = new SfxMap<UserRoleEnum, string>([
    [UserRoleEnum.ReadOnly, 'guest'],
    [UserRoleEnum.Writer, 'redactor'],
    [UserRoleEnum.Expert, 'expert'],
    [UserRoleEnum.Responsible, 'responsible'],
    [UserRoleEnum.Operator, 'operator'],
  ]);

  export const iconData: SfxMap<UserRoleEnum, IconData> = new SfxMap<UserRoleEnum, IconData>([[UserRoleEnum.Operator, { name: 'user-astronaut', prefix: 'fas' }]], {
    name: 'user-tie',
    prefix: 'fas',
  });

  export const iconClass: SfxMap<UserRoleEnum, string> = new SfxMap<UserRoleEnum, string>(
    [
      [UserRoleEnum.Operator, 'sfx-chip-operator'],
      [UserRoleEnum.Operator, 'sfx-chip-operator'],
    ],
    'sfx-chip-primary',
  );

  export const itemsResolver = (): Observable<SingleSelectItemData<UserRoleEnum>[]> =>
    of(values.map(roleContext => ({ value: roleContext, title: toString.getValue(roleContext) })));

  export const comparePredicate = (node: SingleSelectItemData<UserRoleEnum>, item: UserRoleEnum) => node.value === item;

  export const canChangeRole = (currentUserRoles: UserRoleEnum[], contributorRole: UserRoleEnum) =>
    !contributorRole || (contributorRole !== UserRoleEnum.Operator && currentUserRoles?.some(currentUserRole => currentUserRole > contributorRole));
}
