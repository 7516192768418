import { DashboardTabletDialogState, initialDashboardTabletDialogState } from '../../dashboard/tablets/dialogs/state/dashboard-tablet-dialog.reducer';
import { DashboardCategoryDialogState, initialDashboardCategoryDialogState } from '../../dashboard/categories/dialogs/state/category-dialog.reducer';
import { TabletDialogState, tabletDialogInitialState } from '../../contributors/tablets/dialogs/state/tablets-dialog.reducer';
import { LibraryObjectDialogState, initialLibraryObjectDialogState } from '../../library/object-management/dialogs/state/object-dialog.reducer';
import { DefaultCategoryDialogState, initialDefaultCategoryDialogState } from '../../settings/default-categories/dialogs/state/category-dialog.reducer';
import { SettingsFamilyDialogState, initialSettingsFamilyDialogState } from '../../settings/families/dialogs/state/family-dialog.reducer';
import { UserDialogState, initialLibraryContributorDialogState } from '../../contributors/users/dialogs/state/users-dialog.reducer';
import { SettingsClientDialogState, initialSettingsClientDialogState } from '../../settings/client-notebook/dialogs/state/client-dialog.reducer';
import { AccessApiDialogState, initialAccessApiDialogState } from '../../contributors/access-api/access-api-v1/dialogs/state/access-api-dialog.reducer';
import { DashboardFormDialogState, initialDashboardFormDialogState } from '../../dashboard/forms/dialogs/state/form-dialog.reducer';
import { initialDashboardListsDialogState, DashboardListsDialogState } from '../../dashboard/list/dialogs/state/lists-dialog-reducer';
import { OperatorDialogState, initialLibraryOperatorDialogState } from '../../contributors/operators/dialogs/state/operators-dialog.reducer';
import { initialLibraryFormDialogState, LibraryFormDialogState } from '../../library/forms-list/dialogs/state/form-library-dialog.reducer';
import { initialLibraryRiskDialogState, LibraryRiskDialogState } from '../../library/risks-list/dialogs/state/risk-dialog.reducer';
import { LibraryEquipmentDialogState, initialLibraryEquipmentsDialogState } from '../../library/equipments/dialogs/state/equipment-dialog.reducer';
import { initialCustomStatusDialogState, CustomStatusDialogState } from '../../settings/custom-status/dialogs/state/custom-status-dialog.reducer';
import { DashboardProjectDialogState, initialDashboardProjectDialogState } from '../../projects/dialogs/state/project-dialog.reducer';
import { DashboardContributorDialogState, initialDashboardContributorDialogState } from '../../dashboard/contributors/dialogs/state/dashboard-contributor-dialog.reducer';
import { DashboardFlowDialogState, initialDashboardFlowDialogState } from '../../dashboard/flow-management/dialogs/state/flow-dialog.reducer';
import { DashboardReferentialFlowDialogState, initialDashboardReferentialFlowDialogState } from '../../dashboard/flow-referential/dialogs/state/flow-referential-dialog.reducer';
import { CustomFieldDialogState, initialCustomFieldDialogState } from '../../settings/customField/customField-dialog/state/custom-field-dialog.reducer';
import { initialReferenceDialogState, ReferenceDialogState } from '../../settings/references/dialog/state/reference-dialog.reducer';
import { FlowContentDialogState, initialFlowContentDialogState } from '../../dashboard/flow-content/dialogs/state/flow-content-dialog.reducer';
import { BlockContentDialogState, initialBlockContentDialogState } from '../../dashboard/flow-content/step-content/block-content/dialogs/state/block-content-dialog.reducer';
import {
  BlockEquipmentDialogState,
  initialBlockEquipmentDialogState,
} from '../../dashboard/flow-content/step-content/block-content/block-emm/dialogs/state/block-equipment-dialog.reducer';
import { BlockRiskDialogState, initialBlockRiskDialogState } from '../../dashboard/flow-content/step-content/block-content/block-adr/dialogs/state/block-risk-dialog.reducer';
import { BlockRhpDialogState, initialBlockRhpDialogState } from '../../dashboard/flow-content/step-content/block-content/block-rhp/dialogs/state/block-team-dialog.reducer';
import { BlockFormDialogState, initialBlockFormDialogState } from '../../dashboard/flow-content/step-content/block-content/block-form/dialogs/state/block-form-dialog.reducer';
import { blockDsiDialogInitialState, BlockDsiDialogState } from '../../dashboard/flow-content/step-content/block-content/block-dsi/dialogs/state/block-dsi-dialog.reducer';
import { DashboardClosingFolderDialogState, initialDashboardClosingFolderDialogState } from '../../dashboard/closing-folder/dialogs/state/closing-folder-dialogs.reducer';
import { LibraryTagsDialogState, initialLibraryTagsDialogState } from '../../library/tags-list/dialogs/state/tag-dialog.reducer';
import { initialTramDialogState, TramDialogState } from '../../settings/trams/tram-dialogue/state/tram-dialog.reducer';
import { initialSettingsCustomFileDialogState, SettingsCustomFileDialogState } from '../../settings/custom-file/dialogs/state/custom-file-dialog.reducer';
import { initialSettingsCustomCellDialogState, SettingsCustomCellDialogState } from '../../settings/custom-cell/dialogs/state/custom-cell-dialog.reducer';
import { FolderDialogState, initialFolderDialogState } from '../../settings/folders/dialog/state/folder-dialog.reducer';
import {
  blockMultiPrDialogInitialState,
  BlockMultiPrDialogState,
} from '../../dashboard/flow-content/step-content/block-content/block-multi-pr/dialogs/state/block-multi-pr-dialog.reducer';
import { PlanDialogState, initialPlanDialogState } from '../../dashboard/flow-management/list/plan/dialogs/state/plan-dialog.reducer';
import { DashboardDocumentsDialogState, initialDashboardDocumentsDialogState } from '../../dashboard/documents/dialogs/state/dash-document-dialogs.reducer';
import { initialLibraryDocumentsDialogState, LibraryDocumentsDialogState } from '../../library/documents-list/dialogs/state/library-documents-dialog.reducer';
import { FormDialogState, initialFormDialogState } from '../../form/dialogs/state/form-dialog.reducer';
import { DashboardAddFolderState, initialAddFolderState } from '../../dashboard/closing-folder/dialogs/add-folder-dialog/add-folder-state/add-folder.reducer';
import { AddDashDocState, initialAddDashDocState } from '../../dashboard/documents/dialogs/dash-documents-dialog/add-dash-doc-state/add-dash-doc.reducer';
import { AddLibDocState, initialAddLibDocState } from '../../library/documents-list/dialogs/lib-documents-dialog/add-lib-doc-state/add-lib-doc.reducer';
import { DashboardFieldFeedbackDialogState, initialDashboardFieldFeedbackDialogState } from '../../dashboard/field-feedback/dialogs/state/field-feedback-dialog.reducer';
import { initialWordingConfigDialogState, WordingConfigDialogState } from '../../settings/tracking/dialog/state/wording-config-dialog.reducer';
import { initialShareDialogState, ShareDialogState } from '../../entry/share/dialogs/state/share-dialog.reducer';
import { ExternalUserDialogState, initialExternalUserDialogState } from '../../contributors/external-users/external-user-dialog/state/external-user-dialog.reducer';
import { FormSummaryDialogState, initialFormSummaryDialogState } from '../../shared/form-summary-dialog/state/form-summary-dialog.reducer';
import { scheduleInitialState, ScheduleState } from '../../shared/schedule-dialog/state/schedule.reducer';
import { AccessApiV2DialogState, initialAccessApiV2DialogState } from '../../contributors/access-api/access-api-v2/dialogs/state/access-api-v2-dialog.reducer';

import { DialogToasterData } from './dialog-toaster-data';
import { ModalIdEnum } from '../../../core/enums';

export interface SfxCommonDialogState {
  modalId: ModalIdEnum;
  loading: boolean;
  editMode: boolean;
  viewMode: boolean;
  errorData: DialogToasterData;
  warningData: DialogToasterData;
  successData: DialogToasterData;
  currentTab: string;
  submitted: boolean;
  formValid: boolean;
  focusIndex: number;
  keepCreate: boolean;
  createAnother: boolean;
}

// INFO: ActionReducerMap to map all dialog reducer in the same state !
export interface SfxDialogState {
  common: SfxCommonDialogState;
  dashboardTablet: DashboardTabletDialogState;
  libraryDocuments: LibraryDocumentsDialogState;
  addLibDoc: AddLibDocState;
  dashboardCategory: DashboardCategoryDialogState;
  dashboardContributor: DashboardContributorDialogState;
  accessApi: AccessApiDialogState;
  accessApiV2: AccessApiV2DialogState;
  tablet: TabletDialogState;
  settingsFamilies: SettingsFamilyDialogState;
  defaultCategory: DefaultCategoryDialogState;
  libraryObject: LibraryObjectDialogState;
  libraryForm: LibraryFormDialogState;
  lContributor: UserDialogState;
  settingsClient: SettingsClientDialogState;
  settingsCustomFile: SettingsCustomFileDialogState;
  settingsCustomCell: SettingsCustomCellDialogState;
  dashboardForm: DashboardFormDialogState;
  dashboardFieldFeedback: DashboardFieldFeedbackDialogState;
  lOperator: OperatorDialogState;
  dashboardLists: DashboardListsDialogState;
  RiskDialog: LibraryRiskDialogState;
  TagsDialog: LibraryTagsDialogState;
  lEquipment: LibraryEquipmentDialogState;
  customStatus: CustomStatusDialogState;
  customField: CustomFieldDialogState;
  project: DashboardProjectDialogState;
  dashboardFlowState: DashboardFlowDialogState;
  schedule: ScheduleState;
  dashboardReferentialFlow: DashboardReferentialFlowDialogState;
  reference: ReferenceDialogState;
  tramDialog: TramDialogState;
  flowContent: FlowContentDialogState;
  form: FormDialogState;
  blockContent: BlockContentDialogState;
  blockEquipment: BlockEquipmentDialogState;
  blockRisk: BlockRiskDialogState;
  blockRhp: BlockRhpDialogState;
  blockForm: BlockFormDialogState;
  blockDsi: BlockDsiDialogState;
  dashboardClosingFolder: DashboardClosingFolderDialogState;
  addDashFolder: DashboardAddFolderState;
  addDashDocument: AddDashDocState;
  folder: FolderDialogState;
  wordingConfiguration: WordingConfigDialogState;
  blockMultiPr: BlockMultiPrDialogState;
  planState: PlanDialogState;
  dashboardDocuments: DashboardDocumentsDialogState;
  share: ShareDialogState;
  externalUser: ExternalUserDialogState;
  formSummary: FormSummaryDialogState;
}

export const initialSfxCommonDialogState: SfxCommonDialogState = {
  modalId: null,
  loading: false,
  editMode: false,
  viewMode: false,
  errorData: null,
  warningData: null,
  successData: null,
  currentTab: null,
  submitted: false,
  formValid: false,
  focusIndex: 0,
  keepCreate: false,
  createAnother: false,
};

export const initialSfxDialogState = () => ({
  common: { ...initialSfxCommonDialogState },
  dashboardTablet: initialDashboardTabletDialogState(),
  dashboardCategory: initialDashboardCategoryDialogState(),
  dashboardContributor: initialDashboardContributorDialogState(),
  accessApi: initialAccessApiDialogState(),
  accessApiV2: initialAccessApiV2DialogState(),
  tablet: tabletDialogInitialState(),
  settingsFamilies: initialSettingsFamilyDialogState(),
  defaultCategory: initialDefaultCategoryDialogState(),
  libraryObject: initialLibraryObjectDialogState(),
  libraryForm: initialLibraryFormDialogState(),
  lContributor: initialLibraryContributorDialogState(),
  settingsClient: initialSettingsClientDialogState(),
  settingsCustomFile: initialSettingsCustomFileDialogState(),
  settingsCustomCell: initialSettingsCustomCellDialogState(),
  dashboardForm: initialDashboardFormDialogState(),
  dashboardFieldFeedback: initialDashboardFieldFeedbackDialogState(),
  lOperator: initialLibraryOperatorDialogState(),
  RiskDialog: initialLibraryRiskDialogState(),
  TagsDialog: initialLibraryTagsDialogState(),
  dashboardLists: initialDashboardListsDialogState(),
  lEquipment: initialLibraryEquipmentsDialogState(),
  customStatus: initialCustomStatusDialogState(),
  customField: initialCustomFieldDialogState(),
  project: initialDashboardProjectDialogState(),
  dashboardFlowState: initialDashboardFlowDialogState(),
  schedule: scheduleInitialState,
  dashboardReferentialFlow: initialDashboardReferentialFlowDialogState(),
  reference: initialReferenceDialogState(),
  flowContent: initialFlowContentDialogState(),
  form: initialFormDialogState(),
  blockContent: initialBlockContentDialogState,
  blockEquipment: initialBlockEquipmentDialogState(),
  tramDialog: initialTramDialogState(),
  blockRisk: initialBlockRiskDialogState(),
  blockRhp: initialBlockRhpDialogState(),
  blockForm: initialBlockFormDialogState(),
  blockDsi: blockDsiDialogInitialState(),
  dashboardClosingFolder: initialDashboardClosingFolderDialogState(),
  addDashFolder: initialAddFolderState(),
  addDashDocument: initialAddDashDocState(),
  folder: initialFolderDialogState(),
  wordingConfiguration: initialWordingConfigDialogState(),
  blockMultiPr: blockMultiPrDialogInitialState(),
  planState: initialPlanDialogState(),
  libraryDocuments: initialLibraryDocumentsDialogState(),
  addLibDoc: initialAddLibDocState(),
  dashboardDocuments: initialDashboardDocumentsDialogState(),
  share: initialShareDialogState(),
  externalUser: initialExternalUserDialogState,
  formSummary: initialFormSummaryDialogState,
});
