import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { cloneDeep } from 'lodash';

import * as fromActions from './add-dash-doc.actions';
import * as fromVersionActions from '../../../../../shared/documents-tabs/document-tracking-tab/shared-actions/doc-version.actions';

import { DocumentDetailsData } from '../../../../../../core/models/document/document-details';
import { DocumentVersionDetailsData } from '../../../../../../core/models/document/document-version-details';
import { CustomFieldData, ApplicationFileLightData } from '../../../../../../core/models';
import { DocumentVersionSummaryData } from '../../../../../../core/models/document/document-version-summary';
import { CustomWordingsDocumentData } from '../../../../../../core/models/wording-config-setting/custom-wording-document-data';

import { DocumentManageDiffusionEnum } from '../../../../../../core/enums/document/document-manage-diffusion.enum';
import { InnerSortDirectionEnum } from '../../../../../../core/enums';

// Note: Document files subState
const documentFilesAdapter: EntityAdapter<ApplicationFileLightData> = createEntityAdapter<ApplicationFileLightData>({
  selectId: file => file.uuidEntity,
});

export interface DocumentFilesState extends EntityState<ApplicationFileLightData> {
  loading: boolean;
  viewFileLoading: string;
  totalCount: number;
  filteredTotalCount: number;
  reset: boolean;
}

const documentFilesInitialState = (): DocumentFilesState =>
  documentFilesAdapter.getInitialState<DocumentFilesState>({
    ids: [],
    entities: {},
    loading: false,
    viewFileLoading: null,
    totalCount: undefined,
    filteredTotalCount: undefined,
    reset: false,
  });

const adapter: EntityAdapter<DocumentVersionDetailsData> = createEntityAdapter<DocumentVersionDetailsData>({
  selectId: documentsVersion => documentsVersion.uuidEntity,
});

export interface AddDashDocState extends EntityState<DocumentVersionDetailsData> {
  dataLoading: boolean;
  saveLoading: boolean;
  diffuseLoading: boolean;
  manageDiffusionLoading: DocumentManageDiffusionEnum;
  refreshContributorsLoading: boolean;
  menuLoading: string; // Info: loading doc version uuidEntity
  fileLoading: string; // Info: loading doc version uuidEntity
  responseFileLoading: string; // Info: loading doc version uuidEntity
  compressDocsLoading: boolean;
  followTabChanged: boolean;
  documentDetails: DocumentDetailsData;
  initialDocumentDetails: DocumentDetailsData;
  documentCustomFields: CustomFieldData[];
  initialCustomFields: CustomFieldData[];
  reset: boolean;
  totalCount: number;
  filteredTotalCount: number;
  documentFiles: DocumentFilesState;
  documentCustomWording: CustomWordingsDocumentData;
}

export const initialAddDashDocState = (): AddDashDocState =>
  adapter.getInitialState<AddDashDocState>({
    ids: [],
    entities: {},
    dataLoading: false,
    saveLoading: false,
    diffuseLoading: false,
    manageDiffusionLoading: null,
    menuLoading: null,
    fileLoading: null,
    responseFileLoading: null,
    compressDocsLoading: false,
    refreshContributorsLoading: false,
    followTabChanged: false,
    documentDetails: {} as DocumentDetailsData,
    initialDocumentDetails: {} as DocumentDetailsData,
    documentCustomFields: [],
    initialCustomFields: [],
    reset: false,
    totalCount: null,
    filteredTotalCount: null,
    documentFiles: documentFilesInitialState(),
    documentCustomWording: {} as CustomWordingsDocumentData,
  });

const reducer = createReducer<AddDashDocState>(
  initialAddDashDocState(),
  // Info: Loading actions
  on(fromActions.loadDocumentDetails, fromActions.loadDocumentCustomFields, fromVersionActions.loadDocumentVersions, (state): AddDashDocState => ({ ...state, dataLoading: true })),
  on(fromActions.loadDocumentDetails, (state, { reset }): AddDashDocState => (reset ? { ...state, followTabChanged: false } : state)),
  on(
    fromActions.loadDocumentDetailsFail,
    fromActions.loadDocumentCustomFieldsFail,
    fromVersionActions.loadDocumentVersionsFail,
    (state): AddDashDocState => ({ ...state, dataLoading: false }),
  ),
  on(
    fromActions.loadDocumentDetailsSuccess,
    (state, { documentDetails }): AddDashDocState => ({ ...state, documentDetails, initialDocumentDetails: cloneDeep(documentDetails), dataLoading: false }),
  ),
  on(
    fromActions.loadDocumentCustomFieldsSuccess,
    (state, { documentCustomFields }): AddDashDocState => ({ ...state, documentCustomFields, initialCustomFields: cloneDeep(documentCustomFields), dataLoading: false }),
  ),
  on(
    fromVersionActions.loadDocumentVersionsSuccess,
    (state, { documentsVersion, reset }): AddDashDocState => {
      const newState: AddDashDocState = { ...state, dataLoading: false, reset, totalCount: documentsVersion.totalCount, filteredTotalCount: documentsVersion.filteredTotalCount };

      return reset ? adapter.setAll(documentsVersion.payload, newState) : adapter.addMany(documentsVersion.payload, newState);
    },
  ),
  on(
    fromActions.checkGeneratedRefsSuccess,
    (state, { familyUuidEntity, genRefInt, genRefExt, genCompRefInt, genCompRefExt, reset }): AddDashDocState => {
      const partialDocChanges = { generatedRefInt: genRefInt, generatedRefExt: genRefExt, generatedCompRefInt: genCompRefInt, generatedCompRefExt: genCompRefExt };
      const newRefInt = reset && genRefInt ? null : state.documentDetails?.refInt;
      const newRefExt = reset && genRefExt ? null : state.documentDetails?.refExt;

      return {
        ...state,
        documentDetails: {
          ...state.documentDetails,
          ...partialDocChanges,
          refInt: state.initialDocumentDetails?.labelFamily?.uuidEntity === familyUuidEntity ? state.initialDocumentDetails.refInt : newRefInt,
          refExt: state.initialDocumentDetails?.labelFamily?.uuidEntity === familyUuidEntity ? state.initialDocumentDetails.refExt : newRefExt,
        },
        initialDocumentDetails: { ...state.initialDocumentDetails, ...partialDocChanges },
      };
    },
  ),
  on(fromActions.compressSelectedDocs, fromActions.downloadDocumentFiles, (state): AddDashDocState => ({ ...state, compressDocsLoading: true })),
  on(fromActions.finishCompressingDocs, fromActions.downloadDocumentFilesSuccess, (state): AddDashDocState => ({ ...state, compressDocsLoading: false })),

  // Info: Saving actions
  on(fromActions.saveDocumentChanges, (state): AddDashDocState => ({ ...state, saveLoading: true })),
  on(fromActions.createDocumentFail, fromActions.updateDocumentFail, fromActions.stopSaveLoading, (state): AddDashDocState => ({ ...state, saveLoading: false })),
  on(fromVersionActions.refreshContributors, (state): AddDashDocState => ({ ...state, refreshContributorsLoading: true })),
  on(
    fromActions.createDocumentSuccess,
    (state, { documentDetails }): AddDashDocState => {
      const newDocumentData = {
        ...documentDetails,
        generatedRefInt: state.documentDetails.generatedRefInt,
        generatedRefExt: state.documentDetails.generatedRefExt,
        generatedCompRefInt: state.documentDetails.generatedCompRefInt,
        generatedCompRefExt: state.documentDetails.generatedCompRefExt,
      };

      return { ...state, documentDetails: newDocumentData, initialDocumentDetails: cloneDeep(newDocumentData), saveLoading: false };
    },
  ),
  on(
    fromActions.updateDocumentSuccess,
    (state, { documentDetails, customFields }): AddDashDocState => ({
      ...state,
      documentDetails,
      initialDocumentDetails: cloneDeep(documentDetails),
      initialCustomFields: cloneDeep(customFields),
      saveLoading: false,
    }),
  ),

  // Info: Diffusion Actions:
  on(
    fromVersionActions.diffuseDocument,
    fromVersionActions.diffuseBpa,
    fromVersionActions.clientResponse,
    fromVersionActions.clientResponseBpa,
    fromActions.checkUpdatedDocuments,
    (state): AddDashDocState => ({ ...state, diffuseLoading: true }),
  ),
  on(fromVersionActions.addSubIndex, (state): AddDashDocState => ({ ...state, manageDiffusionLoading: DocumentManageDiffusionEnum.AddSubIndex })),
  on(
    fromVersionActions.unlockDocumentVersion,
    (state, { sameIndex }): AddDashDocState => ({ ...state, manageDiffusionLoading: sameIndex ? DocumentManageDiffusionEnum.SameIndex : DocumentManageDiffusionEnum.AddIndex }),
  ),
  on(
    fromActions.checkUpdatedDocuments,
    fromVersionActions.diffuseDocument,
    fromVersionActions.diffuseBpa,
    fromVersionActions.clientResponse,
    fromVersionActions.clientResponseBpa,
    fromVersionActions.generateLibraryDocumentVersionPdf,
    fromVersionActions.convertToBpa,
    fromVersionActions.convertToBpeValid,
    fromVersionActions.editDiffusionFree,
    fromVersionActions.updateClientResponse,
    fromVersionActions.cancelDiffusion,
    fromVersionActions.unlockDocumentVersion,
    fromVersionActions.particularization,
    (state): AddDashDocState => ({ ...state, followTabChanged: true }),
  ),
  on(fromVersionActions.uploadDocVersionFile, (state, { docVersionUuidEntity }): AddDashDocState => ({ ...state, fileLoading: docVersionUuidEntity })),
  on(
    fromVersionActions.viewLibraryDocumentVersionPdf,
    fromVersionActions.generateLibraryDocumentVersionPdf,
    fromVersionActions.checkAndGenerateDoc,
    (state, { docVersionUuidEntity, fileLoading }): AddDashDocState => ({
      ...state,
      fileLoading: fileLoading && docVersionUuidEntity,
      menuLoading: !fileLoading && docVersionUuidEntity,
    }),
  ),
  on(fromVersionActions.downloadDocumentVersionResponse, (state, { docVersionUuidEntity }): AddDashDocState => ({ ...state, responseFileLoading: docVersionUuidEntity })),
  on(
    fromVersionActions.convertToBpa,
    fromVersionActions.convertToBpeValid,
    fromVersionActions.editDiffusionFree,
    fromVersionActions.updateClientResponse,
    fromVersionActions.particularization,
    fromVersionActions.updateDocVersionCollaborators,
    fromVersionActions.downloadLibraryDocumentVersionPdf,
    fromVersionActions.refreshFromConfig,
    fromVersionActions.refreshFromLastIndex,
    (state, { docVersionUuidEntity }): AddDashDocState => ({ ...state, menuLoading: docVersionUuidEntity }),
  ),
  on(
    fromVersionActions.updateDocumentVersionFail,
    fromVersionActions.updateDocumentVersionSuccess,
    fromActions.stopSaveLoading,
    fromVersionActions.clientResponseSuccess,
    fromVersionActions.unlockVdfSuccess,
    fromVersionActions.deleteLastDocumentVersionSuccess,
    (state): AddDashDocState => ({
      ...state,
      diffuseLoading: false,
      refreshContributorsLoading: false,
      menuLoading: null,
      fileLoading: null,
      responseFileLoading: null,
      manageDiffusionLoading: null,
    }),
  ),
  on(
    fromVersionActions.updateDocumentVersionSuccess,
    (state, { docVersion }): AddDashDocState =>
      docVersion
        ? adapter.updateOne(
            { id: docVersion.uuidEntity, changes: docVersion },
            { ...state, documentDetails: { ...state.documentDetails, status: docVersion.lastDocumentVersionStatus, complementRefInt: docVersion.complementRefInt } },
          )
        : state,
  ),
  on(
    fromVersionActions.clientResponseSuccess,
    (state, { docVersions, sortDirection }): AddDashDocState => {
      const newState =
        sortDirection !== InnerSortDirectionEnum.Desc
          ? adapter.addOne(docVersions.currentVersion, state)
          : {
              ...state,
              ids: [docVersions.currentVersion.uuidEntity, ...state.ids] as string[],
              entities: { ...state.entities, [docVersions.currentVersion.uuidEntity]: docVersions.currentVersion },
            };

      return adapter.updateOne({ id: docVersions.previousVersion.uuidEntity, changes: docVersions.previousVersion }, newState);
    },
  ),
  on(
    fromVersionActions.unlockVdfSuccess,
    (state, { docVersions, sortDirection }): AddDashDocState => {
      const newState =
        sortDirection !== InnerSortDirectionEnum.Desc
          ? adapter.addOne(docVersions.currentVersion, {
              ...state,
              documentDetails: { ...state.documentDetails, lastDocumentVersion: DocumentVersionSummaryData.mapFromDocVersionDetails(docVersions.currentVersion) },
            })
          : {
              ...state,
              ids: [docVersions.currentVersion.uuidEntity, ...state.ids] as string[],
              entities: { ...state.entities, [docVersions.currentVersion.uuidEntity]: docVersions.currentVersion },
            };

      return adapter.updateOne({ id: docVersions.previousVersion.uuidEntity, changes: docVersions.previousVersion }, newState);
    },
  ),
  on(
    fromVersionActions.deleteLastDocumentVersionSuccess,
    (state, { docVersions }): AddDashDocState =>
      adapter.updateOne(
        { id: docVersions.currentVersion.uuidEntity, changes: docVersions.currentVersion },
        adapter.removeOne(docVersions.previousVersion.uuidEntity, { ...state, filteredTotalCount: state.filteredTotalCount - 1 }),
      ),
  ),

  // Document Files ons
  on(
    fromActions.loadDocumentFilesSuccess,
    (state, { files, totalCount, filteredTotalCount, reset }): AddDashDocState => {
      const newApplicationFilesState: DocumentFilesState = { ...state.documentFiles, loading: false, reset, totalCount, filteredTotalCount };
      const newState = reset ? documentFilesAdapter.setAll(files, newApplicationFilesState) : documentFilesAdapter.addMany(files, newApplicationFilesState);

      return { ...state, documentFiles: newState };
    },
  ),
  on(fromActions.resetFilesState, (state): AddDashDocState => ({ ...state, documentFiles: documentFilesInitialState() })),
  on(
    fromActions.deleteDocumentFileSuccess,
    (state, { fileUuidEntity }): AddDashDocState => {
      const newState = documentFilesAdapter.removeOne(fileUuidEntity, { ...state.documentFiles, filteredTotalCount: state.documentFiles.filteredTotalCount - 1 });

      return { ...state, documentFiles: newState };
    },
  ),
  on(
    fromActions.viewDocumentFileSuccess,
    fromActions.viewDocumentFileFail,
    (state): AddDashDocState => {
      const newState: DocumentFilesState = { ...state.documentFiles, viewFileLoading: null };

      return { ...state, documentFiles: newState };
    },
  ),
  on(
    fromActions.viewDocumentFile,
    (state, { fileUuidEntity }): AddDashDocState => {
      const newState: DocumentFilesState = { ...state.documentFiles, viewFileLoading: fileUuidEntity };

      return { ...state, documentFiles: newState };
    },
  ),

  on(fromActions.loadCustomWordingDetailsSuccess, (state, { documentCustomWording }): AddDashDocState => ({ ...state, documentCustomWording })),
);

export function AddDashDocReducer(state: AddDashDocState | undefined, action: Action): AddDashDocState {
  return reducer(state, action);
}

export const selectAllDocumentVersions = adapter.getSelectors().selectAll;
export const selectDocFiles = documentFilesAdapter.getSelectors().selectAll;
