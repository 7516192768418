/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateFilterRequest } from './dateFilterRequest';
import { SortDto } from './sortDto';

export interface DocumentFlowFindLazyByProjectRequestDTO {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  familyUuidEntity?: Array<string>;
  customWordingUuidEntities?: Array<string>;
  categoryPrincipalUuidEntity?: Array<string>;
  categorySecondaryUuidEntity?: Array<string>;
  filterEtat?: Array<DocumentFlowFindLazyByProjectRequestDTO.FilterEtatEnum>;
  projectUuidEntity: string;
  provenance?: Array<DocumentFlowFindLazyByProjectRequestDTO.ProvenanceEnum>;
  flowUuidEntity?: Array<string>;
  blocUuidEntity?: string;
  dateFilterRequest?: DateFilterRequest;
  documentFlowUuidEntityToExclude?: string;
}
export namespace DocumentFlowFindLazyByProjectRequestDTO {
  export type FilterEtatEnum =
    | 'PREL'
    | 'CLIENT_RETURN_BPO'
    | 'DIFFUSION_BPO'
    | 'BPE'
    | 'BPE_SUITE_VSOSR'
    | 'BPE_VALID'
    | 'CLIENT_RETURN_BPA'
    | 'DIFFUSION_BPA'
    | 'BPA_VALID'
    | 'WITHOUT';
  export const FilterEtatEnum = {
    PREL: 'PREL' as FilterEtatEnum,
    CLIENTRETURNBPO: 'CLIENT_RETURN_BPO' as FilterEtatEnum,
    DIFFUSIONBPO: 'DIFFUSION_BPO' as FilterEtatEnum,
    BPE: 'BPE' as FilterEtatEnum,
    BPESUITEVSOSR: 'BPE_SUITE_VSOSR' as FilterEtatEnum,
    BPEVALID: 'BPE_VALID' as FilterEtatEnum,
    CLIENTRETURNBPA: 'CLIENT_RETURN_BPA' as FilterEtatEnum,
    DIFFUSIONBPA: 'DIFFUSION_BPA' as FilterEtatEnum,
    BPAVALID: 'BPA_VALID' as FilterEtatEnum,
    WITHOUT: 'WITHOUT' as FilterEtatEnum,
  };
  export type ProvenanceEnum = 'LIBRARY' | 'REFERENTIAL' | 'LIBRARY_CREATED' | 'REFERENTIAL_CREATED' | 'LOCAL' | 'PROVENANCE_LIBRARY' | 'PROVENANCE_REFERENTIAL';
  export const ProvenanceEnum = {
    LIBRARY: 'LIBRARY' as ProvenanceEnum,
    REFERENTIAL: 'REFERENTIAL' as ProvenanceEnum,
    LIBRARYCREATED: 'LIBRARY_CREATED' as ProvenanceEnum,
    REFERENTIALCREATED: 'REFERENTIAL_CREATED' as ProvenanceEnum,
    LOCAL: 'LOCAL' as ProvenanceEnum,
    PROVENANCELIBRARY: 'PROVENANCE_LIBRARY' as ProvenanceEnum,
    PROVENANCEREFERENTIAL: 'PROVENANCE_REFERENTIAL' as ProvenanceEnum,
  };
}
