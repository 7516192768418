import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { BaseHttpService } from './base-http.service';

import { PageApplicationFileTmpRequestDTO, ApplicationFileTmpSummaryResponseDTO } from '../generated/models/models';

import { HttpParamsType, LazyLoadResponse, ApplicationFileTmpData } from '../models';

import { apiUrlMatcher } from './helpers/api-url-matcher';
import { ApiUrlsEnum } from './helpers/api-url';
import { FileTypeEnum } from '../enums';

@Injectable({ providedIn: 'root' })
export class ApplicationFileTmpService {
  constructor(private baseHttpService: BaseHttpService) {}

  public findLazyApplicationFileTmp(params: HttpParamsType<PageApplicationFileTmpRequestDTO>): Observable<LazyLoadResponse<ApplicationFileTmpData[]>> {
    return this.baseHttpService
      .lazyLoad<ApplicationFileTmpSummaryResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.GetLazyApplicationFileTmp), params)
      .pipe(map(response => ({ ...response, payload: response.payload.map(event => ApplicationFileTmpData.mapFromApiValue(event)) })));
  }

  public downloadDocumentByUuidEntity(fileUuidEntity: string, documentType: FileTypeEnum): Observable<Blob> {
    return documentType === FileTypeEnum.Pdf
      ? this.baseHttpService.previewFile(apiUrlMatcher(ApiUrlsEnum.DownloadTemporaryFile, { fileUuidEntity }), null, documentType)
      : this.baseHttpService.downloadFile(apiUrlMatcher(ApiUrlsEnum.DownloadTemporaryFile, { fileUuidEntity }), null, documentType);
  }
}
