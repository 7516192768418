import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { UserSummaryResponseDTO, PageUserRequestDTO } from '../../generated/models/models';
import { EntityLightData } from '../utils/entity-light-data';

import { UserStatusEnum, InnerSortDirectionEnum, UserRightEnum, UserTypeEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';
import { SkillSummaryData } from '../contributors/skill-summary-data';
import { DateUtils } from '../../utils/date.utils';
import { DateFilterData } from '../../models';

export interface LibraryContributorSummaryData {
  function: LabelFamilyLightData;
  hierarchies: LabelFamilyLightData[];
  group: EntityLightData;
  status: UserStatusEnum;
  authority: UserRightEnum;
  name: string;
  uuid: string;
  uuidEntity: string;
  modificationDate: string;
  type: UserTypeEnum;
  registerNumber: string;
  skills: string;
  skillsTooltip: string;
  companyName?: string;
}

export namespace LibraryContributorSummaryData {
  const sortFields = ['function', 'name'];

  export interface QueryRequest extends BaseQuery<LibraryContributorSummaryData> {
    functions?: string[];
    legals?: string[];
    contracts?: string[];
    hierarchies?: string[];
    skills?: string[];
    groups?: string[];
    statuses?: UserStatusEnum[];
    applicability?: string[];
    authority?: UserRightEnum;
    textSearch?: string;
    type?: UserTypeEnum;
    domains?: string[];
    dateFilters?: DateFilterData;
  }

  export function mapFromApiValue(data: UserSummaryResponseDTO): LibraryContributorSummaryData {
    return {
      function: data.labelFamilyFunction && LabelFamilyLightData.mapFromApiValue(data.labelFamilyFunction),
      hierarchies: data.labelFamilyHierarchies && data.labelFamilyHierarchies.map(hierarchy => LabelFamilyLightData.mapFromApiValue(hierarchy)),
      group: data.labelFamilyTeam && LabelFamilyLightData.mapToEntityLightData(data.labelFamilyTeam),
      status: UserStatusEnum.convertFromApiValue.getValue(data.status),
      authority: UserRightEnum.convertFromApiValue.getValue(data.userRight?.name),
      modificationDate: DateUtils.toDateFormat(data.modificationDate),
      name: data.name,
      uuid: data.uuid,
      uuidEntity: data.uuidEntity,
      type: UserTypeEnum.convertFromApiValue.getValue(data.type),
      registerNumber: data.matricule,
      skills: (data.skills || []).map(skill => SkillSummaryData.mapFromApiValue(skill).name).join(' ; '),
      skillsTooltip: (data.skills || []).map(skill => SkillSummaryData.mapFromApiValue(skill).fullName).join(' ; '),
      companyName: data?.userRight?.company?.name,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageUserRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      labelFamilyFunctionUuides: queries.functions,
      labelFamilyHierarchyUuides: queries.hierarchies,
      labelFamilySkillUuides: queries.skills,
      labelFamilyTeamUuides: queries.groups,
      legalEntitiesUuides: queries.legals,
      activityDomainsUuides: queries.domains,
      contratTypeUuides: queries.contracts,
      statuses: (queries.statuses || []).map(status => UserStatusEnum.convertToApiValue.getValue(status)) || undefined,
      applicabilityUuides: queries.applicability,
      userAuthority: UserRightEnum.convertToApiValue.getValue(queries.authority),
      type: UserTypeEnum.convertToApiValue.getValue(queries.type),
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      dateFilterRequest: (queries.dateFilters && DateFilterData.mapToRequestApiValue(queries.dateFilters)) || undefined,
    };
  }

  export function initializeQueryRequest(
    queries: {
      functions: string[];
      legals: string[];
      groups: string[];
      hierarchies: string[];
      contracts: string[];
      dateFilters?: DateFilterData;
    },
    skills: string[],
    domains: string[],
    statuses: string[],
    applicability: string[],
    searchText: string,
    authority: string,
    sort: SortFilter<LibraryContributorSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<LibraryContributorSummaryData>(sort),
      textSearch: searchText || undefined,
      groups: queries && (queries.groups || []).length ? queries.groups : undefined,
      functions: queries && (queries.functions || []).length ? queries.functions : undefined,
      legals: queries && (queries.legals || []).length ? queries.legals : undefined,
      hierarchies: queries && (queries.hierarchies || []).length ? queries.hierarchies : undefined,
      contracts: queries && (queries.contracts || []).length ? queries.contracts : undefined,
      skills: (skills || []).length ? skills : undefined,
      statuses: statuses?.length ? statuses.map(status => UserStatusEnum.convertFromParamsValue.getValue(status)) : undefined,
      applicability: (applicability || []).length ? applicability : undefined,
      authority: UserRightEnum.convertFromParamsValue.getValue(authority) || undefined,
      domains: (domains || []).length ? domains : undefined,
      dateFilters: queries?.dateFilters || undefined,
    };
  }

  export function toString(contributor: LibraryContributorSummaryData): string {
    return contributor.name;
  }
}
