import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

import { SfxRouterState } from './router-state';
import { BreadCrumbData } from '../../core/models';
import { RouteIdEnum } from '../../core/enums';

export const routerNavigate = createAction(
  '[Router] Route navigate',
  props<{ parentId: RouteIdEnum; segments: (string | { outlets: { [key: string]: string | string[] } })[]; extras?: NavigationExtras }>(),
);
export const routerBack = createAction('[Router] Route back');
export const setRouteOutletActionByContext = createAction('[Router] Set route outlet action by context');
export const routerForward = createAction('[Router] Route forward');

export const routerNavigationChange = createAction('[Router] Route navigation change', props<{ routerState: Partial<SfxRouterState> }>());
export const routerNavigatedChange = createAction('[Router] Route navigated change', props<{ routerState: Partial<SfxRouterState> }>());
export const saveRouteFilters = createAction('[Router] Save route filters', props<{ previousRouteId: RouteIdEnum; userUuidEntity: string }>());

export const routerQueryParamsChange = createAction('[Router] Route query params change');

export const buildBreadCrumb = createAction('[Router] Build breadcrumb', props<{ breadCrumbs: BreadCrumbData[] }>());

export const setBackToReferentialModule = createAction('[Router] back to referential module', props<{ value: boolean }>());
