import { ContributorTypeEnum, QuestionTypeEnum } from '../../enums';
import { OptionSignatoryReplayDTOV15 } from '../../generated/models/optionSignatoryReplayDTOV15';
import { RubricOptionReplayDTOV15 } from '../../generated/models/rubricOptionReplayDTOV15';
import { RubricQuestionReplayDTOV15 } from '../../generated/models/rubricQuestionReplayDTOV15';
import { RubricReplayDTOV15 } from '../../generated/models/rubricReplayDTOV15';
import { DateUtils } from '../../utils/date.utils';
import { ApplicationFileLightData } from '../application-file/application-file';
import { QuestionContributorData } from '../form-builder/question-contributor-data';
import { QuestionDurationData } from '../form-builder/question-duration-data';
import { QuestionLocationData } from '../form-builder/question-location-data';
import { optionValueType, QuestionOptionData } from '../form-builder/question-option-data';
import { QuestionDetailsData } from '../question/question-details-data';

export const rubricKeySeparator = '___';
export interface OptionFileTree {
  [key: string]: File[];
}

export interface VariablesTree {
  [key: string]: optionValueType;
}

export interface TreeResponseItem {
  uuidEntity: string;
  nodeOrder: number;
  type: QuestionTypeEnum;
  options: QuestionOptionData<optionValueType>[];
  observation?: string;
  locked?: boolean;
}

export interface RubricResponseTree {
  // info: key is the rubric uuidEntity
  [key: string]: TreeResponseItem[];
}

export namespace RubricResponseTree {
  export function mapToRubricQuestionReplayDTOV15(question: QuestionDetailsData): RubricQuestionReplayDTOV15 {
    return {
      uuidEntity: question.uuidEntity,
      observation: question.observation,
      optionSignatoryReplays: question.type === QuestionTypeEnum.Collaborator ? [] : undefined,
      rubricOptionReplays: question.type !== QuestionTypeEnum.Collaborator ? question.options.map(option => ({ uuidEntity: option.uuidEntity })) : undefined,
    };
  }

  export function mapToRubricOptionReplayDTOV15(option: QuestionOptionData<optionValueType>, questionType: QuestionTypeEnum): RubricOptionReplayDTOV15 {
    return {
      uuidEntity: option.uuidEntity,
      locked: option.locked,
      ...QuestionOptionData.setOptionValue(option, questionType),
      applicationFileUuidEntities: QuestionTypeEnum.fileQuestions.includes(questionType)
        ? (option.applicationFiles || []).filter(file => file.deleted).map(appFile => appFile.uuidEntity)
        : undefined,
    };
  }

  export function mapToRubricOptionSignatoryReplayDTOV15(option: QuestionOptionData<optionValueType>): OptionSignatoryReplayDTOV15 {
    const value = option.value as QuestionContributorData;

    return {
      uuidEntity: option.uuidEntity,
      firstName: value.typeContributor === ContributorTypeEnum.Extern ? value.firstName : value.selectedUser && value.selectedUser.firstName,
      lastName: value.typeContributor === ContributorTypeEnum.Extern ? value.lastName : value.selectedUser && value.selectedUser.lastName,
      email: value.email,
      function: value.function,
      userRef: value.typeContributor === ContributorTypeEnum.Intern ? value.selectedUser && value.selectedUser.uuidEntity : null,
      signatureDate: DateUtils.toZonedDateTime(value.signatureDate),
      typeContributor: ContributorTypeEnum.convertToApiValue.getValue(value.typeContributor),
      imageSignature: value.imageSignature,
      locked: value.locked,
      proxied: value.proxied,
      commentSignature: value.commentSignature,
      signatureProxiedDate: DateUtils.toZonedDateTime(value.signatureProxiedDate),
    };
  }

  export function mapToApiValue(tree: RubricResponseTree, accessDate: string): RubricReplayDTOV15[] {
    const rubricUuids = Object.keys(tree);
    const rubrics: RubricReplayDTOV15[] = [];

    rubricUuids.forEach(key => {
      const rubricUuidEntity = key.split(rubricKeySeparator)[0];

      rubrics.push({
        rubricUuidEntity,
        today: DateUtils.toLocalDateTime(accessDate),
        questionsReplay: (tree[key] || []).map(question => ({
          uuidEntity: question.uuidEntity,
          observation: question.observation,
          locked: question.locked,
          optionSignatoryReplays: question.type === QuestionTypeEnum.Collaborator ? question.options.map(option => mapToRubricOptionSignatoryReplayDTOV15(option)) : undefined,
          rubricOptionReplays: question.type !== QuestionTypeEnum.Collaborator ? question.options.map(option => mapToRubricOptionReplayDTOV15(option, question.type)) : undefined,
        })),
      });
    });

    return rubrics;
  }

  function checkUntouchedPrefilledValue(option: QuestionOptionData<optionValueType>, questionType: QuestionTypeEnum): boolean {
    switch (questionType) {
      case QuestionTypeEnum.ShortAnswer:
      case QuestionTypeEnum.LongAnswer:
      case QuestionTypeEnum.MultipleAnswers:
      case QuestionTypeEnum.ValueUnit:
      case QuestionTypeEnum.Formula:
      case QuestionTypeEnum.Date:
      case QuestionTypeEnum.DateTime:
      case QuestionTypeEnum.TextEditorAnswer:
      case QuestionTypeEnum.UniqueChoice:
      case QuestionTypeEnum.MultipleChoices:
        return !!option.value;

      case QuestionTypeEnum.Duration:
        const { hours, minutes } = option.value as QuestionDurationData;

        return !!(hours && minutes);

      case QuestionTypeEnum.Photo:
        return (option.value as ApplicationFileLightData[]).length > 0;

      case QuestionTypeEnum.Location:
        const { longitude, latitude } = option.value as QuestionLocationData;

        return !!(longitude && latitude);

      default:
        return false;
    }
  }

  export function getUntouchedPrefilledOptions(rubricTree: RubricResponseTree): Set<string> {
    const nonEmptyOptions = new Set<string>();
    Object.values(rubricTree).forEach(questions =>
      Object.values(questions).forEach(question =>
        question.options.filter(option => checkUntouchedPrefilledValue(option, question.type)).forEach(option => nonEmptyOptions.add(option.uuidEntity)),
      ),
    );

    return nonEmptyOptions;
  }
}
