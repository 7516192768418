import { DateUtils } from '../../utils/date.utils';
import { SortFilter } from '../utils/sort-filter';

import { TeamRoleSummaryResponseDTO, PageTeamRoleRequest, PageTeamRoleRequestDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { EntityLightData } from '../utils/entity-light-data';
import { LabelFamilyHierarchyData } from '../label-family/label-family-hierarchy';

import { UserTypeEnum, UserRoleEnum, UserNatureEnum, InnerSortDirectionEnum, UserStatusEnum, UserRightEnum } from '../../enums';

export interface ContributorSummaryData {
  teamRole: UserRoleEnum;
  function: LabelFamilyLightData;
  hierarchies: LabelFamilyLightData[];
  flowHierarchies?: LabelFamilyHierarchyData[];
  group: EntityLightData;
  modificationDate: string;
  name: string;
  teamRoleOrder: number;
  uuid: string;
  uuidEntity: string;
  uuidOrganization: string;
  userUuidEntity: string;
  registerNumber: string;
  applicabilityName: string;
  complement: string;
  status: UserStatusEnum;
  disabledRow?: boolean;
  selectedRow?: boolean;
  authority?: UserRightEnum;
  companyName?: string;
}

export namespace ContributorSummaryData {
  const sortFields = ['name', 'group', 'function'];

  export interface QueryRequest extends BaseQuery<ContributorSummaryData> {
    applicabilities?: string[];
    functions?: string[];
    legals?: string[];
    contracts?: string[];
    hierarchies?: string[];
    skills?: string[];
    activityDomainsUuides?: string[];
    textSearch?: string;
    teams?: string[];
    userType?: UserTypeEnum;
    teamRole?: UserRoleEnum;
    flows?: string[];
  }

  export function mapFromApiValue(data: TeamRoleSummaryResponseDTO): ContributorSummaryData {
    return {
      teamRole: data.choixTeamRole && UserRoleEnum.convertFromApiValue.getValue(data.choixTeamRole),
      function: data.labelFamilyFunction && LabelFamilyLightData.mapFromApiValue(data.labelFamilyFunction),
      hierarchies: data.labelFamilyHierarchies && data.labelFamilyHierarchies.map(hierarchy => LabelFamilyLightData.mapFromApiValue(hierarchy, null, true)),
      flowHierarchies: (data.hierarchies || []).map(flowHierarchy => LabelFamilyHierarchyData.mapFromApiValue(flowHierarchy)),
      group: data.labelFamilyTeam && LabelFamilyLightData.mapToEntityLightData(data.labelFamilyTeam),
      modificationDate: DateUtils.toDateFormat(data.modificationDate),
      name: data.name,
      teamRoleOrder: data.teamRoleOrder,
      uuid: data.uuid,
      uuidEntity: data.uuidEntity,
      uuidOrganization: data.uuidOrganization,
      userUuidEntity: data.userUuidEntity,
      registerNumber: data.matricule,
      applicabilityName: data.applicabilityName,
      complement: data['profession'],
      status: UserStatusEnum.convertFromApiValue.getValue(data.status),
      disabledRow: data.alreadyImported,
      selectedRow: data.alreadyImported || data.affected,
      authority: UserRightEnum.convertFromApiValue.getValue(data.userRight?.name),
      companyName: data?.userRight?.company?.name,
    };
  }

  export function mapToPageRequestApiValue(data: {
    queries: QueryRequest;
    userNatures?: UserNatureEnum[];
    projectUuidEntity?: string;
    userUuidEntities?: string[];
  }): PageTeamRoleRequest {
    const { queries, userNatures, projectUuidEntity, userUuidEntities } = data;

    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      projectUuidEntity,
      choixTeamRole: UserRoleEnum.convertToApiValue.getValue(queries.teamRole),
      labelFamilyFunctionUuides: queries.functions,
      labelFamilyHierarchyUuides: queries.hierarchies,
      labelFamilySkillUuides: queries.skills,
      activityDomainsUuides: queries.activityDomainsUuides,
      legalEntitiesUuides: queries.legals,
      applicabilityUuides: queries.applicabilities,
      contratTypeUuides: queries.contracts,
      userLabelFamilyTeamUuides: queries.teams,
      userUuidEntities,
      userNatures: (userNatures || []).map(nature => UserNatureEnum.convertToApiValue.getValue(nature)),
      userType: UserTypeEnum.convertToApiValue.getValue(queries.userType),
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      flowUuides: queries?.flows,
    };
  }

  export function mapToPageRoleRequestApiValue(queries: QueryRequest, userNatures: UserNatureEnum[], projectUuidEntity: string, flowUuidEntity: string): PageTeamRoleRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      projectUuidEntity,
      flowUuidEntity,
      userNatures: userNatures && userNatures.map(nature => UserNatureEnum.convertToApiValue.getValue(nature)),
      choixTeamRole: UserRoleEnum.convertToApiValue.getValue(queries.teamRole),
      labelFamilyFunctionUuides: queries.functions,
      labelFamilyHierarchyUuides: queries.hierarchies,
      labelFamilySkillUuides: queries.skills,
      activityDomainsUuides: queries.activityDomainsUuides,
      legalEntitiesUuides: queries.legals,
      applicabilityUuides: queries.applicabilities,
      contratTypeUuides: queries.contracts,
      userLabelFamilyTeamUuides: queries.teams,
      userType: UserTypeEnum.convertToApiValue.getValue(queries.userType),
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(
    queries: {
      applicabilities: string[];
      functions: string[];
      legals: string[];
      contracts: string[];
      groups: string[];
      hierarchies: string[];
      flows?: string[];
    },
    skills: string[],
    domains: string[],
    type: string,
    searchText: string,
    sort: SortFilter<ContributorSummaryData>,
    role?: string,
  ): QueryRequest {
    return {
      ...initializeQueries<ContributorSummaryData>(sort),
      textSearch: searchText || undefined,
      functions: queries?.functions?.length ? queries?.functions : undefined,
      legals: queries?.legals?.length ? queries?.legals : undefined,
      contracts: queries?.contracts?.length ? queries?.contracts : undefined,
      teams: queries.groups?.length ? queries?.groups : undefined,
      hierarchies: queries?.hierarchies?.length ? queries?.hierarchies : undefined,
      skills: skills?.length ? skills : undefined,
      activityDomainsUuides: domains?.length ? domains : undefined,
      teamRole: (role && UserRoleEnum.convertFromParamsValue.getValue(role)) || undefined,
      userType: UserTypeEnum.convertFromParamsValue.getValue(type) || undefined,
      applicabilities: queries?.applicabilities?.length ? queries?.applicabilities : undefined,
      flows: queries?.flows,
    };
  }

  export function toString(contributor: ContributorSummaryData): string {
    return contributor.name;
  }
}
