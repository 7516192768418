import { compact, isEqual as _isEqual, omit } from 'lodash';

import { UserCreateRequestDTO, UserDetailsResponseDTO, UserUpdateRequestDTO } from '../../generated/models/models';

import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CompanySummaryData } from '../companies/companies-summary-data';
import { EntityLightData } from '../utils/entity-light-data';

import { normalizeStr } from '../../../modules/shared/helpers/normalize-str';
import { UserRightEnum } from '../../enums';
import { AdditionalAuthEnum } from '../../enums/user/additionalAuth.enum';

export interface LibraryContributorDetailsData {
  uuidEntity: string;
  firstName: string;
  lastName: string;
  trigram: string;
  email: string;
  complement: string;
  registerNumber: string;
  applicabilityName: string;
  ssoIdentifier: string;
  company: CompanySummaryData;
  authority: UserRightEnum;
  function: LabelFamilyLightData;
  group: LabelFamilyLightData;
  legalEstablishment: LabelFamilyLightData;
  contract: LabelFamilyLightData;
  hierarchies: EntityLightData[];
  userGenericForm: boolean;
  userModelForm: boolean;
  userManageDiffusion: boolean;
}
export namespace LibraryContributorDetailsData {
  export function mapFromApiValue(data: UserDetailsResponseDTO): LibraryContributorDetailsData {
    const additionalAuth = data.additionalAuthorities?.map(auth => AdditionalAuthEnum.convertFromApiValue.getValue(auth)) || [];

    return {
      firstName: data.firstName,
      lastName: data.lastName,
      trigram: data.trigram?.toLocaleUpperCase(),
      email: data.email,
      company: data.userRight?.company && CompanySummaryData.mapFromUserRight(data.userRight),
      function: data.labelFamilyFunction && LabelFamilyLightData.mapFromApiValue(data.labelFamilyFunction),
      complement: data.complement,
      group: data.labelFamilyTeam && LabelFamilyLightData.mapFromApiValue(data.labelFamilyTeam),
      legalEstablishment: data.legalEntity && LabelFamilyLightData.mapFromApiValue(data.legalEntity),
      contract: data.contratType && LabelFamilyLightData.mapFromApiValue(data.contratType),
      authority: UserRightEnum.convertFromApiValue.getValue(data.userRight?.name),
      userGenericForm: additionalAuth.includes(AdditionalAuthEnum.QHSE),
      userModelForm: additionalAuth.includes(AdditionalAuthEnum.UserModelForm),
      userManageDiffusion: additionalAuth.includes(AdditionalAuthEnum.ManageDiffusion),
      hierarchies: (data.labelFamilyHierarchies || []).map(hierarchy => LabelFamilyLightData.mapToEntityLightData(hierarchy)),
      registerNumber: data.matricule,
      uuidEntity: data.uuidEntity,
      applicabilityName: data.applicabilityName,
      ssoIdentifier: data.ssoIdentifier,
    };
  }

  export function mapToCreateRequestApiValue(data: LibraryContributorDetailsData, isOperator?: boolean): UserCreateRequestDTO {
    return {
      authority: UserRightEnum.convertToApiValue.getValue(data.authority),
      complement: data.complement,
      firstName: data.firstName,
      email: isOperator ? normalizeStr(data.firstName) + normalizeStr(data.lastName) + '@siteflow' + new Date().getTime() : data.email,
      functionUuidEntity: data.function?.uuidEntity,
      companyUuidEntity: data.company?.uuidEntity,
      hierarchiesUuidEntity: (data.hierarchies || []).length ? data.hierarchies.map(hierarchy => hierarchy.uuidEntity) : undefined,
      lastName: data.lastName,
      matricule: data.registerNumber,
      teamUuidEntity: data.group?.uuidEntity,
      legalEntityUuidEntity: data.legalEstablishment?.uuidEntity,
      contratTypeUuidEntity: data.contract?.uuidEntity,
      trigram: data.trigram?.toLocaleUpperCase(),
      ssoIdentifier: data.ssoIdentifier || null,
      additionalAuthorities: UserRightEnum.rolesWhereAdditionalRoleCanBeManaged.includes(data.authority)
        ? [
            AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.QHSE),
            AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.UserModelForm),
            AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.ManageDiffusion),
          ]
        : [],
    };
  }

  export function mapToUpdateRequestApiValue(contributor: LibraryContributorDetailsData): UserUpdateRequestDTO {
    return {
      authority: UserRightEnum.convertToApiValue.getValue(contributor.authority),
      complement: contributor.complement,
      firstName: contributor.firstName,
      functionUuidEntity: contributor.function?.uuidEntity,
      companyUuidEntity: contributor.company?.uuidEntity,
      hierarchiesUuidEntity: (contributor.hierarchies || []).length ? contributor.hierarchies.map(hierarchy => hierarchy.uuidEntity) : undefined,
      lastName: contributor.lastName,
      matricule: contributor.registerNumber,
      teamUuidEntity: contributor.group?.uuidEntity,
      legalEntityUuidEntity: contributor.legalEstablishment?.uuidEntity,
      contratTypeUuidEntity: contributor.contract?.uuidEntity,
      trigram: contributor.trigram?.toLocaleUpperCase(),
      uuidEntity: contributor.uuidEntity,
      ssoIdentifier: contributor.ssoIdentifier || null,
      additionalAuthorities: !UserRightEnum.rolesWhereAdditionalRoleCanBeManaged.includes(contributor.authority)
        ? []
        : compact([
            contributor.userGenericForm && AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.QHSE),
            contributor.userModelForm && AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.UserModelForm),
            contributor.userManageDiffusion && AdditionalAuthEnum.convertToApiValue.getValue(AdditionalAuthEnum.ManageDiffusion),
          ]),
    };
  }

  export function isEqual(contributor1: LibraryContributorDetailsData, contributor2: LibraryContributorDetailsData): boolean {
    const objectKeys: (keyof LibraryContributorDetailsData)[] = ['function', 'hierarchies', 'group'];

    return (
      _isEqual(
        omit({ ...contributor1, lastName: (contributor1.lastName || '').toLowerCase(), trigram: contributor1.trigram?.toLowerCase() }, objectKeys),
        omit({ ...contributor2, lastName: (contributor2.lastName || '').toLowerCase(), trigram: contributor2.trigram?.toLowerCase() }, objectKeys),
      ) && objectKeys.every(key => _isEqual(contributor1[key], contributor1[key]))
    );
  }
}
