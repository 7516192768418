/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LabelApplicabilityLightDTO } from './labelApplicabilityLightDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';

export interface UserDTO {
  additionalAuthorities?: Array<UserDTO.AdditionalAuthoritiesEnum>;
  addressMac?: string;
  androidVersion?: string;
  applicability?: LabelApplicabilityLightDTO;
  authorities?: Array<UserDTO.AuthoritiesEnum>;
  companyName?: string;
  contratType?: LabelFamilyLightDTO;
  createdBy?: string;
  createdDate?: Date;
  description?: string;
  deviceModel: string;
  deviceScreenSize: string;
  deviceStatus?: UserDTO.DeviceStatusEnum;
  email?: string;
  expirationDate?: Date;
  firstName?: string;
  hasMultiApplicability: boolean;
  hmacIntercom?: string;
  id?: number;
  imageUrl?: string;
  labelFamilyFunction?: LabelFamilyLightDTO;
  labelFamilyTeam?: LabelFamilyLightDTO;
  langKey?: string;
  lastBatteryLevel?: string;
  lastLatitude?: string;
  lastLongitude?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  lastName?: string;
  lastSeen?: Date;
  legalEntity?: LabelFamilyLightDTO;
  login: string;
  magicLink?: string;
  matricule?: string;
  modificationDate?: Date;
  natureUser?: UserDTO.NatureUserEnum;
  profession?: string;
  properties?: string;
  refExtern?: string;
  refIntern?: string;
  serial?: string;
  signature?: string;
  ssoIdentifier?: string;
  startDate?: Date;
  status?: UserDTO.StatusEnum;
  subdomain?: string;
  trigram?: string;
  uniqueDeviceId?: string;
  uuid?: string;
  uuidEntity?: string;
  uuidOrganization?: string;
  uuidType?: string;
  versionApp?: string;
}
export namespace UserDTO {
  export type AdditionalAuthoritiesEnum =
    | 'ROLE_ADMIN'
    | 'ROLE_USER'
    | 'ROLE_ADMIN_APP'
    | 'ROLE_USER_ADVANCED'
    | 'ROLE_USER_OPERATOR'
    | 'ROLE_USER_BASIC'
    | 'ROLE_ANONYMOUS'
    | 'ROLE_USER_MOBILE'
    | 'ROLE_USER_EXTERNAL_API_ACCESS'
    | 'ROLE_ADMIN_APP_GLOBAL'
    | 'ROLE_QHSE'
    | 'ROLE_USER_MODEL_FORM'
    | 'ROLE_EXTERNAL_CONTRIBUTOR'
    | 'ROLE_MANAGE_DIFFUSION';
  export const AdditionalAuthoritiesEnum = {
    ADMIN: 'ROLE_ADMIN' as AdditionalAuthoritiesEnum,
    USER: 'ROLE_USER' as AdditionalAuthoritiesEnum,
    ADMINAPP: 'ROLE_ADMIN_APP' as AdditionalAuthoritiesEnum,
    USERADVANCED: 'ROLE_USER_ADVANCED' as AdditionalAuthoritiesEnum,
    USEROPERATOR: 'ROLE_USER_OPERATOR' as AdditionalAuthoritiesEnum,
    USERBASIC: 'ROLE_USER_BASIC' as AdditionalAuthoritiesEnum,
    ANONYMOUS: 'ROLE_ANONYMOUS' as AdditionalAuthoritiesEnum,
    USERMOBILE: 'ROLE_USER_MOBILE' as AdditionalAuthoritiesEnum,
    USEREXTERNALAPIACCESS: 'ROLE_USER_EXTERNAL_API_ACCESS' as AdditionalAuthoritiesEnum,
    ADMINAPPGLOBAL: 'ROLE_ADMIN_APP_GLOBAL' as AdditionalAuthoritiesEnum,
    QHSE: 'ROLE_QHSE' as AdditionalAuthoritiesEnum,
    USERMODELFORM: 'ROLE_USER_MODEL_FORM' as AdditionalAuthoritiesEnum,
    EXTERNALCONTRIBUTOR: 'ROLE_EXTERNAL_CONTRIBUTOR' as AdditionalAuthoritiesEnum,
    MANAGEDIFFUSION: 'ROLE_MANAGE_DIFFUSION' as AdditionalAuthoritiesEnum,
  };
  export type AuthoritiesEnum =
    | 'ROLE_ADMIN'
    | 'ROLE_USER'
    | 'ROLE_ADMIN_APP'
    | 'ROLE_USER_ADVANCED'
    | 'ROLE_USER_OPERATOR'
    | 'ROLE_USER_BASIC'
    | 'ROLE_ANONYMOUS'
    | 'ROLE_USER_MOBILE'
    | 'ROLE_USER_EXTERNAL_API_ACCESS'
    | 'ROLE_ADMIN_APP_GLOBAL'
    | 'ROLE_QHSE'
    | 'ROLE_USER_MODEL_FORM'
    | 'ROLE_EXTERNAL_CONTRIBUTOR'
    | 'ROLE_MANAGE_DIFFUSION';
  export const AuthoritiesEnum = {
    ADMIN: 'ROLE_ADMIN' as AuthoritiesEnum,
    USER: 'ROLE_USER' as AuthoritiesEnum,
    ADMINAPP: 'ROLE_ADMIN_APP' as AuthoritiesEnum,
    USERADVANCED: 'ROLE_USER_ADVANCED' as AuthoritiesEnum,
    USEROPERATOR: 'ROLE_USER_OPERATOR' as AuthoritiesEnum,
    USERBASIC: 'ROLE_USER_BASIC' as AuthoritiesEnum,
    ANONYMOUS: 'ROLE_ANONYMOUS' as AuthoritiesEnum,
    USERMOBILE: 'ROLE_USER_MOBILE' as AuthoritiesEnum,
    USEREXTERNALAPIACCESS: 'ROLE_USER_EXTERNAL_API_ACCESS' as AuthoritiesEnum,
    ADMINAPPGLOBAL: 'ROLE_ADMIN_APP_GLOBAL' as AuthoritiesEnum,
    QHSE: 'ROLE_QHSE' as AuthoritiesEnum,
    USERMODELFORM: 'ROLE_USER_MODEL_FORM' as AuthoritiesEnum,
    EXTERNALCONTRIBUTOR: 'ROLE_EXTERNAL_CONTRIBUTOR' as AuthoritiesEnum,
    MANAGEDIFFUSION: 'ROLE_MANAGE_DIFFUSION' as AuthoritiesEnum,
  };
  export type DeviceStatusEnum = 'ACTIVE' | 'DISABLE' | 'BROKEN' | 'DOWN' | 'MAINTENANCE';
  export const DeviceStatusEnum = {
    ACTIVE: 'ACTIVE' as DeviceStatusEnum,
    DISABLE: 'DISABLE' as DeviceStatusEnum,
    BROKEN: 'BROKEN' as DeviceStatusEnum,
    DOWN: 'DOWN' as DeviceStatusEnum,
    MAINTENANCE: 'MAINTENANCE' as DeviceStatusEnum,
  };
  export type NatureUserEnum = 'NORMAL' | 'EXTERN' | 'DEVICE' | 'DEVICE_EXT' | 'EXTERNAL_API_ACCESS' | 'USER_CYPRESS' | 'EXTERNAL_CONTRIBUTOR';
  export const NatureUserEnum = {
    NORMAL: 'NORMAL' as NatureUserEnum,
    EXTERN: 'EXTERN' as NatureUserEnum,
    DEVICE: 'DEVICE' as NatureUserEnum,
    DEVICEEXT: 'DEVICE_EXT' as NatureUserEnum,
    EXTERNALAPIACCESS: 'EXTERNAL_API_ACCESS' as NatureUserEnum,
    USERCYPRESS: 'USER_CYPRESS' as NatureUserEnum,
    EXTERNALCONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR' as NatureUserEnum,
  };
  export type StatusEnum = 'ONBOARDING' | 'ACTIVATED' | 'BLOCKED' | 'DEACTIVATED' | 'DELETED';
  export const StatusEnum = {
    ONBOARDING: 'ONBOARDING' as StatusEnum,
    ACTIVATED: 'ACTIVATED' as StatusEnum,
    BLOCKED: 'BLOCKED' as StatusEnum,
    DEACTIVATED: 'DEACTIVATED' as StatusEnum,
    DELETED: 'DELETED' as StatusEnum,
  };
}
