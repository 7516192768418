import { BlockLightData } from '../block/block-light-data';
import { lastOneUuidEntity } from '../../../modules/shared/interfaces/select-item';
import { FlowMenuItemData } from '../utils/flow-menu-item-data';
import { PageStepRequestDTO, StepSummaryResponseDTO } from '../../generated/models/models';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';

import { FlowTypeEnum, BlockTypeEnum, FlowMenuItemTypeEnum, BlockStatusEnum } from '../../enums';
import { BlocRequestDTO } from '../../generated/models/blocRequestDTO';
import { PhaseSummaryData } from '../phase/phase-summary-data';
import { StepDetailsViewData } from './step-view-details-data';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export const firstStepElementInSelectItem: SingleSelectItemData<StepSummaryData> = {
  title: 'flowContentNavigation.steps.firstPosition',
  value: { uuidEntity: lastOneUuidEntity, name: 'flowContentNavigation.steps.firstPosition' } as StepSummaryData,
  icons: [{ name: 'hand-point-up', prefix: 'far' }],
};

export const _firstStepElementInSelectItem: SingleSelectItemData<StepSummaryData> = {
  title: 'flowContentNavigation.phases.lastPosition',
  value: { uuidEntity: lastOneUuidEntity, name: 'flowContentNavigation.phases.lastPosition' } as StepSummaryData,
  icons: [{ name: 'hand-point-down', prefix: 'far' }],
};

export interface StepSummaryData {
  name: string;
  customNodeOrder: string;
  nodeOrder: number;
  description: string;
  blocks: BlockLightData[];
  uuidEntity: string;
  disabled: boolean;
}

export namespace StepSummaryData {
  export interface QueryRequest extends BaseQuery<StepSummaryData> {
    projectUuidEntity: string;
    textSearch?: string;
    statuses?: BlockStatusEnum[];
  }

  export function mapFromApiValue(step: StepSummaryResponseDTO, parentPhaseNodeOrder: number): StepSummaryData {
    return {
      name: `E${step.customNodeOrder || parentPhaseNodeOrder * 100 + step.nodeOrderToDisplay}`,
      customNodeOrder: step.customNodeOrder,
      nodeOrder: step.nodeOrder,
      uuidEntity: step.uuidEntity,
      blocks: [],
      description: step.description,
      disabled: step.disabled,
    };
  }

  export function mapToFlowMenuItemData(step: StepSummaryResponseDTO, flowType: FlowTypeEnum): FlowMenuItemData {
    return {
      title: step.name,
      nodeOrder: step.nodeOrder,
      uuidEntity: step.uuidEntity,
      type: FlowMenuItemTypeEnum.Step,
      customNodeOrder: step.customNodeOrder,
      maskedInPdfFile: step.hideTitlePdf,
      blocks:
        flowType &&
        BlockTypeEnum.filteredValues()
          .getValue(flowType)
          .map(type => ({ blockType: type, disabled: !StepDetailsViewData.isBlockEnabled(step, type) })),
      description: step.description,
      nodeOrderToDisplay: step.nodeOrderToDisplay,
      excludeNumbering: step.excludeNumbering,
      additionalComment: step.additionalComment,
      disabled: step.disabled,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageStepRequestDTO {
    return {
      textSearch: queries.textSearch || undefined,
      page: queries.page,
      size: queries.size,
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function mapToBlocRequestApiValue(queries: PhaseSummaryData.QueryRequest): BlocRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: undefined,
      textSearch: queries.textSearch || undefined,
      natureBlocs: queries.natureBlocks && queries.natureBlocks.length ? queries.natureBlocks.map(nature => BlockTypeEnum.convertToApiValue.getValue(nature)) : undefined,
      statusBloc: queries.statuses && queries.statuses.length ? queries.statuses.map(status => BlockStatusEnum.convertToApiValue.getValue(status)) : undefined,
    };
  }

  export function initializeFilterQueries(page: number, searchText: string, projectUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<StepSummaryData>(),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
    };
  }

  export function mapToSelectItem(step: StepSummaryResponseDTO, parentPhaseNodeOrder: number, disabled: boolean): SingleSelectItemData<StepSummaryData> {
    return {
      title: `E${step.customNodeOrder || parentPhaseNodeOrder * 100 + step.nodeOrderToDisplay} - ${step.name}`,
      value: mapFromApiValue(step, parentPhaseNodeOrder),
      disabled,
    };
  }
}
