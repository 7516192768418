import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { compact } from 'lodash';

import { getFolderExistence } from '../../projects/list/state';
import { getListQueryParam } from '../../router';
import * as fromMain from './main.reducer';

import { EntityLightData, UserData } from '../../../core/models';
import { GeneratedFieldData } from '../../shared/interfaces/generated-field-data';

import { AuthMethodEnum, ProjectTypeEnum, RouteQueryParamEnum, StateName, UserRightEnum } from '../../../core/enums';
import { LabelApplicabilityResponseDTO } from '../../../core/generated/models/models';

const mainState = createFeatureSelector<fromMain.MainState>(StateName.Main);
const menuTitle = createSelector(mainState, state => state.menuTitle);
const menuSubtitle = createSelector(mainState, state => state.menuSubtitle);
const menuTitleIconClass = createSelector(mainState, state => state.menuTitleIconClass);
const getQuickConnectionState = createSelector(mainState, state => state.quickConnection);

export const getAccessHistoryData = createSelector(mainState, state => state.entityAccessHistoryData);
export const getOrganizationConfig = createSelector(mainState, state => state.organizationConfig);
export const getLmsTrainingUrl = createSelector(getOrganizationConfig, state => state?.lmsTrainingUrl);
export const getRubricPermissionsEnabled = createSelector(getOrganizationConfig, state => state?.rubricPermissionsEnabled);
export const getZendeskEnabled = createSelector(getOrganizationConfig, state => state?.zendeskEnabled);

export const getAppMenu = createSelector(mainState, state => state.menuItems);
export const getUserData = createSelector(mainState, state => state.userData);
export const getUserUuidEntity = createSelector(getUserData, userData => userData?.uuidEntity);
export const getUpdateProfileLoading = createSelector(mainState, state => state.updateProfileLoading);
export const getUserForUpdate = createSelector(getUserData, userData => userData && UserData.mapToUserForUpdate(userData));
export const getTabletData = createSelector(getUserData, userData => userData && UserData.mapToTabletData(userData));
export const getCompanyName = createSelector(getUserData, userData => userData?.companyName || '');
export const getUserName = createSelector(getUserData, userData => compact([userData?.firstName, userData?.lastName]).join(' '));
export const getUserNameLight = createSelector(
  getUserData,
  userData => (userData && ((userData.firstName?.length && userData.firstName[0] + '.') || '') + userData.lastName) || '',
);

export const getApplicabilityName = createSelector(getUserData, userData => userData?.applicability?.name || '');
export const getApplicability = createSelector(getUserData, userData => userData?.applicability || ({} as EntityLightData));
export const getApplicabilityUuidEntity = createSelector(getUserData, userData => userData?.applicability?.uuidEntity);
export const multiApplicabilities = createSelector(getUserData, userData => userData?.hasMultiApplicability);

export const getMenuTitle = createSelector(menuTitle, getAppMenu, (title, menu) => (menu && menu.title) || title);
export const getMenuSubTitle = createSelector(menuSubtitle, getAppMenu, (subtitle, menu) => (menu && menu.subtitle) || subtitle);
export const getMenuTitleIconClass = createSelector(menuTitleIconClass, getAppMenu, (titleIconClass, menu) => (menu && menu.titleIconClass) || titleIconClass || 'default');

export const getAppConfigLight = createSelector(mainState, state => state.configLight);
export const getPgacVisibility = createSelector(getAppConfigLight, configLight => configLight && configLight.isModulePgacActive);
export const getFileProcessingVisibility = createSelector(getAppConfigLight, configLight => configLight && configLight.isModuleFileProcessingActive);

export const getCurrentLanguage = createSelector(getUserData, userData => userData?.language);
export const isSso = createSelector(mainState, state => AuthMethodEnum.ssoValues.includes(state.authMethod));
export const isSsoOnly = createSelector(mainState, state => AuthMethodEnum.ssoOnlyValues.includes(state.authMethod));
export const isBasicAuth = createSelector(mainState, state => AuthMethodEnum.basicAuthValues.includes(state.authMethod));
export const isBasicAuthForbidden = createSelector(mainState, state => state.authMethod && !AuthMethodEnum.basicAuthValues.includes(state.authMethod));
export const getSequenceViewStatus = createSelector(mainState, state => state.sequenceViewIsActive);

// Note: User authorities selectors < Rights >
export const getUserAuthority = createSelector(getUserData, userData => userData?.authority);
export const getUserCompany = createSelector(getUserData, userData => userData?.company);
export const hasAnyAuthority = (authorities: UserRightEnum[]) => createSelector(getUserAuthority, userAuthority => authorities.includes(userAuthority));
export const hasRoleAsAdmin = createSelector(hasAnyAuthority(UserRightEnum.rolesAsAdmins), isAdmin => isAdmin);
export const userCanManageGenericForms = (applicabilityUuidEntity: string, type?: LabelApplicabilityResponseDTO.TypeEnum) =>
  createSelector(
    hasAnyAuthority([UserRightEnum.AdminSiteFlow]),
    getUserData,
    (isSiteFlowAdmin, { additionalAuthorities, applicability }) =>
      (isSiteFlowAdmin || additionalAuthorities.includes(UserRightEnum.UserGenericForm)) &&
      (!type || type === LabelApplicabilityResponseDTO.TypeEnum.ORGANIZATION || applicabilityUuidEntity === applicability.uuidEntity),
  );

export const userCanManageModelForms = (applicabilityUuidEntity: string, type?: LabelApplicabilityResponseDTO.TypeEnum) =>
  createSelector(
    hasAnyAuthority([UserRightEnum.AdminSiteFlow]),
    getUserData,
    (isSiteFlowAdmin, { additionalAuthorities, applicability }) =>
      (isSiteFlowAdmin || additionalAuthorities.includes(UserRightEnum.UserModelForm)) &&
      (!type || type === LabelApplicabilityResponseDTO.TypeEnum.ORGANIZATION || applicabilityUuidEntity === applicability.uuidEntity),
  );

export const generatedFieldWithRoleAdmins = (
  selector: MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>>,
): MemoizedSelector<object, GeneratedFieldData, DefaultProjectorFn<GeneratedFieldData>> =>
  createSelector(hasAnyAuthority(UserRightEnum.rolesAsAdmins), selector, (isAdmins, isGenerated) => ({
    canUpdateField: !isGenerated || isAdmins,
    isFieldGenerated: isGenerated,
  }));

export const generatedFieldWithRoleAdminsAndAdvanced = (
  selector: MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>>,
): MemoizedSelector<object, GeneratedFieldData, DefaultProjectorFn<GeneratedFieldData>> =>
  createSelector(hasAnyAuthority(UserRightEnum.rolesAsAdminsAndAdvanced), selector, (isAdmins, isGenerated) => ({
    canUpdateField: !isGenerated || isAdmins,
    isFieldGenerated: isGenerated,
  }));

export const userRightsCanBeManaged = (userRight: UserRightEnum) => createSelector(getUserAuthority, currentUserAuthority => currentUserAuthority <= userRight);

export const userCanModifyOrganizationApplicability = (applicabilityUuidEntity: string) =>
  createSelector(
    getApplicabilityUuidEntity,
    getUserAuthority,
    (userApplicabilityUuidEntity, userAuthority) =>
      UserRightEnum.rolesWhereOrganizationApplicabilityCanBeModified.includes(userAuthority) || userApplicabilityUuidEntity === applicabilityUuidEntity,
  );

export const getLoginFailError = createSelector(mainState, state => state.error && state.error.message);
export const getLoginLoading = createSelector(mainState, state => state.isLoading);

export const getProjectTypes = createSelector(getListQueryParam(RouteQueryParamEnum.Type), getPgacVisibility, getFolderExistence, (routeItems, pgacVisibility, folderExistence) =>
  ProjectTypeEnum.selectItems(pgacVisibility, folderExistence).map(type => ({
    ...type,
    selected: routeItems && routeItems.includes(ProjectTypeEnum.convertToParamsValue.getValue(type.value)),
  })),
);

export const getAppVersion = createSelector(mainState, state => state.appVersion);

export const getConflictData = createSelector(mainState, state => state.deleteEntityError);
export const getConflictUploadElementData = createSelector(mainState, state => state.elementUploadEntityError);

export const getQrCodeData = createSelector(getQuickConnectionState, qcState => qcState && qcState.qrCodeData);
export const getUnlinkUserLoading = createSelector(getQuickConnectionState, qcState => qcState && qcState.unlinkUserLoading);

export const getApplicationFileTmpData = createSelector(mainState, state => state.applicationFileTmpData);
