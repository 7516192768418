import { UserData } from '../account/user-data';
import { LanguageEnum, UserRightEnum } from '../../enums';
import { compact } from 'lodash';
import { ZendeskSingleSignOnAuthRequestDTO } from '../../generated/models/zendeskSingleSignOnAuthRequestDTO';
import { ZendeskEndUserAuthRequestDTO } from '../../generated/models/zendeskEndUserAuthRequestDTO';
import { SfsZendeskWidget } from '../../../../../assets/config/zendesk-config';

export interface CreateZendeskUser {
  user: {
    id?: number;
    name: string;
    email: string;
    organization_id: number;
    external_id: string;
    role: string;
    shared: boolean;
    verified: boolean;
    tags: string[];
    locale: string;
  };
}

export interface CreateZendeskOrganization {
  organization: {
    id?: number;
    name: string;
    tags: string[];
    domain_names: string[];
    external_id: string;
    url: string;
  };
}

export interface CountTicketsNotClosedByUserResponse {
  count: number;
}
export namespace ZendeskData {
  export const scriptFile = 'https://static.zdassets.com/ekr/snippet.js?key=e7b86078-fe69-4551-8f6d-6725bd67978f';
  export const configFile = '/assets/config/zendesk-config.js';
  export const helpCenterLink = 'https://siteflow.zendesk.com/hc/fr';

  export const mapUserDataToCreateOrUpdateOrganization = (userData: UserData): CreateZendeskOrganization => ({
    organization: {
      name: userData.companyName,
      domain_names: [`${userData.subdomain}.siteflow.app`],
      external_id: btoa(userData.companyName),
      url: userData.subdomain && `${userData.subdomain}.siteflow.app/`,
      tags: [`org:${userData.companyName}`],
    },
  });

  export const mapUserDataToCreateOrUpdateRequest = (userData: UserData, organizationZendeskId: number): CreateZendeskUser => ({
    user: {
      name: `${userData.firstName} ${userData.lastName}`,
      email: userData.email,
      organization_id: organizationZendeskId,
      external_id: userData.uuidEntity,
      tags: compact([
        userData.authority &&
          UserRightEnum.convertToApiValue
            .getValue(userData.authority)
            .replace(/^role_/i, 'right:')
            .toLocaleLowerCase(),
      ]),
      locale: LanguageEnum.convertFromApiSupportedLanguage.getValue(userData.language),
      shared: false,
      verified: true,
      role: 'end-user',
    },
  });

  export function mapToSingleSignOnAuthDTO(userData: UserData): ZendeskSingleSignOnAuthRequestDTO {
    return {
      email: userData.email,
      userName: `${userData.firstName} ${userData.lastName}`,
    };
  }

  export function mapToEndUserAuthDTO(userData: UserData): ZendeskEndUserAuthRequestDTO {
    return {
      email: userData.email,
      userName: `${userData.firstName} ${userData.lastName}`,
      externalId: userData.uuidEntity,
    };
  }

  export function buildHelpCenterLink(token: string, link: string): string {
    return `https://siteflow.zendesk.com/access/jwt?jwt=${token}&return_to=${link}`;
  }

  export function shutdown(): void {
    SfsZendeskWidget.hide();
    SfsZendeskWidget.logout();
  }
}
