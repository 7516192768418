/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EntityLight } from './entityLight';
import { EntityLightResponseDTO } from './entityLightResponseDTO';
import { FlowLightDTO } from './flowLightDTO';
import { FormLightResponseDTO } from './formLightResponseDTO';
import { LabelApplicabilityResponseDTO } from './labelApplicabilityResponseDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';
import { SectionDetailsResponseDTO } from './sectionDetailsResponseDTO';
import { VersionDocumentFlowLightDTO } from './versionDocumentFlowLightDTO';

export interface DocumentFlowDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  modificationDate: Date;
  refInt: string;
  refExt: string;
  createDate: Date;
  diffusionDate: Date;
  linkedFlow: FlowLightDTO;
  linkedForm: FormLightResponseDTO;
  linkedResponse: FormLightResponseDTO;
  etatDocument: string;
  preamble: string;
  subject: string;
  glossary: string;
  extern: boolean;
  natureIndice: DocumentFlowDetailsResponseDTO.NatureIndiceEnum;
  responsesStrategy: DocumentFlowDetailsResponseDTO.ResponsesStrategyEnum;
  includeEmmDoc: boolean;
  includeFormDoc: boolean;
  includeRhContent: boolean;
  showAnnexes: boolean;
  includeRefForm: boolean;
  includeSkeletonForm: boolean;
  includeEmptyOption: boolean;
  includeFormReferences: boolean;
  showSkillsSummary: boolean;
  includeAnnexeInterlayer: boolean;
  natureFile: DocumentFlowDetailsResponseDTO.NatureFileEnum;
  linkedTemplate: EntityLightResponseDTO;
  groupedResponses: boolean;
  clientCoverPage: boolean;
  navigationConditions: boolean;
  descriptionCoverPage: string;
  documentProvenance: DocumentFlowDetailsResponseDTO.DocumentProvenanceEnum;
  documentSource: DocumentFlowDetailsResponseDTO.DocumentSourceEnum;
  exportLanguageOne: DocumentFlowDetailsResponseDTO.ExportLanguageOneEnum;
  exportLanguageTwo: DocumentFlowDetailsResponseDTO.ExportLanguageTwoEnum;
  linkedDocumentsUuidEntities: Array<string>;
  linkedFlowsUuidEntities: Array<string>;
  linkedLabelFamiliesUuidEntities: Array<string>;
  application: EntityLight;
  labelFamilyApplication: LabelFamilyLightDTO;
  labelFamilyProject: LabelFamilyLightDTO;
  labelFamilyProjectSecondary: LabelFamilyLightDTO;
  applicability: LabelApplicabilityResponseDTO;
  sections: Array<SectionDetailsResponseDTO>;
  includeResponseDoc: boolean;
  includePhaseNumber: boolean;
  dsiChildRendered: boolean;
  includeTxtContent: boolean;
  includeMultiPrObs: boolean;
  hideCoverPage: boolean;
  includeContributorsPage: boolean;
  includeLinkedDocumentsInfos: boolean;
  contentDisabled: boolean;
  contentModuleDisabled: boolean;
  actionDisabled: boolean;
  vdfCount: number;
  lastVdf: VersionDocumentFlowLightDTO;
  complementRefInt: string;
  complementRefExt: string;
  docDiffused: boolean;
  largeFile: boolean;
  clientSuperVision: boolean;
  hideIndexColumn: boolean;
  includeInstructionBlock: boolean;
  hideMultiPrTitleOnSummary: boolean;
  includeControlBlock: boolean;
  revisionOnMainIndices: boolean;
  showLinkedEntitiesReferences: boolean;
  linkedResponseUuidEntities: Array<string>;
  disabled?: boolean;
}
export namespace DocumentFlowDetailsResponseDTO {
  export type NatureIndiceEnum = 'NUMERIC_ZERO' | 'NUMERIC' | 'ALPHA' | 'NUMERIC_ALL_ZERO' | 'NUMERIC_ALL_ZERO_START_ZERO';
  export const NatureIndiceEnum = {
    NUMERICZERO: 'NUMERIC_ZERO' as NatureIndiceEnum,
    NUMERIC: 'NUMERIC' as NatureIndiceEnum,
    ALPHA: 'ALPHA' as NatureIndiceEnum,
    NUMERICALLZERO: 'NUMERIC_ALL_ZERO' as NatureIndiceEnum,
    NUMERICALLZEROSTARTZERO: 'NUMERIC_ALL_ZERO_START_ZERO' as NatureIndiceEnum,
  };
  export type ResponsesStrategyEnum = 'NONE' | 'ALL' | 'SELECTED';
  export const ResponsesStrategyEnum = {
    NONE: 'NONE' as ResponsesStrategyEnum,
    ALL: 'ALL' as ResponsesStrategyEnum,
    SELECTED: 'SELECTED' as ResponsesStrategyEnum,
  };
  export type NatureFileEnum =
    | 'PR'
    | 'EMM'
    | 'ADR'
    | 'DSI'
    | 'LDA'
    | 'LFA'
    | 'LCA'
    | 'PROJECT'
    | 'AUTRE'
    | 'RFI'
    | 'PFORM'
    | 'FFORM'
    | 'BFORM'
    | 'PREVIEW_FORM'
    | 'MULTI_FLOW_FORM'
    | 'LIST_ADR'
    | 'LIST_EMM'
    | 'LIST_ADR_FLOW'
    | 'LIST_EMM_FLOW'
    | 'MULTI_PR'
    | 'FIELD_FEEDBACK';
  export const NatureFileEnum = {
    PR: 'PR' as NatureFileEnum,
    EMM: 'EMM' as NatureFileEnum,
    ADR: 'ADR' as NatureFileEnum,
    DSI: 'DSI' as NatureFileEnum,
    LDA: 'LDA' as NatureFileEnum,
    LFA: 'LFA' as NatureFileEnum,
    LCA: 'LCA' as NatureFileEnum,
    PROJECT: 'PROJECT' as NatureFileEnum,
    AUTRE: 'AUTRE' as NatureFileEnum,
    RFI: 'RFI' as NatureFileEnum,
    PFORM: 'PFORM' as NatureFileEnum,
    FFORM: 'FFORM' as NatureFileEnum,
    BFORM: 'BFORM' as NatureFileEnum,
    PREVIEWFORM: 'PREVIEW_FORM' as NatureFileEnum,
    MULTIFLOWFORM: 'MULTI_FLOW_FORM' as NatureFileEnum,
    LISTADR: 'LIST_ADR' as NatureFileEnum,
    LISTEMM: 'LIST_EMM' as NatureFileEnum,
    LISTADRFLOW: 'LIST_ADR_FLOW' as NatureFileEnum,
    LISTEMMFLOW: 'LIST_EMM_FLOW' as NatureFileEnum,
    MULTIPR: 'MULTI_PR' as NatureFileEnum,
    FIELDFEEDBACK: 'FIELD_FEEDBACK' as NatureFileEnum,
  };
  export type DocumentProvenanceEnum = 'LIBRARY' | 'REFERENTIAL' | 'LIBRARY_CREATED' | 'REFERENTIAL_CREATED' | 'LOCAL' | 'PROVENANCE_LIBRARY' | 'PROVENANCE_REFERENTIAL';
  export const DocumentProvenanceEnum = {
    LIBRARY: 'LIBRARY' as DocumentProvenanceEnum,
    REFERENTIAL: 'REFERENTIAL' as DocumentProvenanceEnum,
    LIBRARYCREATED: 'LIBRARY_CREATED' as DocumentProvenanceEnum,
    REFERENTIALCREATED: 'REFERENTIAL_CREATED' as DocumentProvenanceEnum,
    LOCAL: 'LOCAL' as DocumentProvenanceEnum,
    PROVENANCELIBRARY: 'PROVENANCE_LIBRARY' as DocumentProvenanceEnum,
    PROVENANCEREFERENTIAL: 'PROVENANCE_REFERENTIAL' as DocumentProvenanceEnum,
  };
  export type DocumentSourceEnum = 'FORMS' | 'FLOWS' | 'MODULES' | 'EXTRENS';
  export const DocumentSourceEnum = {
    FORMS: 'FORMS' as DocumentSourceEnum,
    FLOWS: 'FLOWS' as DocumentSourceEnum,
    MODULES: 'MODULES' as DocumentSourceEnum,
    EXTRENS: 'EXTRENS' as DocumentSourceEnum,
  };
  export type ExportLanguageOneEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageOneEnum = {
    FR: 'FR' as ExportLanguageOneEnum,
    EN: 'EN' as ExportLanguageOneEnum,
    DE: 'DE' as ExportLanguageOneEnum,
    IT: 'IT' as ExportLanguageOneEnum,
    NONE: 'NONE' as ExportLanguageOneEnum,
  };
  export type ExportLanguageTwoEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageTwoEnum = {
    FR: 'FR' as ExportLanguageTwoEnum,
    EN: 'EN' as ExportLanguageTwoEnum,
    DE: 'DE' as ExportLanguageTwoEnum,
    IT: 'IT' as ExportLanguageTwoEnum,
    NONE: 'NONE' as ExportLanguageTwoEnum,
  };
}
