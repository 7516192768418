import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { cloneDeep } from 'lodash';

import * as fromActions from './add-folder.actions';
import * as fromVersionActions from '../../../../../shared/documents-tabs/document-tracking-tab/shared-actions/doc-version.actions';

import { DocumentDetailsData } from '../../../../../../core/models/document/document-details';
import { DocumentVersionDetailsData } from '../../../../../../core/models/document/document-version-details';
import { CustomFieldData } from '../../../../../../core/models';
import { DocumentVersionSummaryData } from '../../../../../../core/models/document/document-version-summary';
import { CustomWordingsDocumentData } from '../../../../../../core/models/wording-config-setting/custom-wording-document-data';

import { DocumentManageDiffusionEnum } from '../../../../../../core/enums/document/document-manage-diffusion.enum';
import { InnerSortDirectionEnum } from '../../../../../../core/enums';

const adapter: EntityAdapter<DocumentVersionDetailsData> = createEntityAdapter<DocumentVersionDetailsData>({
  selectId: documentsVersion => documentsVersion.uuidEntity,
});
export interface DashboardAddFolderState extends EntityState<DocumentVersionDetailsData> {
  dataLoading: boolean;
  saveLoading: boolean;
  diffuseLoading: boolean;
  manageDiffusionLoading: DocumentManageDiffusionEnum;
  menuLoading: string;
  fileLoading: string;
  responseFileLoading: string;
  followTabChanged: boolean;
  folderDetails: DocumentDetailsData;
  initialFolderDetails: DocumentDetailsData;
  customFields: CustomFieldData[];
  initialCustomFields: CustomFieldData[];
  reset: boolean;
  totalCount: number;
  filteredTotalCount: number;
  folderCustomWording: CustomWordingsDocumentData;
}

export const initialAddFolderState = (): DashboardAddFolderState =>
  adapter.getInitialState<DashboardAddFolderState>({
    ids: [],
    entities: {},
    dataLoading: false,
    saveLoading: false,
    diffuseLoading: false,
    manageDiffusionLoading: null,
    menuLoading: null,
    fileLoading: null,
    responseFileLoading: null,
    followTabChanged: false,
    folderDetails: {} as DocumentDetailsData,
    initialFolderDetails: {} as DocumentDetailsData,
    customFields: [],
    initialCustomFields: [],
    reset: false,
    totalCount: null,
    filteredTotalCount: null,
    folderCustomWording: {} as CustomWordingsDocumentData,
  });

const reducer = createReducer<DashboardAddFolderState>(
  initialAddFolderState(),
  // Info: Loading actions
  on(
    fromActions.loadFolderDetails,
    fromActions.loadFolderCustomFields,
    fromVersionActions.loadDocumentVersions,
    (state): DashboardAddFolderState => ({ ...state, dataLoading: true }),
  ),
  on(fromActions.loadFolderDetails, (state, { reset }): DashboardAddFolderState => (reset ? { ...state, followTabChanged: false } : state)),
  on(
    fromActions.loadFolderDetailsFail,
    fromActions.loadFolderCustomFieldsFail,
    fromVersionActions.loadDocumentVersionsFail,
    (state): DashboardAddFolderState => ({ ...state, dataLoading: false }),
  ),
  on(
    fromActions.loadFolderDetailsSuccess,
    (state, { folderDetails }): DashboardAddFolderState => ({ ...state, folderDetails, initialFolderDetails: cloneDeep(folderDetails), dataLoading: false }),
  ),
  on(
    fromActions.loadFolderCustomFieldsSuccess,
    (state, { customFields }): DashboardAddFolderState => ({ ...state, customFields, initialCustomFields: cloneDeep(customFields), dataLoading: false }),
  ),
  on(
    fromVersionActions.loadDocumentVersionsSuccess,
    (state, { documentsVersion, reset }): DashboardAddFolderState => {
      const newState: DashboardAddFolderState = {
        ...state,
        dataLoading: false,
        reset,
        totalCount: documentsVersion.totalCount,
        filteredTotalCount: documentsVersion.filteredTotalCount,
      };

      return reset ? adapter.setAll(documentsVersion.payload, newState) : adapter.addMany(documentsVersion.payload, newState);
    },
  ),
  on(
    fromActions.checkGeneratedRefsSuccess,
    (state, { familyUuidEntity, genRefInt, genRefExt, genCompRefInt, genCompRefExt, reset }): DashboardAddFolderState => {
      const partialDocChanges = { generatedRefInt: genRefInt, generatedRefExt: genRefExt, generatedCompRefInt: genCompRefInt, generatedCompRefExt: genCompRefExt };
      const newRefInt = reset && genRefInt ? null : state.folderDetails?.refInt;
      const newRefExt = reset && genRefExt ? null : state.folderDetails?.refExt;

      return {
        ...state,
        folderDetails: {
          ...state.folderDetails,
          ...partialDocChanges,
          refInt: state.initialFolderDetails?.labelFamily?.uuidEntity === familyUuidEntity ? state.initialFolderDetails.refInt : newRefInt,
          refExt: state.initialFolderDetails?.labelFamily?.uuidEntity === familyUuidEntity ? state.initialFolderDetails.refExt : newRefExt,
        },
        initialFolderDetails: { ...state.initialFolderDetails, ...partialDocChanges },
      };
    },
  ),

  // Info: Saving actions
  on(fromActions.saveFolderChanges, (state): DashboardAddFolderState => ({ ...state, saveLoading: true })),
  on(fromActions.createFolderFail, fromActions.updateFolderFail, fromActions.stopSaveLoading, (state): DashboardAddFolderState => ({ ...state, saveLoading: false })),
  on(
    fromActions.createFolderSuccess,
    (state, { folderDetails }): DashboardAddFolderState => {
      const newFolderData = {
        ...folderDetails,
        generatedRefInt: state.folderDetails.generatedRefInt,
        generatedRefExt: state.folderDetails.generatedRefExt,
        generatedCompRefInt: state.folderDetails.generatedCompRefInt,
        generatedCompRefExt: state.folderDetails.generatedCompRefExt,
      };

      return { ...state, folderDetails: newFolderData, initialFolderDetails: cloneDeep(newFolderData), saveLoading: false };
    },
  ),
  on(
    fromActions.updateFolderSuccess,
    (state, { folderDetails, customFields }): DashboardAddFolderState => ({
      ...state,
      folderDetails,
      initialFolderDetails: cloneDeep(folderDetails),
      initialCustomFields: cloneDeep(customFields),
      saveLoading: false,
    }),
  ),

  // Info: Diffusion Actions:
  on(
    fromActions.checkDiffusedDocuments,
    fromVersionActions.diffuseDocument,
    fromVersionActions.diffuseBpa,
    fromVersionActions.clientResponse,
    fromVersionActions.clientResponseBpa,
    (state): DashboardAddFolderState => ({ ...state, diffuseLoading: true }),
  ),
  on(fromVersionActions.addSubIndex, (state): DashboardAddFolderState => ({ ...state, manageDiffusionLoading: DocumentManageDiffusionEnum.AddSubIndex })),
  on(
    fromVersionActions.unlockDocumentVersion,
    (state, { sameIndex }): DashboardAddFolderState => ({
      ...state,
      manageDiffusionLoading: sameIndex ? DocumentManageDiffusionEnum.SameIndex : DocumentManageDiffusionEnum.AddIndex,
    }),
  ),
  on(
    fromActions.checkDiffusedDocuments,
    fromVersionActions.diffuseDocument,
    fromVersionActions.diffuseBpa,
    fromVersionActions.clientResponse,
    fromVersionActions.clientResponseBpa,
    fromVersionActions.generateLibraryDocumentVersionPdf,
    fromVersionActions.convertToBpa,
    fromVersionActions.convertToBpeValid,
    fromVersionActions.editDiffusionFree,
    fromVersionActions.updateClientResponse,
    fromVersionActions.unlockDocumentVersion,
    fromVersionActions.cancelDiffusion,
    fromVersionActions.particularization,
    (state): DashboardAddFolderState => ({ ...state, followTabChanged: true }),
  ),
  on(fromVersionActions.uploadDocVersionFile, (state, { docVersionUuidEntity }): DashboardAddFolderState => ({ ...state, fileLoading: docVersionUuidEntity })),
  on(
    fromVersionActions.viewLibraryDocumentVersionPdf,
    fromVersionActions.generateLibraryDocumentVersionPdf,
    (state, { docVersionUuidEntity, fileLoading }): DashboardAddFolderState => ({
      ...state,
      fileLoading: fileLoading && docVersionUuidEntity,
      menuLoading: !fileLoading && docVersionUuidEntity,
    }),
  ),
  on(fromVersionActions.downloadDocumentVersionResponse, (state, { docVersionUuidEntity }): DashboardAddFolderState => ({ ...state, responseFileLoading: docVersionUuidEntity })),
  on(
    fromVersionActions.convertToBpa,
    fromVersionActions.convertToBpeValid,
    fromVersionActions.editDiffusionFree,
    fromVersionActions.updateClientResponse,
    fromVersionActions.particularization,
    fromVersionActions.updateDocVersionCollaborators,
    fromVersionActions.downloadLibraryDocumentVersionPdf,
    fromVersionActions.refreshFromConfig,
    fromVersionActions.refreshFromLastIndex,
    (state, { docVersionUuidEntity }): DashboardAddFolderState => ({ ...state, menuLoading: docVersionUuidEntity }),
  ),
  on(
    fromVersionActions.updateDocumentVersionFail,
    fromVersionActions.updateDocumentVersionSuccess,
    fromActions.stopSaveLoading,
    fromVersionActions.clientResponseSuccess,
    fromVersionActions.unlockVdfSuccess,
    fromVersionActions.deleteLastDocumentVersionSuccess,
    (state): DashboardAddFolderState => ({ ...state, diffuseLoading: false, menuLoading: null, fileLoading: null, responseFileLoading: null, manageDiffusionLoading: null }),
  ),
  on(
    fromVersionActions.updateDocumentVersionSuccess,
    (state, { docVersion }): DashboardAddFolderState =>
      docVersion
        ? adapter.updateOne(
            { id: docVersion.uuidEntity, changes: docVersion },
            { ...state, documentDetails: { ...state.folderDetails, status: docVersion.lastDocumentVersionStatus } },
          )
        : state,
  ),
  on(
    fromVersionActions.clientResponseSuccess,
    (state, { docVersions, sortDirection }): DashboardAddFolderState => {
      const newState =
        sortDirection !== InnerSortDirectionEnum.Desc
          ? adapter.addOne(docVersions.currentVersion, state)
          : {
              ...state,
              ids: [docVersions.currentVersion.uuidEntity, ...state.ids] as string[],
              entities: { ...state.entities, [docVersions.currentVersion.uuidEntity]: docVersions.currentVersion },
            };

      return adapter.updateOne({ id: docVersions.previousVersion.uuidEntity, changes: docVersions.previousVersion }, newState);
    },
  ),

  on(
    fromVersionActions.unlockVdfSuccess,
    (state, { docVersions, sortDirection }): DashboardAddFolderState => {
      const newState =
        sortDirection !== InnerSortDirectionEnum.Desc
          ? adapter.addOne(docVersions.currentVersion, {
              ...state,
              documentDetails: { ...state.folderDetails, lastDocumentVersion: DocumentVersionSummaryData.mapFromDocVersionDetails(docVersions.currentVersion) },
            })
          : {
              ...state,
              ids: [docVersions.currentVersion.uuidEntity, ...state.ids] as string[],
              entities: { ...state.entities, [docVersions.currentVersion.uuidEntity]: docVersions.currentVersion },
            };

      return adapter.updateOne({ id: docVersions.previousVersion.uuidEntity, changes: docVersions.previousVersion }, newState);
    },
  ),

  on(
    fromVersionActions.deleteLastDocumentVersionSuccess,
    (state, { docVersions }): DashboardAddFolderState =>
      adapter.updateOne(
        { id: docVersions.currentVersion.uuidEntity, changes: docVersions.currentVersion },
        adapter.removeOne(docVersions.previousVersion.uuidEntity, { ...state, filteredTotalCount: state.filteredTotalCount - 1 }),
      ),
  ),

  on(fromActions.loadCustomWordingDetailsSuccess, (state, { folderCustomWording }): DashboardAddFolderState => ({ ...state, folderCustomWording })),
);

export function dashboardAddFolderReducer(state: DashboardAddFolderState | undefined, action: Action): DashboardAddFolderState {
  return reducer(state, action);
}

export const selectAllDocumentVersions = adapter.getSelectors().selectAll;
