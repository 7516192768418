import { EntityLightData } from '../utils/entity-light-data';
import { FormReorganizeTypeEnum } from '../../enums';
import { FormReorganizeRequestDTO } from '../../generated/models/models';
import { FormLibrarySummaryData } from './form-library-summary';

export interface FormReorganizeData {
  allSelected: boolean;
  formsUuidEntity: string[];
  reorganize: EntityLightData;
  type: FormReorganizeTypeEnum;
  queries: FormLibrarySummaryData.QueryRequest;
}

export namespace FormReorganizeData {
  export function mapToRequestApiValue(formReorganize: FormReorganizeData): FormReorganizeRequestDTO {
    return {
      formsUuidEntity: !formReorganize.allSelected ? formReorganize.formsUuidEntity || [] : undefined,
      familyUuidEntity: FormReorganizeTypeEnum.Family === formReorganize.type && formReorganize.reorganize && formReorganize.reorganize.uuidEntity,
      pageFormRequest: formReorganize.allSelected ? formReorganize.queries && FormLibrarySummaryData.mapToPageRequestApiValue(formReorganize.queries) : undefined,
    };
  }
}
