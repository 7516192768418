import { ProjectDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ProjectColumnEnum {
  Family = 'family',
  Designation = 'designation',
  Client = 'client',
  BusinessRef = 'businessRef',
  Location = 'location',
  Responsible = 'responsible',
  Actions = 'actions',
  Index = 'index',
  Status = 'status',
}

export namespace ProjectColumnEnum {
  export type projectSortField = keyof ProjectDetailsResponseDTO;

  export const allColumns = [
    ProjectColumnEnum.Family,
    ProjectColumnEnum.Designation,
    ProjectColumnEnum.Status,
    ProjectColumnEnum.Client,
    ProjectColumnEnum.BusinessRef,
    ProjectColumnEnum.Location,
    // ProjectColumnEnum.Status,
    ProjectColumnEnum.Responsible,
    ProjectColumnEnum.Actions,
  ];

  export const FolderContent = [ProjectColumnEnum.Designation, ProjectColumnEnum.Status, ProjectColumnEnum.Index, ProjectColumnEnum.Actions];

  const key = 'projects.table.header.';
  export const toString: SfxMap<ProjectColumnEnum, string> = new SfxMap<ProjectColumnEnum, string>([
    [ProjectColumnEnum.Family, key + 'family'],
    [ProjectColumnEnum.Designation, key + 'designation'],
    [ProjectColumnEnum.Client, key + 'client'],
    [ProjectColumnEnum.BusinessRef, key + 'businessRef'],
    [ProjectColumnEnum.Location, key + 'location'],
    [ProjectColumnEnum.Responsible, key + 'responsible'],
    [ProjectColumnEnum.Actions, key + 'actions'],
    [ProjectColumnEnum.Index, key + 'index'],
    [ProjectColumnEnum.Status, key + 'status'],
  ]);

  export const sortField: SfxMap<ProjectColumnEnum, projectSortField> = new SfxMap<ProjectColumnEnum, projectSortField>([
    [ProjectColumnEnum.Family, 'labelFamilyApplication'],
    [ProjectColumnEnum.Designation, 'designation'],
    [ProjectColumnEnum.BusinessRef, 'businessRef'],
    [ProjectColumnEnum.Client, 'clientRef'],
    [ProjectColumnEnum.Location, 'locationLibelle'],
    [ProjectColumnEnum.Status, 'customStatus'],
  ]);
}
