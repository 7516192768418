import { ImportDocumentRequest } from '../../generated/models/importDocumentRequest';

export interface DashboardDocumentImportQueryRequest {
  projectUuidEntity: string;
  documentUuidEntities: string[];
}

export namespace DashboardDocumentImportQueryRequest {
  export function mapToApiValue(data: DashboardDocumentImportQueryRequest): ImportDocumentRequest {
    return {
      projectUuide: data.projectUuidEntity,
      documentUuides: data.documentUuidEntities || [],
    };
  }
}
