import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { FormNatureEnum, InnerSortDirectionEnum, StringUtilsEnum } from '../../enums';
import { BlocFormImportRequestDTO, FormToImportSummaryResponseDTO, PageImportFormRequestDTO } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { SelectAllData } from '../utils/select-all-data';
import { SortFilter } from '../utils/sort-filter';

export interface BlockFormToImportSummaryData {
  uuidEntity: string;
  name: string;
  modificationDate: string;
  nature: FormNatureEnum;
  formIndex: string;
  reference: string;
  family: LabelFamilyLightData;
  customStatus: CustomStatusLightData;
  frozen: boolean;
  additionalComment?: string;
}

export namespace BlockFormToImportSummaryData {
  const sortFields = ['family', 'name', 'reference', 'indice', 'modificationDate'];
  export interface QueryRequest extends BaseQuery<BlockFormToImportSummaryData> {
    applicabilities?: string[];
    families?: string[];
    statuses?: string[];
    projectUuidEntity: string;
    blockUuidEntity: string;
    nature: FormNatureEnum;
    fromLibrary: boolean;
    excludeImportedForm?: boolean;
  }

  export function mapFormApiValue(formBlockSummary: FormToImportSummaryResponseDTO): BlockFormToImportSummaryData {
    return {
      uuidEntity: formBlockSummary.uuidEntity,
      name: formBlockSummary.name,
      family: formBlockSummary.labelFamily && LabelFamilyLightData.mapFromApiValue(formBlockSummary.labelFamily),
      formIndex: formBlockSummary.indice,
      reference: formBlockSummary.reference,
      modificationDate: DateUtils.toDateFormat(formBlockSummary.modificationDate),
      nature: FormNatureEnum.convertFromApiValue.getValue(formBlockSummary.nature),
      customStatus: formBlockSummary.customStatus && CustomStatusLightData.mapFromApiValue(formBlockSummary.customStatus),
      frozen: formBlockSummary.alreadyImported,
      additionalComment: formBlockSummary.additionalComment,
    };
  }

  export function mapToRequestApiValue(projectUuidEntity: string, blockUuidEntity: string, selectAllData: SelectAllData, queries: QueryRequest): BlocFormImportRequestDTO {
    return {
      uuideProject: projectUuidEntity,
      uuideBloc: blockUuidEntity,
      multiSelectRequest: {
        selectedUuids: !selectAllData.allSelected ? selectAllData.selectedUuids || [] : undefined,
        excludedUuids: selectAllData.allSelected ? selectAllData.excludedUuids || [] : undefined,
        pageRequest: selectAllData.allSelected ? mapToPageRequestApiValue(queries) : undefined,
      },
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageImportFormRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      applicabilitiesUuidEntity: (queries.applicabilities || []).length ? queries.applicabilities : undefined,
      familiesUuidEntity: (queries.families || []).length ? queries.families : undefined,
      customStatusUuidEntity: (queries.statuses || []).length ? queries.statuses : undefined,
      type: FormNatureEnum.convertToApiValue.getValue(queries.nature) || undefined,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      blocUuidEntity: queries.blockUuidEntity,
      fromLibrary: queries.fromLibrary,
      excludeImportedForm: queries.excludeImportedForm,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    families: string[],
    statuses: string[],
    searchText: string,
    type: string,
    blockUuidEntity: string,
    projectUuidEntity: string,
    sort: SortFilter<BlockFormToImportSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<BlockFormToImportSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      nature: FormNatureEnum.convertFromParamsValue.getValue(type) || undefined,
      projectUuidEntity,
      blockUuidEntity,
      fromLibrary: false,
    };
  }

  export function initializeFilterQueries(
    page: number,
    searchText?: string,
    projectUuidEntity?: string,
    blockUuidEntity?: string,
    excludeImportedForm?: boolean,
    nature?: FormNatureEnum,
  ): QueryRequest {
    return {
      ...initializeQueries<BlockFormToImportSummaryData>(),
      textSearch: searchText || undefined,
      page,
      blockUuidEntity,
      projectUuidEntity,
      excludeImportedForm,
      nature,
      fromLibrary: false,
    };
  }

  export function mapToSelectItemData(formToImport: FormToImportSummaryResponseDTO): SingleSelectItemData<BlockFormToImportSummaryData> {
    const form = formToImport && mapFormApiValue(formToImport);
    const reference = form.reference ? form.reference : '';
    const referenceSeparator = form.reference ? StringUtilsEnum.DashSeparator : '';
    const index = form.formIndex ? form.formIndex : '';
    const indexSeparator = form.formIndex ? StringUtilsEnum.DashSeparator : '';
    const title = `${form.name}${referenceSeparator}${reference}${indexSeparator}${index}`;

    return {
      value: { ...form, name: title },
      title,
    };
  }
}
