import { compact } from 'lodash';

import { CreateDocumentFlowLibraryRequestDTO, DocumentFlowDetailsResponseDTO } from '../../generated/models/models';

import { IconData } from '../../../modules/shared/interfaces/icon-data';

import { SfxMap } from '../../utils/enum-utils';
import { FlowTypeEnum } from '../flow/flow-type.enum';

export enum DocumentNatureEnum {
  Pr = 1,
  Emm,
  Adr,
  Dsi,
  Lda,
  Lfa,
  Lca,
  Project,
  Other,
  ProjectForm,
  Rfi,
  FlowForm,
  BlockForm,
  ListEmm,
  ListEmmFlow,
  ListAdr,
  ListAdrFlow,
  MultiPr,
  MultiFlowForm,
  FieldFeedback,
}

export namespace DocumentNatureEnum {
  export const contributorsPage = [DocumentNatureEnum.Pr, DocumentNatureEnum.Dsi, DocumentNatureEnum.MultiPr, DocumentNatureEnum.Adr, DocumentNatureEnum.Emm];
  export const DocListNature = [DocumentNatureEnum.Lda, DocumentNatureEnum.Lfa, DocumentNatureEnum.ListEmmFlow, DocumentNatureEnum.ListAdrFlow];

  export const displayLinkedContent = [DocumentNatureEnum.Pr, DocumentNatureEnum.Dsi, DocumentNatureEnum.MultiPr];

  export const displayLinkedTemplate = [DocumentNatureEnum.BlockForm, DocumentNatureEnum.ProjectForm];

  export const convertFromApiValue: SfxMap<DocumentFlowDetailsResponseDTO.NatureFileEnum, DocumentNatureEnum> = new SfxMap<
    DocumentFlowDetailsResponseDTO.NatureFileEnum,
    DocumentNatureEnum
  >([
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.PR, DocumentNatureEnum.Pr],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.MULTIPR, DocumentNatureEnum.MultiPr],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.EMM, DocumentNatureEnum.Emm],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.ADR, DocumentNatureEnum.Adr],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.DSI, DocumentNatureEnum.Dsi],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LDA, DocumentNatureEnum.Lda],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LFA, DocumentNatureEnum.Lfa],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LCA, DocumentNatureEnum.Lca],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.PROJECT, DocumentNatureEnum.Project],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.AUTRE, DocumentNatureEnum.Other],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.RFI, DocumentNatureEnum.Rfi],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.PFORM, DocumentNatureEnum.ProjectForm],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.FFORM, DocumentNatureEnum.FlowForm],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.BFORM, DocumentNatureEnum.BlockForm],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.MULTIFLOWFORM, DocumentNatureEnum.MultiFlowForm],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTEMM, DocumentNatureEnum.ListEmm],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTEMMFLOW, DocumentNatureEnum.ListEmmFlow],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTADR, DocumentNatureEnum.ListAdr],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTADRFLOW, DocumentNatureEnum.ListAdrFlow],
    [DocumentFlowDetailsResponseDTO.NatureFileEnum.FIELDFEEDBACK, DocumentNatureEnum.FieldFeedback],
  ]);

  export const convertToApiValue = new SfxMap<DocumentNatureEnum, DocumentFlowDetailsResponseDTO.NatureFileEnum>([
    [DocumentNatureEnum.Pr, DocumentFlowDetailsResponseDTO.NatureFileEnum.PR],
    [DocumentNatureEnum.MultiPr, DocumentFlowDetailsResponseDTO.NatureFileEnum.MULTIPR],
    [DocumentNatureEnum.Emm, DocumentFlowDetailsResponseDTO.NatureFileEnum.EMM],
    [DocumentNatureEnum.Adr, DocumentFlowDetailsResponseDTO.NatureFileEnum.ADR],
    [DocumentNatureEnum.Dsi, DocumentFlowDetailsResponseDTO.NatureFileEnum.DSI],
    [DocumentNatureEnum.Lda, DocumentFlowDetailsResponseDTO.NatureFileEnum.LDA],
    [DocumentNatureEnum.Lfa, DocumentFlowDetailsResponseDTO.NatureFileEnum.LFA],
    [DocumentNatureEnum.Lca, DocumentFlowDetailsResponseDTO.NatureFileEnum.LCA],
    [DocumentNatureEnum.Project, DocumentFlowDetailsResponseDTO.NatureFileEnum.PROJECT],
    [DocumentNatureEnum.Other, DocumentFlowDetailsResponseDTO.NatureFileEnum.AUTRE],
    [DocumentNatureEnum.Rfi, DocumentFlowDetailsResponseDTO.NatureFileEnum.RFI],
    [DocumentNatureEnum.ProjectForm, DocumentFlowDetailsResponseDTO.NatureFileEnum.PFORM],
    [DocumentNatureEnum.FlowForm, DocumentFlowDetailsResponseDTO.NatureFileEnum.FFORM],
    [DocumentNatureEnum.BlockForm, DocumentFlowDetailsResponseDTO.NatureFileEnum.BFORM],
    [DocumentNatureEnum.MultiFlowForm, DocumentFlowDetailsResponseDTO.NatureFileEnum.MULTIFLOWFORM],
    [DocumentNatureEnum.ListEmm, DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTEMM],
    [DocumentNatureEnum.ListEmmFlow, DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTEMMFLOW],
    [DocumentNatureEnum.ListAdr, DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTADR],
    [DocumentNatureEnum.ListAdrFlow, DocumentFlowDetailsResponseDTO.NatureFileEnum.LISTADRFLOW],
    [DocumentNatureEnum.FieldFeedback, DocumentFlowDetailsResponseDTO.NatureFileEnum.FIELDFEEDBACK],
  ]);

  export const convertToLibraryApiValue = new SfxMap<DocumentNatureEnum, CreateDocumentFlowLibraryRequestDTO.NatureFileEnum>([
    [DocumentNatureEnum.Project, CreateDocumentFlowLibraryRequestDTO.NatureFileEnum.PROJECT],
    [DocumentNatureEnum.Other, CreateDocumentFlowLibraryRequestDTO.NatureFileEnum.AUTRE],
  ]);

  const key = 'document.nature.';
  export const toString: SfxMap<DocumentNatureEnum, string> = new SfxMap<DocumentNatureEnum, string>([
    [DocumentNatureEnum.Pr, key + 'pr'],
    [DocumentNatureEnum.Emm, key + 'emm'],
    [DocumentNatureEnum.Adr, key + 'adr'],
    [DocumentNatureEnum.Dsi, key + 'dsi'],
    [DocumentNatureEnum.Lda, key + 'lda'],
    [DocumentNatureEnum.Lfa, key + 'lfa'],
    [DocumentNatureEnum.Lca, key + 'lca'],
    [DocumentNatureEnum.Project, key + 'project'],
    [DocumentNatureEnum.Other, key + 'other'],
    [DocumentNatureEnum.Rfi, key + 'rfi'],
    [DocumentNatureEnum.ProjectForm, key + 'projectForm'],
    [DocumentNatureEnum.FlowForm, key + 'flowForm'],
    [DocumentNatureEnum.BlockForm, key + 'blockForm'],
    [DocumentNatureEnum.ListEmm, key + 'listEmm'],
    [DocumentNatureEnum.ListEmmFlow, key + 'listEmmFlow'],
    [DocumentNatureEnum.ListAdr, key + 'listAdr'],
    [DocumentNatureEnum.ListAdrFlow, key + 'listAdrFlow'],
    [DocumentNatureEnum.MultiPr, key + 'multiPr'],
    [DocumentNatureEnum.FieldFeedback, key + 'fieldFeedback'],
  ]);

  export const iconData: SfxMap<DocumentNatureEnum, IconData> = new SfxMap<DocumentNatureEnum, IconData>([
    [DocumentNatureEnum.Pr, { name: 'book-open', prefix: 'fas' }],
    [DocumentNatureEnum.Emm, { name: 'tools', prefix: 'fas' }],
    [DocumentNatureEnum.Adr, { name: 'exclamation-triangle', prefix: 'fas' }],
    [DocumentNatureEnum.Dsi, { name: 'file-signature', prefix: 'fas' }],
    [DocumentNatureEnum.FlowForm, { name: 'list-alt', prefix: 'far' }],
    [DocumentNatureEnum.MultiPr, { name: 'check-square', prefix: 'fas' }],
    [DocumentNatureEnum.ProjectForm, { name: 'folder-open', prefix: 'far' }],
    [DocumentNatureEnum.BlockForm, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [DocumentNatureEnum.MultiFlowForm, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [DocumentNatureEnum.Lfa, { name: 'sitemap', prefix: 'fas', iconClassName: 'fa-rotate-270' }],
    [DocumentNatureEnum.Lda, { name: 'file-invoice', prefix: 'fas' }],
    [DocumentNatureEnum.ListAdr, { name: 'exclamation-triangle', prefix: 'fas' }],
    [DocumentNatureEnum.ListEmm, { name: 'tools', prefix: 'fas' }],
    [DocumentNatureEnum.Lca, { name: 'user-friends', prefix: 'fas' }],
    [DocumentNatureEnum.Project, { name: 'file-upload', prefix: 'fas' }],
    [DocumentNatureEnum.Other, { name: 'file-pdf', prefix: 'far' }],
    [DocumentNatureEnum.FieldFeedback, { name: 'comments', prefix: 'fas' }],
  ]);

  const labelKey = 'document.addModal.type.label.';
  export const natureLabel: SfxMap<DocumentNatureEnum, string> = new SfxMap<DocumentNatureEnum, string>([
    [DocumentNatureEnum.Pr, labelKey + 'pr'],
    [DocumentNatureEnum.Emm, labelKey + 'emm'],
    [DocumentNatureEnum.Adr, labelKey + 'adr'],
    [DocumentNatureEnum.Dsi, labelKey + 'dsi'],
    [DocumentNatureEnum.FlowForm, labelKey + 'flowForm'],
    [DocumentNatureEnum.MultiPr, labelKey + 'multiPr'],
    [DocumentNatureEnum.ProjectForm, labelKey + 'projectForm'],
    [DocumentNatureEnum.BlockForm, labelKey + 'blockForm'],
    [DocumentNatureEnum.MultiFlowForm, labelKey + 'multiFlowForm'],
    [DocumentNatureEnum.Lfa, labelKey + 'listFlow'],
    [DocumentNatureEnum.Lda, labelKey + 'listDocument'],
    [DocumentNatureEnum.ListAdr, labelKey + 'listAdr'],
    [DocumentNatureEnum.ListAdrFlow, labelKey + 'listAdr'],
    [DocumentNatureEnum.ListEmm, labelKey + 'listEmm'],
    [DocumentNatureEnum.ListEmmFlow, labelKey + 'listEmm'],
    [DocumentNatureEnum.Lca, labelKey + 'listCollaborator'],
    [DocumentNatureEnum.Project, labelKey + 'pdfVersion'],
    [DocumentNatureEnum.Other, labelKey + 'pdfWithoutVersion'],
    [DocumentNatureEnum.FieldFeedback, labelKey + 'fieldFeedback'],
  ]);

  const commentKey = 'document.addModal.type.comment.';
  export const natureComment: SfxMap<DocumentNatureEnum, string> = new SfxMap<DocumentNatureEnum, string>([
    [DocumentNatureEnum.Pr, commentKey + 'pr'],
    [DocumentNatureEnum.Emm, commentKey + 'emm'],
    [DocumentNatureEnum.Adr, commentKey + 'adr'],
    [DocumentNatureEnum.Dsi, commentKey + 'dsi'],
    [DocumentNatureEnum.FlowForm, commentKey + 'flowForm'],
    [DocumentNatureEnum.MultiPr, commentKey + 'multiPr'],
    [DocumentNatureEnum.ProjectForm, commentKey + 'projectForm'],
    [DocumentNatureEnum.BlockForm, commentKey + 'blockForm'],
    [DocumentNatureEnum.MultiFlowForm, commentKey + 'multiFlowForm'],
    [DocumentNatureEnum.Lfa, commentKey + 'listFlow'],
    [DocumentNatureEnum.Lda, commentKey + 'listDocument'],
    [DocumentNatureEnum.ListAdr, commentKey + 'listAdr'],
    [DocumentNatureEnum.ListAdrFlow, commentKey + 'listAdr'],
    [DocumentNatureEnum.ListEmm, commentKey + 'listEmm'],
    [DocumentNatureEnum.ListEmmFlow, commentKey + 'listEmm'],
    [DocumentNatureEnum.Lca, commentKey + 'listCollaborator'],
    [DocumentNatureEnum.Project, commentKey + 'pdfVersion'],
    [DocumentNatureEnum.Other, commentKey + 'pdfWithoutVersion'],
    [DocumentNatureEnum.FieldFeedback, commentKey + 'fieldFeedback'],
  ]);

  export const blockName: SfxMap<DocumentNatureEnum, string> = new SfxMap<DocumentNatureEnum, string>([
    [DocumentNatureEnum.Pr, 'pr'],
    [DocumentNatureEnum.Emm, 'emm'],
    [DocumentNatureEnum.Adr, 'adr'],
    [DocumentNatureEnum.Dsi, 'dsi'],
    [DocumentNatureEnum.FlowForm, 'form'],
    [DocumentNatureEnum.MultiPr, 'multiInstruction'],
  ]);

  export const displayedDocument: SfxMap<FlowTypeEnum, DocumentNatureEnum[]> = new SfxMap<FlowTypeEnum, DocumentNatureEnum[]>(
    [
      [
        FlowTypeEnum.Referential,
        [DocumentNatureEnum.Pr, DocumentNatureEnum.Emm, DocumentNatureEnum.Adr, DocumentNatureEnum.Dsi, DocumentNatureEnum.FlowForm, DocumentNatureEnum.MultiPr],
      ],
      [
        FlowTypeEnum.Specific,
        [DocumentNatureEnum.Pr, DocumentNatureEnum.Emm, DocumentNatureEnum.Adr, DocumentNatureEnum.Dsi, DocumentNatureEnum.FlowForm, DocumentNatureEnum.MultiPr],
      ],
      [FlowTypeEnum.Generic, [DocumentNatureEnum.Pr, DocumentNatureEnum.Emm, DocumentNatureEnum.Adr]],
      [FlowTypeEnum.Group, [DocumentNatureEnum.Pr, DocumentNatureEnum.Dsi, DocumentNatureEnum.FlowForm]],
      [FlowTypeEnum.FlowForm, [DocumentNatureEnum.FlowForm]],
      [FlowTypeEnum.GroupReferential, [DocumentNatureEnum.Pr, DocumentNatureEnum.Dsi, DocumentNatureEnum.FlowForm]],
      [FlowTypeEnum.ReferentialForm, [DocumentNatureEnum.FlowForm]],
      [FlowTypeEnum.ReferentialGeneric, [DocumentNatureEnum.Pr, DocumentNatureEnum.Emm, DocumentNatureEnum.Adr]],
    ],
    [],
  );

  export const flowTypes = [DocumentNatureEnum.Pr, DocumentNatureEnum.Emm, DocumentNatureEnum.Adr, DocumentNatureEnum.Dsi, DocumentNatureEnum.FlowForm, DocumentNatureEnum.MultiPr];
  export const formTypes = [DocumentNatureEnum.ProjectForm, DocumentNatureEnum.BlockForm, DocumentNatureEnum.MultiFlowForm];
  export const moduleTypes = [
    DocumentNatureEnum.Lfa,
    DocumentNatureEnum.Lda,
    DocumentNatureEnum.ListAdr,
    DocumentNatureEnum.ListEmm,
    DocumentNatureEnum.Lca,
    DocumentNatureEnum.FieldFeedback,
  ];
  export const externTypes = [DocumentNatureEnum.Project, DocumentNatureEnum.Other];

  export const flowTypeByDocNatureAndProjectNature = (isRefProject?: boolean, hideTypeGroup?: boolean) => {
    if (isRefProject) {
      return new SfxMap<DocumentNatureEnum, FlowTypeEnum[]>(
        [
          [DocumentNatureEnum.Pr, [FlowTypeEnum.Referential, !hideTypeGroup && FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric]],
          [DocumentNatureEnum.Dsi, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential]],
          [DocumentNatureEnum.Emm, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential]],
          [DocumentNatureEnum.Adr, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential]],
          [DocumentNatureEnum.MultiPr, [FlowTypeEnum.Referential]],
          [DocumentNatureEnum.BlockForm, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialForm]],
          [DocumentNatureEnum.FlowForm, [FlowTypeEnum.Referential, !hideTypeGroup && FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialForm]],
          [DocumentNatureEnum.Lfa, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm]],
          [DocumentNatureEnum.Lda, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm]],
          [DocumentNatureEnum.ListAdrFlow, [FlowTypeEnum.Referential, FlowTypeEnum.ReferentialGeneric]],
          [DocumentNatureEnum.ListEmmFlow, [FlowTypeEnum.Referential, FlowTypeEnum.ReferentialGeneric]],
          [DocumentNatureEnum.MultiFlowForm, [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialForm]],
        ],
        [FlowTypeEnum.Referential, FlowTypeEnum.GroupReferential, FlowTypeEnum.ReferentialGeneric, FlowTypeEnum.ReferentialForm],
      );
    }

    return new SfxMap<DocumentNatureEnum, FlowTypeEnum[]>(
      [
        [DocumentNatureEnum.Pr, compact([FlowTypeEnum.Specific, !hideTypeGroup && FlowTypeEnum.Group, FlowTypeEnum.Generic])],
        [DocumentNatureEnum.Dsi, compact([FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.Generic])],
        [DocumentNatureEnum.Adr, [FlowTypeEnum.Specific, FlowTypeEnum.Generic]],
        [DocumentNatureEnum.Emm, [FlowTypeEnum.Specific, FlowTypeEnum.Generic]],
        [DocumentNatureEnum.MultiPr, [FlowTypeEnum.Specific]],
        [DocumentNatureEnum.BlockForm, compact([FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.FlowForm])],
        [DocumentNatureEnum.FlowForm, compact([FlowTypeEnum.Specific, FlowTypeEnum.FlowForm, !hideTypeGroup && FlowTypeEnum.Group])],
        [DocumentNatureEnum.Lfa, compact([FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.Generic, FlowTypeEnum.FlowForm])],
        [DocumentNatureEnum.Lda, compact([FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.Generic, FlowTypeEnum.FlowForm])],
        [DocumentNatureEnum.ListAdrFlow, [FlowTypeEnum.Specific, FlowTypeEnum.Generic]],
        [DocumentNatureEnum.ListEmmFlow, [FlowTypeEnum.Specific, FlowTypeEnum.Generic]],
        [DocumentNatureEnum.MultiFlowForm, compact([FlowTypeEnum.Specific, FlowTypeEnum.Group, FlowTypeEnum.FlowForm])],
      ],
      compact([FlowTypeEnum.Specific, !hideTypeGroup && FlowTypeEnum.Group, FlowTypeEnum.Generic, FlowTypeEnum.FlowForm]),
    );
  };

  export const docsWithLinkedFlow = [DocumentNatureEnum.MultiFlowForm, DocumentNatureEnum.Rfi, ...moduleTypes];

  export const docsWithHighlight = [DocumentNatureEnum.Pr, DocumentNatureEnum.MultiPr, DocumentNatureEnum.Dsi, DocumentNatureEnum.Emm, DocumentNatureEnum.Adr];
}
