import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Params } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { last } from 'lodash';

import { SfxRouterState } from '../router-state';
import { sfxModalOutlet } from '../../../core/constant/route.constant';
import { extractSegments, mapActivatedRouteSnapshotToActivatedRouteData } from './helpers';

@Injectable()
export class CustomRouteSerializer implements RouterStateSerializer<SfxRouterState> {
  serialize(routerState: RouterStateSnapshot): SfxRouterState {
    const routeSegments = extractSegments(routerState.root.firstChild).map(segment => mapActivatedRouteSnapshotToActivatedRouteData(segment));
    const dialogSegments = extractSegments(routerState.root.children.find(r => r.outlet === sfxModalOutlet)).map(segment => mapActivatedRouteSnapshotToActivatedRouteData(segment));
    const lastRouteSegment = routeSegments.length ? last(routeSegments).id : undefined;
    const lastDialogSegment = dialogSegments.length ? last(dialogSegments).id : undefined;

    const routeParams = {
      ...routeSegments.reduce((acc: Params, { params }) => ({ ...acc, ...params }), {}),
      ...dialogSegments.reduce((acc: Params, { params }) => ({ ...acc, ...params }), {}),
    };
    const routeQueryParams = {
      ...routeSegments.reduce((acc: Params, { queryParams }) => ({ ...acc, ...queryParams }), {}),
      ...dialogSegments.reduce((acc: Params, { queryParams }) => ({ ...acc, ...queryParams }), {}),
    };

    return {
      segments: routeSegments,
      dialogSegments,
      currentRouteId: lastDialogSegment || lastRouteSegment,
      params: routeParams,
      queryParams: routeQueryParams,
      url: routerState.url,
    };
  }
}
