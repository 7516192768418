import { createAction, props } from '@ngrx/store';
import { ErrorResponseData, ProjectSummaryData, ProjectScoopForUpdate } from '../../../../core/models';
import { ProjectCustomStatusForUpdate } from '../../../../core/models/projects/project-custom-status-for-update';

export const loadProjectNextPage = createAction('[Project/API] Load project next page');
export const loadProjectList = createAction('[Project/API] Load project list');
export const loadProjectListSuccess = createAction(
  '[Project/API] Load project list success',
  props<{ projects: ProjectSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadProjectListFail = createAction('[Project/API] Load project list fail', props<{ error: ErrorResponseData }>());

export const deleteProject = createAction('[Project/API] Delete project', props<{ projectUuidEntity: string }>());
export const deleteProjectFail = createAction('[Project/API] Delete project fail', props<{ error: ErrorResponseData }>());

export const updateProjectScoop = createAction('[Project/API] update project scoop', props<ProjectScoopForUpdate>());
export const updateProjectScoopSuccess = createAction('[Project/API] update project scoop success');
export const updateProjectScoopFail = createAction('[Project/API] update project scoop fail', props<{ error: ErrorResponseData }>());

export const checkFolderExistence = createAction('[Project/API] check folder existence', props<{ exist: boolean }>());
export const checkFolderExistenceFail = createAction('[Project/API] check folder existence fail', props<{ error: ErrorResponseData }>());

export const updateProjectCustomStatus = createAction('[Project/API] update project custom status', props<ProjectCustomStatusForUpdate>());
