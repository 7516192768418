import { PhaseSummaryResponseDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { FlowMenuItemData } from '../utils/flow-menu-item-data';
import { lastOneUuidEntity } from '../../../modules/shared/interfaces/select-item';
import { BlockStatusEnum, BlockTypeEnum, FlowMenuItemTypeEnum, StepSequenceTypeEnum } from '../../enums';
import { PagePhaseRequestDTO } from '../../generated/models/pagePhaseRequestDTO';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface PhaseSummaryData {
  name: string;
  customNodeOrder: string;
  nodeOrder: number;
  hideTitlePdf: boolean;
  uuidEntity: string;
  additionalComment?: string;
  disabled: boolean;
  excludeNumbering?: boolean;
  nodeOrderToDisplay?: number;
}

export namespace PhaseSummaryData {
  export const firstElementInSelectItem: SingleSelectItemData<PhaseSummaryData> = {
    title: 'flowContentNavigation.phases.firstPosition',
    value: { uuidEntity: null, name: 'flowContentNavigation.phases.firstPosition' } as PhaseSummaryData,
    icons: [{ name: 'hand-point-up', prefix: 'far' }],
  };

  export const _firstElementInSelectItem: SingleSelectItemData<PhaseSummaryData> = {
    title: 'flowContentNavigation.phases.lastPosition',
    value: { uuidEntity: lastOneUuidEntity, name: 'flowContentNavigation.phases.lastPosition' } as PhaseSummaryData,
    icons: [{ name: 'hand-point-down', prefix: 'far' }],
  };

  export interface QueryRequest extends BaseQuery<PhaseSummaryData> {
    projectUuidEntity: string;
    textSearch?: string;
    families?: string[];
    statuses?: BlockStatusEnum[];
    natureBlocks?: BlockTypeEnum[];
    categories?: string[];
  }

  export function mapFromApiValue(phase: PhaseSummaryResponseDTO): PhaseSummaryData {
    return {
      name: `P${phase.customNodeOrder || phase.nodeOrderToDisplay * 100}`,
      customNodeOrder: phase.customNodeOrder,
      nodeOrder: phase.nodeOrder,
      uuidEntity: phase.uuidEntity,
      hideTitlePdf: phase.hideTitlePdf,
      additionalComment: phase.additionalComment,
      disabled: phase.disabled,
      excludeNumbering: phase.excludeNumbering,
      nodeOrderToDisplay: phase.nodeOrderToDisplay,
    };
  }

  export function mapToSelectItem(phase: PhaseSummaryResponseDTO): SingleSelectItemData<PhaseSummaryData> {
    return {
      title: `P${phase.customNodeOrder || (phase.nodeOrderToDisplay || phase.nodeOrder) * 100} - ${phase.name}`,
      value: mapFromApiValue(phase),
    };
  }

  export function mapToFlowMenuItemData(phase: PhaseSummaryResponseDTO): FlowMenuItemData {
    return {
      title: phase.name,
      nodeOrder: phase.nodeOrder,
      uuidEntity: phase.uuidEntity,
      type: FlowMenuItemTypeEnum.Phase,
      description: phase.description,
      customNodeOrder: phase.customNodeOrder,
      maskedInPdfFile: phase.hideTitlePdf,
      childrenCount: (phase.steps && phase.steps.length) || 0,
      sequence: { sequenceType: StepSequenceTypeEnum.convertFromApiValue.getValue(phase.stepSequenceType) },
      additionalComment: phase.additionalComment,
      disabled: phase.disabled,
      excludeNumbering: phase.excludeNumbering,
      nodeOrderToDisplay: phase.nodeOrderToDisplay,
    };
  }

  export function mapPhaseToFlowMenuItemData(phase: PhaseSummaryData): FlowMenuItemData {
    return {
      title: phase.name,
      nodeOrder: phase.nodeOrder,
      uuidEntity: phase.uuidEntity,
      type: FlowMenuItemTypeEnum.Phase,
      customNodeOrder: phase.customNodeOrder,
      maskedInPdfFile: phase.hideTitlePdf,
      additionalComment: phase.additionalComment,
      disabled: phase.disabled,
      nodeOrderToDisplay: phase.nodeOrderToDisplay,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PagePhaseRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: undefined,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity,
      families: queries.families && queries.families.length ? queries.families : undefined,
      statusBloc: queries.statuses && queries.statuses.length ? queries.statuses.map(status => BlockStatusEnum.convertToApiValue.getValue(status)) : undefined,
      natureBlocs: queries.natureBlocks && queries.natureBlocks.length ? queries.natureBlocks.map(nature => BlockTypeEnum.convertToApiValue.getValue(nature)) : undefined,
      categories: queries.categories && queries.categories.length ? queries.categories : undefined,
    };
  }

  export function initializeQueryRequest(
    searchText: string,
    projectUuidEntity: string,
    statuses: string[],
    natures: string[],
    families: string[],
    categories: string[],
  ): QueryRequest {
    return {
      ...initializeQueries<PhaseSummaryData>(),
      textSearch: searchText || undefined,
      projectUuidEntity,
      statuses: (statuses || []).map(status => BlockStatusEnum.convertFromParamsValue.getValue(status)),
      natureBlocks: (natures || []).map(nature => BlockTypeEnum.convertFromParamsValue.getValue(nature)),
      families,
      categories,
    };
  }

  export function initializeFilterQueries(page: number, searchText: string, projectUuidEntity: string): QueryRequest {
    return {
      ...initializeQueries<PhaseSummaryData>(),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
    };
  }
}
