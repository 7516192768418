import { createAction, props } from '@ngrx/store';

import { ExternalUserDetailsData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

export const loadExternalUserDetailsSuccess = createAction('[ExternalUsers/Dialog] load external user details success', props<{ externalUser: ExternalUserDetailsData }>());
export const loadExternalUserDetailsFail = createAction('[ExternalUsers/Dialog] load external user details fail', props<{ error: DialogToasterData }>());

export const updateExternalUser = createAction('[ExternalUsers/Dialog] update external user', props<{ user: ExternalUserDetailsData }>());
export const updateExternalUserFail = createAction('[ExternalUsers/Dialog] update external user fail', props<{ error: DialogToasterData }>());
