import { compact } from 'lodash';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

import { ProjectTypeEnum } from '../project/project-type.enum';
import { PermissionActionEnum } from '../permission-action.enum';

export enum FlowActionEnum {
  Duplicate = 1,
  Refresh,
  Delete,
  PGACDetails,
  UpdatePgacForm,
  History,
  Move,
  Property,
  RefreshRefProject,
  Unlock,
  Execute,
  UpdateContent,
  Schedule,
  Collaborators,
}

export namespace FlowActionEnum {
  const key = 'flows.table.actions.';

  export const toString: SfxMap<FlowActionEnum, string> = new SfxMap<FlowActionEnum, string>([
    [FlowActionEnum.Duplicate, key + 'duplicate'],
    [FlowActionEnum.Refresh, key + 'refresh'],
    [FlowActionEnum.RefreshRefProject, key + 'refreshRefProject'],
    [FlowActionEnum.Delete, key + 'delete'],
    [FlowActionEnum.PGACDetails, key + 'pgacDetails'],
    [FlowActionEnum.UpdateContent, key + 'refreshContent'],
    [FlowActionEnum.UpdatePgacForm, key + 'updatePgacForm'],
    [FlowActionEnum.Move, key + 'move'],
    [FlowActionEnum.History, key + 'history'],
    [FlowActionEnum.Property, key + 'property'],
    [FlowActionEnum.Unlock, key + 'unlock'],
    [FlowActionEnum.Execute, key + 'execute'],
    [FlowActionEnum.Schedule, key + 'schedule'],
    [FlowActionEnum.Collaborators, key + 'collaborators'],
  ]);

  export const iconData: SfxMap<FlowActionEnum, IconData> = new SfxMap<FlowActionEnum, IconData>([
    [FlowActionEnum.Duplicate, { name: 'clone', prefix: 'far' }],
    [FlowActionEnum.Refresh, { name: 'shopping-basket', prefix: 'fas' }],
    [FlowActionEnum.RefreshRefProject, { name: 'sync-alt', prefix: 'fas' }],
    [FlowActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [FlowActionEnum.PGACDetails, { name: 'file-csv', prefix: 'fas' }],
    [FlowActionEnum.UpdatePgacForm, { name: 'plug', prefix: 'fas' }],
    [FlowActionEnum.Move, { name: 'suitcase', prefix: 'fas' }],
    [FlowActionEnum.History, { name: 'flag', prefix: 'fas' }],
    [FlowActionEnum.Property, { name: 'info', prefix: 'fas' }],
    [FlowActionEnum.Unlock, { name: 'unlock', prefix: 'fas' }],
    [FlowActionEnum.Execute, { name: 'rocket', prefix: 'fas' }],
    [FlowActionEnum.UpdateContent, { name: 'pencil-ruler', prefix: 'fas' }],
    [FlowActionEnum.Schedule, { name: 'stopwatch', prefix: 'fas' }],
    [FlowActionEnum.Collaborators, { name: 'user-friends', prefix: 'fas' }],
  ]);

  export const getActionMenu = (isReferentialFlow?: boolean, isHeadFlow?: boolean, isIntervention?: boolean, hideUpdate?: boolean, isReferentialGeneric?: boolean) => {
    const isOnlyRef = isReferentialFlow && !isReferentialGeneric;

    return new SfxMap<ProjectTypeEnum, ActionSelectItemData<FlowActionEnum>[]>(
      [
        [
          ProjectTypeEnum.Pgac,
          compact([
            ...(isReferentialFlow
              ? [mapToActionMenuItem(FlowActionEnum.RefreshRefProject, PermissionActionEnum.Edit)]
              : [mapToActionMenuItem(FlowActionEnum.PGACDetails), mapToActionMenuItem(FlowActionEnum.Refresh, PermissionActionEnum.Edit)]),
            isOnlyRef && mapToActionMenuItem(FlowActionEnum.Unlock, PermissionActionEnum.Unlock),
            mapToActionMenuItem(FlowActionEnum.History),
            mapToActionMenuItem(FlowActionEnum.Schedule),
            // mapToActionMenuItem(FlowActionEnum.Collaborators, PermissionActionEnum.EditFlowContributors), // Note: https://siteflow.atlassian.net/browse/SFP-2516

            ...(!isReferentialFlow ? [mapToActionMenuItem(FlowActionEnum.UpdatePgacForm, PermissionActionEnum.Edit), mapToActionMenuItem(FlowActionEnum.Move)] : []),
            mapToActionMenuItem(FlowActionEnum.Property),
            mapToActionMenuItem(FlowActionEnum.Delete, PermissionActionEnum.Delete),
          ]),
        ],
        [
          ProjectTypeEnum.Referential,
          compact([
            mapToActionMenuItem(FlowActionEnum.History),
            !isHeadFlow && mapToActionMenuItem(FlowActionEnum.Duplicate),
            mapToActionMenuItem(FlowActionEnum.Schedule),
            mapToActionMenuItem(FlowActionEnum.Property),
            mapToActionMenuItem(FlowActionEnum.Delete, PermissionActionEnum.Delete),
            !hideUpdate && !isHeadFlow && mapToActionMenuItem(FlowActionEnum.UpdateContent),
          ]),
        ],
        [
          ProjectTypeEnum.Project,
          compact([
            isOnlyRef && mapToActionMenuItem(FlowActionEnum.Execute),
            isReferentialFlow && mapToActionMenuItem(FlowActionEnum.RefreshRefProject, PermissionActionEnum.Edit),
            isOnlyRef && mapToActionMenuItem(FlowActionEnum.Unlock, PermissionActionEnum.Unlock),
            !isReferentialFlow && mapToActionMenuItem(FlowActionEnum.Refresh, PermissionActionEnum.Edit),
            mapToActionMenuItem(FlowActionEnum.History),
            !isReferentialFlow && !isHeadFlow && mapToActionMenuItem(FlowActionEnum.Duplicate),
            mapToActionMenuItem(FlowActionEnum.Schedule),
            // mapToActionMenuItem(FlowActionEnum.Collaborators, PermissionActionEnum.EditFlowContributors), // Note: https://siteflow.atlassian.net/browse/SFP-2516
            mapToActionMenuItem(FlowActionEnum.Property),
            mapToActionMenuItem(FlowActionEnum.Delete, PermissionActionEnum.Delete),
            !hideUpdate && !isHeadFlow && !isIntervention && mapToActionMenuItem(FlowActionEnum.UpdateContent),
          ]),
        ],
        [
          ProjectTypeEnum.Template,
          compact([
            mapToActionMenuItem(FlowActionEnum.History),
            !isHeadFlow && mapToActionMenuItem(FlowActionEnum.Duplicate),
            mapToActionMenuItem(FlowActionEnum.Property),
            mapToActionMenuItem(FlowActionEnum.Delete, PermissionActionEnum.Delete),
          ]),
        ],
      ],
      compact([
        mapToActionMenuItem(FlowActionEnum.Refresh, PermissionActionEnum.Edit),
        mapToActionMenuItem(FlowActionEnum.History),
        mapToActionMenuItem(FlowActionEnum.Duplicate),
        mapToActionMenuItem(FlowActionEnum.Schedule),
        mapToActionMenuItem(FlowActionEnum.Property),
        mapToActionMenuItem(FlowActionEnum.Delete, PermissionActionEnum.Delete),
        !hideUpdate && mapToActionMenuItem(FlowActionEnum.UpdateContent),
      ]),
    );
  };

  export function mapToActionMenuItem(flowActionMenu: FlowActionEnum, permission?: PermissionActionEnum): ActionSelectItemData<FlowActionEnum> {
    return {
      name: FlowActionEnum.toString.getValue(flowActionMenu),
      permissionAction: permission,
      value: flowActionMenu,
      icon: FlowActionEnum.iconData.getValue(flowActionMenu),
    };
  }
}
