import { compact } from 'lodash';

import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { ActionSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';
import { FlowTypeEnum } from '../flow/flow-type.enum';
import { PermissionActionEnum } from '../permission-action.enum';

export enum BlockFormActionEnum {
  Reply = 1,
  Overview = 2,
  Navigate = 3,
  ActivateMandatory = 4,
  DisableMandatory = 5,
  ActivateUnique = 6,
  DisableUnique = 7,
  History = 8,
  Replace = 9,
  Delete = 10,
  Disable = 11,
  Enable = 12,
  Share = 13,
  Summarize = 14,
}

export namespace BlockFormActionEnum {
  const key = 'blocks.form.table.actions.';
  export const toString: SfxMap<BlockFormActionEnum, string> = new SfxMap<BlockFormActionEnum, string>([
    [BlockFormActionEnum.Reply, key + 'reply'],
    [BlockFormActionEnum.Overview, key + 'overview'],
    [BlockFormActionEnum.Navigate, key + 'navigate'],
    [BlockFormActionEnum.ActivateMandatory, key + 'activateMandatory'],
    [BlockFormActionEnum.DisableMandatory, key + 'disableMandatory'],
    [BlockFormActionEnum.ActivateUnique, key + 'activateUnique'],
    [BlockFormActionEnum.DisableUnique, key + 'disableUnique'],
    [BlockFormActionEnum.History, key + 'history'],
    [BlockFormActionEnum.Replace, key + 'replace'],
    [BlockFormActionEnum.Delete, key + 'delete'],
    [BlockFormActionEnum.Disable, key + 'disable'],
    [BlockFormActionEnum.Enable, key + 'enable'],
    [BlockFormActionEnum.Share, key + 'share'],
    [BlockFormActionEnum.Summarize, key + 'summarize'],
  ]);

  const tooltipKey = 'blocks.form.tooltips.';
  export const tooltip: SfxMap<BlockFormActionEnum, string> = new SfxMap<BlockFormActionEnum, string>([
    [BlockFormActionEnum.Navigate, tooltipKey + 'navigate'],
    [BlockFormActionEnum.ActivateMandatory, tooltipKey + 'activateMandatory'],
    [BlockFormActionEnum.ActivateUnique, tooltipKey + 'activateUnique'],
  ]);

  export const iconData: SfxMap<BlockFormActionEnum, IconData> = new SfxMap<BlockFormActionEnum, IconData>([
    [BlockFormActionEnum.Reply, { name: 'envelope-open-text', prefix: 'fas' }],
    [BlockFormActionEnum.Overview, { name: 'eye', prefix: 'far' }],
    [BlockFormActionEnum.Navigate, { name: 'directions', prefix: 'fas' }],
    [BlockFormActionEnum.ActivateMandatory, { name: 'syringe', prefix: 'fas' }],
    [BlockFormActionEnum.DisableMandatory, { name: 'syringe', prefix: 'fas' }],
    [BlockFormActionEnum.ActivateUnique, { name: 'hand-point-up', prefix: 'fas' }],
    [BlockFormActionEnum.DisableUnique, { name: 'hand-point-up', prefix: 'fas' }],
    [BlockFormActionEnum.History, { name: 'flag', prefix: 'fas' }],
    [BlockFormActionEnum.Replace, { name: 'exchange-alt', prefix: 'fas' }],
    [BlockFormActionEnum.Delete, { name: 'trash-alt', prefix: 'far' }],
    [BlockFormActionEnum.Disable, { name: 'eye', prefix: 'fas' }],
    [BlockFormActionEnum.Enable, { name: 'eye-slash', prefix: 'fas' }],
    [BlockFormActionEnum.Share, { name: 'share-square', prefix: 'fas' }],
    [BlockFormActionEnum.Summarize, { name: 'chart-pie', prefix: 'fas' }],
  ]);

  export function getActionMenu(
    flowType: FlowTypeEnum,
    isValid: boolean,
    mandatory: boolean,
    single: boolean,
    revisionMode: boolean,
    isFormDisabled: boolean,
    isActionDisabled?: boolean,
  ): ActionSelectItemData<BlockFormActionEnum>[] {
    return compact([
      (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || isValid) &&
        FlowTypeEnum.flowsWhereFormCanBeReplied.includes(flowType) &&
        mapToActionMenuItem(BlockFormActionEnum.Reply),
      FlowTypeEnum.flowsWhereFormCanOverviewFromMenuAction.includes(flowType) && mapToActionMenuItem(BlockFormActionEnum.Overview),
      FlowTypeEnum.flowsWhereSummaryCanBeDiaplayed.includes(flowType) && mapToActionMenuItem(BlockFormActionEnum.Summarize),
      !isFormDisabled &&
        (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || !isValid) &&
        mapToActionMenuItem(BlockFormActionEnum.Navigate, PermissionActionEnum.Navigate, isActionDisabled),
      !isFormDisabled &&
        (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || !isValid) &&
        mapToActionMenuItem(!mandatory ? BlockFormActionEnum.ActivateMandatory : BlockFormActionEnum.DisableMandatory),
      !isFormDisabled &&
        (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || !isValid) &&
        mapToActionMenuItem(!single ? BlockFormActionEnum.ActivateUnique : BlockFormActionEnum.DisableUnique),
      (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || isValid) &&
        !isFormDisabled &&
        FlowTypeEnum.flowsWhereFormCanBeShared.includes(flowType) &&
        mapToActionMenuItem(BlockFormActionEnum.Share, PermissionActionEnum.Share),
      FlowTypeEnum.flowsWhereFormHistoryCanBeViewed.includes(flowType) && mapToActionMenuItem(BlockFormActionEnum.History),
      !revisionMode && !isValid && !FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) && !isFormDisabled && mapToActionMenuItem(BlockFormActionEnum.Replace),
      (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || !isValid) &&
        (isFormDisabled ? mapToActionMenuItem(BlockFormActionEnum.Enable) : mapToActionMenuItem(BlockFormActionEnum.Disable)),
      !revisionMode && (FlowTypeEnum.flowsWhereFormActionCanBeDisplayed.includes(flowType) || !isValid) && mapToActionMenuItem(BlockFormActionEnum.Delete),
    ]);
  }

  export function mapToActionMenuItem(
    formActionMenu: BlockFormActionEnum,
    permissionAction?: PermissionActionEnum,
    isActionDisabled?: boolean,
  ): ActionSelectItemData<BlockFormActionEnum> {
    return {
      name: BlockFormActionEnum.toString.getValue(formActionMenu),
      value: formActionMenu,
      icon: BlockFormActionEnum.iconData.getValue(formActionMenu),
      tooltip: BlockFormActionEnum.tooltip.getValue(formActionMenu),
      permissionAction,
      disabled: isActionDisabled,
    };
  }
}
