import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { SfxMap } from '../../utils/enum-utils';

export enum FormReorganizeTypeEnum {
  Family = 'family',
}

export namespace FormReorganizeTypeEnum {
  export const reorganizeTypes = [FormReorganizeTypeEnum.Family];

  export const toString: SfxMap<FormReorganizeTypeEnum, string> = new SfxMap<FormReorganizeTypeEnum, string>([
    [FormReorganizeTypeEnum.Family, 'forms.dialogs.reorganize.types.family'],
  ]);

  export const itemsResolver = (): Observable<SingleSelectItemData<FormReorganizeTypeEnum>[]> =>
    of(
      reorganizeTypes.map(value => ({
        value,
        title: FormReorganizeTypeEnum.toString.getValue(value),
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<FormReorganizeTypeEnum>, item: FormReorganizeTypeEnum) => node.value === item;
}
