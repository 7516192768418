import { createReducer, Action, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState, Dictionary } from '@ngrx/entity';

import * as fromFlowContentActions from './flow-content.actions';
import { importFlowSuccess, toggleStepSuccess } from '../dialogs/state/flow-content-dialog.actions';

import { FlowDetailsData, FlowMenuItemData, FlowSourcePropertiesData } from '../../../../core/models';

const adapter: EntityAdapter<FlowMenuItemData> = createEntityAdapter<FlowMenuItemData>({
  selectId: item => item.uuidEntity,
  sortComparer: (item1: FlowMenuItemData, item2: FlowMenuItemData) => (item1.nodeOrder > item2.nodeOrder ? 1 : -1),
});

export interface FlowContentState extends EntityState<FlowMenuItemData> {
  loading: boolean;
  phasesTotalCount: number;
  phasesFilteredTotalCount: number;
  reset: boolean;
  parentPhases: Dictionary<string>;
  steps: Dictionary<{ children: FlowMenuItemData[]; childrenCount: number; page: number }>;
  flowDetails: FlowDetailsData;
  flowSourceData: FlowSourcePropertiesData;
  focusedElement: string;
}

export const flowContentInitialState: FlowContentState = adapter.getInitialState<FlowContentState>({
  ids: [],
  entities: {},
  steps: {},
  parentPhases: {},
  loading: false,
  phasesTotalCount: null,
  phasesFilteredTotalCount: null,
  reset: false,
  flowDetails: undefined,
  flowSourceData: undefined,
  focusedElement: undefined,
});

const _flowContentReducer = createReducer<FlowContentState>(
  flowContentInitialState,
  on(
    fromFlowContentActions.resetCurrentFlowRevision,
    (state, { revision }): FlowContentState => ({ ...state, flowDetails: { ...state.flowDetails, currentRevision: state.flowDetails.currentRevision && revision } }),
  ),
  on(fromFlowContentActions.addFlowRevision, (state, { revision }): FlowContentState => ({ ...state, flowDetails: { ...state.flowDetails, currentRevision: revision } })),
  on(fromFlowContentActions.resetFlowSource, (state): FlowContentState => ({ ...state, flowDetails: { ...state.flowDetails, importedFlowUuidEntity: null } })),
  on(fromFlowContentActions.loadFlowSourcePropertiesSuccess, (state, { flowSourceData }): FlowContentState => ({ ...state, flowSourceData })),
  on(fromFlowContentActions.loadFlowDetailsSuccess, (state, { flow }): FlowContentState => ({ ...state, flowDetails: flow })),
  on(fromFlowContentActions.loadPhases, (state): FlowContentState => ({ ...state, loading: true })),
  on(
    fromFlowContentActions.loadPhasesSuccess,
    (state, { response, reset, refresh }): FlowContentState => {
      const steps: Dictionary<{ children: FlowMenuItemData[]; childrenCount: number; page: number }> = reset ? {} : { ...state.steps };
      const phases = response.payload.map(data => data.phase);

      response.payload.forEach(data => data.steps.length && (steps[data.phase.uuidEntity] = { page: 0, childrenCount: data.phase.childrenCount, children: data.steps }));

      const newState: FlowContentState = {
        ...state,
        phasesTotalCount: response.totalCount,
        phasesFilteredTotalCount: response.filteredTotalCount,
        loading: false,
        reset,
        steps,
      };

      return reset || refresh ? adapter.setAll(phases, newState) : adapter.addMany(phases, newState);
    },
  ),
  on(fromFlowContentActions.updatePhase, (state, { row }): FlowContentState => adapter.updateOne(row, { ...state, reset: false })),
  on(
    fromFlowContentActions.updateStep,
    (state, { step }): FlowContentState => ({
      ...state,
      reset: false,
      steps: {
        ...state.steps,
        [step.parent.uuidEntity]: {
          ...state.steps[step.parent.uuidEntity],
          children: state.steps[step.parent.uuidEntity].children.map(child => (child.uuidEntity === step.uuidEntity ? { ...step } : child)),
        },
      },
    }),
  ),
  on(
    fromFlowContentActions.updateBlock,
    (state, { phaseUuidEntity, stepUuidEntity, blockType }): FlowContentState => ({
      ...state,
      steps: {
        ...state.steps,
        [phaseUuidEntity]: {
          ...state.steps[phaseUuidEntity],
          children: state.steps[phaseUuidEntity]?.children.map(step =>
            step.uuidEntity === stepUuidEntity
              ? {
                  ...step,
                  blocks: step.blocks.map(block =>
                    block.blockType === blockType
                      ? {
                          ...block,
                          disabled: false,
                        }
                      : block,
                  ),
                }
              : step,
          ),
        },
      },
    }),
  ),
  on(
    fromFlowContentActions.loadStepsSuccess,
    (state, { steps, phaseUuidEntity }): FlowContentState => {
      const parentPhases = { ...state.parentPhases };
      const parentRow = { uuidEntity: phaseUuidEntity } as FlowMenuItemData;

      const newSteps: Dictionary<{ children: FlowMenuItemData[]; childrenCount: number; page: number }> = {
        ...state.steps,
        [parentRow.uuidEntity]: {
          page: 0,
          childrenCount: steps.length,
          children: steps.map(step => {
            parentPhases[step.uuidEntity] = parentRow.uuidEntity;

            return { ...step, parent: parentRow };
          }),
        },
      };

      return {
        ...state,
        reset: false,
        entities: { ...state.entities, [parentRow.uuidEntity]: { ...state.entities[parentRow.uuidEntity], childrenCount: steps.length } },
        parentPhases,
        steps: newSteps,
      };
    },
  ),
  on(
    fromFlowContentActions.resetSteps,
    (state, { parentRow }): FlowContentState => {
      const parentPhases = { ...state.parentPhases };
      const steps = { ...state.steps };
      ((steps[parentRow.uuidEntity] && steps[parentRow.uuidEntity].children) || []).forEach(step => delete parentPhases[step.uuidEntity]);
      delete steps[parentRow.uuidEntity];

      return { ...state, parentPhases, steps };
    },
  ),
  on(
    importFlowSuccess,
    (state, { importedFlowUuidEntity }): FlowContentState => ({ ...state, flowDetails: { ...state.flowDetails, importedFlowUuidEntity, hasFlowSource: true } }),
  ),
  on(fromFlowContentActions.focusElement, (state, { elementUuidEntity }): FlowContentState => ({ ...state, focusedElement: elementUuidEntity })),
  on(
    toggleStepSuccess,
    (state, { newState, phaseUuidEntity }): FlowContentState => (newState ? state : adapter.updateOne({ id: phaseUuidEntity, changes: { disabled: newState } }, state)),
  ),
);

export function flowContentReducer(state: FlowContentState | undefined, action: Action): FlowContentState {
  return _flowContentReducer(state, action);
}

export const selectAllPhases = adapter.getSelectors().selectAll;
