import { TechnicalObjectDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';

export enum ObjectColumnEnum {
  Family = 'family',
  Name = 'name',
  Status = 'status',
  InternalReference = 'internalReference',
  ExternalReference = 'externalReference',
  Actions = 'actions',
}

export namespace ObjectColumnEnum {
  export type objectSortField = keyof TechnicalObjectDetailsResponseDTO;

  export const allColumns = [
    ObjectColumnEnum.Family,
    ObjectColumnEnum.Name,
    ObjectColumnEnum.Status,
    ObjectColumnEnum.InternalReference,
    ObjectColumnEnum.ExternalReference,
    ObjectColumnEnum.Actions,
  ];

  const key = 'technicalObjects.table.header.';
  export const toString: SfxMap<ObjectColumnEnum, string> = new SfxMap<ObjectColumnEnum, string>([
    [ObjectColumnEnum.Family, key + 'family'],
    [ObjectColumnEnum.Name, key + 'name'],
    [ObjectColumnEnum.Status, key + 'status'],
    [ObjectColumnEnum.InternalReference, key + 'internalReference'],
    [ObjectColumnEnum.ExternalReference, key + 'externalReference'],
    [ObjectColumnEnum.Actions, key + 'actions'],
  ]);

  export const sortField: SfxMap<ObjectColumnEnum, objectSortField> = new SfxMap<ObjectColumnEnum, objectSortField>([
    [ObjectColumnEnum.Name, 'name'],
    [ObjectColumnEnum.Family, 'labelFamily'],
    [ObjectColumnEnum.Status, 'customStatus'],
    [ObjectColumnEnum.InternalReference, 'refInt'],
    [ObjectColumnEnum.ExternalReference, 'refExt'],
  ]);
}
