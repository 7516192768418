import { compact } from 'lodash';

import { FlowLightDTO, FlowLightResponseDTO, FlowLightSummaryResponseDTO, FlowSummaryResponseDTO, StepSummaryResponseDTO } from '../../generated/models/models';

import { FlowMenuItemData } from '../utils/flow-menu-item-data';
import { EntityLightData } from '../utils/entity-light-data';

import { FlowTypeEnum, FlowMenuItemTypeEnum } from '../../enums';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export interface FlowLightData {
  uuidEntity: string;
  name: string;
  type: FlowTypeEnum;
  color?: string;
  refInt?: string;
  status?: EntityLightData;
  fullName?: string;
}

export namespace FlowLightData {
  export function mapFromApiValue(flowLight: FlowLightDTO | FlowSummaryResponseDTO): FlowLightData {
    return {
      uuidEntity: flowLight.uuidEntity,
      name: flowLight.name,
      type: FlowTypeEnum.convertFromApiValue.getValue(flowLight.typeFlow),
      color: (flowLight as FlowLightDTO).color,
      refInt: flowLight.refInt,
      fullName: compact([flowLight.refInt, flowLight.name]).join(' - '),
    };
  }

  export function mapFromApiResponse(flowLight: FlowLightResponseDTO): FlowLightData {
    return {
      uuidEntity: flowLight.uuidEntity,
      name: flowLight.name,
      type: FlowTypeEnum.convertFromApiValue.getValue(flowLight.typeFlow),
      refInt: flowLight.refIntern,
    };
  }

  export function mapToResponseEntityLightData(flowLight: FlowLightSummaryResponseDTO): FlowLightData {
    return {
      uuidEntity: flowLight.uuidEntity,
      name: flowLight.name,
      status: EntityLightData.mapStatusFromApiValue(flowLight.customStatusFlow),
      type: undefined,
    };
  }

  export function mapToEntityLightData(flowLight: FlowLightDTO): EntityLightData {
    return {
      uuidEntity: flowLight.uuidEntity,
      name: flowLight.name,
      color: flowLight.color,
    };
  }

  export function mapToFlowMenuItemData(step: StepSummaryResponseDTO): FlowMenuItemData {
    const flow = step.flowLight && mapFromApiValue(step.flowLight);

    return {
      title: flow.name,
      description: FlowTypeEnum.toString().getValue(flow.type),
      uuidEntity: flow.uuidEntity,
      nodeOrder: step.nodeOrder,
      metaParent: step.uuidEntity,
      type: FlowMenuItemTypeEnum.Flow,
      sequence: {
        name: (step.sequenceLight && step.sequenceLight.name) || flow.name,
        uuidEntity: step.sequenceLight && step.sequenceLight.uuidEntity,
        color: flow.color || '#0d4e8a',
      },
      nodeOrderToDisplay: step.nodeOrderToDisplay,
      disabled: step.disabled,
      additionalComment: step.additionalComment,
    };
  }

  export function mapToSelectItem(flowLight: FlowSummaryResponseDTO, fullName?: boolean, showType?: boolean, showLabelFamily?: boolean): SingleSelectItemData<FlowLightData> {
    return {
      value: flowLight && mapFromApiValue(flowLight),
      title: compact([fullName && flowLight.refInt, flowLight.name]).join(' - '),
      flowType: showType ? FlowTypeEnum.convertFromApiValue.getValue(flowLight.typeFlow) : null,
      subtitle: getComment(flowLight, showType, showLabelFamily),
    };
  }

  export function mapToFilterSelectItem(flowLight: FlowSummaryResponseDTO): MultiSelectItemData {
    return {
      value: flowLight.uuidEntity,
      title: compact([flowLight.refInt, flowLight.name]).join(' - '),
      subtitle: getComment(flowLight, true, true),
    };
  }

  function getComment(flowLight: FlowSummaryResponseDTO, showType?: boolean, showLabelFamily?: boolean): string {
    if (showLabelFamily) {
      return compact([flowLight?.labelFamilyApplication?.name, flowLight?.labelFamilyProject?.name]).join(' - ');
    }

    return showType ? FlowTypeEnum.toString().getValue(FlowTypeEnum.convertFromApiValue.getValue(flowLight.typeFlow)) : null;
  }
}
