import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Store, select, MemoizedSelector } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { LocalStorageService } from 'ngx-webstorage';
import { ToasterService } from '../shared/components/toaster/toaster.service';
import { ROUTER_NAVIGATION, RouterNavigationAction, ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';

import { compact, findIndex, isEqual, omit, pickBy, take, uniqBy } from 'lodash';
import { of } from 'rxjs';
import { tap, filter, map, withLatestFrom, concatMap, switchMap, distinctUntilChanged, pairwise } from 'rxjs/operators';

import * as routerActions from './router.action';
import { dummyAction } from '../main/state/main.actions';

import { AppState } from '../app.state';
import { getCurrentProjectDesignation, getCurrentProjectIconClass } from '../dashboard/state';
import { getFlowClassName, getFlowName } from '../dashboard/flow-content/state';
import { getSelectedFlowName } from '../dashboard/signatures/state';
import { getUserUuidEntity } from '../main/state';
import { getRouteNavigatedSegments, getActiveRouteId, getQueryParams, getRouteParam, getDialogNavigationSegments } from '.';

import { SfxRouterState } from './router-state';
import { BreadCrumbData, RouteSegment } from '../../core/models';
import { RouterPaths, sfxModalOutlet } from '../../core/constant/route.constant';

import { RouteDataEnum } from '../../core/enums/router-enums/route-data.enum';
import { BreadCrumbEnum, DialogRouteQueryParam, RouteIdEnum, LocalStorageEnum, RouteParamEnum, ToasterTypeEnum } from '../../core/enums';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SfxRouterState>,
    private router: Router,
    private location: PlatformLocation,
    private localStorage: LocalStorageService,
    private toaster: ToasterService,
    private translate: TranslateService,
  ) {}

  onRouteNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction<SfxRouterState>>(ROUTER_NAVIGATION),
      filter(action => action !== null),
      map(action => routerActions.routerNavigationChange({ routerState: action.payload.routerState })),
    ),
  );

  onRouteNavigated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction<SfxRouterState>>(ROUTER_NAVIGATED),
      withLatestFrom(this.store.pipe(select(getQueryParams))),
      filter(([action, _]) => action !== null),
      tap(([action, queryParams]) => !isEqual(action.payload.routerState, queryParams) && this.store.dispatch(routerActions.routerQueryParamsChange())),
      concatMap(([action]) => [
        routerActions.routerNavigatedChange({ routerState: action.payload.routerState }),
        routerActions.buildBreadCrumb({ breadCrumbs: this.buildBreadCrumb(action.payload.routerState.segments) }),
      ]),
    ),
  );

  saveFiltersOnChangeRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.routerNavigationChange),
      map(action => action && action.routerState.currentRouteId),
      distinctUntilChanged((previousRouteId, currentRouteId) => previousRouteId === currentRouteId),
      pairwise(),
      withLatestFrom(this.store.pipe(select(getUserUuidEntity))),
      map(([[previousRouteId, _], userUuidEntity]) => routerActions.saveRouteFilters({ previousRouteId, userUuidEntity })),
    ),
  );

  saveRouteFiltersEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.saveRouteFilters),
        withLatestFrom(
          this.store.pipe(select(getQueryParams)),
          this.store.pipe(select(getRouteParam(RouteParamEnum.ProjectUuidEntity))),
          this.store.pipe(select(getRouteParam(RouteParamEnum.FlowUuidEntity))),
          this.store.pipe(select(getDialogNavigationSegments)),
        ),
        filter(([{ previousRouteId, userUuidEntity }]) => previousRouteId && userUuidEntity && !RouteIdEnum.exceptionSaveFilterRouteIds.includes(previousRouteId)),
        tap(([{ previousRouteId, userUuidEntity }, queryParams, projectUuidEntity, flowUuidEntity, dialogSegments]) => {
          if (RouteIdEnum.blockRoutes.includes(previousRouteId)) {
            previousRouteId = flowUuidEntity as RouteIdEnum;
          }

          const currentFilters = this.localStorage.retrieve(LocalStorageEnum.SelectedFilters) || {};
          const toBeSavedFilters = dialogSegments.length ? pickBy(queryParams, (__, key) => DialogRouteQueryParam.params.includes(key)) : queryParams;
          const filtersKey = compact([previousRouteId, projectUuidEntity, flowUuidEntity]).join('-');
          this.localStorage.store(LocalStorageEnum.SelectedFilters, {
            ...currentFilters,
            [userUuidEntity]: { ...currentFilters[userUuidEntity], [filtersKey]: omit(toBeSavedFilters, DialogRouteQueryParam.Parent) },
          });
        }),
      ),
    { dispatch: false },
  );

  recoverFiltersOnChangeRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.routerNavigatedChange),
        map(action => action && action.routerState.currentRouteId),
        distinctUntilChanged((previousRouteId, currentRouteId) => previousRouteId === currentRouteId),
        withLatestFrom(
          this.store.pipe(select(getUserUuidEntity)),
          this.store.pipe(select(getRouteParam(RouteParamEnum.ProjectUuidEntity))),
          this.store.pipe(select(getRouteParam(RouteParamEnum.FlowUuidEntity))),
        ),
        filter(([currentRouteId, userUuidEntity]) => !!currentRouteId && !!userUuidEntity && !RouteIdEnum.exceptionSaveFilterRouteIds.includes(currentRouteId)),
        tap(([currentRouteId, userUuidEntity, projectUuidEntity, flowUuidEntity]) => {
          if (RouteIdEnum.blockRoutes.includes(currentRouteId)) {
            currentRouteId = flowUuidEntity as RouteIdEnum;
          }

          const currentFilters = this.localStorage.retrieve(LocalStorageEnum.SelectedFilters) || {};
          const filtersKey = currentRouteId + (projectUuidEntity ? `-${projectUuidEntity}` : '') + (flowUuidEntity ? `-${flowUuidEntity}` : '');
          if (currentFilters && currentFilters[userUuidEntity] && currentFilters[userUuidEntity][filtersKey]) {
            this.router.navigate([], { queryParamsHandling: 'merge', queryParams: currentFilters[userUuidEntity][filtersKey] || {} });
          }
        }),
      ),
    { dispatch: false },
  );

  addEntityByContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.setRouteOutletActionByContext),
      withLatestFrom(this.store.pipe(select(getActiveRouteId))),
      switchMap(([_, activeRouteId]) => {
        switch (activeRouteId) {
          case RouteIdEnum.Projects:
          case RouteIdEnum.ProjectFolder:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: [
                        RouterPaths.DialogPaths.dialogPath,
                        RouterPaths.DialogPaths.projectDialogPath,
                        RouterPaths.CommonPaths.addPath,
                        RouterPaths.TabPaths.generalTabPath,
                      ],
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );
          case RouteIdEnum.FlowManagementList:
          case RouteIdEnum.FlowManagementMap:
          case RouteIdEnum.FlowManagementKanban:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [{ outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.flowDialogPath}/${RouterPaths.CommonPaths.addPath}` } }],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.FlowManagementPlan:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.planDialogPath}/${RouterPaths.CommonPaths.addPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.DashboardFlowReferential:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.referentialFlowDialogPath}/${RouterPaths.CommonPaths.importPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.LibraryRisks:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [{ outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.riskDialogPath}/${RouterPaths.CommonPaths.addPath}` } }],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.LibraryEquipments:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.equipmentDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.LibraryTags:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [{ outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.tagsDialogPath}/${RouterPaths.CommonPaths.addPath}` } }],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.LibraryDocuments:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.libDocumentDialogPath}/${RouterPaths.CommonPaths.addPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.LibraryForms:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.libraryFormDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.LibraryObjectTree:
          case RouteIdEnum.LibraryObjectList:
          case RouteIdEnum.LibraryObjectMap:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.objectDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.ContributorUsers:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [{ outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.userDialogPath}/${RouterPaths.CommonPaths.addPath}` } }],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.ContributorOperators:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.operatorDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.ContributorTablets:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.tabletsDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.ContributorExternalUsers:
            this.toaster.show({
              type: ToasterTypeEnum.Info,
              title: this.translate.instant('toaster.types.info'),
              subtitle: this.translate.instant('externalUsers.toaster.createUser'),
            });

            return of(dummyAction());

          case RouteIdEnum.ExternalAccessApiV1:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.accessApiDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.ExternalAccessApiV2:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.accessApiV2DialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsClientBook:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.clientDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsTrams:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [{ outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.tramDialogPath}/${RouterPaths.CommonPaths.addPath}` } }],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsFamilies:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.familyDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );
          case RouteIdEnum.DefaultCategories:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.defaultCategoryDialogPath}/${RouterPaths.CommonPaths.addPath}` },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );
          case RouteIdEnum.CustomStatus:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.customStatusDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsCustomCell:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.customCellDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsCustomFile:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.customFileDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsReferences:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.referenceDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsCustomField:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.customFieldDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsSections:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.folderDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsWordingConfiguration:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.wordingConfigurationsDialogPath}/${RouterPaths.CommonPaths.addPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.DashboardCategories:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.categoryDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.DashboardClosingFolder:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.closingFolderDialogPath}/${RouterPaths.CommonPaths.addPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.DashboardTablets:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.dashboardTabletsDialogPath}/${RouterPaths.CommonPaths.importPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.DashboardContributors:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.collaboratorsDialogPath}/${RouterPaths.CommonPaths.importPath}` },
                  },
                ],
                extras: { queryParamsHandling: 'merge' },
              }),
            );

          case RouteIdEnum.DashboardLists:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      // eslint-disable-next-line max-len
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.listsDialogPath}/${RouterPaths.CommonPaths.addPath}/${RouterPaths.TabPaths.generalTabPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'merge' },
              }),
            );

          case RouteIdEnum.DashboardDocuments:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.dashDocumentsDialogPath}/${RouterPaths.CommonPaths.addPath}` },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.DashboardForms:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.dashboardFromDialogPath}/${RouterPaths.CommonPaths.importPath}` },
                  },
                ],
                extras: { queryParamsHandling: 'merge' },
              }),
            );

          case RouteIdEnum.CustomStatus:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  { outlets: { [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.customStatusDialogPath}/${RouterPaths.CommonPaths.addPath}` } },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          case RouteIdEnum.SettingsCompanies:
            return of(
              routerActions.routerNavigate({
                parentId: RouteIdEnum.Root,
                segments: [
                  {
                    outlets: {
                      [sfxModalOutlet]: `${RouterPaths.DialogPaths.dialogPath}/${RouterPaths.DialogPaths.companiesDialogPath}/${RouterPaths.CommonPaths.addPath}`,
                    },
                  },
                ],
                extras: { queryParamsHandling: 'preserve' },
              }),
            );

          default:
            return of(dummyAction());
        }
      }),
    ),
  );

  navigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.routerNavigate),
      withLatestFrom(this.store.pipe(select(getRouteNavigatedSegments))),
      tap(([{ parentId, segments, extras }, currentSegments]) => {
        const parentSegments = take(currentSegments, findIndex(currentSegments, segment => segment.id === parentId) + 1);
        const parentSegmentUrls = parentSegments.map(seg => seg.url);
        this.router.navigate([...parentSegmentUrls, ...segments], { ...(extras || {}) });
      }),
      map(() => dummyAction()),
    ),
  );

  navigateBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.routerBack),
      tap(() => this.location.back()),
      map(() => dummyAction()),
    ),
  );

  navigateForward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.routerForward),
      tap(() => this.location.forward()),
      map(() => dummyAction()),
    ),
  );

  private buildBreadCrumb(segments: RouteSegment[]): BreadCrumbData[] {
    const routeSegments = ['/'];
    const breadCrumbs = segments.map(segment => {
      routeSegments.push(...segment.url.split('/'));

      return segment.data && segment.data[RouteDataEnum.breadCrumb]
        ? ({
            routeId: segment.data[RouteDataEnum.routeId],
            iconData: BreadCrumbEnum.iconData.getValue(segment.data[RouteDataEnum.routeId]),
            segments: routeSegments.slice(),
            queryParams: segment.queryParams,
            labelSelector: this.getBreadCrumbLabel(segment.data[RouteDataEnum.routeId]),
            iconClassSelector: this.getBreadCrumbIconClass(segment.data[RouteDataEnum.routeId]),
          } as BreadCrumbData)
        : undefined;
    });

    return uniqBy(compact(breadCrumbs), RouteDataEnum.routeId);
  }

  private getBreadCrumbLabel(routeId: RouteIdEnum): MemoizedSelector<AppState, string> {
    switch (routeId) {
      case RouteIdEnum.ProjectDashboard:
      case RouteIdEnum.ProjectFolder:
        return getCurrentProjectDesignation;

      case RouteIdEnum.DashboardSignatureFlow:
        return getSelectedFlowName;

      case RouteIdEnum.FlowContent:
        return getFlowName;
      default:
        return undefined;
    }
  }

  private getBreadCrumbIconClass(routeId: RouteIdEnum): MemoizedSelector<AppState, string> {
    switch (routeId) {
      case RouteIdEnum.ProjectDashboard:
      case RouteIdEnum.ProjectFolder:
        return getCurrentProjectIconClass;

      case RouteIdEnum.FlowContent:
        return getFlowClassName;

      default:
        return undefined;
    }
  }
}
