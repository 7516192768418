/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClientDTO } from './clientDTO';
import { CustomStatusLightDTO } from './customStatusLightDTO';
import { FolderDetailsResponseDTO } from './folderDetailsResponseDTO';
import { LabelFamilyLightDTO } from './labelFamilyLightDTO';

export interface ProjectDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  name: string;
  createDate: Date;
  modificationDate: Date;
  typeProject: ProjectDetailsResponseDTO.TypeProjectEnum;
  designation: string;
  businessRef: string;
  contractRef: string;
  codeProject: string;
  pgacActivities: string;
  clientRef: string;
  locationLibelle: string;
  startDate: string;
  endDate: string;
  labelFamilyApplication: LabelFamilyLightDTO;
  description: string;
  isSupervised: boolean;
  isInternSupervised: boolean;
  supervision1: string;
  supervision2: string;
  supervision3: string;
  supervisionIntern1: string;
  supervisionIntern2: string;
  supervisionIntern3: string;
  isContractual: boolean;
  internalCommand: string;
  externalCommand: string;
  marketNumber: string;
  technicalDeal: string;
  keywords: string;
  businessDomain: string;
  nationalProgram: string;
  exportLanguageOne: ProjectDetailsResponseDTO.ExportLanguageOneEnum;
  exportLanguageTwo: ProjectDetailsResponseDTO.ExportLanguageTwoEnum;
  complementRefOne: string;
  complementRefTwo: string;
  status: ProjectDetailsResponseDTO.StatusEnum;
  deleted: boolean;
  ownerRef: number;
  currentUserRoles: Array<ProjectDetailsResponseDTO.CurrentUserRolesEnum>;
  client: ClientDTO;
  index: string;
  localFormAllowed: boolean;
  customStatus: CustomStatusLightDTO;
  folder: FolderDetailsResponseDTO;
  atLeastOneFlow: boolean;
}
export namespace ProjectDetailsResponseDTO {
  export type TypeProjectEnum = 'PROJECT' | 'REFERENTIAL' | 'TEMPLATE' | 'PGAC' | 'FOLDER';
  export const TypeProjectEnum = {
    PROJECT: 'PROJECT' as TypeProjectEnum,
    REFERENTIAL: 'REFERENTIAL' as TypeProjectEnum,
    TEMPLATE: 'TEMPLATE' as TypeProjectEnum,
    PGAC: 'PGAC' as TypeProjectEnum,
    FOLDER: 'FOLDER' as TypeProjectEnum,
  };
  export type ExportLanguageOneEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageOneEnum = {
    FR: 'FR' as ExportLanguageOneEnum,
    EN: 'EN' as ExportLanguageOneEnum,
    DE: 'DE' as ExportLanguageOneEnum,
    IT: 'IT' as ExportLanguageOneEnum,
    NONE: 'NONE' as ExportLanguageOneEnum,
  };
  export type ExportLanguageTwoEnum = 'FR' | 'EN' | 'DE' | 'IT' | 'NONE';
  export const ExportLanguageTwoEnum = {
    FR: 'FR' as ExportLanguageTwoEnum,
    EN: 'EN' as ExportLanguageTwoEnum,
    DE: 'DE' as ExportLanguageTwoEnum,
    IT: 'IT' as ExportLanguageTwoEnum,
    NONE: 'NONE' as ExportLanguageTwoEnum,
  };
  export type StatusEnum = 'ACTIF' | 'ARCHIVE';
  export const StatusEnum = {
    ACTIF: 'ACTIF' as StatusEnum,
    ARCHIVE: 'ARCHIVE' as StatusEnum,
  };
  export type CurrentUserRolesEnum = 'ROLE_NORMAL' | 'ROLE_ADVANCED' | 'ROLE_READONLY' | 'ROLE_ADMIN_PROJECT' | 'ROLE_OPERATOR';
  export const CurrentUserRolesEnum = {
    NORMAL: 'ROLE_NORMAL' as CurrentUserRolesEnum,
    ADVANCED: 'ROLE_ADVANCED' as CurrentUserRolesEnum,
    READONLY: 'ROLE_READONLY' as CurrentUserRolesEnum,
    ADMINPROJECT: 'ROLE_ADMIN_PROJECT' as CurrentUserRolesEnum,
    OPERATOR: 'ROLE_OPERATOR' as CurrentUserRolesEnum,
  };
}
