import { Dictionary, compact, isNil, omitBy } from 'lodash';

import {
  CustomFieldExtraLightDTO,
  FlowDetailsResponseDTO,
  FlowLightDTO,
  FlowSummaryResponseDTO,
  PageFlowRequest,
  RequestOrEntityUuidsRequestDTOPageFlowRequest,
} from '../../generated/models/models';

import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { TagLightData } from '../library-tag/tag-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { DateFilterData } from '../utils/date-filter-data';
import { EntityLightData } from '../utils/entity-light-data';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';
import { ResetValidationExecuteFlow } from './reset-validation-to-execute-flow';
import { UserLightData } from '../user/user-light-data';

import { NumberFormatUtils } from '../../../modules/shared/helpers/number-format-utils';
import { DateUtils } from '../../utils/date.utils';
import { SortFilter } from '../utils/sort-filter';
import { ColorPickerPaletteEnum, FlowTypeEnum, InnerSortDirectionEnum, StringUtilsEnum, FlowStatusEnum } from '../../enums';

export interface FlowSummaryData {
  name: string;
  description: string;
  labelFamilyApplication: LabelFamilyLightData;
  labelFamilyProject: LabelFamilyLightData;
  modificationDate: string;
  refInt: string;
  refExt: string;
  flowCustomStatus: CustomStatusLightData;
  flowType: FlowTypeEnum;
  assignedUser: EntityLightData;
  reporterUser: EntityLightData;
  userActor1: EntityLightData;
  userActor2: EntityLightData;
  userActor3: EntityLightData;
  contributors: EntityLightData[];
  functionMarker: string;
  locationLocal: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  refFolder: string;
  startDate: string;
  finishDate: string;
  estimatedStartDate: string;
  estimatedEndDate: string;
  achievedStartDate: string;
  achievedEndDate: string;
  color: string;
  order: number;
  unlocked: boolean;
  isReferential: boolean;
  lastUpdateFromSource: string;
  availableUpdate?: boolean;
  frozen?: boolean;
  flowReferentialLocal?: boolean;
  supervision1: boolean;
  supervision2: boolean;
  supervision3: boolean;
  supervisionIntern1: boolean;
  supervisionIntern2: boolean;
  supervisionIntern3: boolean;
  internalSupervisorName1?: string;
  internalSupervisorName2?: string;
  internalSupervisorName3?: string;
  externalSupervisorName1?: string;
  externalSupervisorName2?: string;
  externalSupervisorName3?: string;
  pgacDateChanged?: boolean;
  hasFlowSource?: boolean;
  customFieldValues?: Dictionary<string>;
  tags?: TagLightData[];
  modificationStatus?: FlowStatusEnum;
  simplifiedSignatureMode?: boolean;
  isSelected?: boolean;
}

export namespace FlowSummaryData {
  const sortFields = [
    'name',
    'family',
    'modificationDate',
    'refExt',
    'functionalMarker',
    'locationLocal',
    'customStatusFlow',
    'modificationDate',
    'customStatusFlow',
    'category',
    'tablet_1',
    'tablet_2',
    'tablet_3',
    'estimatedStartDate',
    'estimatedEndDate',
    'startDate',
    'finishDate',
    'achievedStartDate',
    'achievedEndDate',
    'supervisionIntern1',
    'supervisionIntern2',
    'supervisionIntern3',
    'supervision1',
    'supervision2',
    'supervision3',
    'refInt',
  ];

  export const hasSignatureErrorKey = 'flow.flowContainsSomeSignatures';
  export const hasDsiBlocsNotInRedaction = 'flow.flowContainsSomeDsiBlocsNotInRedaction';

  export interface QueryRequest extends BaseQuery<FlowSummaryData> {
    categories?: string[];
    families?: string[];
    statuses?: string[];
    schedulings?: string[];
    locals?: string[];
    landmarks?: string[];
    types?: FlowTypeEnum[];
    assignments?: string[];
    refFlows?: string[];
    dateFilter?: DateFilterData;
    projectUuidEntity?: string;
    lastUpdatePgacForm?: string;
    onlyUsedInProject?: boolean;
    customFieldUuidEntities?: string[];
    advancedSearch?: string;
  }

  export function mapFromApiValue(flowSummary: FlowSummaryResponseDTO): FlowSummaryData {
    return {
      name: flowSummary.name || '',
      description: flowSummary.description || '',
      assignedUser: flowSummary.assigneeUser && UserLightData.mapUserToEntityLightData(flowSummary.assigneeUser),
      labelFamilyApplication: (flowSummary.labelFamilyApplication && LabelFamilyLightData.mapFromApiValue(flowSummary.labelFamilyApplication)) || LabelFamilyLightData.defaultData,
      labelFamilyProject: flowSummary.labelFamilyProject && LabelFamilyLightData.mapFromApiValue(flowSummary.labelFamilyProject),
      modificationDate: DateUtils.toDateFormat(flowSummary.modificationDate),
      reporterUser: flowSummary.reporterUser && UserLightData.mapUserToEntityLightData(flowSummary.reporterUser),
      flowCustomStatus: flowSummary.customStatusFlow && CustomStatusLightData.mapFromApiValue(flowSummary.customStatusFlow),
      flowType: FlowTypeEnum.convertFromApiValue.getValue(flowSummary.typeFlow),
      userActor1: flowSummary.actor1 && UserLightData.mapUserToEntityLightData(flowSummary.actor1),
      userActor2: flowSummary.actor2 && UserLightData.mapUserToEntityLightData(flowSummary.actor2),
      userActor3: flowSummary.actor3 && UserLightData.mapUserToEntityLightData(flowSummary.actor3),
      contributors: (flowSummary.collaborators || []).map(collaborator => UserLightData.mapUserToEntityLightData(collaborator)),
      functionMarker: flowSummary.functionMarker,
      locationLocal: flowSummary.locationLocal,
      uuid: flowSummary.uuid,
      uuidType: flowSummary.uuidType,
      uuidEntity: flowSummary.uuidEntity,
      refInt: flowSummary.refInt,
      refExt: flowSummary.refExt,
      refFolder: flowSummary.refExt || 'global.defaultRefFolder',
      startDate: DateUtils.toDateFormat(flowSummary.startDate),
      finishDate: DateUtils.toDateFormat(flowSummary.finishDate),
      // make models
      estimatedStartDate: DateUtils.toDateFormat(flowSummary['estimatedStartDate']),
      estimatedEndDate: DateUtils.toDateFormat(flowSummary['estimatedEndDate']),
      achievedStartDate: DateUtils.toDateFormat(flowSummary['achievedStartDate']),
      achievedEndDate: DateUtils.toDateFormat(flowSummary['achievedEndDate']),
      color: flowSummary.flowBarColor || ColorPickerPaletteEnum.defaultColor,
      order: flowSummary.flowBarOrder,
      unlocked: flowSummary.unlocked,
      isReferential: FlowTypeEnum.convertFromApiValue.getValue(flowSummary.typeFlow) === FlowTypeEnum.Referential,
      availableUpdate: flowSummary.availableUpdate,
      lastUpdateFromSource: DateUtils.toDateFormat(flowSummary.lastUpdateFromSource),
      flowReferentialLocal: flowSummary.flowReferentialLocal,
      supervision1: flowSummary.supervision1,
      supervision2: flowSummary.supervision2,
      supervision3: flowSummary.supervision3,
      supervisionIntern1: flowSummary.supervisionIntern1,
      supervisionIntern2: flowSummary.supervisionIntern2,
      supervisionIntern3: flowSummary.supervisionIntern3,
      internalSupervisorName1: flowSummary.internalSupervisorName1,
      internalSupervisorName2: flowSummary.internalSupervisorName2,
      internalSupervisorName3: flowSummary.internalSupervisorName3,
      externalSupervisorName1: flowSummary.externalSupervisorName1,
      externalSupervisorName2: flowSummary.externalSupervisorName2,
      externalSupervisorName3: flowSummary.externalSupervisorName3,
      pgacDateChanged: flowSummary.pgacDateChanged,
      hasFlowSource: flowSummary.hasFlowSource,
      customFieldValues: getCustomFieldValues(flowSummary.customFields || []),
      tags: (flowSummary.tags || []).map(link => TagLightData.mapFromApiValue(link)),
      modificationStatus: FlowStatusEnum.convertFromApiValue.getValue(flowSummary?.modificationStatus),
      simplifiedSignatureMode: flowSummary.simplifiedSignatureMode,
      isSelected: false,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageFlowRequest {
    return {
      page: queries.page,
      size: queries.size,
      ...omitBy(
        {
          sort: sortFields.includes(queries?.sort?.key)
            ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
            : undefined,
          categories: queries.categories,
          families: queries.families,
          assignments: queries.assignments,
          types: queries.types?.map(type => FlowTypeEnum.convertToApiValue.getValue(type)),
          customStatuses: queries.statuses,
          dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
          textSearch: queries.textSearch,
          projectUuidEntity: queries.projectUuidEntity,
          referentialsUuidEntity: queries.refFlows,
          lastUpdatePgacForm: queries.lastUpdatePgacForm,
          onlyUsedInProject: queries.onlyUsedInProject,
          locals: queries.locals,
          reperes: queries.landmarks,
          customFieldUuidEntities: queries.customFieldUuidEntities,
          schedulings: queries.schedulings,
        },
        isNil,
      ),
    };
  }

  export function mapToExecuteListPageRequestApiValue(queries: QueryRequest, validationRequest: ResetValidationExecuteFlow): RequestOrEntityUuidsRequestDTOPageFlowRequest {
    return {
      ...omitBy(
        {
          request: {
            page: queries.page,
            size: queries.size,
            sort: sortFields.includes(queries?.sort?.key)
              ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
              : undefined,
            categories: queries.categories,
            families: queries.families,
            assignments: queries.assignments,
            types: queries.types?.map(type => FlowTypeEnum.convertToApiValue.getValue(type)),
            customStatuses: queries.statuses,
            schedulings: queries.schedulings,
            dateFilterRequest: queries.dateFilter && DateFilterData.mapToRequestApiValue(queries.dateFilter),
            textSearch: queries.textSearch,
            projectUuidEntity: queries.projectUuidEntity,
            referentialsUuidEntity: queries.refFlows,
            lastUpdatePgacForm: queries.lastUpdatePgacForm,
            onlyUsedInProject: queries.onlyUsedInProject,
            locals: queries.locals,
            reperes: queries.landmarks,
            customFieldUuidEntities: queries.customFieldUuidEntities,
          },
          resetInternalValidation: validationRequest.resetInternalValidation,
          resetExternalValidation: validationRequest.resetExternalValidation,
          resetInternalParentValidation: validationRequest.resetInternalParentValidation,
          resetInternalChildValidation: validationRequest.resetInternalChildValidation,
          resetExternalParentValidation: validationRequest.resetExternalParentValidation,
          resetExternalChildValidation: validationRequest.resetExternalChildValidation,
          resetDocumentFlows: validationRequest.resetDocumentFlows,
          resetParentDocumentFlows: validationRequest.resetParentDocumentFlows,
          resetChildDocumentFlows: validationRequest.resetChildDocumentFlows,
        },
        isNil,
      ),
    };
  }

  export function mapToExecutePageRequestApiValue(queries: QueryRequest): PageFlowRequest {
    return {
      page: queries.page,
      size: queries.size,
      sort: (queries.sort && { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }) || undefined,
      categories: queries.categories || undefined,
      families: queries.families || undefined,
      assignments: queries.assignments || undefined,
      types: (queries.types && queries.types.map(type => FlowTypeEnum.convertToApiValue.getValue(type))) || undefined,
      textSearch: queries.textSearch || undefined,
      projectUuidEntity: queries.projectUuidEntity || undefined,
      referentialsUuidEntity: queries.refFlows || undefined,
      customStatuses: queries.statuses || undefined,
    };
  }

  export function initializeQueryRequest(
    families: string[],
    categories: string[],
    statuses: string[],
    schedulings?: string[],
    textSearch?: string,
    sort?: SortFilter<FlowSummaryData>,
    otherFilters?: {
      assignments?: string[];
      types?: string[];
      refFlows?: string[];
      dateFilter?: DateFilterData;
      state?: string;
      projectUuidEntity?: string;
      locals?: string[];
      landmarks?: string[];
    },
    advancedSearch?: string,
  ): QueryRequest {
    return {
      ...initializeQueries<FlowSummaryData>(sort),
      textSearch: textSearch || undefined,
      families: families?.length ? families : undefined,
      categories: categories?.length ? categories : undefined,
      statuses: statuses?.length ? statuses : undefined,
      schedulings: schedulings?.length ? schedulings : undefined,
      locals: otherFilters?.locals?.length ? otherFilters?.locals : undefined,
      landmarks: otherFilters?.landmarks?.length ? otherFilters?.landmarks : undefined,
      types: otherFilters?.types?.map(type => FlowTypeEnum.convertFromParamsValue.getValue(type)),
      assignments: otherFilters?.assignments?.length ? otherFilters.assignments : undefined,
      refFlows: otherFilters?.refFlows?.length ? otherFilters.refFlows : undefined,
      dateFilter: otherFilters?.dateFilter || undefined,
      lastUpdatePgacForm: otherFilters?.state || undefined,
      projectUuidEntity: otherFilters?.projectUuidEntity,
      advancedSearch,
    };
  }

  export function initializeExecuteQueryRequest(
    families: string[],
    categories: string[],
    statuses: string[],
    types?: string[],
    projectUuidEntity?: string,
    textSearch?: string,
    sort?: SortFilter<FlowSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<FlowSummaryData>(sort),
      textSearch: textSearch || undefined,
      projectUuidEntity: projectUuidEntity || undefined,
      families: (families || []).length ? families : undefined,
      categories: (categories || []).length ? categories : undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      types: types?.length ? types.map(type => FlowTypeEnum.convertFromParamsValue.getValue(type)) : [FlowTypeEnum.Referential],
    };
  }

  export function initializeReferentialQueryRequest(
    families: string[],
    categories: string[],
    statuses: string[],
    textSearch?: string,
    sort?: SortFilter<FlowSummaryData>,
    otherFilters?: {
      assignments?: string[];
      types?: string[];
      refFlows?: string[];
      dateFilter?: DateFilterData;
      state?: string;
      projectUuidEntity?: string;
    },
  ): QueryRequest {
    return {
      ...initializeQueries<FlowSummaryData>(sort),
      textSearch: textSearch || undefined,
      families: (families || []).length ? families : undefined,
      categories: (categories || []).length ? categories : undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      types: otherFilters?.types?.length ? otherFilters.types.map(type => FlowTypeEnum.convertFromParamsValue.getValue(type)) : FlowTypeEnum.referentialValues,
      assignments: otherFilters?.assignments?.length ? otherFilters.assignments : undefined,
      refFlows: otherFilters?.refFlows?.length ? otherFilters.refFlows : undefined,
      dateFilter: otherFilters?.dateFilter || undefined,
      lastUpdatePgacForm: otherFilters?.state || undefined,
      projectUuidEntity: otherFilters?.projectUuidEntity,
    };
  }

  // @Wissem Review: to improve later
  export function initializeSignatureQueryRequest(projectUuidEntity: string, families: string[], categories: string[], statuses: string[], assignments: string[]): QueryRequest {
    return {
      ...initializeQueries<FlowSummaryData>({ key: 'name', direction: InnerSortDirectionEnum.Asc }),
      projectUuidEntity,
      statuses: (statuses || []).length ? statuses : undefined,
      families: (families || []).length ? families : undefined,
      categories: (categories || []).length ? categories : undefined,
      assignments: (assignments || []).length ? assignments : undefined,
      // dateFilter: otherFilters?.dateFilter || undefined,
      types: FlowTypeEnum.flowsWhereStepCanBeLinked,
    };
  }

  export function initializeFilterQueries(
    page: number,
    searchText?: string,
    projectUuidEntity?: string,
    types?: FlowTypeEnum[],
    families?: string[],
    categories?: string[],
    statuses?: string[],
    assignments?: string[],
    onlyUsedInProject?: boolean,
  ): QueryRequest {
    return {
      ...initializeQueries<FlowSummaryData>(),
      page,
      textSearch: searchText || undefined,
      projectUuidEntity,
      types,
      families,
      categories,
      statuses,
      assignments,
      onlyUsedInProject,
    };
  }

  export function mapToSingleSelectItemFilter(flowLight: FlowSummaryResponseDTO): SingleSelectItemData<string> {
    return {
      value: flowLight.uuidEntity,
      title: compact([flowLight.refInt, flowLight.name]).join(StringUtilsEnum.DashSeparator),
      subtitle: getComment(flowLight, true, true),
    };
  }

  function getComment(flowLight: FlowSummaryResponseDTO, showType?: boolean, showLabelFamily?: boolean): string {
    if (showLabelFamily) {
      return compact([flowLight?.labelFamilyProject?.name, flowLight?.labelFamilyApplication?.name]).join(StringUtilsEnum.DashSeparator);
    }

    return showType ? FlowTypeEnum.toString().getValue(FlowTypeEnum.convertFromApiValue.getValue(flowLight.typeFlow)) : null;
  }

  export function getCustomFieldValues(customFields: CustomFieldExtraLightDTO[]): Dictionary<string> {
    return customFields.reduce((acc, field) => {
      // make models
      acc[field['parentUuidEntity']] =
        ((field.type === 'INTEGER' || field.type === 'DOUBLE') && field.value && NumberFormatUtils.formatNumber(field.value)) ||
        (field.type === 'DATE_TIME' && field.value && DateUtils.toDateTimeFormat(field.value)) ||
        (field.type === 'DATE' && field.value && DateUtils.toDateFormat(field.value)) ||
        field.value;

      return acc;
    }, {});
  }

  export function mapToSelectItem(flowLight: FlowSummaryResponseDTO, cacheFlow?: boolean, fullName?: boolean): SingleSelectItemData<EntityLightData> {
    return {
      value: mapApiValueToEntityLightData(flowLight, fullName),
      title: fullName && flowLight.refInt ? flowLight.refInt.concat(StringUtilsEnum.DashSeparator.concat(flowLight.name)) : flowLight.name,
      icons: cacheFlow ? [{ name: 'clock', prefix: 'far' }] : [],
    };
  }

  export function mapToSingleSelectItemData(flowLight: FlowSummaryResponseDTO | FlowLightDTO): SingleSelectItemData<EntityLightData> {
    return {
      value: mapApiValueToEntityLightData(flowLight),
      title: compact([flowLight.refInt, flowLight.name]).join(StringUtilsEnum.DashSeparator),
    };
  }

  export function mapToMultiSelectItemData(flowLight: FlowSummaryResponseDTO, fullName?: boolean, cacheFlow?: boolean): MultiSelectItemData {
    return {
      value: flowLight.uuidEntity,
      title: compact([fullName && flowLight.refInt, flowLight.name]).join(StringUtilsEnum.DashSeparator),
      icons: cacheFlow ? [{ name: 'clock', prefix: 'far' }] : [],
    };
  }

  export function mapToFilterMultiSelectItemData(flowLight: FlowLightDTO): MultiSelectItemData {
    return {
      value: flowLight.uuidEntity,
      title: compact([flowLight.refInt, flowLight.name]).join(StringUtilsEnum.DashSeparator),
    };
  }

  export function mapApiValueToEntityLightData(flow: FlowSummaryResponseDTO | FlowLightDTO, fullName?: boolean): EntityLightData {
    return {
      uuidEntity: flow.uuidEntity,
      name: compact([fullName && flow.refInt, flow.name]).join(StringUtilsEnum.DashSeparator),
      originFlow: flow as FlowSummaryResponseDTO,
    };
  }

  export function mapToEntityLightData(flowSummary: FlowSummaryData, fullName?: boolean): EntityLightData {
    return {
      uuidEntity: flowSummary.uuidEntity,
      name: fullName && flowSummary.refInt ? flowSummary.refInt.concat(StringUtilsEnum.DashSeparator.concat(flowSummary.name)) : flowSummary.name,
    };
  }

  export function mapFromDetailsData(flow: FlowDetailsResponseDTO): Partial<FlowSummaryData> {
    return {
      uuidEntity: flow.uuidEntity,
      flowCustomStatus: flow.customStatusFlow && CustomStatusLightData.mapFromApiValue(flow.customStatusFlow),
      name: flow.name,
      refInt: flow.refInt,
      internalSupervisorName1: flow.internalSupervisorName1,
      internalSupervisorName2: flow.internalSupervisorName2,
      internalSupervisorName3: flow.internalSupervisorName3,
      externalSupervisorName1: flow.externalSupervisorName1,
      externalSupervisorName2: flow.externalSupervisorName2,
      externalSupervisorName3: flow.externalSupervisorName3,
      supervision1: flow.supervision1,
      supervision2: flow.supervision2,
      supervision3: flow.supervision3,
      supervisionIntern1: flow.supervisionIntern1,
      supervisionIntern2: flow.supervisionIntern2,
      supervisionIntern3: flow.supervisionIntern3,
      hasFlowSource: flow['flowSummary'], // MAKE_MODELS: @aymen
    };
  }

  export function toString(flowSummary: FlowSummaryData): string {
    return (flowSummary.refInt ? flowSummary.refInt.concat(StringUtilsEnum.DashSeparator) : '').concat(flowSummary.name);
  }
}
