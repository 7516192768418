import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { UserDeviceCustomSummaryResponseDTO, PageImportUserDeviceRequestDTO, PageDeviceRequestDTO } from '../../generated/models/models';
import { InnerSortDirectionEnum, UserNatureEnum, TabletStatusEnum } from '../../enums';
import { SortFilter } from '../utils/sort-filter';

export interface TabletImportData {
  uuidEntity: string;
  labelFamily: LabelFamilyLightData;
  nature: UserNatureEnum;
  refIntern: string;
  refExtern: string;
  status: TabletStatusEnum;
  frozen?: boolean;
}

export namespace TabletImportData {
  const sortFields = ['group', 'refExtern', 'batteryLevel', 'deviceStatus', 'position'];
  export interface QueryRequest extends BaseQuery<TabletImportData> {
    status?: TabletStatusEnum;
    nature?: UserNatureEnum;
    families: string[];
    applicabilities: string[];
    projectUuidEntity?: string;
  }

  export function mapFromApiValue(response: UserDeviceCustomSummaryResponseDTO): TabletImportData {
    return {
      uuidEntity: response.device.uuidEntity,
      refIntern: response.device.refIntern,
      refExtern: response.device.refExtern,
      status: response.device.status && TabletStatusEnum.convertFromApiValue.getValue(response.device.status),
      nature: response.device.nature && UserNatureEnum.convertFromApiValue.getValue(response.device.nature),
      labelFamily: (response.device.labelFamily && LabelFamilyLightData.mapFromApiValue(response.device.labelFamily)) || LabelFamilyLightData.defaultData,
      frozen: response.alreadyImported,
    };
  }

  export function mapToRequestApiValue(queries: QueryRequest): PageImportUserDeviceRequestDTO {
    return {
      pageUserDeviceRequestDTO: mapToPageRequestApiValue(queries),
      projectUuidEntity: queries.projectUuidEntity,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageDeviceRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      applicabilityUuides: queries.applicabilities,
      labelFamiliesUuidEntity: queries.families,
      natures: (queries.nature && [UserNatureEnum.convertToApiValue.getValue(queries.nature)]) || [],
      statuses: (queries.status && [TabletStatusEnum.convertToApiValue.getValue(queries.status)]) || [],
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    families: string[],
    searchText: string,
    nature: string,
    status: string,
    projectUuidEntity: string,
    sort: SortFilter<TabletImportData>,
  ): QueryRequest {
    return {
      ...initializeQueries<TabletImportData>(sort),
      textSearch: searchText || undefined,
      families: (families || []).length ? families : undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      nature: UserNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      status: TabletStatusEnum.convertFromParamsValue.getValue(status) || undefined,
      projectUuidEntity,
    };
  }
}
