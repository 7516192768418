import { DeviceSummaryResponseDTO, PageDeviceRequestDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';

import { DateUtils } from '../../utils/date.utils';
import { SortFilter } from '../utils/sort-filter';
import { TabletStatusEnum, InnerSortDirectionEnum, UserNatureEnum, UserStatusEnum, StringUtilsEnum } from '../../enums';

export interface CTabletSummaryData {
  uuidEntity: string;
  labelFamily: LabelFamilyLightData;
  refIntern: string;
  refExtern: string;
  imei: string;
  mobileVersion: string;
  androidVersion: string;
  nature: UserNatureEnum;
  latitude: string;
  longitude: string;
  status: TabletStatusEnum;
  userStatus: UserStatusEnum;
  backgroundColor: string;
  modificationDate?: string;
  lastPush?: string;
  lastSeen?: string;
  companyName?: string;
}

export namespace CTabletSummaryData {
  const sortFields = ['group', 'refExtern', 'lastPush'];

  export interface QueryRequest extends BaseQuery<CTabletSummaryData> {
    states?: TabletStatusEnum[];
    statuses?: UserStatusEnum[];
    nature?: UserNatureEnum;
    families?: string[];
    applicabilities?: string[];
  }

  export function mapFromApiValue(data: DeviceSummaryResponseDTO): CTabletSummaryData {
    return {
      uuidEntity: data.uuidEntity,
      labelFamily: (data.labelFamilyTeam && LabelFamilyLightData.mapFromApiValue(data.labelFamilyTeam)) || LabelFamilyLightData.defaultData,
      refExtern: data.refExt,
      refIntern: data.refInt,
      imei: data.uniqueDeviceId,
      mobileVersion: data.versionApp,
      androidVersion: data.androidVersion,
      nature: data.nature && UserNatureEnum.convertFromApiValue.getValue(data.nature),
      latitude: data.lastLatitude,
      longitude: data.lastLongitude,
      status: data.status && TabletStatusEnum.convertFromApiValue.getValue(data.status),
      userStatus: data.userStatus && UserStatusEnum.convertFromApiValue.getValue(data.userStatus),
      backgroundColor: data.backgroundColor,
      lastPush: DateUtils.toDateTimeFormat(data.lastPush),
      lastSeen: DateUtils.toDateTimeFormat(data.lastSeen),
      companyName: data?.company?.name,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageDeviceRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      labelFamiliesUuidEntity: queries.families,
      applicabilityUuides: queries.applicabilities,
      natures: (queries.nature && [UserNatureEnum.convertToApiValue.getValue(queries.nature)]) || undefined,
      statuses: (queries.states || []).map(state => TabletStatusEnum.convertToApiValue.getValue(state)) || undefined,
      userStatuses: (queries.statuses || []).map(status => UserStatusEnum.convertToApiValue.getValue(status)) || undefined,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
    };
  }

  export function initializeQueryRequest(
    families: string[],
    applicabilities: string[],
    searchText: string,
    nature: string,
    states: string[],
    statuses: string[],
    sort: SortFilter<CTabletSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<CTabletSummaryData>(sort),
      textSearch: searchText || undefined,
      families: (families || []).length ? families : undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      nature: UserNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      states: states.map(state => TabletStatusEnum.convertFromParamsValue.getValue(state)) || undefined,
      statuses: statuses.map(status => UserStatusEnum.convertFromParamsValue.getValue(status)) || undefined,
    };
  }

  export function toString(device: CTabletSummaryData): string {
    return (device.refIntern ? device.refIntern.concat(StringUtilsEnum.DashSeparator) : '').concat(device.refExtern);
  }
}
