import config from '../../../../../assets/config/app-config.json';

interface FrontAppConfig {
  version: string;
  froalaLicenseKey: string;
  froalaLicenseExpirationData: string;
}

// Note: Parse type to avoid compiler error
export const frontAppConfig: FrontAppConfig = config as FrontAppConfig;
