import { FormLibrarySummaryResponseDTO, PageFormRequestDTO } from '../../generated/models/models';

import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { LabelApplicabilityLightData } from '../label-applicability/label-applicability-light-data';
import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { CustomStatusLightData } from '../custom-status/custom-status-light-data';
import { CompanySummaryData } from '../companies/companies-summary-data';

import { SortFilter } from '../utils/sort-filter';
import { DateUtils } from '../../utils/date.utils';
import { FilterArchivedEnum } from '../../enums/utils/filter-archive.enum';
import { FormNatureEnum, InnerSortDirectionEnum, StringUtilsEnum } from '../../enums';

export interface FormLibrarySummaryData {
  name: string;
  uuid: string;
  uuidType: string;
  uuidEntity: string;
  modificationDate: string;
  nature: FormNatureEnum;
  indice: string;
  reference: string;
  labelFamily: LabelFamilyLightData;
  applicability: LabelApplicabilityLightData;
  useTemplate: boolean;
  description: string;
  customStatus: CustomStatusLightData;
  additionalComment?: string;
  archived: boolean;
  companies: CompanySummaryData[];
  companiesTooltip?: string;
}

export namespace FormLibrarySummaryData {
  const sortFields = ['name', 'family', 'reference', 'index', 'modificationDate', 'customStatus'];
  export interface QueryRequest extends BaseQuery<FormLibrarySummaryData> {
    applicabilities: string[];
    type: FormNatureEnum;
    families: string[];
    statuses: string[];
    state?: FilterArchivedEnum;
  }

  export function mapFromApiValue(formLibrarySummary: FormLibrarySummaryResponseDTO): FormLibrarySummaryData {
    const formCompanies = (formLibrarySummary?.companies || []).map(company => CompanySummaryData.mapFromApi(company));

    return {
      uuidEntity: formLibrarySummary.uuidEntity,
      uuidType: formLibrarySummary.uuidType,
      uuid: formLibrarySummary.uuid,
      name: formLibrarySummary.name,
      description: formLibrarySummary.description,
      modificationDate: DateUtils.toDateFormat(formLibrarySummary.modificationDate),
      indice: formLibrarySummary.indice,
      reference: formLibrarySummary.reference,
      useTemplate: formLibrarySummary.useTemplate,
      nature: FormNatureEnum.convertFromApiValue.getValue(formLibrarySummary.nature),
      customStatus: formLibrarySummary.customStatus && CustomStatusLightData.mapFromApiValue(formLibrarySummary.customStatus),
      labelFamily: formLibrarySummary.labelFamily && LabelFamilyLightData.mapFromApiValue(formLibrarySummary.labelFamily),
      applicability: formLibrarySummary.applicability && LabelApplicabilityLightData.mapFromApiValue(formLibrarySummary.applicability),
      additionalComment: formLibrarySummary.additionalComment,
      archived: formLibrarySummary.archived,
      companies: formCompanies,
      companiesTooltip: formCompanies?.length > 1 ? '- ' + formCompanies.map(company => company.name).join('<br/>- ') : null,
    };
  }

  export function mapToPageRequestApiValue(queries: QueryRequest): PageFormRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      applicabilitiesUuidEntity: (queries.applicabilities || []).length ? queries.applicabilities : undefined,
      type: FormNatureEnum.convertToApiValue.getValue(queries.type) || undefined,
      familiesUuidEntity: (queries.families || []).length ? queries.families : undefined,
      customStatusUuidEntity: (queries.statuses || []).length ? queries.statuses : undefined,
      textSearch: queries.textSearch || undefined,
      archiveStatus: FilterArchivedEnum.convertToApiValue.getValue(queries.state) || undefined,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    type: string,
    families: string[],
    statuses: string[],
    searchText: string,
    sort: SortFilter<FormLibrarySummaryData>,
    state?: string,
  ): QueryRequest {
    return {
      ...initializeQueries<FormLibrarySummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      type: FormNatureEnum.convertFromParamsValue.getValue(type) || undefined,
      families: (families || []).length ? families : undefined,
      statuses: (statuses || []).length ? statuses : undefined,
      state: FilterArchivedEnum.convertFromParamsValue.getValue(state) || FilterArchivedEnum.Activated,
    };
  }

  export function toString(form: FormLibrarySummaryData): string {
    return (form.reference ? form.reference.concat(StringUtilsEnum.DashSeparator) : '').concat(form.name);
  }
}
