import { SfxMap } from '../../utils/enum-utils';

export enum RouteIdEnum {
  // root ids
  Root = '',
  Main = 'explore',
  Library = 'library',
  Login = 'login',
  ResetPwd = 'resetPwd',
  Activate = 'activate',
  SignUp = 'signUp',
  Onboarding = 'onboarding',
  Share = 'share',
  Contributors = 'contributors',
  Custom = 'custom',
  Settings = 'settings',
  Profile = 'profile',

  // Share ids
  ShareLogin = 'shareLogin',
  InitShare = 'initShare',
  InitDocShare = 'initDocShare',
  InitFormShare = 'initFormShare',
  ShareContributorInfo = 'shareContributorInfo',
  FinishShare = 'finishShare',
  FinishAndContinueLater = 'FinishAndContinueLater',
  ShareDialog = 'shareDialog',

  // Project management ids
  Projects = 'projects',
  StatusHistoryModal = 'statusHistoryModal',
  ProjectStatusHistoryModal = 'projectStatusHistoryModal',
  ProjectAddModal = 'projectAddModal',
  ProjectEditModal = 'projectEditModal',
  ProjectDuplicateModal = 'projectDuplicateModal',
  ProjectFolderDuplicateModal = 'projectFolderDuplicateModal',
  EditFolderModal = 'editFolderModal',
  ProjectPgacModal = 'projectPgacModal',
  ProjectPgacDetailsModal = 'projectPgacDetailsModal',
  ProjectFileProcessingModal = 'projectFileProcessingModal',
  ProjectPropertiesModal = 'projectPropertiesModal',
  PrepareFolderModal = 'prepareFolderModal',

  // project Modal tabs ids
  ProjectDialogGeneralTabModal = 'projectDialogGeneralTabModal',
  ProjectDialogAttributeTabModal = 'projectDialogAttributeTabModal',
  ProjectDialogOptionsTabModal = 'projectDialogOptionsTabModal',

  // Project dashboard ids
  ProjectDashboard = 'projectDashboard',
  ProjectFolder = 'projectFolder',

  // Dashboard documents
  DashboardDocuments = 'dashboardDocuments',
  DashDocumentsAddModal = 'dashDocumentsAddModal',
  DashDocumentsEditModal = 'dashDocumentsEditModal',
  DashboardDocumentsImportFromLibraryDialog = 'dashboardDocumentsImportFromLibrary',
  DashboardDocumentsPropertiesDialog = 'dashboardDocumentsProperties',
  DashboardDocumentsReorganizeDialog = 'dashboardDocumentsReorganize',

  // Dashboard Closing folders
  DashboardClosingFolder = 'dashboardClosingFolder',
  DashboardAddFolder = 'dashboardAddFolder',
  DashboardEditFolder = 'dashboardEditFolder',
  DashboardAddSection = 'dashboardAddSection',
  DashboardEditSection = 'dashboardEditSection',
  DashboardVerificationSection = 'dashboardVerificationSection',
  DashboardFolderProperties = 'dashboardFolderProperties',

  DashboardForms = 'dashboardForms',
  DashboardFormsImportModal = 'dashboardFormsImportDialog',
  DashboardFormsResponseModal = 'dashboardFormsResponseDialog',
  DashboardFormsReplyModal = 'dashboardFormsReplyDialog',
  DashboardFormsPropertiesModal = 'dashboardFormsPropertiesDialog',
  DashboardTablets = 'dashboardTablets',
  DashboardTabletsImportModal = 'dashboardTabletsImportModal',
  DashboardTabletsQuickConnectionModal = 'dashboardTabletsQuickConnectionModal',
  DashboardFlowReferential = 'dashboardFlowReferential',
  DashboardFlowReferentialEditModal = 'dashboardFlowReferentialEditModal',
  DashboardFlowPgacDetailsModal = 'dashboardFlowPgacDetailsModal',
  DashboardFlowReferentialManagementImportModal = 'dashboardFlowReferentialImportModal',
  DashboardScheduleTasks = 'dashboardScheduleTasks',
  DashboardLists = 'dashboardLists',
  DashboardContributors = 'dashboardContributors',
  DashboardMetrics = 'dashboardMetrics',
  DashboardSignature = 'dashboardFlowSignature',
  DashboardSignatureList = 'dashboardFlowSignatureList',
  DashboardSignatureFlow = 'dashboardSignatureFlow',
  DashboardSignatureTemporal = 'dashboardFlowSignatureTemporal',
  DashboardFieldFeedback = 'dashboardFieldFeedback',
  DashboardInformation = 'dashboardInformation',
  DashboardCategories = 'dashboardCategories',
  DashboardCollaboratorsImportModal = 'dashboardCollaboratorsImportModal',
  DashboardCollaboratorsReorganizeModal = 'dashboardCollaboratorsReorganizeModal',
  DashboardCollaboratorsSkillsModal = 'dashboardCollaboratorsSkillsModal',
  DashboardContributorEditModal = 'dContributorEditModal',
  DashboardEditCategory = 'dashboardEditCategory',
  DashboardAddCategory = 'dashboardAddCategory',
  DashboardListAddModal = 'dListAddModal',
  DashboardAdrListEditModal = 'dListAdrEditModal',
  DashboardEmmListEditModal = 'dashboardEmmListEditModal',
  MultiPRActionSelect = 'multiPRActionSelect',

  // list tabs ids
  DashboardListDialogGeneralTabModal = 'dListDialogGeneralTabModal',
  DashboardListDialogImportationTabModal = 'dListDialogImportationTabModal',
  DashboardListDialogContentTabModal = 'dListDialogContentTabModal',

  // Flow management Ids
  FlowManagement = 'flowManagement',
  FlowManagementAddModal = 'flowManagementAddModal',
  FlowManagementEditModal = 'flowManagementEditModal',
  FlowManagementDuplicateModal = 'flowManagementDuplicateModal',
  FlowManagementMoveModal = 'flowManagementMoveModal',
  FlowManagementScheduleModal = 'flowManagementScheduleModal',
  FlowManagementContributorsModal = 'flowManagementContributorsModal',
  FlowManagementPgacDuplicateModal = 'flowManagementPgacDuplicateModal',
  FlowManagementExecuteModal = 'flowManagementExecuteModal',
  FlowManagementExecuteListModal = 'flowManagementExecuteListModal',
  FlowManagementList = 'flowManagementList',
  FlowManagementPlan = 'flowManagementPlan',
  FlowManagementMap = 'flowManagementMap',
  FlowManagementGeoMapModal = 'flowManagementGeoMapModal',
  FlowManagementKanban = 'flowManagementKanban',
  FlowManagementProgressModal = 'flowManagementProgressModal',
  FlowManagementPropertiesModal = 'flowManagementPropertiesModal',
  FlowManagementReorganizeModal = 'flowManagementReorganizeModal',
  FlowManagementLogReportModal = 'FlowManagementLogReportModal',
  FlowManagementCustomizeColumnsModal = 'FlowManagementCustomizeColumnsModal',
  LogReportModal = 'LogReportModal',
  RevisionModal = 'revisionModal',
  SequenceModal = 'sequenceModal',
  FlowEventsHistoryModal = 'flowEventsHistoryModal',
  OrphanResponsesModal = 'orphanResponsesModal',
  FlowManagementAffectation = 'flowTeamRoles',
  FlowManagementAffectationEdit = 'flowTeamRolesEdit',

  // Plan
  PlanAddModal = 'planAddModal',
  PlanEditModal = 'planEditModal',
  MileStoneAddModal = 'mileStoneAddModal',
  MileStoneEditModal = 'mileStoneEditModal',
  FlowsForPlanModal = 'flowsForPlanModal',
  FlowsToLinkForPlanModal = 'flowsToLinkForPlanModal',
  TeamRolesForPlanModal = 'teamRolesForPlanModal',

  // Flow content ids
  FlowContent = 'flowContent',
  UnlockFlowModal = 'unlockFlowModal',
  StepContent = 'stepContent',
  BlockContentMobileVue = 'stepContentMobileVue',
  BlockContent = 'blockContent',
  BlockContentDialog = 'blockContentDialog',
  BlockRhpContent = 'blockRhpContent',
  BlockEmmContent = 'blockEmmContent',
  BlockAdrContent = 'blockAdrContent',
  BlockDsiContent = 'blockDsiContent',
  BlockFormContent = 'blockFormContent',
  BlockPrContent = 'blockPrContent',
  BlockMultiPrContent = 'blockMultiPrContent',
  BlockMultiPrUpdateSignatory = 'blockMultiPrUpdateSignatory',
  BlockMultiPrCreateSignatory = 'blockMultiPrCreateSignatory',
  BlockMultiPrAddObservation = 'blockMultiPrAddObservation',
  BlockMultiPrEditObservation = 'blockMultiPrEditObservation',
  BlockTxtContent = 'blockTxtContent',
  BlockValidationModal = 'blockValidationModal',
  ResetBlocStatusModal = 'resetBlocStatusModal',
  ImportAdrModal = 'importAdrModal',
  ViewAdrModal = 'viewAdrModal',
  ImportEmmModal = 'importEmmModal',
  ImportFormModal = 'importFormModal',
  ResponseManagementModal = 'responseManagementModal',
  ReplaceFormModal = 'replaceFormModal',
  FormResponseModal = 'formResponseModal',
  FormNavigationModal = 'formNavigationModal',
  FormHistoryModal = 'formHistoryModal',
  MultiPrHistoryModal = 'multiPrHistoryModal',
  EditControlModal = 'editControlModal',
  AddRhpModal = 'addRhpModal',
  EditRhpModal = 'editRhpModal',
  ViewRhpModal = 'viewRhpModal',
  SignModal = 'signModal',
  EditSignatureModal = 'editSignatureModal',
  ViewSignatureModal = 'viewSignatureModal',
  BlockDsiHistoryModal = 'blockDsiHistoryModal',
  ViewEmmModal = 'viewEmmModal',
  BlockStatusHistoryModal = 'blockStatusHistoryModal',
  BlockPropertiesModal = 'blockPropertiesModal',
  LinkDocumentModal = 'linkDocumentModal',
  ImportFlowModal = 'importFlowModal',
  FlowValidationModal = 'flowValidationModal',
  EditPhaseModal = 'editPhaseModal',
  AddPhaseModal = 'addPhaseModal',
  EditStepModal = 'editStepModal',
  AddStepModal = 'addStepModal',
  FlowSourceInfoModal = 'flowSourceInfoModal',
  EditFlowContentModal = 'editFlowContentModal',
  ImageEditorModal = 'imageEditorModal',

  // Library ids
  LibraryRisks = 'libraryRisks',
  LibraryTags = 'libraryTags',
  LibraryTagsAddDialog = 'libraryTagsAddDialog',
  LibraryTagsEditDialog = 'libraryTagsEditDialog',
  LibraryTagLinksDialog = 'libraryTagLinksDialog',
  LibraryRisksAddDialogue = 'libraryRisksAddDialogue',
  LibraryRisksEditDialogue = 'libraryRisksEditDialogue',
  LibraryRisksViewDialogue = 'libraryRisksViewDialogue',
  LibraryRisksImportDialogue = 'libraryRisksImportDialogue',
  LibraryRiskDialog = 'libraryRiskDialog',
  LibraryRiskPropertiesDialog = 'libraryRiskPropertiesDialog',
  LibraryRiskReorganizeDialog = 'libraryRiskReorganizeDialog',
  LibraryEquipments = 'libraryEquipments',

  LibraryDocuments = 'libraryDocuments',
  LibraryDocumentsAddModal = 'libraryDocumentsAddModal',
  LibraryDocumentsEditModal = 'libraryDocumentsEditModal',
  LibraryDocumentsReorganizeModal = 'libraryDocumentsReorganizeModal',
  LibraryDocumentsPropertyModal = 'libraryDocumentsPropertyModal',

  LibraryForms = 'libraryForms',
  LibraryObjectManagement = 'libraryObjectManagement',
  LibraryObjectTree = 'libraryObjectTree',
  LibraryObjectList = 'libraryObjectList',
  LibraryObjectMap = 'libraryObjectMap',
  LibraryObjectManagementAddModal = 'libraryObjectManagementAddModal',
  LibraryObjectManagementEditModal = 'libraryObjectManagementEditModal',
  LibraryObjectManagementAssignModal = 'libraryObjectManagementAssignModal',
  LibraryObjectManagementGenerateModal = 'libraryObjectManagementGenerateModal',
  LibraryObjectManagementDuplicateModal = 'libraryObjectManagementDuplicateModal',
  LibraryObjectPropertiesModal = 'libraryObjectPropertiesModal',
  LibraryFormsAddModal = 'libraryFormsAddModal',
  LibraryFormsEditLocalModal = 'libraryFormsEditLocalModal',
  LibraryFormsEditModal = 'libraryFormsEditModal',
  FormsSummaryModal = 'formsSummaryDialog',
  LibraryFormsDuplicateModal = 'libraryFormsDuplicateDialog',
  LibraryFormsReorganizeModal = 'libraryFormsReorganizeDialog',
  LibraryFormsPropertiesModal = 'libraryFormsPropertiesDialog',

  // Settings ids
  SettingsAccount = 'settingsAccount',
  SettingsClientBook = 'settingsClientBook',
  SettingsCompanies = 'settingsCompanies',
  SettingsFamilies = 'settingsFamilies',
  DefaultCategories = 'settingsCategories',
  SettingsCustomFile = 'settingsCustomFile',
  SettingsCustomCell = 'settingsCustomCell',
  SettingsReferences = 'settingsReferences',
  SettingsCustomField = 'settingsCustomField',
  SettingsSections = 'settingsSections',
  SettingsGeneralAccount = 'settingsGeneralAccount',
  SettingsGeneralOrganizationApplications = 'settingsGeneralGeneralOrganizationApplications',
  SettingsGeneralOrganization = 'settingsGeneralOrganization',
  SettingsModules = 'settingsModules',
  SettingsTrams = 'settingsTrams',
  SettingsWordingConfiguration = 'settingsWordingConfiguration',
  SettingsBackups = 'settingsBackups',
  SettingsLogsPush = 'settingsLogsPush',
  SettingsKpis = 'settingsKpis',
  SettingsTrash = 'settingsTrash',
  EditDefaultCategory = 'editDefaultCategory',
  AddDefaultCategory = 'addDefaultCategory',
  SettingsEditFamily = ' settingsEditFamily',
  SettingsAddFamily = ' settingsAddFamily',
  CustomStatus = 'settingsCustomStatus',
  EditCustomStatus = 'editCustomStatus',
  AddCustomStatus = 'addCustomStatus',
  // customField
  EditCustomField = 'editCustomField',
  AddCustomField = 'addCustomField',

  // References ids
  ReferenceAdd = 'referenceAdd',
  ReferenceEdit = 'referenceEdit',

  // References ids
  AddTram = 'addTram',
  EditTram = 'editTram',

  // Custom file ids
  AddCustomFile = 'addCustomFile',
  EditCustomFile = 'editCustomFile',

  // Custom cell ids
  AddCustomCell = 'addCustomCell',
  EditCustomCell = 'editCustomCell',

  // user ids
  ContributorUsers = 'contributorUsers',
  UserAddModal = 'userAddModal',
  UserEditModal = 'userEditModal',
  UserSkillModal = 'userSkillModal',
  UserUploadModal = 'userUploadModal',
  UserReorganizeModal = 'userReorganizeModal',
  UserMoveApplicabilityModal = 'userMoveApplicabilityModal',
  UsersQuickConnectionModal = 'usersQuickConnectionModal',
  UserPropertiesDialog = 'userPropertiesDialog',

  // external users ids
  ContributorExternalUsers = 'externalUsers',
  EditExternalUser = 'editExternalUser',

  // operator ids
  ContributorOperators = 'contributorOperators',
  OperatorAddModal = 'operatorAddModal',
  OperatorEditModal = 'operatorEditModal',
  OperatorSkillsModal = 'operatorSkillsModal',
  OperatorUploadModal = 'operatorUploadModal',
  OperatorReorganizeModal = 'operatorReorganizeModal',
  OperatorMoveApplicabilityModal = 'operatorMoveApplicabilityModal',
  OperatorPropertiesDialog = 'operatorPropertiesDialog',

  // tablets
  ContributorTablets = 'contributorTablets',

  // external API
  contributorExternalAPI = 'contributorExternalAPI',
  ExternalAccessApiV1 = 'externalApiV1',
  ExternalAccessApiV2 = 'externalApiV2',

  // Equipments Ids
  EquipmentsUpload = 'equipmentsUpload',
  EquipmentsAdd = 'equipmentsAdd',
  EquipmentsEdit = 'equipmentsEdit',
  ViewEquipments = 'viewEquipments',
  EquipmentProperties = 'equipmentProperties',
  LibraryEquipmentReorganizeDialog = 'libraryEquipmentReorganizeDialog',

  // Profile ids
  ProfileInformation = 'profileInformation',
  ProfileResetPassword = 'profileResetPassword',
  ProfileTablet = 'ProfileTablet',
  quickConnectionModal = 'quickConnectionModal',

  // Collaborators
  AddTabletModal = 'addTabletModal',
  EditTabletModal = 'editTabletModal',
  PropertiesTabletModal = 'propertiesTabletModal',

  // Settings client book ids
  SettingsClientBookAddModal = 'settingsClientBookAddModal',
  SettingsClientBookEditModal = 'settingsClientBookEditModal',

  // Settings companies ids
  SettingsCompaniesAddModal = 'settingsCompaniesAddModal',
  SettingsCompaniesEditModal = 'settingsCompaniesEditModal',

  // accessApi
  AddAccessApiModal = 'addAccessApiModal',
  EditAccessApiModal = 'editAccessApiModal',
  AddAccessApiV2Modal = 'addAccessApiModalV2',
  EditAccessApiV2Modal = 'editAccessApiModalV2',
  AccessApiV2PropertiesModal = 'accessApiV2PropertiesModal',

  // Others ids
  FlowFormTab = 'flowFormTab',
  VideoTutorial = 'videoTutorial',
  contributorExternalAPIPath = 'contributorExternalAPIPath',

  // Folder template
  FolderAdd = 'folderAdd',
  FolderEdit = 'folderEdit',
  SectionAdd = 'sectionAdd',
  SectionEdit = 'sectionEdit',

  // wording configuration
  AddWordingConfiguration = 'addWordingConfiguration',
  EditWordingConfiguration = 'editWordingConfiguration',
  AddLanguageConfiguration = 'addLanguageConfiguration',
  EditLanguageConfiguration = 'editLanguageConfiguration',

  // Form
  Form = 'form',
  RubricContent = 'rubricContent',
  EditRubricModal = 'editRubricModal',
  AddRubricModal = 'addRubricModal',
  QuestionDetailsModal = 'questionDetails',
  OptionDetailsModal = 'optionDetails',
  RubricGalleryModal = 'rubricGalleryModal',
  QuestionGalleryModal = 'questionGalleryModal',
  OptionGalleryModal = 'optionGalleryModal',
  AnnotateRubricImageModal = 'annotateRubricImageModal',
  AnnotateQuestionImageModal = 'annotateQuestionImageModal',
  AnnotateOptionImageModal = 'annotateOptionImageModal',
  AnnotateOptionTmpImageModal = 'annotateOptionTmpImageModal',
  ViewImageModal = 'viewImageModal',
  ViewTmpImageModal = 'viewTmpImageModal',
  QuestionChangesHistory = 'questionChangesHistory',
}

export namespace RouteIdEnum {
  export const phasesAndStepsRoutes = [RouteIdEnum.EditPhaseModal, RouteIdEnum.AddPhaseModal, RouteIdEnum.EditStepModal, RouteIdEnum.AddStepModal];
  export const cacheModuleRouteIds = [RouteIdEnum.FlowManagementList];
  export const exceptionSaveFilterRouteIds = [
    RouteIdEnum.DashboardSignature,
    RouteIdEnum.DashboardSignatureList,
    RouteIdEnum.DashboardSignatureTemporal,
    RouteIdEnum.DashboardSignatureFlow,
    RouteIdEnum.LibraryTagLinksDialog,
    RouteIdEnum.FlowsToLinkForPlanModal,
    RouteIdEnum.ImportFlowModal,
    RouteIdEnum.DashboardAddSection,
    RouteIdEnum.DashboardEditSection,
    RouteIdEnum.Form,
    RouteIdEnum.ShareDialog,
    RouteIdEnum.RubricContent,
    RouteIdEnum.MultiPrHistoryModal,
    RouteIdEnum.SequenceModal,
    RouteIdEnum.DashDocumentsAddModal,
    RouteIdEnum.DashDocumentsEditModal,
    RouteIdEnum.DashboardEditFolder,
    RouteIdEnum.DashboardAddFolder,
    RouteIdEnum.LibraryDocumentsAddModal,
    RouteIdEnum.LibraryDocumentsEditModal,
    RouteIdEnum.FlowManagementAffectation,
    RouteIdEnum.FlowManagementAffectationEdit,
  ];

  const key = 'global.videoTutorial.list.';
  export const toString: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.Projects, key + 'projects'],
    [RouteIdEnum.FlowManagementList, key + 'flowsList'],
    [RouteIdEnum.FlowManagementPlan, key + 'roadMap'],
    [RouteIdEnum.FlowManagementMap, key + 'map'],
    [RouteIdEnum.DashboardForms, key + 'forms'],
    [RouteIdEnum.DashboardFlowReferential, key + 'flowReferential'],
    [RouteIdEnum.DashboardContributors, key + 'contributors'],
    [RouteIdEnum.DashboardTablets, key + 'tablets'],
    [RouteIdEnum.DashboardFieldFeedback, key + 'fieldFeedback'],
    [RouteIdEnum.DashboardInformation, key + 'information'],
    [RouteIdEnum.DashboardCategories, key + 'categories'],
    [RouteIdEnum.LibraryObjectTree, key + 'objectTree'],
    [RouteIdEnum.ContributorUsers, key + 'contributorUsers'],
    [RouteIdEnum.ContributorOperators, key + 'contributorOperators'],
    [RouteIdEnum.ContributorTablets, key + 'contributorTablets'],
    [RouteIdEnum.SettingsClientBook, key + 'clientBook'],
    [RouteIdEnum.SettingsFamilies, key + 'settingFamilies'],
    [RouteIdEnum.DefaultCategories, key + 'defaultCategories'],
    [RouteIdEnum.ProfileInformation, key + 'profileInformation'],
    [RouteIdEnum.ProfileResetPassword, key + 'resetPassword'],
  ]);

  export const helpCenterLink: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.Projects, 'https://helpcenter.siteflow.app/fr/articles/3219068-v2-comment-utiliser-la-liste-des-entites-et-ses-options'],
    [RouteIdEnum.ProjectEditModal, 'https://helpcenter.siteflow.app/fr/articles/3302748-v2-comment-creer-et-editer-une-entite'],
    [RouteIdEnum.ProjectDialogGeneralTabModal, 'https://helpcenter.siteflow.app/fr/articles/3302748-v2-comment-creer-et-editer-une-entite'],
    [RouteIdEnum.ProjectDialogAttributeTabModal, 'https://helpcenter.siteflow.app/fr/articles/3302748-v2-comment-creer-et-editer-une-entite'],
    [RouteIdEnum.ProjectDialogOptionsTabModal, 'https://helpcenter.siteflow.app/fr/articles/3302748-v2-comment-creer-et-editer-une-entite'],
    [RouteIdEnum.RevisionModal, 'https://helpcenter.siteflow.app/fr/articles/4732665-la-revision-de-flows'],
    [RouteIdEnum.FlowManagementList, 'https://helpcenter.siteflow.app/fr/articles/3051926-v2-comment-utiliser-la-liste-des-flows-et-ses-options'],
    [RouteIdEnum.FlowManagementAddModal, 'https://helpcenter.siteflow.app/fr/articles/3051925-v2-comment-ajouter-un-nouveau-flow'],
    [RouteIdEnum.FlowManagementPlan, 'https://helpcenter.siteflow.app/fr/articles/3921518-v2-comment-utiliser-la-vue-roadmap'],
    [RouteIdEnum.FlowManagementMap, 'https://helpcenter.siteflow.app/fr/articles/3921533-v2-comment-utiliser-la-vue-map'],
    [RouteIdEnum.DashboardDocuments, 'https://helpcenter.siteflow.app/fr/articles/3513241-v1-comment-gerer-des-documents-sur-un-projet'],
    [RouteIdEnum.DashboardForms, 'https://helpcenter.siteflow.app/fr/articles/3302822-v1-comment-gerer-les-reponses-depuis-le-projet'],
    [RouteIdEnum.DashboardFormsImportModal, 'https://helpcenter.siteflow.app/fr/articles/3219032-v1-comment-ajouter-et-repondre-a-un-formulaire-depuis-un-projet'],
    [RouteIdEnum.DashboardFlowReferential, 'https://helpcenter.siteflow.app/fr/articles/3052004-v2-comment-utiliser-le-module-referentiels-et-ses-options'],
    [RouteIdEnum.DashboardFlowReferentialManagementImportModal, 'https://helpcenter.siteflow.app/fr/articles/3052005-v2-comment-utiliser-un-flow-referentiel-dans-un-projet'],
    // Review: Fix it Later...
    [RouteIdEnum.DashboardScheduleTasks, 'https://helpcenter.siteflow.app/fr/articles/3302753-v2-comment-utiliser-la-liste-des-risques-et-moyens-et-ses-options'],
    [RouteIdEnum.DashboardLists, 'https://helpcenter.siteflow.app/fr/articles/3302753-v2-comment-utiliser-la-liste-des-risques-et-moyens-et-ses-options'],
    [RouteIdEnum.DashboardListDialogGeneralTabModal, 'https://helpcenter.siteflow.app/fr/articles/4476754-v2-comment-creer-et-editer-une-liste-de-risques-ou-de-moyens'],
    [RouteIdEnum.DashboardContributors, 'https://helpcenter.siteflow.app/fr/articles/3219027-v2-comment-utiliser-la-liste-des-collaborateurs-et-ses-options-sur-une-entite'],
    [RouteIdEnum.DashboardCollaboratorsImportModal, 'https://helpcenter.siteflow.app/fr/articles/4476794-v2-comment-ajouter-des-collaborateurs-sur-une-entite'],
    [RouteIdEnum.DashboardTablets, 'https://helpcenter.siteflow.app/fr/articles/3302752-v2-comment-utiliser-la-liste-des-tablettes-et-ses-options-sur-un-projet'],
    [RouteIdEnum.DashboardTabletsImportModal, 'https://helpcenter.siteflow.app/fr/articles/4476865-v2-comment-ajouter-des-tablettes-sur-un-projet'],
    [RouteIdEnum.DashboardMetrics, 'https://helpcenter.siteflow.app/fr/articles/3219033-v1-comment-utiliser-le-module-d-avancement'],
    [RouteIdEnum.DashboardSignatureFlow, 'https://helpcenter.siteflow.app/fr/articles/3219058-v1-comment-utiliser-le-module-d-avancement-des-signatures'],
    [RouteIdEnum.DashboardFieldFeedback, 'https://helpcenter.siteflow.app/fr/articles/3684328-v2-comment-utiliser-la-liste-des-remontees-terrain-et-ses-options'],
    [RouteIdEnum.DashboardClosingFolder, 'https://helpcenter.siteflow.app/fr/articles/4726869-comment-utiliser-la-liste-des-dossiers-de-cloture-et-ses-options'],
    [RouteIdEnum.DashboardInformation, 'https://helpcenter.siteflow.app/fr/articles/3302750-v2-comment-modifier-les-informations-d-une-entite-une-fois-creee'],
    [RouteIdEnum.DashboardCategories, 'https://helpcenter.siteflow.app/fr/articles/4477009-v2-comment-utiliser-la-liste-des-categories-dans-une-entite'],
    [RouteIdEnum.DashboardAddCategory, 'https://helpcenter.siteflow.app/fr/articles/3052032-v2-comment-creer-et-editer-des-categories-dans-mon-entite'],
    [RouteIdEnum.LibraryRisks, 'https://helpcenter.siteflow.app/fr/articles/3052025-v1-comment-ajouter-et-utiliser-des-risques-dans-la-bibliotheque'],
    [RouteIdEnum.LibraryRisksAddDialogue, 'https://helpcenter.siteflow.app/fr/articles/4726873-comment-creer-et-editer-des-risques'],
    [RouteIdEnum.LibraryEquipments, 'https://helpcenter.siteflow.app/fr/articles/3302787-v1-comment-ajouter-et-utiliser-des-moyens-dans-la-bibliotheque'],
    [RouteIdEnum.EquipmentsAdd, 'https://helpcenter.siteflow.app/fr/articles/4726878-comment-creer-et-editer-des-moyens'],
    [RouteIdEnum.LibraryDocuments, 'https://helpcenter.siteflow.app/fr/articles/3302789-v1-comment-ajouter-et-utiliser-des-documents-dans-la-bibliotheque'],
    [RouteIdEnum.LibraryForms, 'https://helpcenter.siteflow.app/fr/articles/4726883-comment-utiliser-la-liste-des-formulaires-et-ses-options'],
    [RouteIdEnum.LibraryFormsAddModal, 'https://helpcenter.siteflow.app/fr/articles/3052041-v1-comment-creer-un-formulaire'],
    [RouteIdEnum.LibraryTags, 'https://helpcenter.siteflow.app/fr/articles/5323150-comment-utiliser-la-liste-des-etiquettes'],
    [RouteIdEnum.LibraryTagsAddDialog, 'https://helpcenter.siteflow.app/fr/articles/5323154-comment-creer-et-editer-des-etiquettes'],
    [RouteIdEnum.LibraryTagLinksDialog, 'https://helpcenter.siteflow.app/fr/articles/5323154-comment-creer-et-editer-des-etiquettes'],
    [RouteIdEnum.LibraryObjectTree, 'https://helpcenter.siteflow.app/fr/articles/4412093-v2-comment-utiliser-la-liste-des-objets-et-ses-options'],
    [RouteIdEnum.LibraryObjectManagementAddModal, 'https://helpcenter.siteflow.app/fr/articles/3921543-v2-comment-creer-et-utiliser-des-objets'],
    [RouteIdEnum.ContributorUsers, 'https://helpcenter.siteflow.app/fr/articles/3052026-v2-comment-creer-un-utilisateur-ou-un-operateur'],
    [RouteIdEnum.UserAddModal, 'https://helpcenter.siteflow.app/fr/articles/3052026-v2-comment-creer-un-utilisateur-ou-un-operateur'],
    [RouteIdEnum.ContributorOperators, 'https://helpcenter.siteflow.app/fr/articles/3052026-v2-comment-creer-un-utilisateur-ou-un-operateur'],
    [RouteIdEnum.ContributorOperators, 'https://helpcenter.siteflow.app/fr/articles/3052026-v2-comment-creer-un-utilisateur-ou-un-operateur'],
    [RouteIdEnum.ContributorTablets, 'https://helpcenter.siteflow.app/fr/articles/4477377-v2-comment-utiliser-la-liste-des-tablettes-et-ses-options'],
    [RouteIdEnum.AddTabletModal, 'https://helpcenter.siteflow.app/fr/articles/4477377-v2-comment-utiliser-la-liste-des-tablettes-et-ses-options'],
    [RouteIdEnum.UserSkillModal, 'https://helpcenter.siteflow.app/fr/articles/4477337-v2-comment-utiliser-la-liste-des-competences-et-ses-options'],
    [RouteIdEnum.SettingsAccount, 'https://helpcenter.siteflow.app/fr/articles/4480249-v2-comment-utiliser-le-module-compte-de-la-page-parametres'],
    [RouteIdEnum.SettingsClientBook, 'https://helpcenter.siteflow.app/fr/articles/4480312-v2-comment-utiliser-la-liste-des-clients-et-ses-options'],
    [RouteIdEnum.SettingsClientBookAddModal, 'https://helpcenter.siteflow.app/fr/articles/3052024-v2-comment-creer-un-client'],
    [RouteIdEnum.SettingsFamilies, 'https://helpcenter.siteflow.app/fr/articles/3052029-v2-comment-utiliser-la-liste-des-familles-et-ses-options'],
    [RouteIdEnum.SettingsAddFamily, 'https://helpcenter.siteflow.app/fr/articles/4480401-v2-comment-creer-et-editer-des-familles'],
    [RouteIdEnum.DefaultCategories, 'https://helpcenter.siteflow.app/fr/articles/4480527-v2-comment-utiliser-la-liste-des-categories-par-defaut-et-ses-options'],
    [RouteIdEnum.AddDefaultCategory, 'https://helpcenter.siteflow.app/fr/articles/3219105-v2-comment-creer-et-editer-une-categorie-par-defaut'],
    [RouteIdEnum.CustomStatus, 'https://helpcenter.siteflow.app/fr/articles/4726892-comment-utiliser-la-liste-des-statuts-et-ses-options'],
    [RouteIdEnum.AddCustomStatus, 'https://helpcenter.siteflow.app/fr/articles/3921534-v2-comment-creer-et-editer-des-statuts-par-defaut'],
    [RouteIdEnum.SettingsReferences, 'https://helpcenter.siteflow.app/fr/articles/4726908-comment-utiliser-la-liste-des-references-et-ses-options'],
    [RouteIdEnum.ReferenceAdd, 'https://helpcenter.siteflow.app/fr/articles/3684176-v1-comment-creer-des-references-et-numeros-de-chrono-par-defaut'],
    [RouteIdEnum.SettingsCustomField, 'https://helpcenter.siteflow.app/fr/articles/4726911-comment-utiliser-la-liste-des-attributs-et-ses-options'],
    [RouteIdEnum.AddCustomField, 'https://helpcenter.siteflow.app/fr/articles/3612701-v1-comment-configurer-des-attributs-par-defaut'],
    [RouteIdEnum.SettingsCustomFile, 'https://helpcenter.siteflow.app/fr/articles/4726915-comment-utiliser-la-liste-des-modeles-de-fichiers-et-ses-options'],
    [RouteIdEnum.AddCustomFile, 'https://helpcenter.siteflow.app/fr/articles/4104314-v2-comment-utiliser-les-fonctions-du-module-fichiers'],
    [RouteIdEnum.SettingsCustomCell, 'https://helpcenter.siteflow.app/fr/articles/4726918-comment-utiliser-la-liste-des-cellules-de-fichiers-et-ses-options'],
    [RouteIdEnum.AddCustomCell, 'https://helpcenter.siteflow.app/fr/articles/4726921-comment-creer-et-editer-des-modeles-de-fichiers'],
    [RouteIdEnum.SettingsSections, 'https://helpcenter.siteflow.app/fr/articles/4726923-comment-utiliser-la-liste-des-dossiers-et-ses-options'],
    [RouteIdEnum.FolderAdd, 'https://helpcenter.siteflow.app/fr/articles/3302790-v1-comment-configurer-des-modeles-de-dossier-de-cloture'],
    [RouteIdEnum.SettingsTrash, 'https://helpcenter.siteflow.app/fr/articles/3515951-v1-comment-utiliser-le-module-corbeille'],
    [RouteIdEnum.ProfileInformation, 'https://helpcenter.siteflow.app/fr/articles/3302833-comment-modifier-mes-informations-personnelles-et-mon-mot-de-passe'],
    [RouteIdEnum.ProfileResetPassword, 'https://helpcenter.siteflow.app/fr/articles/3473762-comment-modifier-votre-mot-de-passe'],
    [RouteIdEnum.ProfileTablet, 'https://helpcenter.siteflow.app/fr/articles/4726928-comment-utiliser-sa-tablette-et-ses-options'],
    [RouteIdEnum.EditStepModal, 'https://helpcenter.siteflow.app/fr/articles/4800011-options-sur-les-phases-et-etapes'],
    [RouteIdEnum.SequenceModal, 'https://helpcenter.siteflow.app/fr/articles/5069807-la-notion-de-sequence'],
  ]);

  export const helpCenterLinkOldModal: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.UserSkillModal, 'https://helpcenter.siteflow.app/fr/articles/3515945-v2-comment-ajouter-et-editer-des-competences-a-un-collaborateur'],
  ]);

  export const guidedTourID: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>([
    [RouteIdEnum.Projects, '128142'],
    [RouteIdEnum.ProjectEditModal, '129733'],
    [RouteIdEnum.ProjectDialogGeneralTabModal, '129733'],
    [RouteIdEnum.ProjectDialogAttributeTabModal, '129733'],
    [RouteIdEnum.ProjectDialogOptionsTabModal, '129733'],
  ]);

  export const blockRoutes = [
    RouteIdEnum.BlockContentMobileVue,
    RouteIdEnum.BlockContent,
    RouteIdEnum.BlockContentDialog,
    RouteIdEnum.BlockRhpContent,
    RouteIdEnum.BlockEmmContent,
    RouteIdEnum.BlockAdrContent,
    RouteIdEnum.BlockDsiContent,
    RouteIdEnum.BlockFormContent,
    RouteIdEnum.BlockPrContent,
    RouteIdEnum.BlockMultiPrContent,
    RouteIdEnum.BlockMultiPrUpdateSignatory,
    RouteIdEnum.BlockMultiPrCreateSignatory,
    RouteIdEnum.BlockMultiPrAddObservation,
    RouteIdEnum.BlockMultiPrEditObservation,
    RouteIdEnum.BlockTxtContent,
    RouteIdEnum.BlockValidationModal,
    RouteIdEnum.ResetBlocStatusModal,
    RouteIdEnum.FlowManagementAddModal,
    RouteIdEnum.FlowManagementEditModal,
  ];
}
