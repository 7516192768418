import { optionValueType } from '../form-builder/question-option-data';
import { QuestionDurationData } from '../form-builder/question-duration-data';
import { OptionAlertData } from '../form-builder/option-alert-data';
import { OptionValidationData } from '../form-builder/option-validation-data';
import { QuestionLocationData } from '../form-builder/question-location-data';

import { DateUtils } from '../../utils/date.utils';
import { PhotoSizeEnum, QuestionTypeEnum, ToasterTypeEnum, ValidationNatureEnum } from '../../enums';
import { OptionGenericParamsRequestDTO } from '../../generated/models/optionGenericParamsRequestDTO';

export interface GenericOptionData {
  questionUuidEntity: string;
  questionType: QuestionTypeEnum;
  name: string;
  value?: optionValueType;
  placeholder?: string;
  disabled?: boolean;
  alertData?: OptionAlertData;
  validationData?: OptionValidationData;
  unitValue?: string;
  preFillCheck?: boolean;
  alertCheck?: boolean;
  validationCheck?: boolean;
  imageSizeCheck?: boolean;
  photoSize?: PhotoSizeEnum;
  preEvaluationValue?: string;
  preEvaluationEnums: string;
  disableSignature?: boolean;
  filledWithDynamicVariable?: boolean;
}

export namespace GenericOptionData {
  export function mapToUpdateRequestApiValue(option: GenericOptionData): OptionGenericParamsRequestDTO {
    return {
      ...setOptionValue(option),
      questionUuidEntity: option.questionUuidEntity,
      checkedDisabledValue: option.disabled,
      /* validation data */
      checkedValidation: option.validationCheck,
      validationAlertText: option.validationCheck ? option.validationData.validationMessage : null,
      checkedBlockingControl: option.validationCheck ? option.validationData.blockValueToLimit : null,
      valueOne: option.validationCheck ? option.validationData.min : null,
      valueTwo: option.validationCheck ? option.validationData.max : null,
      natureValidation: option.validationCheck ? ValidationNatureEnum.mapToApiValue.getValue(option.validationData.nature) : null,
      /* alert data */
      checkedAlert: option.alertCheck,
      natureAlert: option.alertCheck ? ToasterTypeEnum.mapToAlertNature.getValue(option.alertData.nature) : null,
      alertText: option.alertCheck ? option.alertData.alertMessage : null,

      /* Image size */
      checkedImageSize: !!option.photoSize,
      attachementWidth: PhotoSizeEnum.convertToApiValue.getValue(option.photoSize),

      textValueUnit: option.unitValue,
      preEvaluationValue: option.preFillCheck || option.questionType === QuestionTypeEnum.Formula ? option.preEvaluationValue : null,
      name: option.name,
      preEvaluationEnums: option.preEvaluationEnums,
      placeholder: option.placeholder,
      disableSignature: option.disableSignature,
    } as OptionGenericParamsRequestDTO; // Make models
  }

  export function setOptionValue(option: GenericOptionData): Partial<OptionGenericParamsRequestDTO> {
    const fillOptionValue = !option.filledWithDynamicVariable;
    switch (option.questionType) {
      case QuestionTypeEnum.Date:
        return { dateValue: fillOptionValue ? DateUtils.toLocalDate(option.value as string) : null };

      case QuestionTypeEnum.DateTime:
        return { dateTimeValue: fillOptionValue ? DateUtils.toLocalDateTime(option.value as string) : null };

      case QuestionTypeEnum.Duration:
        const time = option.value as QuestionDurationData;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { timeValue: (fillOptionValue && time.hours && time.minutes && (`${time.hours}:${time.minutes}` as any)) || null };

      case QuestionTypeEnum.Location:
        return fillOptionValue
          ? {
              altitude: (option.value as QuestionLocationData).altitude,
              latitude: (option.value as QuestionLocationData).latitude,
              longitude: (option.value as QuestionLocationData).longitude,
            }
          : {};

      case QuestionTypeEnum.UniqueChoice:
      case QuestionTypeEnum.MultipleChoices:
        return { checkedValue: fillOptionValue ? (option.value as boolean) : null };

      case QuestionTypeEnum.ValueUnit:
        return { textValue: fillOptionValue ? (option.value as string) : null };

      default:
        return {};
    }
  }
}
