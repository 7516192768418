import { DateUtils } from '../../utils/date.utils';

import { CompanySummaryResponseDTO } from '../../generated/models/companySummaryResponseDTO';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { UserRightDTO } from '../../generated/models/userRightDTO';
import { CompanyLightDTO } from '../../generated/models/models';

export interface CompanySummaryData {
  uuidEntity: string;
  name: string;
  activityArea?: string;
  lastModificationDate?: string;
  isParentCompany?: boolean;
}

export namespace CompanySummaryData {
  export type QueryRequest = BaseQuery<CompanySummaryData>;

  export function mapFromApi(company: CompanySummaryResponseDTO): CompanySummaryData {
    return {
      name: company.name,
      activityArea: company.activityArea,
      uuidEntity: company.identifier,
      isParentCompany: company.isParentCompany,
      lastModificationDate: DateUtils.toDateTimeFormat(company.lastModifiedDate),
    };
  }

  export function mapFromCompanyLight(company: CompanyLightDTO): CompanySummaryData {
    return {
      name: company.name,
      uuidEntity: company.identifier,
    };
  }

  export function mapFromUserRight(userRight: UserRightDTO): CompanySummaryData {
    return {
      name: userRight?.company.name,
      uuidEntity: userRight?.company.identifier,
    };
  }

  export function initializeCompaniesQueries(name: string): QueryRequest {
    return {
      ...initializeQueries<CompanySummaryData>(),
      textSearch: name || undefined,
    };
  }
}
