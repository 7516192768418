/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateFilterRequest } from './dateFilterRequest';
import { SortDto } from './sortDto';

export interface PageUserRequestDTO {
  page: number;
  size: number;
  textSearch?: string;
  sort?: SortDto;
  labelFamilySkillUuides?: Array<string>;
  labelFamilyFunctionUuides?: Array<string>;
  labelFamilyTeamUuides?: Array<string>;
  labelFamilyHierarchyUuides?: Array<string>;
  legalEntitiesUuides?: Array<string>;
  contratTypeUuides?: Array<string>;
  type?: PageUserRequestDTO.TypeEnum;
  userAuthority?: string;
  applicabilityUuides?: Array<string>;
  statuses?: Array<PageUserRequestDTO.StatusesEnum>;
  activityDomainsUuides?: Array<string>;
  dateFilterRequest?: DateFilterRequest;
}
export namespace PageUserRequestDTO {
  export type TypeEnum = 'USER' | 'OPERATOR';
  export const TypeEnum = {
    USER: 'USER' as TypeEnum,
    OPERATOR: 'OPERATOR' as TypeEnum,
  };
  export type StatusesEnum = 'ONBOARDING' | 'ACTIVATED' | 'BLOCKED' | 'DEACTIVATED' | 'DELETED';
  export const StatusesEnum = {
    ONBOARDING: 'ONBOARDING' as StatusesEnum,
    ACTIVATED: 'ACTIVATED' as StatusesEnum,
    BLOCKED: 'BLOCKED' as StatusesEnum,
    DEACTIVATED: 'DEACTIVATED' as StatusesEnum,
    DELETED: 'DELETED' as StatusesEnum,
  };
}
