/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlocHistoryDetailsResponseDTO } from './blocHistoryDetailsResponseDTO';
import { UserLightDTO } from './userLightDTO';

export interface BlocViewDetailsResponseDTO {
  uuidEntity: string;
  uuid: string;
  uuidType: string;
  uuidOrganization: string;
  natureBloc: BlocViewDetailsResponseDTO.NatureBlocEnum;
  statusBloc: BlocViewDetailsResponseDTO.StatusBlocEnum;
  revision: number;
  rejected: boolean;
  redacteur: UserLightDTO;
  verificateur: UserLightDTO;
  aprobateur: UserLightDTO;
  dateRedaction: Date;
  dateVerification: Date;
  dateAprobation: Date;
  blocEvents?: Array<BlocHistoryDetailsResponseDTO>;
  multiPrTitleHidden?: boolean;
  disabled: boolean;
  requirementStatus: boolean;
  requirementSignatory: boolean;
}
export namespace BlocViewDetailsResponseDTO {
  export type NatureBlocEnum = 'TXT' | 'PR' | 'RHP' | 'EMM' | 'ADR' | 'DSI' | 'FORM' | 'AUTRE' | 'MULTI_PR';
  export const NatureBlocEnum = {
    TXT: 'TXT' as NatureBlocEnum,
    PR: 'PR' as NatureBlocEnum,
    RHP: 'RHP' as NatureBlocEnum,
    EMM: 'EMM' as NatureBlocEnum,
    ADR: 'ADR' as NatureBlocEnum,
    DSI: 'DSI' as NatureBlocEnum,
    FORM: 'FORM' as NatureBlocEnum,
    AUTRE: 'AUTRE' as NatureBlocEnum,
    MULTIPR: 'MULTI_PR' as NatureBlocEnum,
  };
  export type StatusBlocEnum = 'REDACTION' | 'VERIFICATION' | 'APROBATION' | 'VALIDE';
  export const StatusBlocEnum = {
    REDACTION: 'REDACTION' as StatusBlocEnum,
    VERIFICATION: 'VERIFICATION' as StatusBlocEnum,
    APROBATION: 'APROBATION' as StatusBlocEnum,
    VALIDE: 'VALIDE' as StatusBlocEnum,
  };
}
