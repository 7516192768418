import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { TechnicalObjectDetailsResponseDTO } from '../../generated/models/models';
import { SfxMap } from '../../utils/enum-utils';
import { Observable, of } from 'rxjs';
import { SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

export enum TechnicalObjectNatureEnum {
  GeographyObject = 1,
  TechnicalObject = 2,
}

export namespace TechnicalObjectNatureEnum {
  export const values: TechnicalObjectNatureEnum[] = [TechnicalObjectNatureEnum.GeographyObject, TechnicalObjectNatureEnum.TechnicalObject];

  export const convertFromApiValue = new SfxMap<TechnicalObjectDetailsResponseDTO.NatureEnum, TechnicalObjectNatureEnum>([
    [TechnicalObjectDetailsResponseDTO.NatureEnum.GEOGRAPHICOBJECT, TechnicalObjectNatureEnum.GeographyObject],
    [TechnicalObjectDetailsResponseDTO.NatureEnum.TECHNICALOBJECT, TechnicalObjectNatureEnum.TechnicalObject],
  ]);

  export const convertToApiValue = new SfxMap<TechnicalObjectNatureEnum, TechnicalObjectDetailsResponseDTO.NatureEnum>([
    [TechnicalObjectNatureEnum.GeographyObject, TechnicalObjectDetailsResponseDTO.NatureEnum.GEOGRAPHICOBJECT],
    [TechnicalObjectNatureEnum.TechnicalObject, TechnicalObjectDetailsResponseDTO.NatureEnum.TECHNICALOBJECT],
  ]);

  const key = 'technicalObjects.nature.';
  export const toString = new SfxMap<TechnicalObjectNatureEnum, string>([
    [TechnicalObjectNatureEnum.GeographyObject, key + 'geographyObject'],
    [TechnicalObjectNatureEnum.TechnicalObject, key + 'technicalObject'],
  ]);

  export const iconData = new SfxMap<TechnicalObjectNatureEnum, IconData>([
    [TechnicalObjectNatureEnum.GeographyObject, { name: 'globe-europe', prefix: 'fas' }],
    [TechnicalObjectNatureEnum.TechnicalObject, { name: 'cogs', prefix: 'fas' }],
  ]);

  export const selectItems: SingleSelectItemData<TechnicalObjectNatureEnum>[] = values.map(nature => ({
    value: nature,
    title: toString.getValue(nature),
    icons: [iconData.getValue(nature)],
  }));

  export const itemsResolver = (): Observable<SingleSelectItemData<TechnicalObjectNatureEnum>[]> =>
    of(
      values.map(nature => ({
        value: nature,
        title: toString.getValue(nature),
        icons: [iconData.getValue(nature)],
      })),
    );

  export const comparePredicate = (node: SingleSelectItemData<TechnicalObjectNatureEnum>, item: TechnicalObjectNatureEnum) => node.value === item;
}
