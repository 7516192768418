import { Injectable } from '@angular/core';

import dayjs from 'dayjs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  UserData,
  HttpParamsType,
  LazyLoadResponse,
  ContributorImportData,
  UserLightData,
  ContributorDeleteResponseData,
  UserQrCodeData,
  SkillDetailsData,
  SkillSummaryData,
  LibraryContributorDetailsData,
  LibraryContributorSummaryData,
  CTabletSummaryData,
  CTabletDetailsData,
  TabletSendEmailData,
  LibraryContributorPropertiesData,
  TabletPropertiesData,
  defaultPageSize,
} from '../models';
import {
  UserDTO,
  UserCustomSummaryResponseDTO,
  PageImportUserRequestDTO,
  UserDetailsResponseDTO,
  PageUserRequestDTO,
  UserUpdateRequestDTO,
  SkillDetailsResponseDTO,
  SkillCreateRequestDTO,
  SkillUpdateRequestDTO,
  UserCreateRequestDTO,
  UserReorganizeRequestDTO,
  UserSummaryResponseDTO,
  UserDeviceDetailResponseDTO,
  ChangePasswordRequestDTO,
  AccountUpdateRequestDTO,
  DeviceSummaryResponseDTO,
  DeviceCreateRequestDTO,
  DeviceDetailsResponseDTO,
  DeviceUpdateRequestDTO,
  SkillSummaryResponseDTO,
  DevicePropertiesResponseDTO,
  UserPropertiesResponseDTO,
  UserLightDTOV15,
} from '../generated/models/models';

import { OperatorErrorEnum } from '../enums';
import { apiUrlMatcher } from './helpers/api-url-matcher';
import { ApiUrlsEnum } from './helpers/api-url';
import { BaseHttpService } from './base-http.service';
import { LazySelectItemData, MultiSelectItemData } from '../../modules/shared/components/select-v2/utils/select-item-data';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private baseHttpService: BaseHttpService) {}

  public getUserInfo(): Observable<UserData> {
    return this.baseHttpService.get<UserDTO>(apiUrlMatcher(ApiUrlsEnum.UserAccount)).pipe(map(userInfo => UserData.mapFromApiValue(userInfo)));
  }

  public updateUserAccount(params: HttpParamsType<AccountUpdateRequestDTO>): Observable<UserData> {
    return this.baseHttpService.put<UserDTO>(apiUrlMatcher(ApiUrlsEnum.UserAccount), params).pipe(map(userAccountData => UserData.mapFromApiValue(userAccountData)));
  }

  public updateUserPwd(params: HttpParamsType<ChangePasswordRequestDTO>): Observable<boolean> {
    return this.baseHttpService.post(apiUrlMatcher(ApiUrlsEnum.UserAccountPwdUpdate), params).pipe(map(() => true));
  }

  public getApplicationUsers(params: HttpParamsType<PageImportUserRequestDTO>): Observable<LazyLoadResponse<ContributorImportData[]>> {
    return this.baseHttpService
      .lazyLoad<UserCustomSummaryResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.UsersApplication), params)
      .pipe(map(response => ({ ...response, payload: response.payload.map(data => ContributorImportData.mapFromApiValue(data)) })));
  }

  public getLibraryUsers(params: HttpParamsType<PageUserRequestDTO>): Observable<LazyLoadResponse<LibraryContributorSummaryData[]>> {
    return this.baseHttpService
      .lazyLoad<UserSummaryResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.LibraryContributor), params)
      .pipe(map(response => ({ ...response, payload: response.payload.map(data => LibraryContributorSummaryData.mapFromApiValue(data)) })));
  }

  public deleteContributor(uuidEntity: string): Observable<ContributorDeleteResponseData> {
    return this.baseHttpService
      ._delete<never>(apiUrlMatcher(ApiUrlsEnum.DeleteLibraryContributor, { uuidEntity }))
      .pipe(map(_ => ContributorDeleteResponseData.mapFromApiValue({ success: true, links: [] })));
  }

  public getTabletDetailsData(tabletUuidEntity: string): Observable<CTabletDetailsData> {
    return this.baseHttpService
      .get<DeviceDetailsResponseDTO>(apiUrlMatcher(ApiUrlsEnum.TabletDetails, { tabletUuidEntity }))
      .pipe(map(payload => CTabletDetailsData.mapFromApiValue(payload)));
  }

  public getTabletQrCode(params: UserQrCodeData.QrCodeRequest): Observable<UserQrCodeData> {
    return this.baseHttpService
      .post<UserDeviceDetailResponseDTO>(apiUrlMatcher(ApiUrlsEnum.LTabletQrCode), { body: UserQrCodeData.mapToRequestApiValue(params) })
      .pipe(map(data => UserQrCodeData.mapFromApiValue(data)));
  }

  public unlinkMyTablet(): Observable<boolean> {
    return this.baseHttpService.put<void>(apiUrlMatcher(ApiUrlsEnum.UnlinkMyTablet), {}).pipe(map(() => true));
  }

  public unlinkTablet(tabletUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put<void>(apiUrlMatcher(ApiUrlsEnum.UnlinkTablet, { tabletUuidEntity }), {}).pipe(map(() => true));
  }

  public sendEmail(sendEmailData: TabletSendEmailData): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.SendEmail), { body: TabletSendEmailData.mapToRequestApiValue(sendEmailData) });
  }

  public trashUsersFilterResolver = (page: number, searchText?: string): Observable<LazySelectItemData<MultiSelectItemData>> => {
    const body: PageUserRequestDTO = { page, size: defaultPageSize, textSearch: searchText };

    return this.baseHttpService
      .lazyLoad<UserLightDTOV15[]>(apiUrlMatcher(ApiUrlsEnum.TrashContributors), { body })
      .pipe(map(response => ({ ...response, payload: response.payload.map(user => UserLightData.mapToTrashFilterSelectItem(user)) })));
  };

  public getContributorData(uuidEntity: string): Observable<LibraryContributorDetailsData> {
    return this.baseHttpService
      .get<UserDetailsResponseDTO>(apiUrlMatcher(ApiUrlsEnum.GetUser, { uuidEntity }))
      .pipe(map(payload => LibraryContributorDetailsData.mapFromApiValue(payload)));
  }

  public updateContributor(params: HttpParamsType<UserUpdateRequestDTO>): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.UpdateUser), params).pipe(
      map(() => true),
      catchError(error => throwError(() => ({ ...error, message: OperatorErrorEnum.convertFromApiValues.getValue(error.message) }))),
    );
  }

  public createContributor(params: HttpParamsType<UserCreateRequestDTO>): Observable<LibraryContributorDetailsData> {
    return this.baseHttpService.post<UserDetailsResponseDTO>(apiUrlMatcher(ApiUrlsEnum.CreateUser), params).pipe(
      map(payload => LibraryContributorDetailsData.mapFromApiValue(payload)),
      catchError(error => throwError(() => ({ ...error, message: OperatorErrorEnum.convertFromApiValues.getValue(error.message) }))),
    );
  }

  public getContributorSkillsListData(queries: SkillSummaryData.QueryRequest): Observable<LazyLoadResponse<SkillSummaryData[]>> {
    return this.baseHttpService
      .lazyLoad<SkillSummaryResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.SkillsLazy), { body: SkillSummaryData.mapToPageRequestApiValue(queries) })
      .pipe(map(response => ({ ...response, payload: response.payload.map(data => SkillSummaryData.mapFromApiValue(data)) })));
  }

  public addSkillContributor(params: HttpParamsType<SkillCreateRequestDTO>): Observable<boolean> {
    const errorMessage = this.getConsistencyError(params);
    if (errorMessage) {
      return throwError(() => new Error(errorMessage));
    }

    return this.baseHttpService.post(apiUrlMatcher(ApiUrlsEnum.CreateSkill), params).pipe(map(() => true));
  }

  public deleteSkill(uuidEntity: string): Observable<boolean> {
    return this.baseHttpService._delete(apiUrlMatcher(ApiUrlsEnum.DeleteSkill, { uuidEntity }));
  }

  public getSkillData(uuidEntity: string): Observable<SkillDetailsData> {
    return this.baseHttpService.get<SkillDetailsResponseDTO>(apiUrlMatcher(ApiUrlsEnum.GetSkill, { uuidEntity })).pipe(map(payload => SkillDetailsData.mapFromApiValue(payload)));
  }

  public updateSkill(params: HttpParamsType<SkillUpdateRequestDTO>): Observable<boolean> {
    const errorMessage = this.getConsistencyError(params);
    if (errorMessage) {
      return throwError(() => new Error(errorMessage));
    }

    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.PutSkill), params).pipe(map(() => true));
  }

  public getTabletListData(queries: CTabletSummaryData.QueryRequest): Observable<LazyLoadResponse<CTabletSummaryData[]>> {
    return this.baseHttpService
      .lazyLoad<DeviceSummaryResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.LazyTablets), {
        body: CTabletSummaryData.mapToPageRequestApiValue(queries),
      })
      .pipe(map(response => ({ ...response, payload: response.payload.map(data => CTabletSummaryData.mapFromApiValue(data)) })));
  }

  public resendOnBoardingEmail(userUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.resendOnBoardingEmail, { userUuidEntity }), {}).pipe(map(() => true));
  }

  public unblockUser(userUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.unblockUser, { userUuidEntity }), {}).pipe(map(() => true));
  }

  public reorganizeUsers(params: HttpParamsType<UserReorganizeRequestDTO>): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.ReorganizeContributorsUsers), params).pipe(map(() => true));
  }

  public restoreUser(userUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.restoreUser, { userUuidEntity }), {}).pipe(map(() => true));
  }

  public restoreOperator(operatorUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.restoreOperator, { operatorUuidEntity }), {}).pipe(map(() => true));
  }

  public deactivateUser(userUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.deactivateUser, { userUuidEntity }), {}).pipe(map(() => true));
  }

  public reactivateTabletOrOperator(tabletUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.reactivateTabletOrOperator, { tabletUuidEntity }), {}).pipe(map(() => true));
  }

  public deactivateTabletOrOperator(tabletUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.deactivateTabletOrOperator, { tabletUuidEntity }), {}).pipe(map(() => true));
  }

  public changeApplicabilityUser(userUuidEntity: string, applicabilityUuidEntity: string): Observable<boolean> {
    return this.baseHttpService.put(apiUrlMatcher(ApiUrlsEnum.MoveApplicabilityUser, { userUuidEntity }), { body: { applicabilityUuidEntity } }).pipe(map(() => true));
  }

  public createTablet(params: HttpParamsType<DeviceCreateRequestDTO>): Observable<CTabletDetailsData> {
    return this.baseHttpService.post<DeviceDetailsResponseDTO>(apiUrlMatcher(ApiUrlsEnum.CreateTablet), params).pipe(map(tablet => CTabletDetailsData.mapFromApiValue(tablet)));
  }

  public updateTablet(params: HttpParamsType<DeviceUpdateRequestDTO>): Observable<CTabletDetailsData> {
    return this.baseHttpService.put<DeviceDetailsResponseDTO>(apiUrlMatcher(ApiUrlsEnum.UpdateTablet), params).pipe(map(tablet => CTabletDetailsData.mapFromApiValue(tablet)));
  }

  public deleteTablet(uuidEntity: string): Observable<ContributorDeleteResponseData> {
    return this.baseHttpService
      ._delete<never>(apiUrlMatcher(ApiUrlsEnum.DeleteTablet, { uuidEntity }))
      .pipe(map(_ => ContributorDeleteResponseData.mapFromApiValue({ success: true, links: [] })));
  }

  private getConsistencyError(params: HttpParamsType<SkillUpdateRequestDTO> | HttpParamsType<SkillCreateRequestDTO>): string {
    if (params.body && params.body.startDate && params.body.endDate && dayjs(params.body.endDate).isBefore(params.body.startDate)) {
      return 'users.error.skill.endDateIsBeforeStartDate';
    }
  }

  public getContributorPropertiesData(userUuidEntity: string): Observable<LibraryContributorPropertiesData> {
    return this.baseHttpService
      .get<UserPropertiesResponseDTO>(apiUrlMatcher(ApiUrlsEnum.GetUserProperties, { userUuidEntity }))
      .pipe(map(payload => LibraryContributorPropertiesData.mapFromApiValue(payload)));
  }

  public getTabletPropertiesData(tabletUuidEntity: string): Observable<TabletPropertiesData> {
    return this.baseHttpService
      .get<DevicePropertiesResponseDTO>(apiUrlMatcher(ApiUrlsEnum.TabletProperties, { tabletUuidEntity }))
      .pipe(map(payload => TabletPropertiesData.mapFromApiValue(payload)));
  }
}
