import dayjs from 'dayjs';

import { BusinessClassNameEnum, DateFrequencyEnum, FilterDateEnum, FilterDateNatureEnum, FilterTypeDateEnum, ProjectTypeEnum, ResponseStateEnum } from '../../enums';
import { DateFilterRequest } from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';

export interface DateFilterData {
  dateFilterBy: FilterDateEnum;
  typeDate: FilterTypeDateEnum;
  endDate: Date;
  startDate: Date;
  status?: ResponseStateEnum;
  frequency?: DateFrequencyEnum;
  filterNature?: FilterDateNatureEnum;
  dialogFilter?: boolean;
  secondaryDate?: boolean;
  projectType?: ProjectTypeEnum;
  hideFilterNature?: boolean;
  businessClassName?: BusinessClassNameEnum;
  dateTime?: boolean;
  hidePrefilling?: boolean;
  maxDate?: Date;
}

export namespace DateFilterData {
  export function mapToDateFilterData(startDate: string, endDate: string, dateFilterBy: string, typeDate: string, state?: string): DateFilterData {
    return (
      startDate &&
      endDate &&
      dateFilterBy && {
        startDate: dayjs.unix(+startDate).toDate(),
        endDate: dayjs.unix(+endDate).toDate(),
        dateFilterBy: FilterDateEnum.convertFromParamsValue.getValue(dateFilterBy),
        typeDate: FilterTypeDateEnum.convertFromParamsValue.getValue(typeDate),
        status: ResponseStateEnum.convertFromParamsValue.getValue(state),
      }
    );
  }

  export function mapToFrequencyDateFilter(startDate: string, endDate: string, frequency: string): DateFilterData {
    return (frequency &&
      startDate &&
      endDate && {
        startDate: dayjs.unix(+startDate).toDate(),
        endDate: dayjs.unix(+endDate).toDate(),
        frequency: DateFrequencyEnum.convertFromParamsValue.getValue(frequency),
      }) as DateFilterData;
  }

  export function mapToRequestApiValue(value: DateFilterData): DateFilterRequest {
    return {
      dateFilterBy: FilterDateEnum.convertToApiValue.getValue(value.dateFilterBy),
      startDate: DateUtils.toZonedDateTime(dayjs(value.startDate).startOf('day').toDate()),
      endDate: DateUtils.toZonedDateTime(dayjs(value.endDate).endOf('day').toDate()),
    };
  }
}
