import { IconData } from '../../../modules/shared/interfaces/icon-data';
import { RouteIdEnum } from '../router-enums/route.enum';
import { SfxMap } from '../../utils/enum-utils';

export namespace BreadCrumbEnum {
  export const iconData: SfxMap<RouteIdEnum, IconData> = new SfxMap<RouteIdEnum, IconData>(
    [
      [RouteIdEnum.ProjectDashboard, { name: 'folder-open' }],
      [RouteIdEnum.Projects, { name: 'folder-open' }],
      [RouteIdEnum.Library, { name: 'shopping-basket' }],
      [RouteIdEnum.Contributors, { name: 'users' }],
      [RouteIdEnum.Custom, { name: 'plus-square' }],
      [RouteIdEnum.Settings, { name: 'cog' }],
      [RouteIdEnum.FlowContent, { name: 'sitemap', iconClassName: 'fa-rotate-270' }],
      [RouteIdEnum.FlowManagement, { name: 'sitemap', iconClassName: 'fa-rotate-270' }],
      [RouteIdEnum.FlowManagementList, { name: 'list', prefix: 'fas' }],
      [RouteIdEnum.FlowManagementMap, { name: 'map-marked-alt' }],
      [RouteIdEnum.FlowManagementKanban, { name: 'columns' }],
      [RouteIdEnum.FlowManagementPlan, { name: 'map' }],
      [RouteIdEnum.Profile, { name: 'user-circle' }],
      [RouteIdEnum.ProfileInformation, { name: 'passport' }],
      [RouteIdEnum.ProfileResetPassword, { name: 'unlock-alt' }],
      [RouteIdEnum.LibraryRisks, { name: 'exclamation-triangle' }],
      [RouteIdEnum.LibraryEquipments, { name: 'tools' }],
      [RouteIdEnum.LibraryForms, { name: 'list-alt' }],
      [RouteIdEnum.DashboardForms, { name: 'list-alt' }],
      [RouteIdEnum.DashboardDocuments, { name: 'file-invoice' }],
      [RouteIdEnum.LibraryDocuments, { name: 'file-invoice' }],
      [RouteIdEnum.LibraryTags, { name: 'barcode' }],
      [RouteIdEnum.LibraryObjectManagement, { name: 'object-group' }],
      [RouteIdEnum.LibraryObjectTree, { name: 'project-diagram' }],
      [RouteIdEnum.LibraryObjectList, { name: 'table' }],
      [RouteIdEnum.LibraryObjectMap, { name: 'map-marked-alt' }],
      [RouteIdEnum.ContributorUsers, { name: 'user-tie' }],
      [RouteIdEnum.ContributorOperators, { name: 'user-astronaut' }],
      [RouteIdEnum.DashboardTablets, { name: 'tablet-alt' }],
      [RouteIdEnum.ContributorTablets, { name: 'tablet-alt' }],
      [RouteIdEnum.ProfileTablet, { name: 'tablet-alt' }],
      [RouteIdEnum.DashboardFlowReferential, { name: 'pencil-ruler' }],
      [RouteIdEnum.DashboardLists, { name: 'file-alt', prefix: 'far' }],
      [RouteIdEnum.DashboardScheduleTasks, { name: 'calendar', prefix: 'far' }],
      [RouteIdEnum.DashboardContributors, { name: 'user-friends' }],
      [RouteIdEnum.DashboardMetrics, { name: 'chart-line' }],
      [RouteIdEnum.DashboardSignatureList, { name: 'file-signature' }],
      [RouteIdEnum.DashboardSignatureFlow, { name: 'sitemap', prefix: 'fas' }],
      [RouteIdEnum.DashboardSignatureTemporal, { name: 'clock' }],
      [RouteIdEnum.DashboardFieldFeedback, { name: 'comments' }],
      [RouteIdEnum.DashboardClosingFolder, { name: 'archive' }],
      [RouteIdEnum.SettingsSections, { name: 'archive' }],
      [RouteIdEnum.DashboardInformation, { name: 'id-card' }],
      [RouteIdEnum.DashboardCategories, { name: 'tags' }],
      [RouteIdEnum.DefaultCategories, { name: 'tags' }],
      [RouteIdEnum.SettingsAccount, { name: 'building', prefix: 'far' }],
      [RouteIdEnum.SettingsClientBook, { name: 'address-book', prefix: 'far' }],
      [RouteIdEnum.SettingsFamilies, { name: 'tag' }],
      [RouteIdEnum.CustomStatus, { name: 'flag' }],
      [RouteIdEnum.SettingsReferences, { name: 'toolbox' }],
      [RouteIdEnum.SettingsCustomField, { name: 'project-diagram' }],
      [RouteIdEnum.SettingsGeneralAccount, { name: 'building' }],
      [RouteIdEnum.SettingsGeneralOrganization, { name: 'border-all' }],
      [RouteIdEnum.SettingsModules, { name: 'th-large' }],
      [RouteIdEnum.SettingsTrams, { name: 'file-code' }],
      [RouteIdEnum.SettingsWordingConfiguration, { name: 'file-signature' }],
      [RouteIdEnum.SettingsBackups, { name: 'database' }],
      [RouteIdEnum.SettingsLogsPush, { name: 'database' }],
      [RouteIdEnum.SettingsKpis, { name: 'chart-bar' }],
      [RouteIdEnum.SettingsTrash, { name: 'trash-alt' }],
      [RouteIdEnum.SettingsCustomFile, { name: 'sticky-note', prefix: 'far' }],
      [RouteIdEnum.SettingsCustomCell, { name: 'border-all' }],
      [RouteIdEnum.contributorExternalAPI, { name: 'key' }],
      [RouteIdEnum.ExternalAccessApiV1, { name: 'key' }],
      [RouteIdEnum.ExternalAccessApiV2, { name: 'key' }],
      [RouteIdEnum.ProjectFolder, { name: 'folder-open' }],
      [RouteIdEnum.ContributorExternalUsers, { name: 'user-secret' }],
      [RouteIdEnum.SettingsCompanies, { name: 'people-arrows' }],
    ],
    { name: '' },
  );

  export const iconClass: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>(
    [
      [RouteIdEnum.Projects, 'entities'],
      [RouteIdEnum.Library, 'library'],
      [RouteIdEnum.Contributors, 'contributors'],
      [RouteIdEnum.Settings, 'settings'],
      [RouteIdEnum.Profile, 'user-account'],
      [RouteIdEnum.DashboardSignatureFlow, 'sfx-flow-icon fa-rotate-270'],
      [RouteIdEnum.FlowContent, 'default fa-rotate-270'],
      [RouteIdEnum.ProjectFolder, ' project-referential'],
    ],
    'default',
  );

  export const label: SfxMap<RouteIdEnum, string> = new SfxMap<RouteIdEnum, string>(
    [
      [RouteIdEnum.Projects, 'breadcrumb.projects.title'],
      [RouteIdEnum.Library, 'breadcrumb.library.title'],
      [RouteIdEnum.Contributors, 'breadcrumb.contributors.title'],
      [RouteIdEnum.ContributorUsers, 'breadcrumb.contributors.users.title'],
      [RouteIdEnum.ContributorExternalUsers, 'breadcrumb.contributors.externalUsers.title'],
      [RouteIdEnum.ContributorOperators, 'breadcrumb.contributors.operators.title'],
      [RouteIdEnum.ContributorTablets, 'breadcrumb.contributors.tablets.title'],
      [RouteIdEnum.contributorExternalAPI, 'breadcrumb.contributors.external.title'],
      [RouteIdEnum.ExternalAccessApiV1, 'breadcrumb.contributors.externalV1.title'],
      [RouteIdEnum.ExternalAccessApiV2, 'breadcrumb.contributors.externalV2.title'],
      [RouteIdEnum.Custom, 'breadcrumb.custom.title'],
      [RouteIdEnum.Settings, 'breadcrumb.settings.title'],
      [RouteIdEnum.FlowManagement, 'breadcrumb.dashboard.flows.title'],
      [RouteIdEnum.FlowManagementList, 'breadcrumb.dashboard.flows.viewList'],
      [RouteIdEnum.FlowManagementPlan, 'breadcrumb.dashboard.flows.viewPlan'],
      [RouteIdEnum.FlowManagementMap, 'breadcrumb.dashboard.flows.viewMap'],
      [RouteIdEnum.FlowManagementKanban, 'breadcrumb.dashboard.flows.viewKanban'],
      [RouteIdEnum.ProjectDashboard, 'breadcrumb.dashboard.title'],
      [RouteIdEnum.DashboardDocuments, 'breadcrumb.dashboard.documents.title'],
      [RouteIdEnum.DashboardForms, 'breadcrumb.dashboard.forms.title'],
      [RouteIdEnum.DashboardFlowReferential, 'breadcrumb.dashboard.referential.title'],
      [RouteIdEnum.DashboardLists, 'breadcrumb.dashboard.lists.title'],
      [RouteIdEnum.DashboardScheduleTasks, 'breadcrumb.dashboard.scheduleTasks.title'],
      [RouteIdEnum.DashboardContributors, 'breadcrumb.dashboard.contributors.title'],
      [RouteIdEnum.DashboardTablets, 'breadcrumb.dashboard.tablets.title'],
      [RouteIdEnum.DashboardMetrics, 'breadcrumb.dashboard.advancement.title'],
      [RouteIdEnum.DashboardSignatureList, 'breadcrumb.dashboard.signatures.viewList'],
      // [RouteIdEnum.DashboardSignatureTemporal, 'breadcrumb.dashboard.signatures.viewTemporal'], // REVIEW: To be Hidden on december version
      [RouteIdEnum.DashboardFieldFeedback, 'breadcrumb.dashboard.fieldFeedback.title'],
      [RouteIdEnum.DashboardClosingFolder, 'breadcrumb.dashboard.closingFolder.title'],
      [RouteIdEnum.DashboardInformation, 'breadcrumb.dashboard.information.title'],
      [RouteIdEnum.DashboardCategories, 'breadcrumb.dashboard.categories.title'],
      [RouteIdEnum.Profile, 'breadcrumb.profile.title'],
      [RouteIdEnum.ProfileInformation, 'breadcrumb.profile.information'],
      [RouteIdEnum.ProfileResetPassword, 'breadcrumb.profile.resetPassword'],
      [RouteIdEnum.ProfileTablet, 'breadcrumb.profile.tablet'],
      [RouteIdEnum.LibraryRisks, 'breadcrumb.library.risks.title'],
      [RouteIdEnum.LibraryTags, 'breadcrumb.library.tags.title'],
      [RouteIdEnum.LibraryEquipments, 'breadcrumb.library.equipments.title'],
      [RouteIdEnum.LibraryDocuments, 'breadcrumb.library.documents.title'],
      [RouteIdEnum.LibraryForms, 'breadcrumb.library.forms.title'],
      [RouteIdEnum.LibraryObjectManagement, 'breadcrumb.library.objects.title'],
      [RouteIdEnum.LibraryObjectTree, 'breadcrumb.library.objects.viewTree'],
      [RouteIdEnum.LibraryObjectList, 'breadcrumb.library.objects.viewList'],
      [RouteIdEnum.LibraryObjectMap, 'breadcrumb.library.objects.viewMap'],
      [RouteIdEnum.SettingsAccount, 'breadcrumb.settings.account.title'],
      [RouteIdEnum.SettingsClientBook, 'breadcrumb.settings.clientBook.title'],
      [RouteIdEnum.SettingsFamilies, 'breadcrumb.settings.families.title'],
      [RouteIdEnum.DefaultCategories, 'breadcrumb.settings.categories.title'],
      [RouteIdEnum.CustomStatus, 'breadcrumb.settings.statuses.title'],
      [RouteIdEnum.SettingsReferences, 'breadcrumb.settings.references.title'],
      [RouteIdEnum.SettingsCustomField, 'breadcrumb.settings.attributes.title'],
      [RouteIdEnum.SettingsSections, 'breadcrumb.settings.folders.title'],
      [RouteIdEnum.SettingsGeneralAccount, 'breadcrumb.settings.general.title'],
      [RouteIdEnum.SettingsGeneralOrganization, 'breadcrumb.settings.general.organization'],
      [RouteIdEnum.SettingsModules, 'breadcrumb.settings.modules.title'],
      [RouteIdEnum.SettingsTrams, 'breadcrumb.settings.trams.title'],
      [RouteIdEnum.SettingsWordingConfiguration, 'breadcrumb.settings.tracking.title'],
      [RouteIdEnum.SettingsBackups, 'breadcrumb.settings.backups.title'],
      [RouteIdEnum.SettingsLogsPush, 'breadcrumb.settings.logsPush.title'],
      [RouteIdEnum.SettingsKpis, 'breadcrumb.settings.kpis.title'],
      [RouteIdEnum.SettingsTrash, 'breadcrumb.settings.trash.title'],
      [RouteIdEnum.SettingsCustomFile, 'breadcrumb.settings.customFile.title'],
      [RouteIdEnum.SettingsCustomCell, 'breadcrumb.settings.customCell.title'],
      [RouteIdEnum.SettingsCompanies, 'breadcrumb.settings.companies'],
    ],
    'Unknown',
  );
}
