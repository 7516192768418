import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, withLatestFrom, map } from 'rxjs/operators';

import { intersection } from 'lodash';

import { SfxMap } from '../../../core/utils/enum-utils';
import { sfxPermissionObject, PermissionActionEnum, UserRightEnum, UserRoleEnum, RouteIdEnum, UserRoleType } from '../../../core/enums';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private permissions$ = new BehaviorSubject<sfxPermissionObject>(new SfxMap());
  private userRights$ = new BehaviorSubject<UserRightEnum[]>([]);
  private userRoles$ = new BehaviorSubject<UserRoleType>({});

  public loadPermissions(): void {
    this.permissions$.next(PermissionActionEnum.allPermissions);
  }

  public flushPermissions(): void {
    this.permissions$.next(new SfxMap());
  }

  public flushRoles(): void {
    this.userRights$.next([]);
    this.userRoles$.next({});
  }

  public addUserRights(roles: UserRightEnum[]): void {
    this.userRights$.next([...this.userRights$.value, ...roles]);
  }

  public addUserRoles(uuidEntity: string, roles: UserRoleEnum[]): void {
    this.userRoles$.next({ ...this.userRoles$.value, [uuidEntity]: roles });
  }

  public checkUserRight(routeId: RouteIdEnum, permissionAction?: PermissionActionEnum): Observable<boolean> {
    return this.userRights$.pipe(
      filter(roles => !!roles.length),
      withLatestFrom(this.permissions$.pipe(map(permissions => permissions.getValue(routeId)?.getValue(permissionAction || PermissionActionEnum.Read)))),
      map(([rights, permissions]) => !permissions || permissions.includes(UserRightEnum.Any) || !!intersection(permissions, rights).length),
    );
  }

  public checkUserRole(routeId: RouteIdEnum, uuidEntityContext: string, permissionAction: PermissionActionEnum): Observable<boolean> {
    return this.userRoles$.pipe(
      withLatestFrom(
        this.userRights$,
        this.permissions$.pipe(map(permissions => (permissions.has(routeId) ? permissions.getValue(routeId).getValue(permissionAction) : undefined))),
      ),
      map(([userRoles, userRights, permissions]) => {
        if (!userRights || !permissions) {
          return true;
        }
        const noContractorRight = userRights.includes(UserRightEnum.UserContractor) && permissions.includes(UserRoleEnum.NotContractor);
        const hasAnyRight = permissions.includes(UserRightEnum.Any) || !!intersection(permissions, [...userRights, ...(userRoles[uuidEntityContext] || [])]).length;

        return hasAnyRight && !noContractorRight;
      }),
    );
  }
}
