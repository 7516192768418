import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { Dictionary } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApplicationFileForUpdateDTO } from '../models/application-file/application-file-for-update';

import {
  ISearchParams,
  mapErrorResponseToErrorResponseData,
  HttpParamsType,
  HttpMethod,
  RequestOptions,
  LazyLoadResponse,
  totalCountKey,
  filteredTotalCountKey,
  deepFilteredTotalCountKey,
  ApplicationFileForCreate,
  disabledFilteredTotalCountKey,
} from '../models';
import { FileTypeEnum } from '../enums/utils/file-type.enum';
import { LocalStorageEnum } from '../enums/local-storage.enum';

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({ providedIn: 'root' })
export class BaseHttpService {
  constructor(private http: HttpClient, private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) {}

  public get<T>(url: string, paramsData?: HttpParamsType): Observable<T> {
    return this.requestAction<T>('GET', url, { params: this.parseQuery(paramsData?.queryParams) });
  }

  public post<T>(url: string, paramsData: HttpParamsType): Observable<T> {
    return this.requestAction<T>('POST', url, {
      params: this.parseQuery(paramsData?.queryParams),
      body: paramsData?.body,
    });
  }

  public getLazy<T, R>(url: string, queryParams: ISearchParams, mapper?: (item: T) => R): Observable<LazyLoadResponse<R[]>> {
    const headers = this.getHeaders();
    const options: RequestOptions = { params: this.parseQuery(queryParams) };

    return this.http
      .request<{ data: T[] }>('GET', url, { ...options, headers, observe: 'response' })
      .pipe(
        map(response => ({ payload: response.body?.data.map(item => mapper(item)), totalCount: +response.headers.get(filteredTotalCountKey) })),
        catchError((errorResponse: HttpErrorResponse) => throwError(() => mapErrorResponseToErrorResponseData(errorResponse))),
      );
  }

  public lazyLoad<T>(url: string, paramsData: HttpParamsType): Observable<LazyLoadResponse<T>> {
    const headers = this.getHeaders();
    const options: RequestOptions = {
      params: this.parseQuery(paramsData?.queryParams),
      body: paramsData?.body,
    };

    return this.http
      .request<T>('POST', url, { ...options, headers, observe: 'response' })
      .pipe(
        map(response => ({
          payload: response.body,
          totalCount: +response.headers.get(totalCountKey),
          filteredTotalCount: +response.headers.get(filteredTotalCountKey),
          deepFilteredTotalCount: +response.headers.get(deepFilteredTotalCountKey),
          disabledFilteredTotalCount: +response.headers.get(disabledFilteredTotalCountKey),
        })),
        catchError((errorResponse: HttpErrorResponse) => throwError(() => mapErrorResponseToErrorResponseData(errorResponse))),
      );
  }

  public put<T>(url: string, paramsData: HttpParamsType): Observable<T> {
    return this.requestAction<T>('PUT', url, {
      params: this.parseQuery(paramsData?.queryParams),
      body: paramsData?.body as T,
    });
  }

  public patch<T>(url: string, paramsData: HttpParamsType): Observable<T> {
    return this.requestAction<T>('PATCH', url, {
      params: this.parseQuery(paramsData?.queryParams),
      body: paramsData?.body as T,
    });
  }

  public _delete<T>(url: string, paramsData?: HttpParamsType): Observable<T> {
    return this.requestAction<T>('DELETE', url, {
      params: this.parseQuery(paramsData?.queryParams),
    });
  }

  public uploadFile<T>(url: string, fileData: ApplicationFileForCreate): Observable<T> {
    const formData: FormData = new FormData();
    Object.keys(fileData || {}).forEach(key => fileData[key] && formData.append(key, fileData[key]));

    return this.http
      .request<T>('POST', url, { headers: this.getHeaders(), body: formData })
      .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => mapErrorResponseToErrorResponseData(errorResponse))));
  }

  public uploadFiles<T>(url: string, fileData: ApplicationFileForCreate, files: File[]): Observable<T> {
    const formData: FormData = new FormData();
    Object.keys(fileData || {}).forEach(key => fileData[key] && formData.append(key, fileData[key]));
    files = files.map(
      file =>
        new File(
          [file],
          file.name.replace(file.name.split('.').pop(), value => value.toLocaleLowerCase()),
        ),
    );
    files.forEach(file => formData.append('myfile', file));

    return this.http
      .request<T>('POST', url, { headers: this.getHeaders(), body: formData })
      .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => mapErrorResponseToErrorResponseData(errorResponse))));
  }

  public updateFile<T>(url: string, fileData: ApplicationFileForUpdateDTO): Observable<T> {
    const formData: FormData = new FormData();
    Object.keys(fileData || {}).forEach(key => fileData[key] && formData.append(key, fileData[key]));

    return this.http
      .request<T>('PUT', url, { headers: this.getHeaders(), body: formData })
      .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => mapErrorResponseToErrorResponseData(errorResponse))));
  }

  public previewFile(url: string, params?: ISearchParams, fileType: FileTypeEnum = FileTypeEnum.Pdf): Observable<Blob> {
    return this.http.request('GET', url, { headers: this.getHeaders(), params, responseType: 'arraybuffer', observe: 'response' }).pipe(
      map(({ body: blobPart }) => {
        const blob = new Blob([blobPart], { type: FileTypeEnum.mapToApiValue.getValue(fileType) });
        const windowUrl = window.URL.createObjectURL(blob);
        window.open(windowUrl);

        return blob;
      }),
      catchError((crashResponse: HttpErrorResponse) => this.decodeArraybufferCrash(crashResponse)),
    );
  }

  public downloadFile(url: string, params?: ISearchParams, fileType?: FileTypeEnum, customFileName?: string): Observable<Blob> {
    return this.http.request('GET', url, { headers: this.getHeaders(), params, responseType: 'arraybuffer', observe: 'response' }).pipe(
      map(response => this.mapFileResponse(response, fileType, customFileName)),
      catchError((crashResponse: HttpErrorResponse) => this.decodeArraybufferCrash(crashResponse)),
    );
  }

  // Note: file type calculated from response header
  public downloadGeneratedDocument(url: string): Observable<Blob> {
    return this.http.request('GET', url, { headers: this.getHeaders(), responseType: 'arraybuffer', observe: 'response' }).pipe(
      map(response => this.mapFileResponse(response, FileTypeEnum.mapFromApiValue.getValue(response.headers.get('content-type')))),
      catchError((crashResponse: HttpErrorResponse) => this.decodeArraybufferCrash(crashResponse)),
    );
  }

  public exportFile<T>(url: string, body?: T, fileType?: FileTypeEnum, customFileName?: string): Observable<Blob> {
    return this.http.request('POST', url, { headers: this.getHeaders(fileType), body, responseType: 'arraybuffer', observe: 'response' }).pipe(
      map(response => this.mapFileResponse(response, fileType, customFileName)),
      catchError((crashResponse: HttpErrorResponse) => this.decodeArraybufferCrash(crashResponse)),
    );
  }

  /**
   * Get JWT token form storage
   */
  public getJwtAccessToken(): string {
    return this.localStorage.retrieve(LocalStorageEnum.AuthToken) || this.sessionStorage.retrieve(LocalStorageEnum.SessionAuthToken) || '';
  }

  /**
   * Set request action headers
   * @param  multiPart
   */
  private getHeaders(fileType?: FileTypeEnum): Dictionary<string> {
    // tslint:disable-next-line:object-literal-key-quotes
    let headers: Dictionary<string> = {
      Accept: FileTypeEnum.mapToApiValue.getValue(fileType) || 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    };

    const token = this.getJwtAccessToken();

    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    return headers;
  }

  /**
   * Parse query params as URLSearchParams object
   * @param query
   */
  private parseQuery(query: ISearchParams): HttpParams {
    return query && new HttpParams({ fromObject: query, encoder: new CustomEncoder() });
  }

  /**
   * Request action based on request options Args params
   * @param method
   * @param url
   * @param options
   * @param authRequired
   * @param multiPart
   */
  private requestAction<T>(method: HttpMethod, url: string, options: RequestOptions): Observable<T> {
    return this.http
      .request<T>(method, url, { ...options, headers: this.getHeaders() })
      .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => mapErrorResponseToErrorResponseData(errorResponse))));
  }

  private mapFileResponse(response: HttpResponse<ArrayBuffer>, fileType?: FileTypeEnum, customFileName?: string): Blob {
    const blob = new Blob([response.body], { type: FileTypeEnum.mapToApiValue.getValue(fileType) });
    const linkElement = document.createElement('a');
    linkElement.href = window.URL.createObjectURL(blob);
    linkElement.download = customFileName || response?.headers?.get('filename') || `${new Date().getTime()}${FileTypeEnum.acceptType.getValue(fileType)}`;
    document.body.appendChild(linkElement);
    linkElement.click();
    window.URL.revokeObjectURL(linkElement.href);
    document.body.removeChild(linkElement);

    return blob;
  }

  private decodeArraybufferCrash(crashResponse: HttpErrorResponse): Observable<Blob> {
    const errorData = { ...crashResponse };
    try {
      const decoder = new TextDecoder('utf-8');
      errorData.error = JSON.parse(decoder.decode(crashResponse.error));
    } catch {
      return throwError(() => mapErrorResponseToErrorResponseData(errorData));
    }

    return throwError(() => mapErrorResponseToErrorResponseData(errorData));
  }
}
