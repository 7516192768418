import { LabelFamilyLightData } from '../label-family/label-family-light-data';
import { BaseQuery, initializeQueries } from '../utils/base-query.model';
import { PageTeamRoleTabletRequestDTO } from '../../generated/models/pageTeamRoleTabletRequestDTO';
import { TeamRoleDeviceSummaryResponseDTO } from '../../generated/models/teamRoleDeviceSummaryResponseDTO';
import { ProjectLightDTO } from '../../generated/models/models';
import { SortFilter } from '../utils/sort-filter';

import { InnerSortDirectionEnum, UserNatureEnum, TabletStatusEnum, UserStatusEnum } from '../../enums';

export interface TabletSummaryData {
  uuidOrganization: string;
  uuidEntity: string;
  batteryLevel: string;
  latitude: string;
  longitude: string;
  status: TabletStatusEnum;
  nature: UserNatureEnum;
  refIntern: string;
  refExtern: string;
  uniqueDeviceId: string;
  labelFamily: LabelFamilyLightData;
  deviceUuidEntity: string;
  project: ProjectLightDTO;
  userStatus: UserStatusEnum;
  frozen?: boolean;
  applicabilityName: string;
  companyName?: string;
}

export namespace TabletSummaryData {
  const sortFields = ['group', 'refExtern', 'batteryLevel', 'deviceStatus', 'position'];

  export interface QueryRequest extends BaseQuery<TabletSummaryData> {
    status?: TabletStatusEnum;
    nature?: UserNatureEnum;
    applicabilities?: string[];
    families: string[];
  }

  export function mapFromApiValue(data: TeamRoleDeviceSummaryResponseDTO): TabletSummaryData {
    return {
      uuidOrganization: data.uuidOrganization,
      uuidEntity: data.uuidEntity,
      batteryLevel: data.batteryLevel,
      latitude: data.latitude,
      longitude: data.longitude,
      status: data.status && TabletStatusEnum.convertFromApiValue.getValue(data.status),
      nature: data.nature && UserNatureEnum.convertFromApiValue.getValue(data.nature),
      refIntern: data.refIntern,
      uniqueDeviceId: data.uniqueDeviceId,
      refExtern: data.refExtern,
      labelFamily: (data.labelFamily && LabelFamilyLightData.mapFromApiValue(data.labelFamily)) || LabelFamilyLightData.defaultData,
      deviceUuidEntity: data.deviceUuidEntity,
      project: data.project,
      userStatus: UserStatusEnum.convertFromApiValue.getValue(data['userStatus']),
      frozen: data.alreadyImported,
      applicabilityName: data['applicabilityName'] || '',
      companyName: data?.company?.name,
    };
  }

  export function toString(teamRole: TabletSummaryData): string {
    return teamRole.project && (teamRole.applicabilityName ? `${teamRole.project.designation} - ${teamRole.applicabilityName}` : teamRole.project.designation);
  }

  export function mapToPageRequestApiValue(queries: QueryRequest, projectUuidEntity?: string): PageTeamRoleTabletRequestDTO {
    return {
      page: queries.page,
      size: queries.size,
      textSearch: queries.textSearch,
      labelFamiliesUuidEntity: queries.families,
      natures: (queries.nature && [UserNatureEnum.convertToApiValue.getValue(queries.nature)]) || [],
      statuses: (queries.status && [TabletStatusEnum.convertToApiValue.getValue(queries.status)]) || [],
      sort: sortFields.includes(queries?.sort?.key)
        ? { attribute: queries.sort.key, direction: InnerSortDirectionEnum.convertToApiValue.getValue(queries.sort.direction) }
        : undefined,
      projectUuidEntity,
      applicabilityUuides: queries.applicabilities,
    };
  }

  export function initializeQueryRequest(
    applicabilities: string[],
    families: string[],
    searchText: string,
    nature: string,
    status: string,
    sort: SortFilter<TabletSummaryData>,
  ): QueryRequest {
    return {
      ...initializeQueries<TabletSummaryData>(sort),
      textSearch: searchText || undefined,
      applicabilities: (applicabilities || []).length ? applicabilities : undefined,
      families: (families || []).length ? families : undefined,
      nature: UserNatureEnum.convertFromParamsValue.getValue(nature) || undefined,
      status: TabletStatusEnum.convertFromParamsValue.getValue(status) || undefined,
    };
  }
}
