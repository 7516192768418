import { createAction, props } from '@ngrx/store';

import {
  ApplicationEventLightData,
  BlockDocumentData,
  CheckExistedReferenceRequest,
  ContributorSummaryData,
  CustomColumnData,
  CustomColumnsConfigurationData,
  CustomFieldData,
  DocumentFlowSummaryData,
  EpsilonOutForUpdate,
  EpsilonOutSummaryData,
  ErrorResponseData,
  FlowAffectationData,
  FlowDetailsData,
  FlowForDuplicate,
  FlowForMove,
  FlowPgacDetails,
  LazyLoadResponse,
  PlanTeamRoleResponseData,
} from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';
import { GeoJsonSummaryData } from '../../../../../core/models/map/geo-json-summary';

import { FlowTeamRolesEnum, PopoverPositionEnum } from '../../../../../core/enums';
import { ContributorTableData } from '../../../../../core/models/contributors/contributor-table';

// Flow dialog actions
export const addFlow = createAction('[dFlowDialog] add new flow', props<{ flow: FlowDetailsData; closeModal?: boolean; nextTab?: string }>());
export const addFlowSuccess = createAction('[dFlowDialog] add new flow success', props<{ flowDetails: FlowDetailsData; closeModal?: boolean; nextTab?: string }>());
export const addFlowFail = createAction('[dFlowDialog] add new flow fail', props<DialogToasterData>());

export const updateFlow = createAction('[dFlowDialog] update flow', props<{ flow: FlowDetailsData; closeModal?: boolean; nextTab?: string; reloadCustomFields?: boolean }>());
export const updateFlowSuccess = createAction(
  '[dFlowDialog] update flow success',
  props<{ flowDetails: FlowDetailsData; initialFlowDetails: FlowDetailsData; close?: boolean; nextTab?: string }>(),
);
export const updateFlowFail = createAction('[dFlowDialog] update flow fail', props<DialogToasterData>());

export const saveFlowCustomFields = createAction('[dFlowDialog] save flow custom fields', props<{ customFields: CustomFieldData[]; closeModal?: boolean; nextTab?: string }>());
export const saveFlowCustomFieldsSuccess = createAction('[dFlowDialog] save flow custom fields success', props<{ closeModal?: boolean; nextTab?: string }>());
export const saveFlowCustomFieldsFail = createAction('[dFlowDialog] save flow custom fields fail', props<DialogToasterData>());

export const getFlowDetailsSuccess = createAction('[dFlowDialog] get flow details success', props<{ flowDetails: FlowDetailsData }>());
export const getFlowDetailsFail = createAction('[dFlowDialog] get flow details fail', props<DialogToasterData>());

export const saveAll = createAction(
  '[dFlowDialog] save all (flow and custom fields)',
  props<{ flow: FlowDetailsData; initialFlow: FlowDetailsData; customFields: CustomFieldData[]; initialCustomFields: CustomFieldData[]; close?: boolean; nextTab?: string }>(),
);

export const loadFlowCustomFields = createAction('[dFlowDialog] load flow custom fields', props<{ flow: FlowDetailsData }>());
export const loadFlowCustomFieldsSuccess = createAction('[dFlowDialog] load flow custom fields success', props<{ customFields: CustomFieldData[] }>());
export const loadFlowCustomFieldsFail = createAction('[dFlowDialog] load flow custom fields fail', props<DialogToasterData>());

export const initializeFlowDetails = createAction('[dFlowDialog] initialize flow details', props<{ flowDetails: FlowDetailsData }>());
export const initializeFlowCustomFields = createAction('[dFlowDialog] initialize flow custom fields', props<{ customFields: CustomFieldData[] }>());

export const saveChangesFlowDialog = createAction('[dFlowDialog] save changes flow dialog', props<{ flow: FlowDetailsData; customFields: CustomFieldData[]; nextTab: string }>());
export const createFlow = createAction('[dFlowDialog] create flow', props<{ flow: FlowDetailsData; customFields: CustomFieldData[]; nextTab: string }>());
export const createFlowFail = createAction('[dFlowDialog] create flow fail', props<DialogToasterData>());

export const navigateThroughFlows = createAction(
  '[dFlowDialog] navigate through flows',
  props<{ uuidEntity: string; step: number; flowDetails: FlowDetailsData; customFields: CustomFieldData[]; formsValidity: boolean }>(),
);

export const navigateThroughFlowsSuccess = createAction('[dFlowDialog] Navigate through flows success', props<{ uuidEntity: string; step: number }>());
export const resetFlowDialogState = createAction('[dFlowDialog] Reset flow dialog state');

export const checkReferenceGenerated = createAction('[dFlowDialog] check reference generated', props<CheckExistedReferenceRequest>());
export const checkReferenceGeneratedFail = createAction('[dFlowDialog] check reference generated fail', props<DialogToasterData>());
export const checkReferenceGeneratedSuccess = createAction('[dFlowDialog] check reference generated success', props<{ hasReferenceGenerated: boolean; reset: boolean }>());

export const loadFlowDocuments = createAction('[dFlowDialog] load flow documents', props<{ flowDetails: FlowDetailsData }>());
export const loadFlowDocumentsFail = createAction('[dFlowDialog] load flow documents fail', props<DialogToasterData>());
export const loadFlowDocumentsSuccess = createAction('[dFlowDialog] load flow documents success', props<{ flowDocumentBlocks: BlockDocumentData[] }>());

export const generateFlowDocuments = createAction('[dFlowDialog] generate flow documents', props<{ flowDocumentBlocks: BlockDocumentData[]; flowDetails: FlowDetailsData }>());
export const generateFlowDocumentsFail = createAction('[dFlowDialog] generate flow documents fail', props<DialogToasterData>());
export const generateFlowDocumentsSuccess = createAction('[dFlowDialog] generate flow documents success', props<{ flowDocuments: DocumentFlowSummaryData[] }>());

export const viewFlowDocument = createAction('[dFlowDialog] view flow document', props<{ versionUuidEntity: string }>());
export const viewFlowDocumentFail = createAction('[dFlowDialog] view flow document fail', props<DialogToasterData>());
export const viewFlowDocumentSuccess = createAction('[dFlowDialog] view flow document success');

export const loadPgacDuplicateList = createAction('[dFlowDialog] Load duplicate pgac list');
export const loadPgacDuplicateListSuccess = createAction(
  '[dFlowDialog] Load duplicate pgac list success',
  props<{ rows: EpsilonOutSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadPgacDuplicateListListNextPage = createAction('[dFlowDialog] Load duplicate pgac list next page');
export const loadPgacDuplicateListFails = createAction('[dFlowDialog] Load duplicate pgac list fails', props<DialogToasterData>());

export const resetEpsilonState = createAction('[dFlowDialog] reset epsilon state', props<{ epsilonOutUuidEntity: string }>());
export const resetEpsilonStateSuccess = createAction('[dFlowDialog] reset epsilon state success', props<EpsilonOutSummaryData>());
export const resetEpsilonStateFail = createAction('[dFlowDialog] reset epsilon state fail', props<DialogToasterData>());

export const rejectEpsilonOutPopover = createAction(
  '[dFlowDialog] reject epsilon out popover',
  props<{ origin: HTMLElement; position: PopoverPositionEnum; epsilon: EpsilonOutForUpdate }>(),
);

export const rejectEpsilonOut = createAction('[dFlowDialog] reject epsilon out', props<{ epsilon: EpsilonOutForUpdate }>());
export const rejectEpsilonOutSuccess = createAction('[dFlowDialog] reject epsilon out success', props<{ epsilon: EpsilonOutSummaryData }>());
export const rejectEpsilonOutFail = createAction('[dFlowDialog] reject epsilon out fail', props<DialogToasterData>());

export const activateEpsilonOut = createAction('[dFlowDialog] activate epsilon out', props<{ uuidEntity: string }>());
export const activateEpsilonOutSuccess = createAction('[dFlowDialog] activate epsilon out success', props<{ epsilon: EpsilonOutSummaryData }>());
export const activateEpsilonOutFail = createAction('[dFlowDialog] activate epsilon out fail', props<DialogToasterData>());

export const loadHistoryListSuccess = createAction('[dFlowDialog] Load history list success', props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>());
export const loadHistoryListNextPage = createAction('[dFlowDialog] Load history list next page');
export const loadHistoryListFails = createAction('[dFlowDialog] Load history list fails', props<DialogToasterData>());

export const loadPgacDetails = createAction('[dFlowDialog] load pgac details');
export const loadPgacDetailsSuccess = createAction('[dFlowDialog] load pgac details Success', props<{ pgacDetails: FlowPgacDetails }>());
export const loadPgacDetailsFail = createAction('[dFlowDialog] load load pgac fail', props<DialogToasterData>());

export const updatePgacDetails = createAction('[dFlowDialog] update pgac details', props<{ pgacDetails: FlowPgacDetails; nextTab: string }>());
export const updatePgacDetailsSuccess = createAction('[dFlowDialog] update pgac details Success', props<{ pgacDetails: FlowPgacDetails; nextTab: string }>());
export const updatePgacDetailsFail = createAction('[dFlowDialog] update pgac details fail', props<ErrorResponseData>());

export const pastePgacDetails = createAction('[dFlowDialog] paste pgac details', props<{ refSourceFlow: string; refDestinationFlow: string }>());
export const pastePgacDetailsSuccess = createAction('[dFlowDialog] paste pgac details Success');
export const pastePgacDetailsFail = createAction('[dFlowDialog] paste pgac details fail', props<ErrorResponseData>());

export const loadFlowDetailsFail = createAction('[dFlowDialog] load flow details fail', props<DialogToasterData>());

export const initializeDuplicateFlow = createAction('[dFlowDialog] initialize duplicate flow', props<{ duplicateFlow: FlowForDuplicate }>());
export const duplicateFlow = createAction('[dFlowDialog] duplicate flow', props<{ flow: FlowForDuplicate }>());
export const duplicateFlowFail = createAction('[dFlowDialog] duplicate flow fail', props<DialogToasterData>());
export const duplicateFlowSuccess = createAction('[dFlowDialog] duplicate flow success');

export const initializeMoveFlow = createAction('[dFlowDialog] initialize move flow', props<{ moveFlow: FlowForMove }>());
export const moveFlow = createAction('[dFlowDialog] move flow', props<{ flow: FlowForDuplicate }>());
export const moveFlowFail = createAction('[dFlowDialog] move flow fail', props<DialogToasterData>());
export const moveFlowSuccess = createAction('[dFlowDialog] move flow success');

// Geo Json modal actions
export const loadGeoJsonSummaryDataElements = createAction('[dFlowDialog] load geo json elements');
export const loadGeoJsonSummaryDataElementsSuccess = createAction('[dFlowDialog] load geo json  elements Success', props<{ geoJsonSummaryDataElements: GeoJsonSummaryData[] }>());
export const loadGeoJsonSummaryDataElementsFail = createAction('[dFlowDialog] load geo json  elements fail', props<DialogToasterData>());
export const addGeoJson = createAction('[dFlowDialog] add Geo Json');
export const addGeoJsonFail = createAction('[dFlowDialog] add Geo Json fail', props<DialogToasterData>());
export const addGeoJsonSuccess = createAction('[dFlowDialog] add Geo Json success');
export const deleteGeoJson = createAction('[dFlowDialog] delete Geo Json', props<{ geoJsonSummaryData: GeoJsonSummaryData }>());
export const deleteGeoJsonSuccess = createAction('[dFlowDialog] delete Geo Json success', props<{ geoJsonSummaryData: GeoJsonSummaryData }>());
export const deleteGeoJsonFail = createAction('[dFlowDialog] delete geo json fail', props<DialogToasterData>());
export const updateGeoJson = createAction('[dFlowDialog] update Geo Json', props<{ uuidEntity: string; color: string; enabled: boolean }>());
export const updateGeoJsonSuccess = createAction('[dFlowDialog] update Geo Json success');
export const updateGeoJsonFail = createAction('[dFlowDialog] update Geo Json fail', props<DialogToasterData>());
export const deactivateGeoJson = createAction('[dFlowDialog] deactivate geo json', props<{ geoJsonSummaryData: GeoJsonSummaryData }>());
export const deactivateGeoJsonSuccess = createAction('[dFlowDialog] deactivate geo json success');
export const deactivateGeoJsonFail = createAction('[dFlowDialog] deactivate geo json fail', props<DialogToasterData>());
export const openGeoFileUploaderPopover = createAction('[dFlowDialog] open geo file uploader popup', props<{ origin: HTMLElement }>());

export const openFlowFilterDatePopover = createAction('[FlowReorganizeDialog] open reorganize filter date modal', props<{ origin: HTMLElement }>());

// custom fields
export const loadCustomFieldsList = createAction('[dFlowDialog/API] Load customFields list');
export const loadCustomFieldsNextPage = createAction('[dFlowDialog/API] Load customFields next page');
export const loadCustomFieldsListSuccess = createAction(
  '[dFlowDialog/API] Load customFields list success',
  props<{ response: LazyLoadResponse<CustomColumnData[]>; reset?: boolean }>(),
);
export const loadCustomFieldsListFail = createAction('[dFlowDialog/API] Load customFields list fail', props<{ error: ErrorResponseData }>());

export const saveColumns = createAction('[dFlowDialog/LocalStorage] save custom columns', props<CustomColumnsConfigurationData>());

/** Team Role */
export const loadTeamRoleList = createAction('[dFlowDialog] load teamRole list');
export const loadTeamRoleListSuccess = createAction(
  '[dFlowDialog] load teamRole list success',
  props<{ planTeamRoles: PlanTeamRoleResponseData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const resetFlowTeamRoleDialogState = createAction('[dFlowDialog] Reset flow team role dialog state');

export const loadMoreTeamRoles = createAction('[dFlowDialog] load more team roles');

export const loadFlowDetails = createAction('[dFlowDialog] load Flow Details', props<{ flowUuidEntity: string }>());
export const loadFlowDetailsSuccess = createAction('[dFlowDialog] load Flow Details success', props<{ flowDetails: FlowDetailsData }>());

export const openAffectationsListDialog = createAction('[dFlowDialog] open affectations list dialog ', props<{ flowUuidEntity: string; flowTeamRole?: FlowTeamRolesEnum }>());
export const loadFlowAffectations = createAction('[dFlowDialog] load Flow affectations', props<{ flowUuidEntity: string }>());
export const loadFlowAffectationsSuccess = createAction('[dFlowDialog] load Flow affectations success', props<{ flowAffectations: FlowAffectationData }>());
export const loadFlowAffectationsFail = createAction('[dFlowDialog] load Flow affectations fail', props<DialogToasterData>());
export const deleteFlowAffectation = createAction('[dFlowDialog] delete Flow affectation', props<{ teamRoleUuidEntity: string; flowTeamRole: FlowTeamRolesEnum }>());
export const deleteFlowAffectationFail = createAction('[dFlowDialog] delete Flow affectation fail', props<DialogToasterData>());
export const setFlowAffectation = createAction('[dFlowDialog] set Flow affectation', props<{ teamRoleUuidEntities: string[]; flowTeamRole: FlowTeamRolesEnum }>());
export const setFlowAffectationFail = createAction('[dFlowDialog] set Flow affectation fail', props<DialogToasterData>());
// Flow Contributors Modal
export const loadFlowContributors = createAction('[dFlowDialog] Load the contributors', props<{ preselectedContributors: ContributorSummaryData[] }>());
export const loadMoreFlowContributors = createAction('[dFlowDialog] Load the next page of contributors');
export const saveModal = createAction(
  '[dFlowDialog] save the contributors who will be restricted',
  props<{ selectedContributors: string[]; contributors: ContributorSummaryData[] }>(),
);
export const loadFlowContributorsSuccess = createAction('[dFlowDialog] Load contributors success', props<ContributorTableData>());
export const loadFlowContributorsFail = createAction('[dFlowDialog] Load contributors failure', props<DialogToasterData>());
export const saveFlowContributorsSuccess = createAction('[dFlowDialog] Save contributors success', props<{ showSuccessToaster?: boolean }>());
export const saveFlowContributorsFail = createAction('[dFlowDialog] Save contributors failure', props<DialogToasterData>());
